import React from "react";

import MessageContent from "./MessageContent";
import UserThumbnail from "../../../../../../Common/UserThumbnail";

const MessageCardContainer = ({
  message,
  ticketId,
  isArchived,
  messageContenttoShow,
  showOriginal,
  setshowOriginal,
  toolTipRef,
  showTooltip,
  setshowTooltip,
  tooltipCoordinates,
  setTooltipCoordinates,
  meSending,
  setMeSending,
}) => {
  return (
    <div
      id={`messageId-${message.id}`}
      className={`d-flex gap-2 px-2 w80 ${
        meSending ? "flex-row-reverse align-self-end" : ""
      }`}
    >
      <div className="align-self-end mb-4">
        <UserThumbnail
          userId={message.attributes.sender?.data?.id}
          connected={message.attributes.sender?.data?.attributes?.connected}
          lastConnected={
            message.attributes.sender?.data?.attributes?.lastConnected
          }
          apiImg={
            message.attributes.sender?.data?.attributes?.avatar?.data
              ?.attributes?.image?.data?.attributes?.url
          }
          img={`user${
            message.attributes.sender?.data?.attributes?.genre || "3"
          }`}
          size={40}
        />
      </div>

      <MessageContent
        ticketId={ticketId}
        isArchived={isArchived}
        message={message}
        messageContenttoShow={messageContenttoShow}
        showOriginal={showOriginal}
        setshowOriginal={setshowOriginal}
        toolTipRef={toolTipRef}
        showTooltip={showTooltip}
        setshowTooltip={setshowTooltip}
        tooltipCoordinates={tooltipCoordinates}
        setTooltipCoordinates={setTooltipCoordinates}
        meSending={meSending}
        setMeSending={setMeSending}
      />
    </div>
  );
};

export default MessageCardContainer;

import React, { useEffect, useState } from "react";
import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import useWindowWidth from "../../Hooks/useWindowWidth";
import DiffusionItem from "../../Components/Diffusion/DiffusionItem";
import { openModal } from "../../REDUX/actions/modal";
import DiffusionForm from "../../Components/Diffusion/DiffusionForm";
import { useDispatch, useSelector } from "react-redux";
import EmptyList from "../../Components/Common/EmptyList";

const DiffusionView = () => {
  const screenWidth = useWindowWidth();
  const dispatch = useDispatch();
  const { diffusions } = useSelector((state) => state.alertsReducer);
  const { canCreateDiffusion } = useSelector((state) => state.roleReducer);
  const [filteredDiffusion, setFilteredDiffusion] = useState(diffusions);
  const [searchDiffusion, setSearchDiffusion] = useState("");
  useEffect(() => {
    const filteredData = diffusions?.filter((el) => {
      const titleMatch = searchDiffusion
        ? el?.attributes?.titre
            ?.toLowerCase()
            ?.includes(searchDiffusion?.toLowerCase())
        : true;
      const descriptionMatch = searchDiffusion
        ? el?.attributes?.description
            ?.toLowerCase()
            ?.includes(searchDiffusion?.toLowerCase())
        : true;
      const imageNameMatch = searchDiffusion
        ? el?.attributes?.image?.data?.attributes?.name
            ?.toLowerCase()
            ?.includes(searchDiffusion?.toLowerCase())
        : true;
      return titleMatch || descriptionMatch || imageNameMatch;
    });
    setFilteredDiffusion(filteredData);
  }, [searchDiffusion, diffusions]);
  return (
    <div className="container-fluid h100">
      <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className={`${canCreateDiffusion ? "col-10" : "col-12"} h100`}>
          <ListHeader showSocityFilter={false}>
            <SearchInput
              placeholder="Search..."
              onChange={(e) => {
                setSearchDiffusion(e.target.value);
              }}
            />
          </ListHeader>
        </div>
        {canCreateDiffusion ? (
          <div className="col-2 ms-2 h100">
            <ColoredButton
              height="100"
              textButton={screenWidth > 600 && "Ajouter une diffusion"}
              icon={screenWidth < 600 && "plus"}
              color="light-blue"
              callback={() => {
                dispatch(
                  openModal({
                    type: "small",
                    content: <DiffusionForm />,
                  })
                );
              }}
            />
          </div>
        ) : null}
      </div>

      {diffusions.length === 0 ? (
        <EmptyList type="diffusion" />
      ) : (
        <div className="row  p-0">
          {filteredDiffusion.map((item) => {
            return (
              <DiffusionItem
                data={item?.attributes}
                id={item.id}
                key={item.id}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DiffusionView;

import React from "react";
import { useSelector } from "react-redux";

import Loading from "../../Common/Loading";
import SocietyDetails from "./SocietyDetails";
import SocietyUsers from "./SocietyUsers";

const SocietySideBar = ({ societe, isToggle }) => {
  const { societeLoading } = useSelector((state) => state.societesReducer);

  return societeLoading || !societe ? (
    <Loading />
  ) : (
    <div className="d-flex flex-column justify-content-between h100 sideChatListContainer">
      {isToggle === "infos" && <SocietyDetails society={societe} />}
      {(isToggle === "collaborators" || isToggle === "operators") && (
        <SocietyUsers
          society={societe}
          users={
            isToggle === "collaborators"
              ? societe?.attributes.workInOrg?.data
              : societe?.attributes.responsibleOperatorsOrg?.data
          }
        />
      )}
    </div>
  );
};

export default SocietySideBar;

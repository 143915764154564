import React from "react";

import NotificationCard from "./NotificationCard";
import LightDarkText from "../../Common/LightDarkText";

const NotificationList = ({ show, notifications }) => {
  return (
    <>
      {notifications?.length > 0 ? (
        (!notifications.filter(
          (notification) => !notification?.attributes?.read
        ).length
          ? notifications.slice(0, 15)
          : notifications
        ).map((el, index) => (
          <NotificationCard
            key={index}
            index={index}
            notification={{ id: el?.id, ...el?.attributes }}
            show={show}
          />
        ))
      ) : (
        <LightDarkText className="f14 h100 alignC text-center">
          Vous n'avez pas de notifications
        </LightDarkText>
      )}
    </>
  );
};

export default NotificationList;

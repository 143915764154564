import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "./LightDarkText";
import { useSelector } from "react-redux";

const InfoQuestion = ({
  htmlMessage,
  position = "relative",
  top = 25,
  right = 25,
  small = false,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <OverlayTrigger
      style={{ zIndex: 100 }}
      placement="bottom"
      overlay={
        <Tooltip style={{ zIndex: 10000 }}>
          <LightDarkText className="custom-font-size py-1 text-white">
            <span dangerouslySetInnerHTML={{ __html: htmlMessage }}></span>
          </LightDarkText>
        </Tooltip>
      }
    >
      <div
        className={`pointer alignC t-02 ${
          small ? "smallQuestionBox" : "mediumQuestionBox"
        } radius50 ${
          mode === "light"
            ? "text-light-mode bg-dark-blue"
            : "text-dark-mode bg-light-blue-grey"
        }`}
        style={
          position === "absolute"
            ? { position: position, top: top, right: right }
            : {}
        }
      >
        ?
      </div>
    </OverlayTrigger>
  );
};

export default InfoQuestion;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TableList from "../../Components/Common/TableComponents/TableList";
import TicketRow from "../../Components/Tickets/TicketRow";

import { ticketTableData } from "../../Utils/tableStaticData";
import { sortFunction } from "../../Utils/utils";
import EmptyList from "../../Components/Common/EmptyList";

const TicketsView = () => {
  const { tickets, ticketsLoading, filteredTickets } = useSelector(
    (state) => state.ticketsReducer
  );
  const { showUnreadTickets } = useSelector((state) => state.filtersReducer);
  const { notificationsLloading } = useSelector(
    (state) => state.notificationsReducer
  );
  const { ticketHandleLogic } = useSelector(
    (state) => state.organisationsReducer
  );

  const [sortTicket, setsortTicket] = useState({ champs: "id", asc: false });

  const filteredTableListData =
    ticketHandleLogic !== 2
      ? ticketTableData(ticketHandleLogic)
      : ticketTableData(ticketHandleLogic).filter((item) => item.alwaysVisible);
  const [unreadTickets, setUnreadTickets] = useState([]);

  useEffect(() => {
    if (showUnreadTickets) {
      setUnreadTickets(
        filteredTickets.filter((ticket) => {
          return ticket?.attributes?.notifications?.data?.some(
            (notification) => !notification?.attributes.read
          );
        })
      );
    } else {
      setUnreadTickets(filteredTickets);
    }
  }, [showUnreadTickets, filteredTickets]);

  useEffect(() => {
    if (sortTicket.champs) {
      sortFunction(filteredTickets, sortTicket.champs, sortTicket.asc);
    }
  }, [tickets, sortTicket.champs, sortTicket.asc]);

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between p-0">
        <TableList
          headerItems={filteredTableListData}
          setSort={setsortTicket}
          sort={sortTicket}
          hasTooltip={false}
          loading={ticketsLoading}
        >
          {unreadTickets.length === 0 && !ticketsLoading ? (
            <EmptyList type="ticket" />
          ) : (
            unreadTickets.map((ticket, index) => (
              <TicketRow
                ticket={ticket}
                tableList={filteredTableListData}
                isLoading={ticketsLoading || notificationsLloading}
                key={index}
                index={index}
              />
            ))
          )}
        </TableList>
      </div>
    </div>
  );
};

export default TicketsView;

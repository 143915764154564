import { gql } from "@apollo/client";
import { USER_ORG_ATTRIBUTES } from "../Attributes/UserOrg";
import { userAttributes } from "../general/userFields";

export const GET_NOTIFICATION = gql`
  query ($id: ID!, $orgId: ID!) {
    notification(id: $id) {
      data {
        id
        attributes {
          type
          read
          redirection
          createdAt
          user {
            data {
              id
              attributes {
                ${userAttributes}
              }
            }
          }
          ticket {
            data {
              id
              attributes {
                name
                archived
                statut
                societe {
                  data {
                    id
                    attributes {
                      name
                      avatar {
                        data {
                          id
                          attributes {
                            image {
                              data {
                                id
                                attributes {
                                  url
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          from {
            data {
              id
              attributes {
                ${userAttributes}
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                role {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                workIn {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                user_org_permissions (
                  filters: {
                    organisation: { id: { eq: $orgId} }  
                  }
                ) 
                {
                  data {
                    id
                    attributes {
                      ${USER_ORG_ATTRIBUTES}
                    }
                  }
                }
              }
            }
          }
          intervention {
            data {
              id
              attributes {
                title
                statut
                dateDebut
                duration
                createdAt
                oldCreatedAt
                societe {
                  data {
                    id
                    attributes {
                      name
                      avatar {
                        data {
                          id
                          attributes {
                            image {
                              data {
                                id
                                attributes {
                                  url
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          folder {
            data {
              id
              attributes {
                name
              }
            }
          }
          fichier {
            data {
              id
              attributes {
                file {
                  data {
                    id
                    attributes {
                      url
                      name
                      mime
                    }
                  }
                }
              }
            }
          }
          societe {
            data {
              id
              attributes {
                name
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          calendar {
            data {
              id
              attributes {
                name
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          event {
            data {
              id
              attributes {
                name
                calendar {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          action {
            data {
              id
              attributes {
                title
                statut
                by {
                  data {
                    id
                    attributes {
                      ${userAttributes}
                      user_org_permissions(pagination: { pageSize: 1000 }) {
                        data {
                          id
                          attributes {
                            organisation {
                              data {
                                id
                                attributes {
                                  name
                                }
                              }
                            }
                            cloud {
                              data {
                                id
                              }
                            }
                            notification_setting {
                              data {
                                id
                                attributes {
                                  ticketCreation
                                  ticketResolved
                                  ticketArchive
                                  message
                                  intervention
                                  cloudShare
                                  fileImport
                                  collaboratorAdded
                                  profileMention
                                  packageExceeded
                                  prospectActions
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          facture {
            data {
              id
              attributes {
                type
              }
            }
          }
        }
      }
    }
  }
`;

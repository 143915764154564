import React from "react";
import {
  transformDataToMultiLines,
  transformticketsToStatsPie,
} from "../Charts/chartsUtils";
import PieChart from "../Charts/PieChart";
import MultipleLineChart from "../Charts/MultipleLineChart";
import { useSelector } from "react-redux";

const PropetctsStats = () => {
  const { stats } = useSelector((state) => state.statsReducer);

  const prospectsPerOrigin = transformticketsToStatsPie(
    stats?.prospectsStats?.prospectByOrigin
  );
  const devisPerOrigin = transformticketsToStatsPie(
    stats?.prospectsStats?.devisByOrigin
  );
  const commandesPerOrigin = transformticketsToStatsPie(
    stats?.prospectsStats?.commandesByOrigin
  );
  const commandeStatus1 = transformDataToMultiLines(
    stats.prospectsStats?.commandeStatus1,
    "#FF721B",
    "Archivées"
  );
  const commandeStatus2 = transformDataToMultiLines(
    stats.prospectsStats?.commandeStatus2,
    "#3365FF",
    "Validées"
  );
  const commandeStatus3 = transformDataToMultiLines(
    stats.prospectsStats?.commandeStatus3,
    "#FFBC20",
    "En attente"
  );

  const devisStatus1 = transformDataToMultiLines(
    stats.prospectsStats?.devisStatus1,
    "#FF721B",
    "Archivées"
  );
  const devisStatus2 = transformDataToMultiLines(
    stats.prospectsStats?.devisStatus2,
    "#3365FF",
    "attente d'affectation revendeur"
  );
  const devisStatus3 = transformDataToMultiLines(
    stats.prospectsStats?.devisStatus3,
    "#0CCE91",
    "attente validation client"
  );

  const prospectStatus1 = transformDataToMultiLines(
    stats.prospectsStats?.prospectStatus1,
    "#3365FF",
    "attente validation admin"
  );
  const prospectStatus2 = transformDataToMultiLines(
    stats.prospectsStats?.prospectStatus2,
    "#0CCE91",
    "attente validation commercial"
  );
  const prospectStatus3 = transformDataToMultiLines(
    stats.prospectsStats?.prospectStatus3,
    "#FFBC20",
    "attente validation revendeur"
  );
  const prospectStatus4 = transformDataToMultiLines(
    stats.prospectsStats?.prospectStatus4,
    "#FF721B",
    "Archivées"
  );
  return (
    <>
      <div className={`statisticsLine alignH gap-2`}>
        <div className="w70 h100">
          <MultipleLineChart
            title="Commandes les 12 derniers mois"
            dataSets={[commandeStatus2, commandeStatus3, commandeStatus1]}
          />
        </div>
        <div className="w30">
          <PieChart
            title={"Répartition des commandes par origine"}
            infos={commandesPerOrigin}
          />
        </div>
      </div>
      <div className={`statisticsLine alignH gap-2`}>
        <div className="w30">
          <PieChart
            title={"Répartition des devis par origine"}
            infos={devisPerOrigin}
          />
        </div>
        <div className="w70 h100">
          <MultipleLineChart
            title="Devis les 12 derniers mois"
            dataSets={[devisStatus2, devisStatus3, devisStatus1]}
          />
        </div>
      </div>
      <div className={`statisticsLine alignH gap-2`}>
        <div className="w70 h100">
          <MultipleLineChart
            title="Prospets les 12 derniers mois"
            dataSets={[
              prospectStatus1,
              prospectStatus2,
              prospectStatus3,
              prospectStatus4,
            ]}
          />
        </div>
        <div className="w30">
          <PieChart
            title={"Répartition des prospets par origine"}
            infos={prospectsPerOrigin}
          />
        </div>
      </div>
    </>
  );
};

export default PropetctsStats;

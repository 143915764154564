import moment from "moment";

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Thumbnail from "../Common/Thumbnail";
import LightDarkText from "../Common/LightDarkText";
import StatusButton from "../Common/Buttons/StatusButton";
import LightDarkContainer from "../Common/LightDarkContainer";
import { DeleteIcon, EditIcon, EyeIcon } from "../Common/LightDarkSvg";
import InterventionForm from "./InterventionForm/InterventionForm";
import TooltipActions from "../Common/Tooltip/TooltipActions";

import { getValueByAccessor } from "../../Utils/utils";
import { handleDuration } from "../Tickets/ticketUtils";
import { handleInterventionStatut } from "./interventionUtils";

import useWindowWidth from "../../Hooks/useWindowWidth";

import { openSideBarCard } from "../../REDUX/actions/general";
import { openModal, closeModal } from "../../REDUX/actions/modal";
import { deleteIntervention } from "../../REDUX/actions/interventions";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";

const InterventionsCard = ({
  intervention,
  index,
  tableList,
  selectedItem,
  setSelectedItem,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const screenWidth = useWindowWidth();
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { canCreateInterventions } = useSelector((state) => state.roleReducer);
  const { interventionRoute, selectedOrganisation, dashboardRoute } =
    useSelector((state) => state.organisationsReducer);

  const [showTooltip, setshowTooltip] = useState(false);

  const showInterventionDetails = () => {
    if (location.pathname === "/") {
      navigate(interventionRoute);
    }
    setSelectedItem(intervention);
    dispatch(openSideBarCard());
  };

  const tooltipData = [
    {
      id: 1,
      text: "Voir les détails",
      icon: <EyeIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        showInterventionDetails();
      },
      condition: true,
    },
    {
      id: 2,
      text: "Éditer",
      icon: <EditIcon className="me-2" />,
      action: () => {
        dispatch(
          openModal({
            type: "small",
            preventClose: true,
            content: (
              <InterventionForm
                societeId={intervention.attributes.societe.data.id}
                relatedTicket={intervention.attributes.ticket}
                intervention={intervention}
              />
            ),
          })
        );
        setshowTooltip(false);
      },
      condition:
        ![4, 5, 7].includes(intervention.attributes.statut) &&
        canCreateInterventions,
    },
    {
      id: 3,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(deleteIntervention(intervention));
              dispatch(closeModal());
            },
            message: `Voulez vous vraiment supprimer l'intervention ${intervention.attributes.title}?`,
            message2:
              "En confirmant la suppression, l'intervention sera définitivement supprimée.",
            confirmButtonText: "Je confirme la suppression",
          })
        );
      },
      condition: intervention.attributes.statut === 1 && canCreateInterventions,
    },
  ];

  return (
    <LightDarkContainer
      key={index}
      index={index}
      className={`${mode === "light" ? "lightListCard" : "darkListCard"}
      ${
        selectedItem?.id === intervention?.id &&
        location.pathname === interventionRoute &&
        (mode === "light" ? "lightListCardFocus" : "darkListCardFocus")
      } d-flex py-2 px-3 w-100 pointer`}
      colorLight="white"
      colorDark="dark-grey-bg"
      hover={true}
      cardContainer={true}
      callBack={() =>
        location.pathname === `${dashboardRoute}`
          ? () => {}
          : showInterventionDetails()
      }
    >
      <div className="w-100 d-flex">
        {tableList?.map(({ widthSideBar, width, id, accessor }) => {
          const columnCol = false ? widthSideBar : width;

          return (
            <div
              className={` alignH w${columnCol} p-0  
                
            `}
              key={id}
            >
              {accessor === "status" ? (
                <div className="w-100  ">
                  <StatusButton
                    height={screenWidth < 1200 ? "30px" : "40px"}
                    type={
                      handleInterventionStatut(intervention.attributes.statut)
                        .color
                    }
                    text={
                      handleInterventionStatut(intervention.attributes.statut)
                        .text
                    }
                    background={
                      handleInterventionStatut(intervention.attributes.statut)
                        .bg
                    }
                  />
                </div>
              ) : accessor === "duration" &&
                selectedOrganisation.attributes.interventionLogic !== 2 ? (
                <div className={` `}>
                  <LightDarkText
                    className={
                      "custom-ticket-font-size bold text-start text-ellipsis"
                    }
                    color={
                      mode === "light" ? "text-blue-grey" : "text-light-text"
                    }
                  >
                    {handleDuration(intervention.attributes?.duration)}
                  </LightDarkText>
                </div>
              ) : accessor === "stringCreatedAt" ? (
                <div
                  className={`
              `}
                >
                  <LightDarkText
                    className={
                      "custom-ticket-font-size bold text-start text-ellipsis"
                    }
                    color={
                      mode === "light" ? "text-blue-grey" : "text-light-text"
                    }
                  >
                    {moment(intervention.attributes.stringCreatedAt).format(
                      "DD.MM.YYYY"
                    )}
                  </LightDarkText>
                </div>
              ) : (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        {getValueByAccessor(intervention, accessor)}
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div className={` d-flex align-items-center w95  `}>
                    {accessor.includes("stringSociety") && (
                      <Thumbnail
                        size={screenWidth < 1200 ? 30 : 40}
                        apiImg={
                          intervention.attributes?.societe?.data?.attributes
                            ?.avatar?.data?.attributes?.image?.data?.attributes
                            ?.url
                        }
                        img={"society"}
                        rounded
                      />
                    )}
                    <LightDarkText
                      className={`custom-ticket-font-size  ${
                        accessor.includes("stringSociety")
                          ? "bolder ms-1"
                          : "bold"
                      } text-start text-ellipsis`}
                      color={
                        accessor.includes("stringSociety") && mode === "light"
                          ? "text-dark-blue"
                          : accessor.includes("stringSociety") &&
                            mode !== "light"
                          ? "text-white"
                          : mode === "light"
                          ? "text-blue-grey"
                          : "text-light-text"
                      }
                    >
                      {accessor.includes("id") ? "#" : null}
                      {getValueByAccessor(intervention, accessor)}
                    </LightDarkText>
                  </div>
                </OverlayTrigger>
              )}
            </div>
          );
        })}
      </div>
      <div className="d-flex align-items-center justify-content-end">
        {/* <TooltipActions
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
        /> */}
      </div>
    </LightDarkContainer>
  );
};

export default InterventionsCard;

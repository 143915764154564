import { combineReducers } from "redux";
import genearlReducer from "./general.js";
import ticketsReducer from "./tickets.js";
import societesReducer from "./societe.js";
import interventionsReducer from "./intervention";
import operateursReducer from "./operateur";
import facturesReducer from "./facture";
import clientsReducer from "./client";
import notificationsReducer from "./notifications";
import sharedDataReducer from "./sharedData.js";
import confirmationModalReducer from "./confirmationModal.js";
import modalReducer from "./modal.js";
import filesReducer from "./files.js";
import toastMessagesReducer from "./toastMessage.js";
import userReducer from "./user.js";
import filtersReducer from "./filters.js";
import cloudReducer from "./cloud.js";
import socketReducer from "./socket.js";
import planReducer from "./plan.js";
import statsReducer from "./stats.js";
import messagesReducer from "./messages.js";
import alertsReducer from "./alerts.js";
import notificationSettingsReducer from "./notificationSettings.js";
import calendarsReducer from "./calendar.js";
import organisationsReducer from "./organisations.js";
import formCategoriesReducer from "./formCategories.js";
import formQuestionsReducer from "./formQuestions.js";
import prospectReducer from "./prospect.js";
import departmentReducer from "./department.js";
import devisReducer from "./devis.js";
import commandeReducer from "./commande.js";
import roleReducer from "./role.js";
import externalServicesReducer from "./externalServices.js";
import loginViewDataReducer from "./loginViewDataReducer.js";
const appReducer = combineReducers({
  genearlReducer,
  loginViewDataReducer,
  cloudReducer,
  userReducer,
  roleReducer,
  filtersReducer,
  modalReducer,
  confirmationModalReducer,
  filesReducer,
  toastMessagesReducer,
  ticketsReducer,
  externalServicesReducer,
  societesReducer,
  interventionsReducer,
  operateursReducer,
  facturesReducer,
  clientsReducer,
  notificationsReducer,
  sharedDataReducer,
  socketReducer,
  planReducer,
  statsReducer,
  messagesReducer,
  alertsReducer,
  notificationSettingsReducer,
  calendarsReducer,
  organisationsReducer,
  formCategoriesReducer,
  formQuestionsReducer,
  prospectReducer,
  departmentReducer,
  devisReducer,
  commandeReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import FormHeader from "../../Common/Forms/FormHeader";
import MenuTabs from "../../Common/MenuTabs";

import { UserFormTabs } from "./userFormUtils";

const UserFormHeader = ({
  isNew,
  client,
  isToggle,
  setIsToggle,
  isOp,
  clientInputs,
  emailInfo,
}) => {
  const { user } = useSelector((state) => state.userReducer);
  const {
    canModifySupervisorPermissions,
    canModifyAdminPermissions,
    canCreateCollabs,
    canCreateOperators,
  } = useSelector((state) => state.roleReducer);

  const [inputRole, setinputRole] = useState(clientInputs?.role[0]?.value);

  useEffect(() => {
    setinputRole(clientInputs?.role[0]?.value);
  }, [clientInputs]);

  return (
    <>
      <FormHeader
        formTitle={
          isNew
            ? `Ajouter un ${isOp ? "opérateur" : "client"}`
            : user.id === client?.id
            ? "Mon profil"
            : `Éditer ${isOp ? "l'opérateur" : "le client"}`
        }
      />
      <div className="d-flex justify-content-start align-items-center pb-3">
        {UserFormTabs(
          isNew,
          emailInfo,
          user,
          client,
          inputRole,
          canModifySupervisorPermissions,
          canCreateOperators,
          canModifyAdminPermissions,
          canCreateCollabs,
          clientInputs
        )?.map((tab, index) =>
          !tab.hideTab ? (
            <MenuTabs
              key={index}
              tabTitle={tab.title}
              toggleValue={tab.toggleValue}
              isToggle={isToggle}
              setIsToggle={() => setIsToggle(tab.toggleValue)}
              isVisible={tab.isVisible}
            />
          ) : null
        )}
      </div>
    </>
  );
};

export default UserFormHeader;

import React, { useState } from "react";
import { useSelector } from "react-redux";

import LightDarkText from "../../Common/LightDarkText";
import SwitchItem from "../../Layout/Notification/SwitchItem";
import { handlPermissionsList } from "./permissionList";
import UserPermissionGroup from "./UserPermissionGroup";

const UserPermissionForm = ({ values, setValues, role }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const organisationsReducer = useSelector(
    (state) => state.organisationsReducer
  );

  const [disabled, setdisabled] = useState(true);

  return (
    <div className={`my-3 flex-1 d-flex flex-column justify-content-between`}>
      <LightDarkText
        className="f11 italic"
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        Les permissions affichées ici représentent les paramètres par défaut
        pour le rôle sélectionné. Vous pouvez les personnaliser selon les
        besoins spécifiques de votre organisation.
      </LightDarkText>
      <div className="w50 mt-3 mb-5">
        <SwitchItem
          label="Personaliser les permissions"
          value={!disabled}
          onChange={() => setdisabled(!disabled)}
        />
      </div>
      <div className="row gap-4">
        {handlPermissionsList(organisationsReducer, role).map((list, index) => (
          <UserPermissionGroup
            list={list}
            key={index}
            disabled={disabled}
            values={values}
            setValues={setValues}
          />
        ))}
      </div>
    </div>
  );
};

export default UserPermissionForm;

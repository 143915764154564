import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardsIcon, ListIcon, SortIcon } from "../Common/LightDarkSvg";
import EmptyList from "../Common/EmptyList";
import LightDarkText from "../Common/LightDarkText";
import AttachementCardCard from "./AttachementCardCard";
import AttachementListCard from "./AttachementListCard";
import useOnScreen from "../../Hooks/useScreen";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { getAttachement } from "../../REDUX/actions/cloud";
import Loading from "../Common/Loading";
import TableEltPlaceHolder from "../Common/TableComponents/TableEltPlaceHolder";
import LightDarkContainer from "../Common/LightDarkContainer";

const LazyAttachementList = ({ search }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { ticketsAttchment, totalTicketsAttchment, ticketsAttchmentLoading } =
    useSelector((state) => state.cloudReducer);
  const [list, setlist] = useState(false);
  const [sortFiles, setsortFiles] = useState({ champs: "id", asc: false });
  const screenWidth = useWindowWidth();
  const [page, setPage] = useState(1);

  const handleTicketMode = () => {
    setlist(!list);
  };

  const sortString = () => {
    return `${sortFiles.champs}:${sortFiles.asc ? "asc" : "desc"}`;
  };

  const { measureRef, isIntersecting, observer } = useOnScreen();

  useEffect(() => {
    if (isIntersecting) {
      if (totalTicketsAttchment <= page * 10) {
        observer.disconnect();
        return;
      }
      setPage((page) => page + 1);
      dispatch(getAttachement(user, page + 1, 10, search, sortString())); //yosri refetch on bottom of the page
      // observer.disconnect();
    }
  }, [isIntersecting, dispatch, observer]);

  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_ATTACHMENTS" });
      setPage(1);
    };
  }, [dispatch]);

  useEffect(() => {
    const getAttach = () => {
      setPage(1);
      dispatch(getAttachement(user, 1, 10, search, sortString())); //yosri refetch on bottom of the page
    };
    return getAttach();
  }, [search, sortFiles, dispatch]);
  return (
    <div style={{ height: "400px" }}>
      <div className="row">
        <div className="col-10">
          {ticketsAttchment?.length !== 0 && (
            <LightDarkText className="custom-title-font-size bold text-start mt-4 mb-2">
              Pièces jointes des tickets
            </LightDarkText>
          )}
        </div>
        {ticketsAttchment?.length !== 0 && (
          <div className="col-2">
            {ticketsAttchment?.length !== 0 && (
              <div className="alignH justify-content-end">
                <div className=" d-flex align-items-center justify-content-end gap-3 mb-1">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <LightDarkText className="custom-ticket-font-size py-1 text-white">
                          Grille
                        </LightDarkText>
                      </Tooltip>
                    }
                  >
                    <div onClick={handleTicketMode}>
                      <CardsIcon
                        selected={list}
                        size={screenWidth < 1200 ? 22 : 24}
                      />
                    </div>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <LightDarkText className="custom-ticket-font-size py-1 text-white">
                          Liste
                        </LightDarkText>
                      </Tooltip>
                    }
                  >
                    <div onClick={handleTicketMode}>
                      <ListIcon
                        selected={!list}
                        size={screenWidth < 1200 ? 22 : 24}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {/* <EmptyList type="pj" /> */}
      {/* ticketsAttchment?.length === 0 && !ticketsAttchmentLoading */}
      {
        <div className="mt-2 mb-4 p-0 radius05">
          {!list && (
            <div
              className={`d-flex py-3 px-3 ${
                mode === "light" ? "bg-white" : "bg-dark-grey-bg"
              }`}
              style={{
                position: "sticky",
                top: 0,
              }}
            >
              <div className="alignH col-1 w5 ">
                <LightDarkText
                  className={
                    "pointer fitContent custom-ticket-font-size bold text-start"
                  }
                  color="text-light-text"
                  onclickCallback={() =>
                    setsortFiles({ champs: "id", asc: !sortFiles.asc })
                  }
                >
                  ID
                </LightDarkText>
                <SortIcon />
              </div>

              <div className="col-1 alignH p-0 w5 ">
                <LightDarkText
                  className={
                    "pointer fitContent custom-ticket-font-size bold text-start"
                  }
                  color="text-light-text"
                  onclickCallback={() =>
                    setsortFiles({
                      champs: "ticket.id",
                      asc: !sortFiles.asc,
                    })
                  }
                >
                  Id ticket
                </LightDarkText>
                <SortIcon />
              </div>

              <div className="col-2 alignH p-0 text-start ">
                <LightDarkText
                  className="pointer fitContent custom-ticket-font-size bold text-start"
                  color="text-light-text"
                  onclickCallback={() =>
                    setsortFiles({
                      champs: "ticket.name",
                      asc: !sortFiles.asc,
                    })
                  }
                >
                  Ticket
                </LightDarkText>
                <SortIcon />
              </div>
              <div className="col-2 alignH p-0 ">
                <LightDarkText
                  className="pointer fitContent custom-ticket-font-size bold text-start"
                  color="text-light-text"
                  onclickCallback={() =>
                    setsortFiles({
                      champs: "ticket.societe.name",
                      asc: !sortFiles.asc,
                    })
                  }
                >
                  Société
                </LightDarkText>
                <SortIcon />
              </div>

              <div className="col-3 alignH p-0 ">
                <LightDarkText
                  className="fitContent custom-ticket-font-size bold text-start"
                  color="text-light-text"
                >
                  Nom
                </LightDarkText>
                {/* <SortIcon  /> */}
              </div>
              <div className={`col-2 alignH p-0 `}>
                <LightDarkText
                  className="pointer fitContent custom-ticket-font-size bold text-start"
                  color="text-light-text"
                  onclickCallback={() =>
                    setsortFiles({ champs: "createdAt", asc: !sortFiles.asc })
                  }
                >
                  Date
                </LightDarkText>
                <SortIcon />
              </div>
              <div className={`col-1 alignC p-0 `}>
                <LightDarkText
                  className="fitContent custom-ticket-font-size bold text-start"
                  color="text-light-text"
                >
                  Taille
                </LightDarkText>
                {/* <SortIcon /> */}
              </div>
            </div>
          )}

          <div className="fileListContainer position-relative">
            <div className={`${list ? "gx-3 gx-xl-4 row" : ""}`}>
              {ticketsAttchment?.length === 0 && !ticketsAttchmentLoading ? (
                <EmptyList type="pj" />
              ) : ticketsAttchmentLoading ? (
                <LightDarkContainer
                  className="py-2"
                  colorDark="dark-grey-bg"
                  colorLight="white"
                >
                  <TableEltPlaceHolder />
                </LightDarkContainer>
              ) : (
                ticketsAttchment.map((fichier, index) =>
                  list ? (
                    <AttachementCardCard
                      key={index}
                      fichier={fichier}
                      shared={fichier?.attributes?.sharedUsers?.data.length}
                    />
                  ) : (
                    <AttachementListCard
                      key={index}
                      index={index}
                      fichier={fichier}
                    />
                  )
                )
              )}
            </div>
          </div>
          <div
            ref={totalTicketsAttchment > page * 10 ? measureRef : null}
            className="opacity-0"
          >
            lazy pointer
          </div>
          {ticketsAttchmentLoading && (
            <div className="my-3 position-relative py-3">
              <Loading />
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default LazyAttachementList;

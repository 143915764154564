import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LightDarkContainer from "../../Common/LightDarkContainer";
import TitleImageBlock from "../TicketCommonComponents/TitleImageBlock";
import TooltipActions from "../../Common/Tooltip/TooltipActions";
import TicketForm from "../TicketForm/TicketForm";
import DateText from "../TicketCommonComponents/DateText";
import MessageRightBottomPannel from "./MessageRightBottomPannel";
import TicketInfoBlock from "./TicketInfoBlock";
import {
  DeleteIcon,
  EditIcon,
  NotificationNew,
  ReopenIcon,
  ResolveIcon,
} from "../../Common/LightDarkSvg";

import { ticketInfosData } from "../ticketUtils";

import { markAsNotReadNotification } from "../../../REDUX/actions/notifications";
import { toggleArchive, updateTicket } from "../../../REDUX/actions/tickets";
import { openConfirmationModal } from "../../../REDUX/actions/confirmationModal";
import { openModal, closeModal } from "../../../REDUX/actions/modal";
import { handleError } from "../../../REDUX/actions/toastMessage";

import useWindowWidth from "../../../Hooks/useWindowWidth";

import "./MessagesRightPanel.css";

const MessagesRightPanel = () => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const navigate = useNavigate();

  const { canArchiveTickets, isSupervisor, canResolveTickets } = useSelector(
    (state) => state.roleReducer
  );
  const { ticket, ticketsLoading } = useSelector(
    (state) => state.ticketsReducer
  );
  const { ticketRoute } = useSelector((state) => state.organisationsReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { messagesLoading } = useSelector((state) => state.messagesReducer);
  const { ticketHandleLogic } = useSelector(
    (state) => state.organisationsReducer
  );

  const [showTooltip, setshowTooltip] = useState(false);
  const [canbeResolved, setcanbeResolved] = useState(
    !ticket?.attributes?.interventions?.data?.filter(
      (intervention) =>
        intervention?.attributes?.statut !== 5 &&
        intervention?.attributes?.statut !== 4
    ).length
  );
  useEffect(() => {
    setcanbeResolved(
      !ticket?.attributes?.interventions?.data?.filter(
        (intervention) =>
          intervention?.attributes?.statut !== 5 &&
          intervention?.attributes?.statut !== 4
      ).length
    );
  }, [ticket, dispatch]);

  const resolveTicket = () => {
    setshowTooltip(false);
    dispatch(
      updateTicket(
        {
          id: ticket?.id,
          statut: 2,
        },
        true,
        user?.id
      )
    );
  };

  const tooltipData = [
    {
      id: 0,
      text: "Réouvrir",
      icon: <ReopenIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          dispatch(
            updateTicket(
              {
                id: ticket?.id,
                statut: 1,
              },
              true,
              user?.id
            )
          )
        );
      },
      condition:
        ticket?.attributes?.statut === 2 &&
        isSupervisor &&
        !ticket?.attributes?.archived,
    },
    {
      id: 1,
      text: "Éditer",
      icon: <EditIcon className="me-2" />,
      action: () => {
        dispatch(
          openModal({
            type: "small",
            content: <TicketForm ticket={ticket} />,
          })
        );
        setshowTooltip(false);
      },
      condition:
        ticket?.attributes?.statut !== 2 &&
        isSupervisor &&
        !ticket?.attributes?.archived,
    },
    {
      id: 2,
      text: ticket?.attributes?.archived ? "Désarchiver" : "Archiver",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        ticket?.attributes?.archived
          ? dispatch(
              openConfirmationModal({
                callBack: () => {
                  dispatch(
                    toggleArchive(ticket?.id, (newId) =>
                      navigate(`${ticketRoute}/${newId}`)
                    )
                  );
                  dispatch(closeModal());
                },
                message: "Êtes-vous sur de vouloir désarchiver ce ticket?",
                message2: `#${ticket?.id} | ${ticket?.attributes?.name}`,
                confirmButtonText: "Désarchiver le ticket",
              })
            )
          : dispatch(
              openConfirmationModal({
                callBack: () => {
                  dispatch(
                    toggleArchive(ticket?.id, (newId) =>
                      navigate(`${ticketRoute}/${newId}`)
                    )
                  );
                  dispatch(closeModal());
                },
                message: "Êtes-vous sur de vouloir archiver ce ticket?",
                message2: `#${ticket?.id} ${ticket?.attributes?.name}`,
                confirmButtonText: "Archiver le ticket",
              })
            );
      },
      condition: canArchiveTickets,
    },
    {
      id: 3,
      text: "Résoudre",
      icon: <ResolveIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        canbeResolved
          ? resolveTicket()
          : dispatch(
              handleError(
                "Vous ne pouvez pas résoudre le ticket car des interventions sont en cours de traitements.",
                1
              )
            );
      },
      condition:
        ticket?.attributes?.statut !== 2 &&
        canResolveTickets &&
        !ticket?.attributes?.archived,
    },
    {
      id: 4,
      text: "Non lu",
      icon: (
        <NotificationNew tichetNotification color="#354074" className="me-1" />
      ),
      action: () => {
        setshowTooltip(false);
        dispatch(
          markAsNotReadNotification(
            ticket?.attributes?.notifications?.data[0]?.id,
            ticket?.id
          )
        );
      },
      condition:
        // ticket?.attributes?.notifications?.data.length !== 0 &&
        !ticket?.attributes?.archived,
    },
  ];

  return ticketsLoading || !ticket.attributes ? null : (
    <div className={`d-flex flex-column gap-2 col-3 h100`}>
      <LightDarkContainer
        className={`radius05 ${screenWidth < 1200 ? "p-3" : " py-4 px-3"}`}
        colorLight="white"
        colorDark="dark-grey-bg"
      >
        <div className="d-flex w-100 align-items-start justify-content-between">
          <TitleImageBlock
            isLoading={messagesLoading}
            avatar={
              ticket?.attributes?.societe?.data?.attributes?.avatar?.data
                ?.attributes?.image?.data?.attributes?.url
            }
            defaultImage="society"
            societyName={ticket?.attributes?.societe?.data?.attributes?.name}
            extraData={<DateText date={ticket?.attributes?.createdAt} />}
          />
          {true ? (
            <div className="w10 p-0 d-flex justify-content-end pointer">
              <TooltipActions
                setshowTooltip={setshowTooltip}
                showTooltip={showTooltip}
                tooltipData={tooltipData}
              />
            </div>
          ) : null}
        </div>
        <TicketInfoBlock
          ticketInfosData={ticketInfosData(
            ticket,
            ticketHandleLogic,
            screenWidth
          )}
          ticket={ticket}
          isLoading={messagesLoading}
        />
      </LightDarkContainer>
      <MessageRightBottomPannel />
    </div>
  );
};

export default MessagesRightPanel;



const initialState = {
  societes: [],
  societe: null,
  societeLoading: true,
  equipe: [],
  societySideBar: false,
  societeStats: {},
};

const societesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_SOCIETE":
      return { ...state, societes: payload, societeLoading: false };

    case "FETCH_NEW_COMPANY":
      return { ...state, societes: [payload, ...state.societes] };
    case "FETCH_SOCIETE_ITEM":
      return {
        ...state,
        societe: payload,
        // equipe: payload.attributes.equipe.data,
        // societeLoading: false,
      };
    case "SOCIETY_CREATED": {
      if (!payload) {
        return {
          ...state,
          societeLoading: false,
        };
      }

      return {
        ...state,
        societes: [payload, ...state.societes],
        societeLoading: false,
      };
    }
    case "SOCIETE_UPDATED":
      return {
        ...state,
        societes: state.societes.map((el) =>
          el.id === payload.id ? payload : el
        ),
        societe: state.societe?.id === payload.id ? payload : state.societe,
        societeLoading: false,
      };
    case "DELETE_SOCIETE":
      return {
        ...state,
        societes: state.societes.filter((item) => item.id !== payload),
        societeLoading: false,
      };
    case "SOCIETE_LOADING":
      return { ...state, societeLoading: true };
    case "SOCIETE_STATS_SUCCESS":
      return { ...state, societeStats: payload };
    default:
      return state;
  }
};
export default societesReducer;

import React, { useEffect, useState } from "react";
import LightDarkText from "../Common/LightDarkText";
import { useDispatch, useSelector } from "react-redux";

const FileUploader = ({
  label,
  required,
  multiple,
  champs,
  totalInputs,
  setValue,
  id,
  extraLabel,
}) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const [filesNames, setfilesNames] = useState(
    "Cliquer ou deplacer vos fichiers dans le cadre"
  );

  const getFileName = (files) => {
    setfilesNames(
      files.length === 1
        ? files[0].name
        : files.length < 1
        ? "Cliquer ou deplacer vos fichiers dans le cadre"
        : `${files.length} fichiers séléctionnés`
    );

    setValue({ ...totalInputs, [champs]: files });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    getFileName(e.dataTransfer.files);
  };
  return (
    <div className="">
      <div className="directionC">
        <LightDarkText
          className={
            " d-flex text-start bold custom-ticket-font-size mb-2 w-100"
          }
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          {label} {required ? <span className="text-red">*</span> : null}
          {extraLabel && (
            <div className="text-light-text ms-1"> {extraLabel}</div>
          )}
        </LightDarkText>
      </div>
      <label
        htmlFor={id}
        className={`attachUploadLabel ${
          mode === "light" ? "lightAttachUploadLabel" : `darkAttachUploadLabel`
        }  alignC`}
        style={{
          height: `${"75px"}`,
          width: "100%",
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div
          className={`attachUploaderLoadingbar`}
          // style={{ width: percentComplete + "%" }}
        ></div>
        <input
          type="file"
          multiple={multiple}
          className="attachUploaderButton"
          id={id}
          onChange={(e) => getFileName(e.target.files)}
        />
        <div
          className={`text-center px-1 attachFilesText custom-ticket-font-size`}
        >
          {filesNames}
        </div>
      </label>
    </div>
  );
};

export default FileUploader;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import SideBar from "../../Components/Common/TableComponents/SideBar";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import InterventionsList from "../../Components/Interventions/InterventionsList";
import InterventionSideBar from "../../Components/Interventions/InterventionSideBar";
import InterventionForm from "../../Components/Interventions/InterventionForm/InterventionForm";

import { interventionSideBarTabs } from "../../Components/Interventions/interventionUtils";

import { openModal } from "../../REDUX/actions/modal";
import { resetInterventionFilters } from "../../REDUX/actions/filters";

const InterventionsView = () => {
  const dispatch = useDispatch();

  const { openSideBar } = useSelector((state) => state.genearlReducer);
  const { canCreateInterventions, isSupervisor } = useSelector(
    (state) => state.roleReducer
  );

  const [selectedItem, setSelectedItem] = useState();
  const [isToggle, setIsToggle] = useState("intervention");
  const [searchInterventions, setsearchInterventions] = useState("");

  useEffect(() => {
    return () => dispatch(resetInterventionFilters());
  }, [dispatch]);

  return (
    <div className="container-fluid">
      <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className={`${canCreateInterventions ? "col-10" : "col-12"} h100`}>
          <ListHeader
            showInterventionFilter
            isInterventionHeader
            showOperatorsFilter={isSupervisor}
          >
            <SearchInput
              placeholder="ID, société, titre, ID ticket..."
              onChange={(e) => {
                setsearchInterventions(e.target.value);
              }}
            />
          </ListHeader>
        </div>
        {canCreateInterventions && (
          <div className="col-2 ms-2 h100">
            <ColoredButton
              height="100"
              textButton={"Créer une intervention"}
              color="light-blue"
              callback={() => {
                dispatch(
                  openModal({
                    type: "small",
                    preventClose: true,
                    content: <InterventionForm isNew={true} />,
                  })
                );
              }}
            />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between gap-3 p-0">
        <InterventionsList
          searchInterventions={searchInterventions}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
        {openSideBar && (
          <SideBar
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            isToggle={isToggle}
            tabsData={interventionSideBarTabs}
            setIsToggle={setIsToggle}
          >
            <InterventionSideBar
              selectedIntervention={selectedItem}
              isToggle={isToggle}
            />
          </SideBar>
        )}
      </div>
    </div>
  );
};

export default InterventionsView;

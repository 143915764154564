import { gql } from "@apollo/client";
import { random } from "../../../Utils/utils";
import {
  ATTRIBUTES_ALL_SOCIETIES,
  ATTRIBUTES_ITEM_SOCIETY,
} from "../Attributes/Societies";

export const CREATE_SOCIETES = (data) => gql`
  mutation {
    createSociete(data: { 
          name: "${data.name}", 
          siret: "${data.siret && data.siret}", 
          phoneNumber: "${data.phoneNumber || ""}", 
          adress: "${data.adress || ""}", 
          city: "${data.city || ""}", 
          zipCode: "${data.zipCode || ""}", 
          email: "${data.email}", 
          siteWeb: "${data.siteWeb}", 
          emailFacture: "${data.emailFacture}",
          monthlyPlan: ${data.monthlyPlan || null}, 
          monthlyQuota: ${data.monthlyQuota || null}, 
          archived: false,
          avatar: ${data.avatar},
          organisation: ${data.organisation},
          creator: ${data?.creator}
          society_type:${data?.society_type || null}
        }
    ) 
    {
      data {
        id
        attributes {
          ${ATTRIBUTES_ALL_SOCIETIES}
        }
      }
    }
  }
`;

export const UPDATE_SOCIETES = gql`
  mutation($id:ID!,$society_type:ID,$name:String,$siret:String,$phoneNumber:String,$archived:Boolean,$adress:String,$city:String,$zipCode:String,$email:String,$siteWeb:String,$emailFacture:String,$monthlyPlan:Float, $monthlyQuota:Float) {
    updateSociete(
        id: $id,
        data: { 
          name: $name 
          siret: $siret 
          phoneNumber: $phoneNumber
          adress: $adress
          archived:$archived
          city: $city
          zipCode: $zipCode
          email: $email 
          siteWeb: $siteWeb 
          emailFacture: $emailFacture
          monthlyPlan: $monthlyPlan
          monthlyQuota: $monthlyQuota
          society_type: $society_type
        }
    ) {
      data {
        id
        attributes {
          ${ATTRIBUTES_ITEM_SOCIETY}
        }
      }
    }
  }
`;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../REDUX/actions/modal";
import { getCloud } from "../../REDUX/actions/cloud";
import FilesList from "../../Components/Cloud/FilesList";
import FolderList from "../../Components/Cloud/FolderList";
import ListHeader from "../../Components/Common/ListHeader";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import CloudImportForm from "../../Components/Cloud/CloudImportForm";
import Loading from "../../Components/Common/Loading";
import SearchInput from "../../Components/Common/SearchInput";
import useWindowWidth from "../../Hooks/useWindowWidth";
import LazyAttachementList from "../../Components/Cloud/LazyAttachementList";
import _ from "lodash";
import OneDriveSection from "../../Components/Cloud/OneDrive/OneDriveSection";

const CloudView = () => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const [searchFiles, setsearchFiles] = useState("");

  const { user } = useSelector((state) => state.userReducer);

  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const {
    fichiers,
    dossiers,
    sharedFolders,
    sharedFiles,
    cloudLoading,
    refetchCloud,
  } = useSelector((state) => state.cloudReducer);

  useEffect(() => {
    if (refetchCloud) {
      dispatch(getCloud());
    }
  }, [user?.id, dispatch, selectedOrganisation?.id]);

  const debounceSearch = _.debounce((value) => {
    setsearchFiles(value);
  }, 300);

  // useEffect(() => {
  //   dispatch(getSharedFiles(user?.id));
  // }, [user?.id, dispatch]);

  return cloudLoading ? (
    <Loading showLoader />
  ) : (
    <div className="container-fluid">
      <div className="d-flex flex-row justify-content-between align-items-center h50px mb-2">
        <div className="col-10 h100">
          <ListHeader showSocityFilter={false} Archives={true}>
            <SearchInput onChange={(e) => debounceSearch(e.target.value)} />
          </ListHeader>
        </div>
        <div className="col-2 ms-2 h100">
          <ColoredButton
            height="100"
            textButton={screenWidth > 600 && "Importer un document"}
            icon={screenWidth < 600 && "plus"}
            color="light-blue"
            callback={() =>
              dispatch(
                openModal({ type: "small", content: <CloudImportForm /> })
              )
            }
          />
        </div>
      </div>
      <div
        id="fileBody"
        className="ps-1"
        style={{ height: "80vh", overflowY: "scroll", overflowX: "hidden" }}
      >
        <OneDriveSection search={searchFiles} />
        <div className={`w-100 d-flex mb-3`}>
          <FolderList
            dossiers={[...dossiers, ...sharedFolders]
              .filter(
                (el) =>
                  !el?.attributes?.isSubFolder ||
                  +user?.attributes?.user_org_permissions?.data[0]?.attributes
                    ?.cloud?.data?.id !== +el?.attributes?.cloud?.data?.id
              )
              .sort((a, b) => b?.id - a?.id)}
            search={searchFiles}
          />
        </div>
        <div className="w-100 d-flex mb-3">
          <FilesList
            fichiers={[...fichiers, ...sharedFiles].sort(
              (a, b) => b?.id - a?.id
            )}
            search={searchFiles}
          />
        </div>

        <LazyAttachementList search={searchFiles} />
      </div>
    </div>
  );
};

export default CloudView;

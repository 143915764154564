import client from "./Client";

import {
  DELETE_MESSAGE,
  SEND_MESSAGE,
  UPDATE_MESSAGE,
  UPDATE_TICKET,
  ADD_TICKET_OPERATORS,
} from "../Graphql/Mutations/Tickets";
import { handleError, handleInfo } from "./toastMessage";
import { closeModal } from "./modal";
import { uploadFiles } from "./files";
import store from "../store/store";
import { GET_TICKET_MESSAGE } from "../Graphql/Queries/Message";
import { CREATE_ACTION } from "../Graphql/Mutations/interventions";
import Api from "../../Api/Api";
import { getMessages } from "./messages";
const color = "light-blue";

export const changeExpandedChatList = (isExpanded) => async (dispatch) => {
  dispatch({ type: "EXPANDED_CHAT_LIST", payload: isExpanded });
};

export const getAllTickets =
  (withLoading = true, select = false, callback) =>
  async (dispatch) => {
    const user = store.getState().userReducer.user;
    const organisation =
      store.getState().organisationsReducer.selectedOrganisation;

    const archivedTickets = store.getState().filtersReducer.ticketsArchived;

    if (withLoading) {
      dispatch({ type: "TICKETS_LOADING" });
    }
    if (!user || !organisation.id) return;
    try {
      const tickets = await Api.get(
        `/api/fetchConditionalTickets?organisation=${organisation.id}&archived=${archivedTickets}`
      );
      if (select) {
        callback(tickets.data.tickets.data[0].id);
      }
      if (user?.attributes?.role?.data?.id === "6") {
        dispatch({
          type: "ADD_OPERATOR_SOCIETY",
          payload: tickets.data.tickets.data.map(
            (el) => el.attributes.societe.data
          ),
        });
      }
      dispatch({
        type: "FETCH_TICKETS",
        payload: tickets.data.tickets.data,
      });
    } catch (error) {
      console.log(error);

      dispatch(handleError("Erreur réseau", 1));
    }
  };
const removeAccents = (str) =>
  str
    ?.normalize("NFD")
    ?.replace(/[\u0300-\u036f]/g, "")
    ?.toLowerCase() || "";
export const handleFilterTickets = (tickets) => async (dispatch) => {
  const { search, selectedSociety, selectedStatus, selectedOperators } =
    store.getState().filtersReducer;
  const normalizedSearch = removeAccents(search);

  const filteredTickets = tickets.filter(
    // filter ticket with name, society, status, operators, devices and prestations
    (el) =>
      (selectedSociety?.length
        ? selectedSociety?.includes(el.attributes?.societe?.data?.id)
        : el) &&
      (selectedOperators?.length
        ? el.attributes?.visibleBy.some((operator) =>
            selectedOperators.includes(operator.id)
          )
        : el) &&
      (selectedStatus?.length
        ? selectedStatus?.includes(el.attributes?.statut)
        : el) &&
      (search
        ? removeAccents(el.attributes.name).includes(normalizedSearch) ||
          removeAccents(el.id.toString())?.includes(normalizedSearch) ||
          removeAccents(el.attributes?.stringSociety).includes(
            normalizedSearch
          ) ||
          el.attributes.prestation.data.attributes.name.includes(
            normalizedSearch
          ) ||
          el.attributes.device.data.attributes.name.includes(
            normalizedSearch
          ) ||
          el.attributes.type.data.attributes.name.includes(normalizedSearch) ||
          el.attributes?.messages?.data?.some((message) =>
            removeAccents(message.attributes?.content)?.includes(
              normalizedSearch
            )
          )
        : el)
  );
  dispatch({ type: "HANDLE_FILTER_TICKETS", payload: filteredTickets });
};

export const getTicketItem =
  (id, navigate, withLoading = true) =>
  async (dispatch, getState) => {
    if (withLoading) {
      dispatch({ type: "FETCH_TICKET_ITEM_LOADING" });
      dispatch({ type: "FETCH_MESSAGES_LOAD" });
    }

    const organisationId =
      getState().organisationsReducer.selectedOrganisation.id;
    const ticketRoute = getState().organisationsReducer.ticketRoute;
    try {
      const { data } = await Api.get(
        `/api/fetchTicketItem?ticketId=${id}&organisation=${organisationId}`
      );

      dispatch({ type: "FETCH_TICKET_ITEM", payload: data.data });

      // dispatch({
      //   type: "CHANGE_CHECKS",
      //   payload: { ticketsArchived: data.data.attributes.archived },
      // });

      dispatch(getMessages(id, 0, withLoading));
    } catch (error) {
      console.log(error);
      if (error.response?.status === 403) {
        dispatch(handleError("Accès refusé au ticket.", 1));
        navigate(ticketRoute);
      } else {
        dispatch(handleError("Erreur réseau", 1));
      }
    }
  };

export const sendMessage =
  (
    newMessage,
    attachementFiles,
    uploadedFiles,
    showPercentage = true,
    mentions
  ) =>
  async (dispatch) => {
    dispatch({ type: "SEND_MESSAGE_LOADING" });
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    let attachedFiles = [];
    if (!uploadedFiles) {
      attachedFiles = await dispatch(
        uploadFiles(
          {
            ticket: newMessage.ticket,
            societe: newMessage.societe,
            intervention: newMessage.intervention,
            cloud: newMessage.cloud,
          },
          attachementFiles?.files,
          showPercentage
        )
      );
    } else {
      attachedFiles = uploadedFiles;
    }
    let links = [];
    if (newMessage.links?.length) {
      const createdLinks = await Api.post(`/api/handleMessageLinks`, {
        data: {
          ticketId: newMessage.ticket,
          links: newMessage.links,
        },
      });
      links = createdLinks.data;
    }
    try {
      const { data } = await client.mutate({
        mutation: SEND_MESSAGE,
        variables: {
          firstMessage: newMessage.firstMessage,
          content: newMessage.content,
          ticket: newMessage.ticket,
          event: newMessage.event,
          sender: newMessage.sender,
          type: newMessage.type,
          confidentiel: attachementFiles?.isConfidentiel,
          relatedIntervention: newMessage.relatedIntervention,
          fichiers: attachedFiles,
          mentions: mentions,
          message_links: links,
          orgId: orgId,
        },
      });

      const intervention =
        data?.createMessage?.data?.attributes?.relatedIntervention?.data;
      if (intervention) {
        dispatch({
          type: "UPDATE_TICKET_INTERVIENTS",
          payload: intervention,
        });
      } else if (
        data?.createMessage?.data?.attributes?.fichiers?.data?.length > 0
      ) {
        dispatch({
          type: "UPDATE_TICKET_FILES",
          payload: data?.createMessage?.data?.attributes?.fichiers?.data,
        });
      }

      const { ticket } = store.getState().ticketsReducer;

      if (+ticket?.id === +newMessage?.ticket) {
        dispatch(getMessages(ticket?.id, 0, false));
      }

      dispatch({ type: "CLEAR_UPLOAD" });
      dispatch({ type: "SEND_MESSAGE_SUCCESS" });
      if (newMessage.links.length) {
        dispatch(getTicketItem(newMessage.ticket, () => {}, false));
      }

      await Api.post(`/api/translate`, {
        message: data.createMessage.data,
        ticketId: newMessage.ticket,
        senderId: data.createMessage.data.attributes.sender.data.id,
      });
    } catch (error) {
      // dispatch(handleError("Erreur envoi du message", 1));
    }
  };

const createAction = (action) => async () => {
  await client.mutate({
    mutation: CREATE_ACTION,
    variables: {
      ticket: action.ticket,
      by: action.by,
      statut: action.statut,
    },
  });
};

export const updateTicket =
  (newTicket, statutHasChanged, by, showInfo = true) =>
  async (dispatch, getState) => {
    const ticketFields = getState().organisationsReducer.ticketFields;
    const additionalData = ticketFields.map((el) => {
      return {
        title: el.attributes.title,
        name: el.attributes.name,
        value: newTicket[el.attributes.name],
      };
    });

    try {
      await client.mutate({
        mutation: UPDATE_TICKET,
        variables: {
          id: newTicket.id,
          archived: newTicket.archived,
          name: newTicket.name,
          statut: newTicket.statut,
          description: newTicket.description,
          priority: newTicket.priority,
          prestation: newTicket.prestation,
          type: newTicket.type,
          device: newTicket.device,
          visibleBy: newTicket.visibleBy?.map((el) => el.id),
          additionalFields: JSON.stringify(additionalData),
        },
      });
      dispatch(getTicketItem(newTicket.id, () => {}, false));
      // dispatch({ type: "UPDATE_TICKET", payload: data.updateTicket.data }); CHANGING TO API

      if (statutHasChanged) {
        await dispatch(
          createAction({ ticket: newTicket?.id, statut: newTicket?.statut, by })
        );
      } else {
        await dispatch(
          createAction({
            ticket: newTicket?.id,
            statut: 1,
            by,
          })
        );
      }
      showInfo && dispatch(handleInfo("Ticket mis à jour", 1, color));
    } catch (error) {
      console.log(error);
      dispatch(handleError("Erreur mise à jour ticket", 1));
    }
  };

export const createTicket =
  (newTicket, attachementFiles, navigateToTicket, mentions) =>
  async (dispatch) => {
    const attachedFiles = await dispatch(
      uploadFiles(
        { societe: newTicket.societe, cloud: newTicket.cloud },
        attachementFiles.files
      )
    );
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    // List of all the users who can see the ticket
    console.log(newTicket);
    try {
      const { data } = await Api.post(
        `/api/handleNewTicket?organisation=${orgId}`,
        {
          data: {
            ...newTicket,

            fichiers: attachedFiles,
          },
        }
      );
      dispatch(handleInfo("Ticket créé", 1, color));
      dispatch(closeModal());

      dispatch(getAllTickets(false));

      dispatch({ type: "CLEAR_UPLOAD" });

      const flatMentions = mentions?.map(
        (mention) => mention?.insert?.mention?.id
      );
      await dispatch(
        sendMessage(
          {
            firstMessage: true,
            content: newTicket.description,
            links: newTicket.links,
            ticket: data.createTicket.data.id,
            sender: newTicket.creator,
            confidentiel: attachementFiles.isConfidentiel,
            fichiers: attachedFiles,
          },
          attachementFiles,
          attachedFiles,
          true,
          flatMentions
        )
      );
      navigateToTicket(data.createTicket.data.id);
    } catch (error) {
      console.log(error);
      dispatch(handleError("Erreur création du  ticket", 1));
      throw dispatch(closeModal());
    }
  };

export const addTicketParticipants = (updatedTicket) => async (dispatch) => {
  try {
    await client.query({
      query: ADD_TICKET_OPERATORS,
      variables: {
        visibleBy: updatedTicket.visibleBy,
        id: updatedTicket.id,
      },
    });
    dispatch(handleInfo("Ticket mis à jour", 1, color));
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

//  delete message
export const deleteMessage =
  (messageId, withInfo = true) =>
  async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: DELETE_MESSAGE,
        variables: { id: messageId },
      });

      dispatch({ type: "DELETE_MESSAGE", payload: data.deleteMessage.data.id });
      if (withInfo) {
        dispatch(handleInfo("Message supprimé", 1, color));
      }
    } catch (error) {
      dispatch(handleError("Erreur erreur suppression message", 1));
    }
  };

export const deleteOtherMessage =
  ({ id, ticketId }) =>
  async (dispatch, getState) => {
    try {
      const openedTicketId = getState().ticketsReducer.openedTicketId;
      if (parseInt(openedTicketId) === parseInt(ticketId)) {
        dispatch({ type: "DELETE_OTHER_MESSAGE", payload: id });
      }
    } catch {}
  };

//  update message
export const updateMessage =
  (id, newMessage, mentions, ticket) => async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_MESSAGE,
        variables: {
          id,
          content: newMessage.content,
          mentions,
        },
      });

      dispatch({ type: "UPDATE_MESSAGE", payload: data.updateMessage.data });
      dispatch(handleInfo("Message mis à jour", 1, color));
      await Api.post(`/api/translate`, {
        message: data.updateMessage.data,
        ticketId: ticket.id,
        senderId: data.updateMessage.data.attributes.sender.data.id,
      });
    } catch (error) {
      dispatch(handleError("Erreur mise à jour message", 1));
    }
  };

export const fetchUpdatedMessage =
  ({ id, ticketId }) =>
  async (dispatch, getState) => {
    try {
      const openedTicketId = getState().ticketsReducer.openedTicketId;

      const orgId =
        store.getState().organisationsReducer.selectedOrganisation.id;
      if (!orgId) {
        return;
      }

      if (parseInt(openedTicketId) === parseInt(ticketId)) {
        const { data } = await client.query({
          query: GET_TICKET_MESSAGE,
          variables: {
            id,
            orgId: orgId,
          },
        });

        const intervertion =
          data?.message?.data?.attributes?.relatedIntervention?.data;
        if (!intervertion) {
          dispatch({
            type: "UPDATE_MESSAGE",
            payload: data?.message?.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const toggleArchive = (id, callback) => async (dispatch) => {
  const user = store.getState().userReducer.user;

  try {
    const { tickets } = store.getState().ticketsReducer;
    const { data } = await Api.post(`/api/toggle-archive/${id}`);
    dispatch(handleInfo("Ticket mis à jour", 1, color));

    const ticketIndex = tickets.findIndex((ticket) => ticket?.id === id);
    const updatedTickets = tickets.filter((ticket) => ticket?.id !== id);

    await dispatch(createAction({ ticket: id, statut: 0, by: user?.id }));

    dispatch({ type: "DELETE_TICKET_ARCHIVED", payload: updatedTickets });

    //  callback(data.id);
    console.log(
      updatedTickets[ticketIndex],
      updatedTickets[ticketIndex - 1]?.id
    );
    if (updatedTickets[ticketIndex]) {
      callback(updatedTickets[ticketIndex]?.id);
    } else {
      callback(updatedTickets[ticketIndex - 1]?.id);
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateTicketArchive =
  ({ id, archived }) =>
  async (dispatch) => {
    try {
      dispatch(
        handleInfo(
          `Ticket # ${id} ${archived ? "est archivé" : "est déarchivé"}`,
          1,
          color
        )
      );
    } catch (error) {}
  };

import QueryString from "qs";
import { userApiAttributes } from "./general/userApiFields";

const avatar = {
  fields: ["id"],
  populate: { image: { fields: ["url"] } },
};

export const notificationsQuery = (id, orgId) => {
  return QueryString.stringify(
    {
      filters: {
        to_user: { id: { $eq: id } },
        organisation: { id: { $eq: orgId } },
      },
      sort: ["read:asc", "createdAt:desc"],
      pagination: { start: 0, limit: 100 },
      fields: ["id", "type", "read", "redirection", "createdAt"],
      populate: {
        user: {
          fields: userApiAttributes,
          populate: true,
        },
        from: {
          populate: {
            fields: userApiAttributes,
            avatar: avatar,
            user_org_permissions: {
              filters: {
                organisation: { id: { $eq: orgId } },
              },
              fields: ["id"],
              populate: {
                workIn: { fields: ["id", "name"] },
                SocietiesUnderResponsability: { fields: ["id", "name"] },
              },
            },
          },
        },
        action: {
          fields: ["id", "title", "statut"],
          populate: {
            by: {
              fields: userApiAttributes,
            },
          },
        },
        folder: { fields: ["id", "name"], populate: true },
        facture: { fields: ["id"] },
        fichier: {
          fields: ["id"],
          populate: { file: { fields: ["id", "url", "name", "mime", "size"] } },
        },
        societe: {
          populate: {
            fields: ["id", "name"],
            avatar: avatar,
          },
        },
        calendar: {
          populate: {
            fields: ["id", "name"],
            avatar: avatar,
          },
        },
        event: {
          populate: {
            fields: ["id", "name"],
            calendar: {
              populate: {
                fields: ["id", "name"],
                avatar: avatar,
              },
            },
          },
        },
        intervention: {
          fields: [
            "title",
            "statut",
            "dateDebut",
            "duration",
            "createdAt",
            "oldCreatedAt",
          ],
          populate: {
            societe: {
              populate: {
                fields: ["name"],
                avatar: avatar,
              },
            },
          },
        },
        ticket: {
          fields: ["name", "archived", "statut"],
          populate: {
            societe: {
              populate: {
                fields: ["id", "name"],
                avatar: avatar,
              },
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
};

export const messagesQuery = (ticketId, page, orgId, userId) => {
  return QueryString.stringify(
    {
      filters: { ticket: { id: { $eq: ticketId } } },
      // fields: [
      //   "id",
      //   "oldId",
      //   "type",
      //   "content",
      //   "createdAt",
      //   "firstMessage",
      //   "oldDate",
      //   "confidentiel",
      // ],
      populate: {
        message_favorites: {
          filters: { user: { id: { $eq: userId } } },
          fields: ["id"],
        },
        reacts: {
          sort: ["id:desc"],
          fields: ["url", "emojiKey", "createdAt"],
          populate: {
            user: {
              fields: userApiAttributes,
              populate: { avatar: avatar },
            },
          },
        },
        fichiers: {
          fields: ["id"],
          populate: {
            file: { fields: ["url", "name", "mime", "size"] },
            cloud: { fields: ["id"] },
          },
          pagination: { limit: 100 },
        },
        ticket: {
          populate: {
            events: { populate: true },
          },
        },
        relatedIntervention: {
          populate: {
            societe: { populate: true },
            actions: { populate: true },
          },
        },
        mentions: {
          populate: {
            avatar: avatar,
            device: { populate: true },
            poste: { populate: true },
            role: { populate: true },
          },
        },
        event: {
          fields: ["id", "name"],
          populate: {
            calendar: {
              fields: ["id", "name"],
            },
          },
        },
        sender: {
          fields: userApiAttributes,
          populate: {
            workIn: { fields: ["id", "name"] },
            SocietiesUnderResponsability: { fields: ["id", "name"] },
            user_org_permissions: {
              fields: ["id", "role", "name"],
              filters: { organisation: { id: { $eq: orgId } } },
              populate: {
                workIn: { fields: ["id", "name"] },
                SocietiesUnderResponsability: { fields: ["id", "name"] },
                poste: { fields: ["id", "name"] },
              },
            },

            avatar: avatar,
          },
        },
      },
      sort: ["createdAt:desc"],
      pagination: { start: page, limit: 10000 },
    },

    {
      encodeValuesOnly: true,
    }
  );
};
export const messageItemQuery = (orgId, userId) => {
  return QueryString.stringify(
    {
      populate: {
        reacts: {
          sort: ["id:desc"],
          fields: ["url", "emojiKey", "createdAt"],
          populate: {
            user: {
              fields: userApiAttributes,
              populate: { avatar: avatar },
            },
          },
        },
        fichiers: {
          fields: ["id"],
          populate: {
            file: { fields: ["url", "name", "mime", "size"] },
            cloud: { fields: ["id"] },
          },
          pagination: { limit: 100 },
        },
        ticket: {
          populate: {
            events: { populate: true },
          },
        },
        relatedIntervention: {
          populate: {
            societe: { populate: true },
            actions: { populate: true },
          },
        },
        mentions: {
          populate: {
            avatar: avatar,
            device: { populate: true },
            poste: { populate: true },
            role: { populate: true },
          },
        },
        event: {
          fields: ["id", "name"],
          populate: {
            calendar: {
              fields: ["id", "name"],
            },
          },
        },
        message_favorites: {
          filters: { user: { id: { $eq: userId } } },
          fields: ["id"],
        },
        sender: {
          fields: userApiAttributes,
          populate: {
            workIn: { fields: ["id", "name"] },
            SocietiesUnderResponsability: { fields: ["id", "name"] },
            user_org_permissions: {
              fields: ["id", "role", "name"],
              filters: { organisation: { id: { $eq: orgId } } },
              populate: {
                workIn: { fields: ["id", "name"] },
                SocietiesUnderResponsability: { fields: ["id", "name"] },
                poste: { fields: ["id", "name"] },
              },
            },

            avatar: avatar,
          },
        },
      },
    },

    {
      encodeValuesOnly: true,
    }
  );
};

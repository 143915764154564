import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import HeaderIcons from "./HeaderIcons";
import UserProfile from "./UserProfile";

import { BreadCrumb } from "../../../Utils/BreadCrumb";

import "./Header.css";

const Header = () => {
  const location = useLocation();

  const { unreadAlerts } = useSelector((state) => state.alertsReducer);

  const [showNewAlertTooltip, setShowNewAlertTooltip] = useState(null);

  useEffect(() => {
    unreadAlerts.length
      ? setShowNewAlertTooltip(true)
      : setShowNewAlertTooltip(false);
  }, [unreadAlerts?.length]);

  return (
    <LightDarkContainer
      className="container-fluid alignH justify-content-between py-3"
      style={{ zIndex: 7 }}
    >
      <LightDarkText className="bold title-font-size text-start text-capitalize">
        {BreadCrumb(location.pathname)}
      </LightDarkText>
      <div className="flex-1 d-flex gap-3 justify-content-end align-items-center">
        <HeaderIcons
          showNewAlertTooltip={showNewAlertTooltip}
          setShowNewAlertTooltip={setShowNewAlertTooltip}
        />
        <UserProfile />
      </div>
    </LightDarkContainer>
  );
};

export default Header;

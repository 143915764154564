import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import LightDarkText from "../Common/LightDarkText";

const Recap = ({ formData }) => {
  const [transformedData, setTransformedData] = useState([]);
  const { mode } = useSelector((state) => state.genearlReducer);

  const { questions } = useSelector((state) => state.formQuestionsReducer);
  useEffect(() => {
    const transformData = () => {
      const transformedArray = questions
        .slice()
        .sort((a, b) => {
          const categoryPositionA =
            a?.attributes?.category?.data?.attributes?.position;
          const categoryPositionB =
            b?.attributes?.category?.data?.attributes?.position;

          return categoryPositionA - categoryPositionB;
        })
        ?.map((question) => {
          const questionId = `question-${question?.id}`;
          const questionName = question?.attributes?.name;
          const questiontype = question?.attributes?.input_type;
          if (formData.hasOwnProperty(questionId)) {
            const questionValue = formData[questionId];
            let answer;
            if (["multi_select", "checkbox"].includes(questiontype)) {
              answer = questionValue.join(", ");
            }
            if (
              [
                "text",
                "select",
                "radio",
                "departement",
                "date",
                "number",
                "number_range",
                "date",
                "textarea",
                "boolean",
              ].includes(questiontype)
            ) {
              answer = questionValue;
            }
            if (questiontype === "file") {
              answer = questionValue[0]?.name;
            }
            return { id: question.id, question: questionName, answer };
          }

          return null;
        })
        .filter(Boolean);
      const groupedQuestions = {};
      transformedArray.forEach((question) => {
        const categoryId = questions.find((q) => q.id === question.id)
          ?.attributes?.category?.data?.id;
        if (!groupedQuestions[categoryId]) {
          groupedQuestions[categoryId] = [];
        }
        groupedQuestions[categoryId].push(question);
      });

      for (const categoryId in groupedQuestions) {
        if (groupedQuestions.hasOwnProperty(categoryId)) {
          groupedQuestions[categoryId].sort((a, b) => {
            const positionA = questions.find((q) => q.id === a.id)?.attributes
              ?.position;
            const positionB = questions.find((q) => q.id === b.id)?.attributes
              ?.position;
            return positionA - positionB;
          });
        }
      }

      const sortedData = Object.values(groupedQuestions).flat();
      setTransformedData(sortedData);
    };

    transformData();
  }, []);
  return (
    <div className="overflow-auto h90">
      <LightDarkText
        color="text-light-blue"
        className={"ms-2 bold f18 text-start mb-3"}
      >
        Recap Synthétisés
      </LightDarkText>
      {transformedData
        ?.filter((el) => el.answer !== "")
        ?.map(({ id, question, answer }, index) => {
          return (
            <div
              key={id}
              className="d-flex p-2 align-items-center radius05 my-1"
              style={{ backgroundColor: index % 2 === 0 ? "#3365ff22" : "" }}
            >
              <LightDarkText
                className={"ms-2 bold f16 text-start me-1"}
                color={mode === "light" ? "text-blue-grey" : "text-light-text"}
              >
                {question} :
              </LightDarkText>
              <LightDarkText className={"ms-2 medium f16 text-start me-2"}>
                {answer}
              </LightDarkText>
            </div>
          );
        })}
    </div>
  );
};

export default Recap;

import React from "react";
import {
  InterventionIconNotification,
  FileIconNotification,
  TicketIconNotification,
  InvoiceIconNotification,
  CalendarIconNotification,
} from "../../Common/LightDarkSvg";
import Thumbnail from "../../Common/Thumbnail";

const NotificationIcon = ({ notification }) => {
  return (
    <div>
      <div className="position-relative">
        <div
          style={{
            border: `2px solid ${notification.read ? "#F4F6FD" : "#3365FF"}`,
            borderRadius: "50%",
            backgroundColor: "white",
          }}
        >
          <Thumbnail
            img={
              notification.type === "Ticket" ||
              notification.type === "Message" ||
              notification.type === "Mention" ||
              notification.type === "File" ||
              notification.type === "Folder" ||
              notification.type === "Folder_update" ||
              notification.type === "newCollaborator" ||
              notification.type === "newCalendar" ||
              notification.type === "sharedCalendar" ||
              notification.type === "newEvent" ||
              notification.type === "updateEvent" ||
              notification.type === "revisionCreated" ||
              notification.type === "eventToValidate" ||
              notification.type === "validateEvent" ||
              notification.type === "programEvent" ||
              notification.type === "acceptEvent" ||
              notification.type === "declineEvent" ||
              (notification.type === "Action" &&
                (notification?.intervention?.data?.attributes?.statut === 2 ||
                  notification?.from?.data?.id))
                ? `user${notification?.from?.data?.attributes?.genre || "3"}`
                : "society"
            }
            apiImg={
              notification.type === "Ticket" ||
              notification.type === "Message" ||
              notification.type === "Mention" ||
              notification.type === "File" ||
              notification.type === "Folder" ||
              notification.type === "Folder_update" ||
              notification.type === "newCollaborator" ||
              notification.type === "newCalendar" ||
              notification.type === "sharedCalendar" ||
              notification.type === "newEvent" ||
              notification.type === "updateEvent" ||
              notification.type === "revisionCreated" ||
              notification.type === "eventToValidate" ||
              notification.type === "validateEvent" ||
              notification.type === "programEvent" ||
              notification.type === "acceptEvent" ||
              notification.type === "declineEvent" ||
              (notification.type === "Action" &&
                (notification?.intervention?.data?.attributes?.statut === 2 ||
                  notification?.intervention?.data?.attributes?.statut === 5 ||
                  notification?.intervention?.data?.attributes?.statut === 6 ||
                  notification?.user?.data?.id))
                ? notification?.from?.data?.attributes?.avatar?.data?.attributes
                    ?.image?.data?.attributes?.url
                : notification?.societe?.data?.attributes?.avatar?.data
                    ?.attributes?.image?.data?.attributes?.url
            }
            size={44}
            rounded
          />
        </div>
        {notification.type === "newCalendar" ||
        notification.type === "sharedCalendar" ||
        notification.type === "newEvent" ||
        notification.type === "updateEvent" ||
        notification.type === "revisionCreated" ||
        notification.type === "eventToValidate" ||
        notification.type === "validateEvent" ||
        notification.type === "programEvent" ||
        notification.type === "acceptEvent" ||
        notification.type === "declineEvent" ? (
          <CalendarIconNotification wasRead={notification.read} />
        ) : notification.type === "Message" ||
          notification.type === "Ticket" ||
          notification.type === "Mention" ||
          notification.type === "newCollaborator" ? (
          <div
            style={{
              position: "absolute",
              top: 20,
              left: 25,
              border: `2px solid ${notification.read ? "#F4F6FD" : "#3365FF"}`,
              borderRadius: "50%",
            }}
          >
            <Thumbnail
              img="society"
              apiImg={
                notification?.ticket?.data?.attributes?.societe?.data
                  ?.attributes?.avatar?.data?.attributes?.image?.data
                  ?.attributes?.url ||
                notification?.societe?.data?.attributes?.avatar?.data
                  ?.attributes?.image?.data?.attributes?.url
              }
              size={30}
              rounded
            />
          </div>
        ) : notification.type === "Intervention" ||
          notification.type === "Action" ? (
          <InterventionIconNotification wasRead={notification.read} />
        ) : notification.type === "Ticket_archive" ? (
          <TicketIconNotification wasRead={notification.read} />
        ) : notification.type === "Facture" ? (
          <InvoiceIconNotification wasRead={notification.read} />
        ) : (
          <FileIconNotification wasRead={notification.read} />
        )}
      </div>
    </div>
  );
};

export default NotificationIcon;

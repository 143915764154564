import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import TicketItemContainer from "../../Components/Tickets/TicketItemContainer";

import { getTicketItem } from "../../REDUX/actions/tickets";
import { handleReadTicketNotifications } from "../../REDUX/actions/notifications";

const TicketItemView = () => {
  const dispatch = useDispatch();

  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTicketItem(params.id, navigate));
    dispatch(
      handleReadTicketNotifications(
        ["Ticket", "Mention", "Message", "Ticket_archive", "Action"],
        params.id
      )
    );
  }, [params.id]);

  return (
    <div className="overflow-hidden">
      <TicketItemContainer />
    </div>
  );
};

export default TicketItemView;

import moment from "moment";
import { imageEndPoint } from "../Api/endPoint";
import { saveAs } from "file-saver";
import axios from "axios";
import Api from "../Api/Api";
import _ from "lodash";
import { utils as XLSXUtils, write as writeXLSX } from "xlsx";

export const validMail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test(mail)) {
    return true;
  }
  return false;
};

export const validPhone = (phone) => {
  const validPhoneRegex =
    /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/;

  return validPhoneRegex.test(phone);
};

export const validURL = (url) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const formatLinks = (link) => {
  return link.replace(/(?:https?:\/\/)?(?:www\.)?/i, "");
};

export const random = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const capitalizeFirst = (str) => {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

export const showPreferredLanguage = (user) => {
  try {
    const language =
      user?.attributes?.user_org_permissions.data[0]?.attributes
        ?.preferredLanguage.data.attributes;
    return `${language.name} (${language.code})`;
  } catch (error) {
    return "non disponible";
  }
};

export const showMultipleSocieties = (user) => {
  try {
    const userOrg = user?.attributes?.user_org_permissions.data[0]?.attributes;
    const societies =
      userOrg.role === 1 || userOrg.role === 2
        ? userOrg.SocietiesUnderResponsability.data
        : userOrg.workIn.data;
    const wording =
      userOrg.role === 1 || userOrg.role === 2
        ? "Toutes les sociétés"
        : "Pas de société";
    const reste = societies?.length - 2;
    if (!societies?.length > 0) {
      return wording;
    } else if (societies.length === 1) {
      return societies[0].attributes.name;
    } else if (societies.length === 2) {
      return `${societies[0].attributes.name}, ${societies[1].attributes.name}`;
    } else {
      return `${societies[0].attributes.name}, ${
        societies[2].attributes.name
      } et ${societies.length - 2} ${reste === 1 ? "autre" : "autres"}`;
    }
  } catch (error) {
    return "non disponible";
  }
};

export const showPoste = (user) => {
  try {
    const userOrg = user?.user_org_permissions.data[0];
    return userOrg.attributes.poste?.data?.attributes?.name || "Pas de poste";
  } catch (error) {
    return "non disponible";
  }
};

export const showPosteNoData = (user) => {
  try {
    const userOrg = user?.user_org_permissions[0];
    return userOrg?.poste?.name || "Pas de poste";
  } catch (error) {
    return "non disponible";
  }
};

export const showMultipleSocietiesNoData = (user) => {
  try {
    const userOrg = user?.attributes?.user_org_permissions[0];
    const societies =
      userOrg.role === 1 || userOrg.role === 2
        ? userOrg.SocietiesUnderResponsability
        : userOrg.workIn;
    const wording =
      userOrg.role === 1 || userOrg.role === 2
        ? "Toutes les sociétés"
        : "Pas de société";

    const reste = societies?.length - 2;
    if (!societies?.length > 0) {
      return wording;
    } else if (societies.length === 1) {
      return societies[0].name;
    } else if (societies.length === 2) {
      return `${societies[0].name}, ${societies[1].name}`;
    } else {
      return `${societies[0].name}, ${societies[2].name} et ${
        societies.length - 2
      } ${reste === 1 ? "autre" : "autres"}`;
    }
  } catch (error) {
    return "non disponible";
  }
};

export const formatDate = (date) => {
  return moment(date).format("DD.MM.YYYY à HH:mm");
};
export const formatDateForDB = (date) => {
  return moment(date).format();
};

export const bgImage = (file) => {
  return {
    backgroundImage: `url(${imageEndPoint}${file?.attributes?.file?.data?.attributes?.url})`,
  };
};
export const fileCompleteUrl = (file) =>
  `${imageEndPoint}${file?.attributes?.file?.data?.attributes?.url}`;

export const invoiceFileCompleteUrl = (file) =>
  `${imageEndPoint}${file.attributes.media.data[0].attributes.url}`;

export const fileExtention = (file) => {
  if (!file.attributes.file.data) {
    return "";
  }
  return file.attributes.file.data.attributes.mime.split("/")[1];
};

export const scrollTo = (ref) => {
  document.querySelector(`#${ref}`)?.scrollIntoView({
    behavior: "smooth",
  });
};

export const handleFirstLastName = (user) => {
  return user?.attributes?.firstName
    ? user.attributes?.firstName + " " + user.attributes?.lastName
    : user?.attributes?.lastName;
};

export const showAllPepartements = (departements) =>
  departements.length < 40
    ? departements
        .map((el) => `${el.attributes.code}-${el.attributes.name}`)
        .join(" / ")
    : departements.map((el) => `${el.attributes.code}`).join(" / ");

export const showmultipleDepartements = (departements) => {
  if (!departements?.length) {
    return "Pas de departements";
  } else if (departements.length === 1) {
    return `${departements[0].attributes?.code}-${departements[0].attributes?.name}`;
  } else if (departements.length === 2) {
    return `${departements[0].attributes?.code}-${departements[0].attributes?.name} ${departements[1].attributes?.code}-${departements[1].attributes?.name}`;
  } else {
    return showAllPepartements(departements);
  }
};

export const showMultipleDevices = (devices) => {
  const reste = devices?.length - 2;
  if (devices?.length === 0) {
    return "non-mentionné";
  } else if (devices?.length === 1) {
    return devices[0]?.attributes?.name;
  } else if (devices?.length === 2) {
    return `${devices[0]?.attributes?.name}, ${devices[1]?.attributes?.name}`;
  } else {
    return `${devices[0]?.attributes?.name}, ${
      devices[2]?.attributes?.name
    } et ${devices?.length - 2} ${reste === 1 ? "autre" : "autres"}`;
  }
};

export const sortFunction = (arr, champs, asc) => {
  return arr?.sort((a, b) => {
    switch (champs) {
      case "createdAt":
        return asc
          ? (a.attributes.oldCreatedAt || a.attributes.createdAt)
              ?.toString()
              ?.localeCompare(
                (
                  b.attributes.oldCreatedAt || b.attributes.createdAt
                )?.toString(),
                "fr",
                { numeric: true }
              )
          : (b.attributes.oldCreatedAt || b.attributes.createdAt)
              ?.toString()
              ?.localeCompare(
                (
                  a.attributes.oldCreatedAt || a.attributes.createdAt
                )?.toString(),
                "fr",
                { numeric: true }
              );
      case "connected":
        // First, compare the boolean connected values
        const connectedComparison = asc
          ? a.attributes.connected - b.attributes.connected
          : b.attributes.connected - a.attributes.connected;

        // If the connected values are equal, compare by lastConnected (date)
        if (connectedComparison === 0) {
          return asc
            ? new Date(a.attributes.lastConnected) -
                new Date(b.attributes.lastConnected)
            : new Date(b.attributes.lastConnected) -
                new Date(a.attributes.lastConnected);
        }

        return connectedComparison;
      case "monthlyPlan":
        return asc
          ? a.attributes?.plans?.data[0]?.attributes?.consumption_0 -
              b.attributes?.plans?.data[0]?.attributes?.consumption_0
          : b.attributes?.plans?.data[0]?.attributes?.consumption_0 -
              a.attributes?.plans?.data[0]?.attributes?.consumption_0;
      case "id":
        const aId = typeof a.id === "string" ? a.id : String(a.id);
        const bId = typeof b.id === "string" ? b.id : String(b.id);
        return asc
          ? aId.localeCompare(bId, "fr", { numeric: true })
          : bId.localeCompare(aId, "fr", { numeric: true });
      default:
        return asc
          ? a.attributes[champs]
              ?.toString()
              ?.localeCompare(b.attributes[champs]?.toString(), "fr", {
                numeric: true,
              })
          : b.attributes[champs]
              ?.toString()
              ?.localeCompare(a.attributes[champs]?.toString(), "fr", {
                numeric: true,
              });
    }
  });
};

export const sortProspectFunction = (arr, champs, asc) => {
  return arr?.sort((a, b) => {
    switch (champs) {
      case "id":
        const aId = typeof a.id === "string" ? a.id : String(a.id);
        const bId = typeof b.id === "string" ? b.id : String(b.id);
        return asc
          ? aId.localeCompare(bId, "fr", { numeric: true })
          : bId.localeCompare(aId, "fr", { numeric: true });
      default:
        return asc
          ? a[champs]?.toString()?.localeCompare(b[champs]?.toString(), "fr", {
              numeric: true,
            })
          : b[champs]?.toString()?.localeCompare(a[champs]?.toString(), "fr", {
              numeric: true,
            });
    }
  });
};

export const fileUrl = (url) => {
  return imageEndPoint + url;
};

export const downloadInvoice = (facture) => {
  saveAs(
    fileUrl(facture.attributes.media.data[0].attributes.url),
    facture.attributes.media.data[0].attributes.name
  );
};

export const donwloadFile = (file) => {
  saveAs(
    fileUrl(file.attributes.file.data.attributes.url),
    file.attributes.file.data.attributes.name
  );
};

export const donwloadDiffusionFile = (url, name) => {
  saveAs(fileUrl(url), name);
};

export const getInvoiceFileSize = async (fichier, decimals = 2) => {
  const hello = await axios.get(invoiceFileCompleteUrl(fichier));
  const bytes = hello.headers["content-length"];

  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getFileSize = async (fichier, decimals = 2) => {
  return ""; // stash until better solution
  const hello = await axios.get(fileCompleteUrl(fichier));
  const bytes = hello.headers["content-length"];

  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const calculateSize = (bytes) => {
  const decimals = 2;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const parsed = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  if (isNaN(parsed)) {
    return "";
  }
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const imageLoader = (url) => imageEndPoint + url;

export const transformTicketData = (ticket) => ({
  ...ticket,
  attributes: {
    ...ticket.attributes,
    stringCreator: handleFirstLastName(ticket.attributes?.creator?.data),
    stringSociety: ticket.attributes.societe?.data?.attributes.name,
    stringDevice: ticket.attributes.device?.data?.attributes.name,
    stringType: ticket.attributes.type?.data?.attributes.name,
    stringPrestation: ticket.attributes.prestation?.data?.attributes.name,
    stringDepartements: showmultipleDepartements(
      ticket.attributes.departements.data
    ),
    stringCreatedAt: ticket.attributes.oldCreatedAt
      ? ticket.attributes.oldCreatedAt
      : ticket.attributes.createdAt,
    visibleBy: ticket.attributes.visibleBy.data?.map((el) => {
      return {
        ...el,
        attributes: {
          ...el?.attributes,
          roleId: showRoleIdByUser(el?.attributes),

          stringRole: showRoleNameByUser(el?.attributes),
          stringPoste: showPoste(el.attributes),
        },
      };
    }),
  },
});

export const extractLinks = (text) => {
  console.log(text);
  let allLinks = [];
  const pattern =
    /(?<!href="[^"]*)((?:(?:https?:\/\/|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b)/gi;

  // Find and collect all matches before doing the replacement
  let match;
  while ((match = pattern.exec(text)) !== null) {
    const link = match[0].trim();
    let type;

    if (link.startsWith("http") || link.startsWith("www")) {
      if (/\.(jpeg|jpg|gif|png|svg|bmp|webp)$/.test(link)) {
        type = "image";
      } else {
        type = "URL";
      }
    } else if (
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/.test(link)
    ) {
      type = "email";
    } else {
      type = "unknown";
    }

    allLinks.push({ link, type });
  }

  return allLinks;
};

export const urlifyMessage = (text) => {
  // Regular expression to match URLs and email addresses
  const pattern =
    /(?<!href="[^"]*)((?:(?:https?:\/\/|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b)/gi;

  function replace(match) {
    const url = match.trim();

    if (url.includes("https://cdn.jsdelivr.net/npm/emoji-datasource-apple")) {
      return match;
    } else if (url.startsWith("http://") || url.startsWith("https://")) {
      return `<a href="${url}" rel="noopener noreferrer" target="_blank">${url}</a>`;
    } else if (url.includes("@")) {
      return `<a href="mailto:${url}">${url}</a>`;
    } else if (url.startsWith("www.")) {
      return `<a href="https://${url}" rel="noopener noreferrer" target="_blank">${url}</a>`;
    } else {
      return match;
    }
  }
  // Find all matches and replace them with formatted links
  let formattedText = text?.replace(pattern, replace);
  try {
    while (formattedText?.endsWith("<p><br></p>")) {
      formattedText = formattedText?.substring(0, formattedText?.length - 11);
    }
  } catch (error) {}
  return formattedText;
};

export const getRandomPic = () => {
  const numberOfPic = 14;
  const imgMap = {
    0: "1-Agilix.webp",
    1: "2-Agilix.webp",
    2: "3-Agilix.webp",
    3: "4-Agilix.webp",
    4: "5-Agilix.webp",
    5: "6-Agilix.webp",
    6: "7-Agilix.webp",
    7: "8-Agilix.webp",
    8: "9-Agilix.webp",
    9: "10-Agilix.webp",
    10: "11-Agilix.webp",
    11: "12-Agilix.webp",
    12: "13-Agilix.webp",
    13: "14-Agilix.webp",
  };

  const random = Math.floor(Math.random() * Math.floor(numberOfPic));
  return require(`../Images/loginImages/${imgMap[random]}`);
};

export const checkEmailExists = async (email) => {
  try {
    const data = await Api.get(
      `api/users?filters[email][$eq]=${email.toLowerCase()}`
    );

    if (data?.data?.length > 0) {
      return true;
    }

    return false;
  } catch {}
};

export const calculateAllConsumption = (plan) => {
  const filteredPlan = _.pickBy(plan, (value, key) =>
    _.includes(key, "consumption")
  );
  return (
    plan.monthly_plan - _.sum(Object.values(filteredPlan)) - plan?.monthly_quota
  );
};

function sendNotification(data, route, redirect) {
  var span = document.createElement("span");
  span.innerHTML = data?.messageContent;
  const subtitle = data?.notificationsSubtitle
    ? `${data?.notificationsSubtitle}\n`
    : "";

  const redirection = route ? `${route}${data?.link}` : `${data?.link}`;

  const notification = new Notification(data?.notificationTitle, {
    icon: require("../Images/agilix-logo-nom.png"),
    body: `${subtitle}${span.textContent || span.innerText}`,
  });
  notification.addEventListener("click", () => {
    if (redirect) {
      return window.open(redirect);
    } else {
      window.open(`${redirection}`);
    }
  });
}

export function checkPageStatus(data, route, redirect) {
  if (!("Notification" in window)) {
    alert(
      "Votre navigateur ne prend pas en charge les notifications système !"
    );
  } else if (Notification.permission === "granted") {
    sendNotification(data, route, redirect);
  } else if (
    Notification.permission !== "denied" ||
    Notification.permission === "default"
  ) {
    Notification.requestPermission((permission) => {
      if (permission === "granted") {
        sendNotification(data, route, redirect);
      }
    });
  }
}

export const getHighestPosition = (data) =>
  data.reduce((maxItem, currentItem) => {
    return currentItem?.attributes?.position > maxItem?.attributes?.position
      ? currentItem
      : maxItem;
  }, data[0])?.attributes?.position;

export const CategoryData = (categories, attachId) => {
  return categories?.map((el) => ({
    label: el?.attributes?.name,
    value: attachId === true ? el?.id : el?.attributes?.name,
  }));
};

export const answerData = (answers) => {
  return answers?.map((el) => ({
    id: el?.id,
    label: el?.attributes?.answer,
    value: el?.attributes?.answer,
  }));
};

export const mapDepData = (departments, withId) => {
  return departments?.map((el) => ({
    label: el?.attributes?.code + "-" + el?.attributes?.name,
    value: withId ? el?.id : el?.attributes?.name,
  }));
};

export const mapPrestationData = (prestations) => {
  return prestations?.map((el) => ({
    value: el?.id,
    label: el?.attributes?.name,
  }));
};
export const questionData = (questions) => {
  return questions
    ?.filter((q) =>
      [
        "text",
        "select",
        "number",
        "number_range",
        "multi_select",
        "radio",
        "boolean",
        "checkbox",
      ].includes(q?.attributes?.input_type)
    )
    ?.sort(
      (a, b) =>
        a.attributes.category.data.attributes.position -
        b.attributes.category.data.attributes.position
    )
    ?.map((el) => ({
      id: el?.id,
      label: `<strong>${el?.attributes?.name}</strong></br> <i style="font-size:9px"> Catégorie ${el.attributes.category.data.attributes.position} | ${el.attributes.category.data.attributes.name}</i> 
      `,
      value: el?.id,
    }));
};

export const conditionsData = (conditions) => {
  return conditions?.map((el) => ({
    id: el?.id,
    question: el?.attributes?.condition?.data?.id,
    answer: el?.attributes?.answer,
  }));
};

export const boolData = [
  { id: 1, label: "oui", value: "oui" },
  { id: 2, label: "non", value: "non" },
];

export const selectFormat = (tab) => {
  if (!tab?.length) {
    return [];
  }
  return tab.map((el) => {
    return {
      value: Number(el.id),
      label:
        el.attributes.title ||
        handleFirstLastName(el) ||
        `#${el.id}-${el.attributes.name}`,
      isArchived: el?.attributes.archived,
    };
  });
};
export const renderOriginText = (text) => {
  switch (text) {
    case "WEBSITE":
      return "web";
    case "INTERNAL":
      return "interne";
    case "ARTEFACTO":
      return "ARTEFACTO";

    default:
      break;
  }
};

export const handleProspectStatut = (statut) => {
  switch (statut) {
    //prospect
    case "DRAFT":
      return {
        text: "attente validation admin",
        color: "turquoise",
        bg: " #17DCCD40 ",
      };
    case "DRAFT_COMMERCIAL":
      return {
        text: "attente validation commercial",
        color: "turquoise",
        bg: " #17DCCD40 ",
      };
    case "DRAFT_SELLER":
      return {
        text: "attente validation revendeur",
        color: "orange",
        bg: "#FF721B40",
      };

    //prospect archivee
    case "KO_DRAFT":
      return {
        text: "archivé",
        color: "red",
        bg: " #ff2c2c40 ",
      };

    // --------------------------------------

    //commande
    case "TO_VALIDATE":
      return {
        text: "attente validation admin",
        color: "yellow",
        bg: " #FFBC2040 ",
      };
    case "VALID":
      return {
        text: "validé",
        color: "green",
        bg: " #c3ebda ",
      };

    //commande archivee
    case "KO":
      return {
        text: "archivé",
        color: "red",
        bg: " #ff2c2c40 ",
      };

    // --------------------------------------

    //devis
    case "NEW":
      return {
        text: "attente d'affectation revendeur",
        color: "turquoise",
        bg: " #0CCE9140",
      };
    case "NEW_SELLER":
      return {
        text: "attente validation client",
        color: "green",
        bg: "#0CCE9140",
      };

    //devis archivee
    case "KO_NEW":
      return {
        text: "archivé",
        color: "red",
        bg: " #ff2c2c40 ",
      };

    default:
      break;
  }
};

export const handleUpdateStatus = (statut) => {
  switch (statut) {
    case "DRAFT":
      return {
        text: "Attribuer à un commercial",
        newStatus: "DRAFT_COMMERCIAL",
      };
    case "DRAFT_COMMERCIAL":
      return {
        text: "Attribuer à un revendeur",
        newStatus: "DRAFT_SELLER",
      };
    case "DRAFT_SELLER":
      return {
        text: "Confirmer",
        newStatus: "NEW",
      };
    case "NEW":
      return {
        text: "Attribuer",
        newStatus: "NEW_SELLER",
      };
    case "NEW_SELLER":
      return {
        text: "Valider",
        newStatus: "TO_VALIDATE",
      };
    case "TO_VALIDATE":
      return {
        text: "Valider",
        newStatus: "VALID",
      };

    default:
      break;
  }
};

export const mapOperator = (operators) => {
  return operators?.map((el) => ({
    label: el?.attributes?.firstName + " " + el?.attributes?.lastName,
    value: el?.id,
  }));
};

export const transformObject = (item) => {
  const transformedItem = {
    id: item?.id,
    origin: item?.origin,
    status: item?.status,
    date: moment(item?.createdAt).format("MM-DD-YYYY"),
    note: item?.note,
    archived: item?.archived,
    actor:
      item?.acteur && item?.acteur?.firstName + " " + item?.acteur?.lastName,
  };

  item?.prospect_fields?.forEach((dataItem) => {
    const question = dataItem?.question;
    const accessor = question?.accessor;

    if (accessor) {
      transformedItem[accessor] = dataItem?.value;
    }
  });
  return transformedItem;
};
export const transformGraphObject = (item) => {
  const transformedItem = {
    id: item?.id,
    origin: item?.attributes?.origin,
    status: item?.attributes?.status,
    date: moment(item?.attributes?.createdAt).format("MM-DD-YYYY"),
    note: item?.attributes?.note,
    archived: item?.attributes?.archived,
    document: item?.attributes?.document?.data,

    actor:
      item.attributes?.acteur?.data &&
      item.attributes?.acteur?.data?.attributes?.workIn?.data[0]?.attributes
        ?.name,
  };
  item?.attributes?.prospect_fields?.data.forEach((dataItem) => {
    const question = dataItem?.attributes?.question?.data;
    const accessor = question?.attributes?.accessor;

    if (accessor) {
      transformedItem[accessor] = dataItem?.attributes?.value;
    }
  });
  return transformedItem;
};

export const exportXlsx = (prospect, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const wsData = [["Question", "Valeur"]];

  (prospect?.attributes?.prospect_fields?.data || [])
    .slice()
    .sort((a, b) => {
      const categoryPositionA =
        a?.attributes?.question?.data?.attributes?.category?.data?.attributes
          ?.position || 0;
      const categoryPositionB =
        b?.attributes?.question?.data?.attributes?.category?.data?.attributes
          ?.position || 0;

      if (categoryPositionA !== categoryPositionB) {
        return categoryPositionA - categoryPositionB;
      } else {
        const questionPositionA =
          a?.attributes?.question?.data?.attributes?.position || 0;
        const questionPositionB =
          b?.attributes?.question?.data?.attributes?.position || 0;

        return questionPositionA - questionPositionB;
      }
    })
    .forEach((field) => {
      const questionData = field?.attributes || {};
      const inputType = questionData?.question?.data?.attributes.input_type;
      const questionName = questionData?.question?.data?.attributes.name;

      let value = "";

      if (
        [
          "text",
          "number",
          "number_range",
          "date",
          "departement",
          "textarea",
        ].includes(inputType)
      ) {
        value = questionData.value || "";
      } else if (
        ["multi_select", "checkbox", "radio", "select"].includes(inputType)
      ) {
        const formAnswers = field.attributes.form_answers?.data || [];
        value = formAnswers.map((value) => value.attributes.answer).join("||");
      } else if (inputType === "file") {
        value = questionData?.file?.data?.attributes?.name || "";
      }

      if (value !== "") {
        wsData.push([questionName, value]);
      }
    });

  const ws = XLSXUtils.aoa_to_sheet(wsData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = writeXLSX(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  saveAs(data, fileName + fileExtension);
};

export const mapCloudData = (files) => {
  return files?.map((el) => ({
    label: el?.attributes?.name,
    value: +el?.attributes?.file.data.id,
  }));
};

export const handleuserRoleName = (roleId) => {
  switch (roleId) {
    case 1:
      return "Superviseur";
    case 2:
      return "Opérateur";
    case 3:
      return "Administrateur";
    case 4:
      return "Collaborateur";
    case 5:
      return "Invité";

    default:
      return "non disponible";
  }
};

export const showRoleNameByUser = (user) => {
  try {
    const roleId = +user.user_org_permissions?.data[0]?.attributes?.role;
    switch (roleId) {
      case 1:
        return "Superviseur";
      case 2:
        return "Opérateur";
      case 3:
        return "Administrateur";
      case 4:
        return "Collaborateur";
      case 5:
        return "Invité";

      default:
        return "non disponible";
    }
  } catch (error) {
    return "non disponible";
  }
};

export const showRoleNameByUserNoData = (user) => {
  try {
    const roleId = +user.user_org_permissions[0]?.role;
    switch (roleId) {
      case 1:
        return "Superviseur";
      case 2:
        return "Opérateur";
      case 3:
        return "Administrateur";
      case 4:
        return "Collaborateur";
      case 5:
        return "Invité";

      default:
        return "non disponible";
    }
  } catch (error) {
    return "non disponible";
  }
};

export const showRoleIdByUser = (user) => {
  const roleId = +user.user_org_permissions?.data[0]?.attributes?.role;

  switch (roleId) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;

    default:
      return "non disponible";
  }
};

export const showRoleIdByUserNoData = (user) => {
  const roleId = +user.user_org_permissions[0]?.role;

  switch (roleId) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;

    default:
      return "non disponible";
  }
};

export const populateUser = (user, withData = true) => {
  try {
    if (withData) {
      return {
        stringRole: showRoleNameByUser(user.attributes),
        roleId: showRoleIdByUser(user.attributes),
        stringPoste: showPoste(user.attributes),
        stringSocieties: showMultipleSocieties(user),
      };
    } else {
      return {
        stringRole: showRoleNameByUserNoData(user.attributes),
        roleId: showRoleIdByUserNoData(user.attributes),
        stringPoste: showPosteNoData(user.attributes),
        stringSocieties: showMultipleSocietiesNoData(user),
      };
    }
  } catch (error) {}
};

export const generateProspectData = (prospect) => {
  let data = [];
  (prospect?.attributes?.prospect_fields?.data || [])
    .slice()
    .sort((a, b) => {
      const categoryPositionA =
        a?.attributes?.question?.data?.attributes?.category?.data?.attributes
          ?.position || 0;
      const categoryPositionB =
        b?.attributes?.question?.data?.attributes?.category?.data?.attributes
          ?.position || 0;

      if (categoryPositionA !== categoryPositionB) {
        return categoryPositionA - categoryPositionB;
      } else {
        const questionPositionA =
          a?.attributes?.question?.data?.attributes?.position || 0;
        const questionPositionB =
          b?.attributes?.question?.data?.attributes?.position || 0;

        return questionPositionA - questionPositionB;
      }
    })
    .forEach((field) => {
      const questionData = field?.attributes || {};
      const questionId = questionData?.question?.data?.id;
      const questionAcccessor =
        questionData?.question?.data?.attributes.accessor;
      const inputType = questionData?.question?.data?.attributes.input_type;
      const questionName = questionData?.question?.data?.attributes.name;

      let value = "";
      let link = "";

      if (
        [
          "text",
          "number",
          "number_range",
          "date",
          "departement",
          "textarea",
          "boolean",
        ].includes(inputType)
      ) {
        value = questionData.value || "";
      } else if (
        ["multi_select", "checkbox", "radio", "select"].includes(inputType)
      ) {
        const formAnswers = field.attributes.form_answers?.data || [];

        value = formAnswers
          .map((value) => value.attributes.answer)
          .join(" || ");
      } else if (inputType === "file") {
        value = questionData?.file?.data?.attributes?.name || "";
        link = questionData?.file?.data?.attributes?.url
          ? `${imageEndPoint}${questionData?.file?.data?.attributes?.url}`
          : "";
      }

      if (value !== "" && !questionAcccessor) {
        data.push({
          id: questionId,
          question: questionName,
          answer: value,
          link: link,
        });
      }
    });
  console.log(data);
  return data;
};

export const getValueByAccessor = (obj, accessor) => {
  const accessors = accessor.split("?.");
  let value = obj;

  for (let i = 0; i < accessors.length; i++) {
    if (value && typeof value === "object") {
      const nestedAccessor = accessors[i];
      value = value[nestedAccessor];

      if (value === undefined || value === null) {
        break; // Exit loop if encountered undefined or null value
      }
    } else {
      // Return null or any default value if the path is not valid
      return null; // You can modify this to return any default value
    }
  }

  return value;
};

export const convertMilliseconds = (ms) => {
  const hours = Math.floor(ms / (1000 * 60 * 60));
  const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));

  return `${hours} heure(s) & ${minutes} minute(s)`;
};
export const convertMillisecondsToHours = (ms) => {
  return Math.floor(ms / (1000 * 60 * 60));
};

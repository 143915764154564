import { useDispatch, useSelector } from "react-redux";

import ColoredButton from "../../Common/Buttons/ColoredButton";
import SocietyForm from "../../Societies/SocietyForm/SocietyForm";

import { UpdateSociete } from "../../../REDUX/actions/societe";
import { closeModal, openModal } from "../../../REDUX/actions/modal";
import { openConfirmationModal } from "../../../REDUX/actions/confirmationModal";

const SocietyActions = ({ society }) => {
  const dispatch = useDispatch();

  const { canCreateSociety } = useSelector((state) => state.roleReducer);
  return (
    canCreateSociety && (
      <div className="d-flex justify-content-between align-items-center mt-3">
        <ColoredButton
          color="blue-grey"
          outlined
          textButton="Archiver"
          disabled={society?.attributes.archived}
          callback={() =>
            dispatch(
              openConfirmationModal({
                callBack: () => {
                  dispatch(UpdateSociete({ id: society?.id, archived: true }));
                  dispatch(closeModal());
                },
                message: "Voulez vous vraiment archiver la société?",
                message2: `#${society?.id} ${society?.attributes.name}`,
                confirmButtonText: "Archiver la société",
              })
            )
          }
        />
        <ColoredButton
          color="light-blue"
          textButton="Informations"
          disabled={society?.attributes.archived}
          callback={() =>
            dispatch(
              openModal({
                type: "small",
                preventClose: true,
                content: <SocietyForm society={society} />,
              })
            )
          }
        />
      </div>
    )
  );
};

export default SocietyActions;

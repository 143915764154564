import React, { useState } from "react";
import {
  EqualIcon,
  MessageQuestionIcon,
  TrashIcon,
} from "../../../Common/LightDarkSvg";
import Select from "../../Select";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllQuestions } from "../../../../REDUX/actions/formQuestion";
import { answerData, boolData, questionData } from "../../../../Utils/utils";
import LightDarkInput from "../../../Common/LightDarkInput";
import LighDarkMultiChoice from "../../../Common/LighDarkMultiChoice";
import Loading from "../../../Common/Loading";

const ConditionItem = ({
  data,
  updateConditionItem,
  deleteConditionItem,
  index,
}) => {
  const dispatch = useDispatch();
  const [newQuestion, setnewQuestion] = useState({
    question: [],
    answer: "",
  });
  const { questions } = useSelector((state) => state.formQuestionsReducer);
  useEffect(() => {
    if (!questions.length) {
      dispatch(GetAllQuestions());
    }
  }, []);

  const [selected, setSelected] = useState();

  useEffect(() => {
    if (data.id) {
      const foundItem = questions.find((item) => item?.id === data.question);
      if (foundItem) {
        setSelected(foundItem);
        setnewQuestion({
          question: [
            {
              id: data.id,
              label: foundItem.attributes.name,
              value: data.question,
            },
          ],
          answer: data.answer,
        });
      }
    }
  }, [data.id, questions]);

  useEffect(() => {
    const foundItem = questions.find(
      (item) => +item?.id === +newQuestion?.question[0]?.value
    );

    if (foundItem) {
      setSelected(foundItem);

      updateConditionItem(
        index,
        data.id
          ? {
              question: foundItem.id,
              answer: newQuestion.answer,
              id: data.id,
            }
          : {
              question: foundItem.id,
              answer: newQuestion.answer,
            }
      );
    }
  }, [newQuestion, questions]);

  useEffect(() => {
    if (selected) {
      setnewQuestion({
        ...newQuestion,
        answer: "",
      });
    }
  }, [newQuestion.question[0]]);

  const handleDelete = () => {
    deleteConditionItem(index);
  };
  return !questions.length ? (
    <Loading />
  ) : (
    <div className="w-100 mt-4 bg-light-gray p-3 radius05 d-flex flex-row align-items-center justify-content-center">
      <div className="col-lg-5 col-12">
        <LighDarkMultiChoice
          placeholder={"Question"}
          multiple={false}
          htmlElements
          // icon={<MessageQuestionIcon />}
          data={questionData(questions)}
          bgWhite={true}
          totalInputs={newQuestion}
          setValue={setnewQuestion}
          champs={"question"}
        />
      </div>
      <div className="col-1 d-flex justify-content-center mt-2">
        <EqualIcon />
      </div>
      <div className="col-lg-5 col-12">
        {["select", "radio", "checkbox", "multi_select"].includes(
          selected?.attributes?.input_type
        ) && (
          <Select
            placeholder={"Reponse"}
            icon={<MessageQuestionIcon />}
            data={answerData(selected?.attributes?.answers?.data)}
            bgWhite={true}
            champs={"answer"}
            setValue={setnewQuestion}
            totalInput={newQuestion}
          />
        )}
        {["boolean"].includes(selected?.attributes?.input_type) && (
          <Select
            placeholder={"Reponse"}
            icon={<MessageQuestionIcon />}
            data={boolData}
            bgWhite={true}
            champs={"answer"}
            setValue={setnewQuestion}
            totalInput={newQuestion}
          />
        )}

        {["text", "number"].includes(selected?.attributes?.input_type) && (
          <LightDarkInput
            bgWhite={true}
            setValue={setnewQuestion}
            champs={"answer"}
            totalInputs={newQuestion}
          />
        )}
      </div>
      <div
        className="col-1 d-flex justify-content-center mt-2"
        onClick={handleDelete}
      >
        <TrashIcon />
      </div>
    </div>
  );
};

export default ConditionItem;

import _ from "lodash";

import React from "react";
import { useSelector } from "react-redux";

import AttachmentPlaceHolder from "../../PlaceHolders/AttachmentPlaceHolder";
import AttachementUploader from "../../Common/AttachementUploader";

import AttachmentsList from "../../Common/AttachmentsList";

const RightAttachedFiles = ({ ticket }) => {
  const { canSendMessages } = useSelector((state) => state.roleReducer);
  const { messagesLoading } = useSelector((state) => state.messagesReducer);
  const { open } = useSelector((state) => state.modalReducer);

  return (
    <div
      className="directionC justify-content-between "
      style={{ height: "90%" }}
    >
      <div className="sideChatListContainer mb-3 overflow-auto">
        <div className="d-flex flex-column gap-2 my-2">
          {messagesLoading ? (
            <AttachmentPlaceHolder />
          ) : (
            <AttachmentsList
              files={ticket.attributes?.fichiers.data}
              ticketAttachedFiles
              ticketNotArchived={!ticket.attributes.archived}
            />
          )}
        </div>
      </div>
      {open || ticket?.attributes?.archived || !canSendMessages ? null : (
        <AttachementUploader
          ticketUploader
          label={"Joindre un fichier"}
          ticket={ticket}
        />
      )}
    </div>
  );
};

export default RightAttachedFiles;

const initialState = {
  user: null,
  role: null,
  userLoading: true,
  isAdmin: false,
  isOperator: false,
  isClientAdmin: false,
  isClient: false,
  isLector: false,
  error: null,
  resetSuccess: false,
  resetError: false,
  loadingReset: false,
  userOrganisations: [],
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOADING_USER":
      return { ...state, userLoading: true };
    case "LOADING_RESET":
      return { ...state, loadingReset: true };

    case "LOGIN_SUCCESS":
      const role = +payload.attributes.role.data.id;
      // eslint-disable-next-line default-case
      switch (role) {
        case 3:
          return {
            ...state,
            user: payload,
            userLoading: false,
            role: role,
            isAdmin: true,
            isOperator: false,
            isClientAdmin: false,
            isClient: false,
            isLector: false,
            error: null,
            userOrganisations: payload.attributes.user_org_permissions.data,
          };
        case 6:
          return {
            ...state,
            user: payload,
            userLoading: false,
            role: role,
            isAdmin: false,
            isOperator: true,
            isClientAdmin: false,
            isClient: false,
            isLector: false,
            error: null,
            userOrganisations: payload.attributes.user_org_permissions.data,
          };
        case 1:
          return {
            ...state,
            user: payload,
            userLoading: false,
            role: role,
            isAdmin: false,
            isOperator: false,
            isClientAdmin: true,
            isClient: false,
            isLector: false,
            error: null,
            userOrganisations: payload.attributes.user_org_permissions.data,
          };
        case 4:
          return {
            ...state,
            user: payload,
            userLoading: false,
            role: role,
            isAdmin: false,
            isOperator: false,
            isClientAdmin: false,
            isClient: true,
            isLector: false,
            error: null,
            userOrganisations: payload.attributes.user_org_permissions.data,
          };
        case 5:
          return {
            ...state,
            user: payload,
            userLoading: false,
            role: role,
            isAdmin: false,
            isOperator: false,
            isClientAdmin: false,
            isClient: false,
            isLector: true,
            error: null,
            userOrganisations: payload.attributes.user_org_permissions.data,
          };
      }
      return {
        ...state,
        user: payload,
        userLoading: false,
        role: role,
        userOrganisations: payload.attributes.user_org_permissions.data,
        error: null,
      };
    case "LOGIN_ERROR":
      return { ...state, error: payload.message, userLoading: false };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        role: null,
        userLoading: false,
        error: null,
      };
    case "RESET_SUCCESS":
      return {
        ...state,
        resetSuccess: true,
        loadingReset: false,

        userLoading: false,
      };
    case "RESET_ERROR":
      return {
        ...state,
        resetError: true,
        loadingReset: false,
        userLoading: false,
      };
    case "UNAUTHORIZED":
      return {
        ...state,
        user: null,
        role: null,
        userLoading: false,
        error: payload.message,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: payload,
      };
    // MODIFY THE USER TO KEEP ONLY THE SELECTED ORGANISATION IN HIS PROFILE
    case "ORGANISATIONS_SUCCESS":
      const selected = localStorage.getItem("organisation");

      const alreadySelected = selected
        ? payload.filter((el) => +el.id === +selected)[0]
          ? payload.filter((el) => +el.id === +selected)[0]
          : payload[0]
        : payload[0];

      localStorage.setItem("organisation", alreadySelected.id);
      const newUserOrgs = state.userOrganisations.filter(
        (userOrg) =>
          +userOrg.attributes.organisation.data.id === +alreadySelected?.id
      );

      return {
        ...state,
        user: {
          ...state.user,
          attributes: {
            ...state.user.attributes,
            SocietiesUnderResponsability:
              newUserOrgs[0].attributes.SocietiesUnderResponsability,
            workIn: newUserOrgs[0].attributes.workIn,
            cloud: newUserOrgs[0].attributes.cloud,
            devices: newUserOrgs[0].attributes.devices,
            poste: newUserOrgs[0].attributes.poste,
            prestations: newUserOrgs[0].attributes.prestations,

            user_org_permissions: { data: newUserOrgs },
          },
        },
      };
    case "CHNAGE_ORGANISATION":
      const newUserChanged = state.userOrganisations.filter(
        (userOrg) => +userOrg.attributes.organisation.data.id === payload
      );

      return {
        ...state,
        user: {
          ...state.user,
          attributes: {
            ...state.user.attributes,
            user_org_permissions: { data: newUserChanged },
          },
        },
      };
    default:
      return state;
  }
};
export default userReducer;

import { useState } from "react";
import { useSelector } from "react-redux";

import MessageInput from "./MessageInput";
import MessageSelectedFiles from "./MessageSelectedFiles";

const MessageInputContainer = () => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { canSendMessages } = useSelector((state) => state.roleReducer);

  const { ticket } = useSelector((state) => state.ticketsReducer);

  return !ticket.attributes.archived && canSendMessages ? (
    <>
      <div
        className="my-1"
        style={{
          borderBottom: `2px solid ${mode === "light" ? "#F8F9FF" : "#252C45"}`,
        }}
      />
      <MessageInput />
      <MessageSelectedFiles />
    </>
  ) : null;
};

export default MessageInputContainer;

import Api from "../../Api/Api";
import {
  CREATE_SOCIETES,
  UPDATE_SOCIETES,
} from "../Graphql/Mutations/Societes";
import {
  CREATE_AVATAR,
  DELETE_FILE,
  UPDATE_AVATAR,
  UPLOAD,
} from "../Graphql/Mutations/files";
import { GET_SOCIETE_ITEM } from "../Graphql/Queries/Societes";
import { GET_AVATAR_SOCIETE } from "../Graphql/Queries/files";
import store from "../store/store";
import client from "./Client";
import { handleError, handleInfo } from "./toastMessage";
import { getAllCalendars } from "./calendars";
const color = "light-blue";

export const getAllSocietes = () => async (dispatch) => {
  try {
    dispatch({ type: "SOCIETE_LOADING" });
    const archivedSociety = store.getState().filtersReducer.archivedSociety;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    const { data } = await Api.get(
      `/api/fetchConditionalSocieties?organisation=${orgId}&archived=${archivedSociety}`
    );

    dispatch({ type: "FETCH_SOCIETE", payload: data.societes.data });
    dispatch({ type: "FETCH_SHARED_SOCIETY", payload: data.societes.data });
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const getNewCompany =
  ({ id }) =>
  async (dispatch) => {
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    try {
      const archivedSociety = store.getState().filtersReducer?.archivedSociety;
      const { data } = await client.query({
        query: GET_SOCIETE_ITEM(id, orgId),
      });
      dispatch({
        type: "FETCH_NEW_SHARED_COMPANY",
        payload: data.societe.data,
      });

      if (!archivedSociety) {
        dispatch({ type: "FETCH_NEW_COMPANY", payload: data.societe.data });
      }

      dispatch(handleInfo("Société créée", 1, color));
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const CreateSociete = (newSociete, avatar) => async (dispatch) => {
  try {
    const user = store.getState().userReducer.user;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    let avatarId = "";
    if (avatar) {
      const { data: fileData } = await client.mutate({
        mutation: UPLOAD,
        variables: {
          file: avatar,
        },
      });

      const fileId = fileData.upload.data.id;

      const { data: avatarData } = await client.mutate({
        mutation: CREATE_AVATAR,
        variables: {
          image: fileId,
        },
      });

      avatarId = avatarData?.createAvatar?.data?.id;
    }

    await client.mutate({
      mutation: CREATE_SOCIETES({
        ...newSociete,
        avatar: avatarId || null,
        organisation: orgId,
        creator: user?.id,
        society_type: newSociete.society_type[0]?.value || null,
      }),
      refetchQueries: true,
    });
    dispatch(getAllSocietes());
    dispatch(handleInfo("Société créée", 1, color));
    dispatch(getAllCalendars());
    dispatch({ type: "SOCIETY_CREATED", payload: undefined });
  } catch (error) {
    dispatch(handleError("Erreur ajout de la société", 1));
  }
};

export const UpdateSociete = (Societe, avatar) => async (dispatch) => {
  try {
    if (avatar) {
      const { data: currentAvatar } = await client.query({
        query: GET_AVATAR_SOCIETE,
        variables: {
          societeId: Societe.id,
        },
      });
      const { data: fileData } = await client.mutate({
        mutation: UPLOAD,
        variables: {
          file: avatar,
        },
      });
      const fileId = fileData.upload.data.id;

      if (!currentAvatar?.avatars?.data?.length > 0) {
        await client.mutate({
          mutation: CREATE_AVATAR,
          variables: {
            image: fileId,
            societe: Societe.id,
          },
        });
      } else {
        const currentAvatarId = currentAvatar?.avatars?.data[0]?.id;
        const currentImageId =
          currentAvatar?.avatars?.data[0]?.attributes?.image?.data?.id;

        currentAvatarId &&
          (await client.mutate({
            mutation: UPDATE_AVATAR,
            variables: {
              id: currentAvatarId,
              imageId: fileId,
            },
          }));

        currentImageId &&
          (await client.mutate({
            mutation: DELETE_FILE,
            variables: {
              id: currentImageId,
            },
          }));
      }
    }

    const { data } = await client.mutate({
      mutation: UPDATE_SOCIETES,
      variables: {
        id: Societe.id,
        name: Societe.name,
        siret: Societe.siret,
        phoneNumber: Societe.phoneNumber,
        adress: Societe.adress,
        archived: Societe.archived,
        city: Societe.city,
        zipCode: Societe.zipCode,
        email: Societe.email,
        siteWeb: Societe.siteWeb,
        emailFacture: Societe.emailFacture,
        monthlyPlan: Societe.monthlyPlan,
        monthlyQuota: Societe.monthlyQuota,
        society_type: Societe?.society_type
          ? Societe?.society_type[0]?.value
          : null,
      },
    });
    // await dispatch(uploadFile(avatar, null, data?.updateSociete.data?.id));

    await dispatch({
      type: "SOCIETE_UPDATED",
      payload: data.updateSociete.data,
    });
    dispatch(getAllSocietes());
    dispatch(handleInfo("Société mise à jour", 1, color));
  } catch (error) {
    console.log(error);
    dispatch(handleError("Erreur mise à jour société", 1));
  }
};

export const getSocietyStats = (societyId) => async (dispatch) => {
  try {
    const organisation =
      store.getState().organisationsReducer.selectedOrganisation;

    const data = await Api.get(
      `/api/users-permissions/user/getConditionalStats?organisation=${
        organisation.id
      }&societies=${[societyId]}`
    );
    dispatch({ type: "SOCIETE_STATS_SUCCESS", payload: data?.data });
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

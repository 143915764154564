import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import LightDarkText from "../../../../../../Common/LightDarkText";

import {
  handleFirstLastName,
  showMultipleSocieties,
  showPoste,
} from "../../../../../../../Utils/utils";

const MessageSender = ({ message, sender, setMeSending }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );

  const { user } = useSelector((state) => state.userReducer);

  const [senderRole, setsenderRole] = useState(0);

  useEffect(() => {
    setMeSending(+message?.attributes?.sender?.data?.id === +user.id);
    setsenderRole(
      +message?.attributes?.sender?.data?.attributes?.user_org_permissions
        ?.data[0]?.attributes?.role
    );
  }, [message?.attributes?.sender?.data?.id, user.id]);

  return sender ? (
    <LightDarkText
      color={mode === "light" ? "text-light-text" : "text-white"}
      className="f10 bold"
    >
      {`${handleFirstLastName(sender)} - `}
      {senderRole === 1 || senderRole === 2 ? (
        <span className="bolder text-light-blue">
          {selectedOrganisation?.attributes?.name}
        </span>
      ) : (
        showMultipleSocieties(sender)
      )}
      {" - "}
      {showPoste(sender?.attributes)}
    </LightDarkText>
  ) : (
    <LightDarkText color="text-light-text" className="f10 bold">
      Utilisateur supprimé
    </LightDarkText>
  );
};

export default MessageSender;

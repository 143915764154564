import client from "./Client";
import store from "../store/store";
import { GET_ALERTS } from "../Graphql/Queries/Alerts";
import {
  CREATE_ALERT,
  READ_ALERT,
  DELETE_ALERT,
  UPDATE_ALERT,
} from "../Graphql/Mutations/Alerts";
import { UPLOAD } from "../Graphql/Mutations/files";
import { handleError, handleInfo } from "./toastMessage";
import { clearTempUpload } from "./files";

const color = "light-blue";

export const getAllAlerts = () => async (dispatch) => {
  dispatch({ type: "ALERTS_LOADING" });
  const user = store.getState().userReducer.user;
  const { isOperator, isLecteur, isCollab, isClientAdmin } =
    store.getState().roleReducer;
  try {
    const { data } = await client.query({
      query: GET_ALERTS,
      variables: {
        administrators: isClientAdmin ? true : undefined,
        collaborators: isCollab ? true : undefined,

        operators: isOperator ? true : undefined,
        lectors: isLecteur ? true : undefined,
      },
    });
    let userUnreadAlerts = data?.alerts?.data?.filter(
      (alert) =>
        !alert?.attributes?.seenBy?.data?.filter(
          (seen) => seen?.id === user?.id
        ).length
    );

    dispatch({
      type: "FETCH_ALERTS",
      payload: {
        alerts: data.alerts?.data || [],
        userUnreadAlerts: userUnreadAlerts?.slice(0, 3),
        lastAlert: userUnreadAlerts[0],
      },
    });
  } catch (error) {
    console.log(error);
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const createAlert = (newAlert) => async (dispatch) => {
  const temporarySelected = store.getState().filesReducer.temporarySelected;
  const { data } = await client.mutate({
    mutation: UPLOAD,
    variables: {
      file: temporarySelected[0],
    },
  });

  const fileId = data.upload.data.id;
  try {
    const { data } = await client.mutate({
      mutation: CREATE_ALERT,
      variables: {
        description: newAlert.description,
        url: newAlert.url,
        image: fileId,
        creator: newAlert.creator,
        administrators: newAlert.administrators,
        collaborators: newAlert.collaborators,
        operators: newAlert.operators,
        lectors: newAlert.lectors,
      },
      refetchQueries: true,
    });
    dispatch({ type: "CREATE_ALERT", payload: data.createAlert.data });
    dispatch(handleInfo("Alerte créée", 1, color));
    dispatch(clearTempUpload());
    return data.createAlertedata;
  } catch (error) {
    console.log(error);
    dispatch(handleError("Erreur de la création de l'alerte", 1));
  }
};

export const updateAlert = (alert) => async (dispatch) => {
  const temporarySelected = store.getState().filesReducer.temporarySelected;
  let fileId = "";
  if (temporarySelected[0]) {
    const { data } = await client.mutate({
      mutation: UPLOAD,
      variables: {
        file: temporarySelected[0],
      },
    });
    fileId = data.upload.data.id;
  }

  try {
    const { data } = await client.mutate({
      mutation: UPDATE_ALERT,
      variables: {
        id: alert.id,
        description: alert.description,
        url: alert.url,
        image: fileId ? fileId : alert?.image?.data?.id,
        administrators: alert.administrators,
        collaborators: alert.collaborators,
        operators: alert.operators,
        lectors: alert.lectors,
      },
      refetchQueries: true,
    });
    dispatch({ type: "UPDATE_ALERT", payload: data.updateAlert.data });
    dispatch(handleInfo("Alerte mise à jour", 1, color));
    dispatch(getAllAlerts());
    dispatch(clearTempUpload());
    return data.createAlertedata;
  } catch (error) {
    console.log(error);
    dispatch(handleError("Erreur de la modifications de l'alerte", 1));
  }
};

export const readAlert = (alert) => async (dispatch) => {
  const id = store.getState().userReducer.user?.id;
  try {
    await client.mutate({
      mutation: READ_ALERT,
      variables: {
        id: alert?.id,
        seenBy: [...alert?.attributes?.seenBy?.data.map((el) => el?.id), id],
      },
    });
    dispatch({ type: "READ_ALERT", payload: alert.id });
  } catch (error) {
    console.log(error);
  }
};

export const deleteAlert = (id) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_ALERT(id),
    });
    dispatch({ type: "DELETE_ALERT", payload: data.deleteAlert.data.id });
    dispatch(handleInfo("Alerte supprimée", 1, color));
  } catch (error) {
    dispatch(handleError("Erreur de la suppression de l'alerte", 1));
  }
};

import React from "react";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import LightDarkInput from "../../Common/LightDarkInput";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";
import { useDispatch, useSelector } from "react-redux";
import LightDarkText from "../../Common/LightDarkText";
import { useEffect } from "react";
import { getDevices } from "../../../REDUX/actions/sharedData";

const UserSettingsForm = ({
  clientInputs,
  setclientInputs,
  client,
  isNew,
  invitation,
  isOp,
}) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const { user } = useSelector((state) => state.userReducer);
  const { devicesPerPrestations, prestations, societes, departements } =
    useSelector((state) => state.sharedDataReducer);
  const { ticketHandleLogic } = useSelector(
    (state) => state.organisationsReducer
  );
  useEffect(() => {
    dispatch(getDevices(clientInputs?.prestations));
  }, [clientInputs?.prestations, dispatch, client]);

  return (
    <>
      <div className={`${screenWidth > 600 ? "mt-5 mb-4 mx-3" : "mt-5"} row `}>
        {user.id !== client?.id && clientInputs.role[0]?.value !== 1 && (
          <div className="">
            <LighDarkMultiChoice
              label="Société(s)"
              data={societes}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs={isOp ? "SocietiesUnderResponsability" : "workIn"}
              userSocietySelectInput
            />
          </div>
        )}
      </div>
      {ticketHandleLogic === 2 ? (
        user.id !== client?.id && clientInputs.role[0]?.value !== 1 ? (
          <div className={`mx-3 row `}>
            <div className={`col-12`}>
              <LighDarkMultiChoice
                label={"Département(s)"}
                data={departements}
                totalInputs={clientInputs}
                multiple={true}
                setValue={setclientInputs}
                champs="departements"
                newField="departements"
                canSelectAll
              />
            </div>
          </div>
        ) : null
      ) : user.id !== client?.id && clientInputs.role[0]?.value === 2 ? (
        <div className={`${screenWidth > 600 ? "my-4 mx-3" : ""} row `}>
          <div
            className={`${screenWidth < 600 ? "col-12 mt-4" : "col-6 mt-2"}`}
          >
            <LighDarkMultiChoice
              label={"Compétence(s)"}
              data={prestations}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs="prestations"
              newField="prestation"
              canSelectAll
            />
          </div>
          <div
            className={`${screenWidth < 600 ? "col-12 mt-4" : "col-6 mt-2"}`}
          >
            <LighDarkMultiChoice
              label={"Spécialité(s)"}
              data={isNew ? [] : devicesPerPrestations}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs="devices"
              newField="device"
              canSelectAll
            />
          </div>
        </div>
      ) : null}

      <div className={`${screenWidth > 600 ? "my-5 mx-3" : "mt-5"} row `}>
        {!invitation ? (
          <>
            <div
              className={`${screenWidth > 600 ? "col-6 mb-5" : "col-12 mt-4"}`}
            >
              <LightDarkInput
                placeholder={"Mot de passe"}
                label={"Mot de passe"}
                totalInputs={clientInputs}
                setValue={setclientInputs}
                champs="password"
                required={isNew}
                passwordInput
                type="password"
              />
              {clientInputs.password && clientInputs.password.length < 6 && (
                <span className="field-error f11 position-absolute mt-1 text-red">
                  Le mot de passe doit comporter au moins
                  {screenWidth >= 600 && <br />} 6 caractères
                </span>
              )}
            </div>
            <div
              className={`${screenWidth > 600 ? "col-6 mb-5" : "col-12 mt-4"}`}
            >
              <LightDarkInput
                placeholder={"Confirmation du mot de passe"}
                label={"Confirmation du mot de passe"}
                totalInputs={clientInputs}
                setValue={setclientInputs}
                champs="password2"
                required={isNew}
                passwordInput
                type="password"
              />
              {clientInputs.password2 &&
                clientInputs.password2 !== clientInputs.password && (
                  <span className="field-error f11 position-absolute mt-1 text-red">
                    Les mots de passe ne correspondent pas
                  </span>
                )}
            </div>
          </>
        ) : null}
        <div className={`row mt-5 ${!isNew && "mt-5"}`}>
          <LightDarkText
            className={"text-start bold custom-ticket-font-size mb-2"}
          >
            <span className="text-red">*</span>Champs obligatoires
          </LightDarkText>
        </div>
      </div>
    </>
  );
};

export default UserSettingsForm;

import moment from "moment";

import React from "react";
import { useSelector } from "react-redux";

import LightDarkText from "../../Common/LightDarkText";
import SocietyBundleBar from "../SocietyBundleBar";
import SocietyActions from "./SocietyActions";

import { handleDuration } from "../../Tickets/ticketUtils";

const SocietyDetails = ({ society }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const currentPlan = society?.attributes?.plans?.data?.find((plan) => {
    return (
      moment(plan?.attributes?.date).format("YYYY-MM-DD") ===
      moment().format("YYYY-MM-01")
    );
  });

  const consumption_0 = currentPlan?.attributes?.consumption_0 || 0;
  const monthlyPlan = currentPlan?.attributes?.monthlyPlan || 0;
  const monthlyQuota = currentPlan?.attributes?.monthlyQuota || 0;

  const societyDetails = [
    {
      label: "Siret/ RCI",
      value: society?.attributes.siret
        ? `${society?.attributes.siret}`
        : "Pas de Siret",
      isLink: false,
      isMail: false,
    },
    {
      label: "Téléphone",
      value: society?.attributes.phoneNumber
        ? `${society?.attributes.phoneNumber}`
        : "Pas de téléphone",
      isLink: false,
      isMail: false,
    },
    {
      label: "E-mail",
      value: society?.attributes.email
        ? `${society?.attributes.email}`
        : "Pas d'e-mail",
      isLink: true,
      isMail: true,
    },
    {
      label: "Adresse",
      value: society?.attributes.adress
        ? `${society?.attributes.adress}`
        : "Pas d'addresse",
      isLink: false,
      isMail: false,
    },
    {
      label: "Code postal",
      value: society?.attributes.zipCode
        ? `${society?.attributes.zipCode}`
        : "Pas de code postal",
      isLink: false,
      isMail: false,
    },
    {
      label: "Ville",
      value: society?.attributes.city
        ? `${society?.attributes.city}`
        : "Pas de ville",
      isLink: false,
      isMail: false,
    },
    {
      label: "E-mail de facturation",
      value: society?.attributes.emailFacture
        ? `${society?.attributes.emailFacture}`
        : "Pas d'email de facturation",
      isLink: false,
      isMail: false,
    },
    {
      label: "Site web",
      value: society?.attributes.siteWeb
        ? `${society?.attributes.siteWeb}`
        : "Pas de site web",
      isLink: true,
      isMail: false,
    },
    {
      label: "Date de création",
      value: moment(society?.attributes.createdAt).format("DD MMMM YYYY"),

      isLink: false,
      isMail: false,
    },
    {
      label: "Forfait mensuel",
      value: `${handleDuration(consumption_0 + monthlyQuota)}/ ${handleDuration(
        monthlyPlan
      )}`,
      isLink: false,
      isMail: false,
      isBundle: true,
    },
  ];

  return (
    <div
      className="d-flex flex-column justify-content-between pt-3 h100 sideChatListContainer"
      style={{
        overflowX: "hidden",
      }}
    >
      {societyDetails?.map((information, index) => (
        <div key={index}>
          <div
            className={`${
              information?.isBundle &&
              "d-flex justify-content-between align-items-center"
            }`}
          >
            <LightDarkText
              className="text-start bold custom-ticket-font-size"
              color={mode === "light" ? "text-light-text" : "text-white"}
            >
              {information?.label}
            </LightDarkText>
            <LightDarkText
              className={`text-break text-start bold custom-ticket-font-size
             ${
               !information?.value.includes("Pas de") &&
               information?.isLink &&
               "text-decoration-underline pointer"
             }`}
              color={
                (!information?.value.includes("Pas de") &&
                  information?.isLink) ||
                information?.isBundle
                  ? "text-light-blue"
                  : mode === "light"
                  ? "text-blue-grey"
                  : "text-light-text"
              }
              onclickCallback={() =>
                information?.isMail
                  ? window.open(`mailto:${society?.attributes.email}`)
                  : information?.isLink &&
                    window.open(`//${society?.attributes.siteWeb}`)
              }
            >
              {information?.value}
            </LightDarkText>
          </div>
          {information?.isBundle && (
            <SocietyBundleBar
              consumption_0={consumption_0}
              monthlyPlan={monthlyPlan}
              monthlyQuota={monthlyQuota}
            />
          )}
        </div>
      ))}
      <SocietyActions society={society} />
    </div>
  );
};

export default SocietyDetails;

import LightDarkText from "../../Common/LightDarkText";

const TicketTitle = ({
  id,
  title,
  className,
  color,
  textsize,
  notRead = false,
}) => {
  return (
    <LightDarkText
      className={`${textsize} d-flex gap-1 ${className} bolder text-start`}
      color="text-light-blue"
    >
      #{id}
      <span className={`bold ${color} ${notRead && "xbold"}`}>| {title}</span>
    </LightDarkText>
  );
};

export default TicketTitle;

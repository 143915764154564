import { useSelector } from "react-redux";

import UserSmallCard from "../../Clients/UserSmallCard";

import { populateUser } from "../../../Utils/utils";

const SocietyUsers = ({ society, users }) => {
  const { user } = useSelector((state) => state.userReducer);

  return (
    <div className="d-flex flex-column justify-content-start h100 sideChatListContainer">
      {users?.map((userOrg, index) => (
        <UserSmallCard
          key={index}
          index={index}
          responsible={{
            ...userOrg.attributes.user.data,
            attributes: {
              ...userOrg.attributes.user.data.attributes,
              ...populateUser(userOrg.attributes.user.data),
            },
          }}
          user={user}
          society={society}
          societyId={society?.id}
          societySideBar
        />
      ))}
    </div>
  );
};

export default SocietyUsers;

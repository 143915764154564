import { gql } from "@apollo/client";
import { ATTRIBUTES_PROSPECT_FIELD } from "../Attributes/Prospect_field";
import { ATTRIBUTES_PROSPECT } from "../Attributes/Prospect";

export const CREATE_PROSPECT = gql`
  mutation ($origin: ENUM_PROSPECT_ORIGIN, $status: ENUM_PROSPECT_STATUS,$user:ID,$organisation:ID!) {
    createProspect(data: { origin:$origin,status:$status,user:$user,organisation:$organisation }) {
      data {
        id
        attributes {
          ${ATTRIBUTES_PROSPECT}
        }
      }
    }
  }
`;

export const CREATE_PROSPECT_FIELD = gql`
  mutation ($value: String, $question: ID,$prospect:ID,$form_answers:[ID],$file:ID) {
    createProspectField(data: { value:$value,question:$question,prospect:$prospect,form_answers:$form_answers,file:$file }) {
      data {
        id
        attributes {
          ${ATTRIBUTES_PROSPECT_FIELD}
        }
      }
    }
  }
`;

export const UPDATE_PROSPECT_FIELD = gql`
  mutation($id:ID!,$value: String, $question: ID,$prospect:ID,$form_answers:[ID],$file:ID) {
    updateProspectField(
        id: $id,
        data: { value:$value,question:$question,prospect:$prospect,form_answers:$form_answers,file:$file }
    ) {
      data {
        id
        attributes{
          ${ATTRIBUTES_PROSPECT_FIELD}
        }
      }
    }
  }
`;

export const ARCHIVE_PROSPECT = gql`
  mutation ($id:ID!,$archived:Boolean,$status:ENUM_PROSPECT_STATUS,$note:String) {
    updateProspect(
      id:$id,
      data: { archived:$archived ,status:$status,note:$note}) {
      data {
        id
        attributes {
          ${ATTRIBUTES_PROSPECT}
        }
      }
    }
  }
`;

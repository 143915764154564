const initialState = {
  stats: undefined,
  loading: false,
  error: undefined,
  operatorStats: undefined,
  historyStats: null,
  historyStatsLoading: null,
};
const statsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "STATS_LOADING":
      return { ...state, loading: true };
    case "RESET_ORGANISATION":
      return {
        ...state,
        stats: undefined,
        loading: false,
        error: undefined,
        operatorStats: undefined,
        historyStats: null,
        historyStatsLoading: null,
      };
    case "STATS_SUCCESS":
      return { ...state, loading: false, stats: payload };
    case "STATS_FAIL":
      return { ...state, loading: false, error: payload };
    case "OPERATOR_STATS_SUCCESS":
      return { ...state, loading: false, operatorStats: payload };
    case "HISTORY_STATS_LOADING":
      return { ...state, historyStatsLoading: true };
    case "HISTORY_STATS_SUCCESS":
      return { ...state, historyStatsLoading: false, historyStats: payload };
    default:
      return state;
  }
};

export default statsReducer;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ColoredButton from "../../Common/Buttons/ColoredButton";

import SocietyFormHeader from "./SocietyFormHeader";
import SocietyInfosForm from "./SocietyInfosForm";
import SocietyBundleForm from "./SocietyBundleForm";

import { checkRequiredSocietyInputs, societyFields } from "./societyFormUtils";

import useWindowWidth from "../../../Hooks/useWindowWidth";

import { closeModal } from "../../../REDUX/actions/modal";
import { getCurrentPlan } from "../../../REDUX/actions/plan";
import { getConditionalStats } from "../../../REDUX/actions/stats";
import {
  CreateSociete,
  UpdateSociete,
  getSocietyStats,
} from "../../../REDUX/actions/societe";

import "react-phone-number-input/style.css";

const SocietyForm = ({ isNew, society }) => {
  const dispatch = useDispatch();

  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { role } = useSelector((state) => state.roleReducer);
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );

  const [avatar, setAvatar] = useState();
  const [isToggle, setIsToggle] = useState("infos");
  const [editbundle, setEditBundle] = useState(false);
  const [societyInputs, setsocietyInputs] = useState(
    societyFields(isNew, society)
  );
  const { societyTypesValues } = useSelector(
    (state) => state.sharedDataReducer
  );
  const handleSubmitSociety = () => {
    if (isNew) {
      dispatch(CreateSociete(societyInputs, avatar));
      dispatch(closeModal());
    } else {
      dispatch(UpdateSociete({ ...societyInputs, id: society.id }, avatar));
      dispatch(closeModal());
    }
  };

  const currentImage =
    society?.attributes?.avatar?.data?.attributes?.image?.data?.attributes?.url;

  useEffect(() => {
    dispatch(getCurrentPlan(society?.id, moment().format("YYYY-MM-01")));
    dispatch(getSocietyStats(society?.id));
  }, [dispatch, society?.id]);

  useEffect(() => {
    if (!isNew) {
      dispatch(getConditionalStats([society?.id]));
    }
  }, [dispatch, user, society?.id, isNew]);

  return (
    <div
      className={`${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      } px-4 py-3 radius05 sideChatListContainer`}
      style={{
        height: "700px",
        width: "700px",
        paddingInline: "10px",
      }}
    >
      <div
        className="h100 directionC justify-content-between"
        onSubmit={(e) => e.preventDefault()}
      >
        <div>
          <SocietyFormHeader
            isNew={isNew}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
          />
          {isToggle === "infos" && (
            <SocietyInfosForm
              setAvatar={setAvatar}
              avatar={avatar}
              currentImage={currentImage}
              societyInputs={societyInputs}
              setsocietyInputs={setsocietyInputs}
            />
          )}
          {isToggle === "forfait" && (
            <SocietyBundleForm
              isNew={isNew}
              editbundle={editbundle}
              societyInputs={societyInputs}
              setsocietyInputs={setsocietyInputs}
            />
          )}
        </div>
        <div
          className={`d-flex ${
            screenWidth < 600 && "row"
          } align-items-center justify-content-center mt-1`}
        >
          {isToggle === "forfait" && editbundle && (
            <div
              className={`${
                screenWidth > 600 ? "col-2 me-3" : "col-12 order-1"
              }`}
            >
              <ColoredButton
                textButton={"Annuler"}
                color="blue-grey"
                outlined
                callback={() => setEditBundle(false)}
              />
            </div>
          )}
          {(isNew || editbundle || isToggle === "infos") && (
            <div
              className={`${screenWidth > 600 ? "col-4" : "col-12 mt-4 pb-3"}`}
            >
              <ColoredButton
                disabled={checkRequiredSocietyInputs(
                  societyInputs,
                  societyTypesValues
                )}
                textButton={
                  isNew ? "Créer la société" : "Mettre à jour les informations"
                }
                color="light-blue"
                callback={() => handleSubmitSociety()}
              />
            </div>
          )}
        </div>
        {role === 1 &&
          isToggle === "forfait" &&
          !editbundle &&
          !isNew &&
          !selectedOrganisation.attributes.withClicks && (
            <div className="col-4 mt-1 align-self-center">
              <ColoredButton
                textButton={"Modifier le forfait"}
                color="blue-grey"
                callback={() => setEditBundle(true)}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default SocietyForm;

import { gql } from "@apollo/client";
import { userAttributes } from "../general/userFields";

export const GET_TICKET_MESSAGE = gql`
  query ($id: ID!, $orgId: ID!) {
    message(id: $id) {
      data {
        id
        attributes {
          fichiers(pagination: { page: 1, pageSize: 100 }) {
            data {
              id
              attributes {
                file {
                  data {
                    id
                    attributes {
                      url
                      name
                      mime
                    }
                  }
                }
                cloud {
                  data {
                    id
                  }
                }
              }
            }
          }
          oldId
          type
          content
          createdAt
          oldDate
          confidentiel
          relatedIntervention {
            data {
              id
              attributes {
                createdAt
                updatedAt
                duration
                dateDebut
                description
                statut
                title
                acceptDate
                refusDate
                revisionDate
                validateDate
                societe {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                actions {
                  data {
                    id
                    attributes {
                      createdAt
                      title
                      motif
                      by {
                        data {
                          id
                          attributes {
                            ${userAttributes}
                            user_org_permissions(
                              filters: { organisation: { id: { eq: $orgId } } }
                            ) {
                              data {
                                id
                                attributes {
                                  role
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          sender {
            data {
              id
              attributes {
                ${userAttributes}
                user_org_permissions(
                  filters: { organisation: { id: { eq: $orgId } } }
                ) {
                  data {
                    id
                    attributes {
                      role
                      name
                    }
                  }
                }
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                poste {
                  data {
                    attributes {
                      name
                    }
                  }
                }
                role {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          relatedIntervention {
            data {
              id
              attributes {
                title
              }
            }
          }
          mentions {
            data {
              id
              attributes {
                ${userAttributes}
                user_org_permissions(
                  filters: { organisation: { id: { eq: $orgId } } }
                ) {
                  data {
                    id
                    attributes {
                      role
                      name
                    }
                  }
                }
                poste {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                role {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

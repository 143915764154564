import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./Select.css";

import OutsideClickHandler from "react-outside-click-handler";
import LightDarkText from "../Common/LightDarkText";
import { ArrowDownIcon } from "../Common/LightDarkSvg";
import LightDarkInput from "../Common/LightDarkInput";
import { CheckIcon } from "../Common/Icons/CheckIcon";

const Select = ({
  label,
  required,
  placeholder = "select",
  icon,
  data,
  searchable = false,
  bgWhite = false,
  setValue,
  totalInput,
  champs,
  isCondition = false,
  position = "fixed",
  isTop = false,
  extraCss,
}) => {
  const elementRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { mode } = useSelector((state) => state.genearlReducer);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState();
  const [width, setWidth] = useState(0);
  const [direction, setDirection] = useState("top");
  const [containerHeight, setContainerHeight] = useState(0);
  const filteredData = data?.filter(({ label }) =>
    label?.toLowerCase()?.includes(search?.toLowerCase())
  );
  const handleSelect = (label, value) => {
    if (selected === label) {
      setSelected();
      setValue({ ...totalInput, [champs]: null });
    } else {
      setSelected(label);
      setValue({ ...totalInput, [champs]: value });
    }
    setOpen(false);
  };
  useEffect(() => {
    if (totalInput[champs]) {
      const selected = data?.find((el) =>
        isCondition
          ? el?.id === totalInput[champs]
          : el?.value === totalInput[champs]
      )?.label;
      setSelected(selected);
    }
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, [totalInput, data, champs]);
  useEffect(() => {
    if (!elementRef?.current) return;
    const containerHeight =
      elementRef.current.getBoundingClientRect().height * data?.length ?? 0;
    setContainerHeight(containerHeight);
    const top = elementRef.current.getBoundingClientRect().top;
    const availableHeight = window.screen.height - top;
    if (availableHeight < containerHeight + 100) {
      setDirection("top");
    } else {
      setDirection("bottom");
    }
  }, [data?.length]);
  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="w-100" ref={elementRef}>
        <LightDarkText
          className={`text-start bold f12 mb-2 ${extraCss}`}
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          {label} {required ? <span className="text-red">*</span> : null}
          <div></div>
        </LightDarkText>
        <div className="w-100 position-relative">
          <div
            className={`w-100 h40  radius05 d-flex align-items-center  ps-2 ${
              mode === "light" ? "lightInput" : "darkInput"
            } ${bgWhite && mode === "light" && "bg-white"}`}
            onClick={() => setOpen(!open)}
          >
            <div className="w90 d-flex align-items-center ">
              {selected ? (
                <LightDarkText
                  className={"text-start f12 ms-1"}
                  color={
                    mode === "light" ? "text-dark-blue" : "text-light-text"
                  }
                >
                  {selected}
                </LightDarkText>
              ) : (
                <>
                  {icon}
                  <LightDarkText
                    className={"text-start f12 ms-1"}
                    color={
                      mode === "light" ? "text-dark-blue" : "text-light-text"
                    }
                  >
                    {placeholder}
                  </LightDarkText>
                </>
              )}
            </div>
            <div className="w10 d-flex justify-content-end pe-2">
              <ArrowDownIcon />
            </div>
          </div>
          {open && (
            <div
              className={`position-${position} ${
                mode === "light" ? "bg-light-gray" : "bg-dark-grey-bg"
              } selectContainer  ms-1 ${isTop && "select-direction-top"}`}
              style={{ width: width - 8, maxHeight: 200 }}
            >
              {searchable && (
                <div className="mx-2 mb-2">
                  <LightDarkInput
                    placeholder={"search"}
                    champs={"search"}
                    setValue={(value) => setSearch(value["search"])}
                  />
                </div>
              )}
              {filteredData?.map(({ id, value, label }, index) => {
                return (
                  <LightDarkText
                    key={index}
                    className={
                      "d-flex justify-content-between bold f12 p-2 selectItem"
                    }
                    style={{
                      backgroundColor: selected === label && "#59638b26",
                    }}
                    onclickCallback={() => handleSelect(label, value)}
                  >
                    {label}
                    {selected === label && <CheckIcon />}
                  </LightDarkText>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Select;

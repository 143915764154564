import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import TicketInfosForm from "./TicketInfosForm";
import FormHeader from "../../Common/Forms/FormHeader";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import FormRequiredMessage from "../../Common/Forms/FormRequiredMessage";

import { prepareticketsFields } from "../ticketUtils";
import { checkRequiredTicketInputs } from "./ticketFormUtils";

import useWindowWidth from "../../../Hooks/useWindowWidth";

import { closeModal } from "../../../REDUX/actions/modal";
import { clearTempUpload } from "../../../REDUX/actions/files";
import { getDevices } from "../../../REDUX/actions/sharedData";
import { createTicket, updateTicket } from "../../../REDUX/actions/tickets";

const TicketForm = ({ isNew, ticket }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const navigate = useNavigate();
  const location = useLocation();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { ticketRoute, ticketHandleLogic, ticketFields } = useSelector(
    (state) => state.organisationsReducer
  );

  const {
    societes,
    devicesPerPrestations,
    prestations,
    priorityValues,
    ticketStatutValues,
  } = useSelector((state) => state.sharedDataReducer);
  const { temporarySelected, isConfidentiel } = useSelector(
    (state) => state.filesReducer
  );

  const { user } = useSelector((state) => state.userReducer);

  const [statutHasChanged, setStatutHasChanged] = useState(false);
  const [ticketInputs, setticketInputs] = useState(
    isNew
      ? {
          name: "",
          description: "",
          priority: [],
          societe: societes.length === 1 ? societes : [],
          prestation: prestations.length === 1 ? prestations : [],
          type: [],
          departements: [],
          statut: [ticketStatutValues[0]],
          responsibleOperators: [],
          device:
            devicesPerPrestations.length === 1 ? devicesPerPrestations : [],
          creator: user.id,
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        }
      : {
          ...ticket?.attributes,
          ...Object.fromEntries(
            ticket.attributes.additionalFields.map((item) => [
              item.name,
              item.value,
            ])
          ),
          name: ticket.attributes.name,
          description: ticket.attributes.description,
          priority: [
            {
              label: priorityValues[ticket.attributes.priority - 1].label,
              value: ticket.attributes.priority,
            },
          ],
          statut: [
            {
              label: ticketStatutValues[ticket.attributes.statut - 1].label,
              value: ticket.attributes.statut,
            },
          ],
          prestation: [
            {
              label: ticket?.attributes?.prestation?.data?.attributes?.name,
              value: ticket?.attributes?.prestation?.data?.id,
            },
          ],
          departements: [
            ticket.attributes.departements.data.map((departement) => ({
              label: `${departement.attributes.code}-${departement.attributes.name}`,
              value: departement.id,
            })),
          ],
          type: [
            {
              label: ticket.attributes.type.data.attributes.name,
              value: ticket.attributes.type.data.id,
            },
          ],
          device: [
            {
              label: ticket?.attributes?.device?.data?.attributes?.name,
              value: ticket?.attributes?.device?.data?.id,
              prestation:
                ticket?.attributes?.device?.data?.attributes?.prestation?.data
                  ?.id,
            },
          ],
        }
  );

  const handleSubmitTicket = () => {
    if (isNew) {
      dispatch(
        createTicket(
          prepareticketsFields(ticketInputs),
          {
            files: temporarySelected,
            isConfidentiel: isConfidentiel,
          },
          (newTicket) =>
            location.pathname !== ticketRoute &&
            navigate(`${ticketRoute}/${newTicket}`, {
              state: { notification: true },
            })
        )
      );
      dispatch(clearTempUpload());
      dispatch(closeModal());
    } else {
      dispatch(
        updateTicket(
          prepareticketsFields({ ...ticketInputs, id: ticket.id }),
          statutHasChanged,
          user?.id
        )
      );
      dispatch(clearTempUpload());
      dispatch(closeModal());
    }
  };

  useEffect(() => {
    if (isNew) {
      setStatutHasChanged(false);
      return;
    }
    if (
      ticket?.attributes?.statut !== ticketInputs?.statut[0]?.value &&
      ticket?.attributes?.statut &&
      ticketInputs?.statut[0]?.value
    ) {
      setStatutHasChanged(true);
    } else {
      setStatutHasChanged(false);
    }
  }, [ticket?.attributes?.statut, isNew, ticketInputs?.statut]);

  useEffect(() => {
    dispatch(getDevices(ticketInputs?.prestation));
  }, [ticketInputs?.prestation, dispatch, ticket]);

  useEffect(() => {
    setticketInputs((state) => ({
      ...state,
      device: devicesPerPrestations?.filter(
        (device) => +ticket?.attributes?.device?.data?.id === +device?.value
      ),
    }));
  }, [devicesPerPrestations, ticket]);

  return (
    <div
      className={`${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      } p-4 radius05 directionC sideChatListContainer`}
      style={{
        maxHeight: `${isNew && "700px"}`,
        width: `${
          isNew ? (screenWidth > 600 ? "700px" : "calc(100vw - 50px)") : ""
        }`,
      }}
      onSubmit={(e) => e.preventDefault()}
    >
      <FormHeader
        formTitle={isNew ? "Créer un ticket" : "Modifier le ticket"}
      />
      <TicketInfosForm
        isNew={isNew}
        ticketInputs={ticketInputs}
        setticketInputs={setticketInputs}
      />
      <FormRequiredMessage />
      <div
        className={`${
          screenWidth < 600 && !isNew ? "col-8" : isNew ? "col-5" : "col-6"
        } align-self-center mt-4 p-0`}
      >
        <ColoredButton
          disabled={checkRequiredTicketInputs(
            isNew,
            ticketInputs,
            ticketHandleLogic,
            ticketFields,
            prestations,
            devicesPerPrestations,
            societes
          )}
          textButton={
            isNew ? "Créer le ticket" : "Mettre à jour les informations"
          }
          color="light-blue"
          callback={() => handleSubmitTicket()}
        />
      </div>
    </div>
  );
};

export default TicketForm;

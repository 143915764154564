import moment from "moment";
import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";

import { handleFirstLastName } from "../../../../Utils/utils";

const StatusMessage = ({ color, icon, statut, date, by }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div
      className={`radius05 m-auto d-flex my-4 gap-4 align-items-center p-2 example-${color}`}
    >
      <LightDarkText
        className="custom-ticket-font-size bold text-center flex-1"
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        {icon} Ticket {statut} le {moment(date).format("DD/MM/YYYY à HH:mm ")}
        par {handleFirstLastName(by)}
        {by?.attributes?.poste?.data &&
          `, ${by?.attributes?.poste?.data?.attributes?.name}`}
      </LightDarkText>
    </div>
  );
};

export default StatusMessage;

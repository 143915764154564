import React from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";

import {
  Tickets,
  InresolvedTickets,
  Interventions,
} from "../Common/LightDarkSvg";
import LightDarkText from "../Common/LightDarkText";
import LightDarkContainer from "../Common/LightDarkContainer";

import useWindowWidth from "../../Hooks/useWindowWidth";

const TicketsGeneralStats = ({ tickets, interventions, hours }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const screenWidth = useWindowWidth();

  const ticketOverview = [
    {
      icon: Tickets,
      number: tickets,
      title: "Tickets créés",
    },
    {
      icon: InresolvedTickets,
      number: interventions,
      title: "Interventions créées",
    },
    {
      icon: Interventions,
      number: hours,
      title: "D'interventions traitées",
      text: " Heures",
    },
  ];
  return (
    <div className="d-flex justify-content-between">
      {ticketOverview.map((el, i) => (
        <LightDarkContainer
          key={i}
          className="d-flex justify-content-start align-items-center px-2  radius05"
          colorLight="white"
          colorDark="dark-grey-bg"
          overviewContainer={true}
        >
          <el.icon size={screenWidth < 1200 ? 30 : null} />
          <div className="ms-lg-4 ">
            <LightDarkText
              className={"bolder custom-bold-font-size text-start"}
            >
              <CountUp duration={1} end={el.number} />
              {el.text}
            </LightDarkText>
            <LightDarkText
              className={"bold custom-font-size"}
              color={
                mode === "light" ? "text-blue-grey" : "text-light-blue-grey"
              }
            >
              {el.title}
            </LightDarkText>
          </div>
        </LightDarkContainer>
      ))}
    </div>
  );
};

export default TicketsGeneralStats;

import { useSelector } from "react-redux";

import MenuTabs from "../MenuTabs";

const SideBarTabs = ({
  data,
  tabsProps,
  isToggle,
  setIsToggle,
  sideBarHeaderInfo = false,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      <div className="alignH w-100 justify-content-evenly">
        {data(tabsProps)?.map((tab, index) =>
          !tab.hideTab ? (
            <MenuTabs
              key={index}
              tabTitle={tab.title}
              toggleValue={tab.toggleValue}
              isToggle={isToggle}
              setIsToggle={() => setIsToggle(tab.toggleValue)}
              isVisible={tab.isVisible}
              sideBarHeaderInfo={sideBarHeaderInfo}
            />
          ) : null
        )}
      </div>
      <div
        className="mt-1 mb-3"
        style={{
          borderBottom: `3px solid ${mode === "light" ? "#F8F9FF" : "#252C45"}`,
        }}
      />
    </>
  );
};

export default SideBarTabs;

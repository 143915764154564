import React from "react";
import { useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import Uploader from "../../Common/Uploader";
import EmailInput from "../../Common/EmailInput";
import LightDarkText from "../../Common/LightDarkText";
import LightDarkInput from "../../Common/LightDarkInput";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";

import { clientRoles, genres, operatorRoles } from "./roles";

import "react-phone-number-input/style.css";

const UserInfoForm = ({
  setAvatar,
  avatar,
  currentImage,
  clientInputs,
  setclientInputs,
  client,
  isNew,
  setemailInfo,
  emailInfo,
  isOp,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { postes, languages } = useSelector((state) => state.sharedDataReducer);
  const { userAdditionalFields } = useSelector(
    (state) => state.organisationsReducer
  );

  const { user } = useSelector((state) => state.userReducer);
  return (
    <div className="pb-5">
      <div className="mt-4 ">
        <div className="row align-items-center mb-3">
          {!emailInfo.alreadyUsed && (
            <div className={`col-4`}>
              <Uploader
                setState={setAvatar}
                state={avatar}
                id="image"
                name="image"
                placeholder="Ajouter une photo de profil"
                defaultImage={currentImage}
              />
            </div>
          )}
          <div className={`col`}>
            <div className="row">
              <div className="col-12 my-1">
                <EmailInput
                  clientInputs={clientInputs}
                  setclientInputs={setclientInputs}
                  client={client}
                  isNew={isNew}
                  setemailInfo={setemailInfo}
                  emailInfo={emailInfo}
                />
              </div>
            </div>
            {!emailInfo.alreadyUsed && (
              <div className="row mt-4">
                <div className="col">
                  <LightDarkInput
                    placeholder={"Nom"}
                    label={"Nom"}
                    totalInputs={clientInputs}
                    disabled={emailInfo.alreadyUsed}
                    setValue={setclientInputs}
                    champs="lastName"
                    required
                  />
                </div>
                <div className="col">
                  <LightDarkInput
                    placeholder={"Prénom"}
                    label={"Prénom"}
                    disabled={emailInfo.alreadyUsed}
                    totalInputs={clientInputs}
                    setValue={setclientInputs}
                    champs="firstName"
                    required
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!emailInfo.inMyOrg && (
        <div className="row align-items-center mt-4">
          <div className={"col"}>
            <LighDarkMultiChoice
              label="Poste"
              data={postes}
              createNew
              totalInputs={clientInputs}
              setValue={setclientInputs}
              multiple={false}
              champs="poste"
              required
              selectMoreInfo="Vous ne trouvez pas votre poste ? </br> Vous pouvez le créer."
            />
          </div>
          {user.id !== client?.id ? (
            <div className="col">
              <LighDarkMultiChoice
                label="Rôle"
                required
                data={isOp ? operatorRoles : clientRoles}
                totalInputs={clientInputs}
                setValue={setclientInputs}
                multiple={false}
                champs="role"
              />
            </div>
          ) : null}
          {languages.length ? (
            <div className="col">
              <LighDarkMultiChoice
                label="Langue préférée"
                data={languages}
                totalInputs={clientInputs}
                setValue={setclientInputs}
                multiple={false}
                champs="preferredLanguage"
              />
            </div>
          ) : null}
        </div>
      )}

      {!emailInfo.alreadyUsed ? (
        <div className="row align-items-center mt-4">
          <div className="col my-1">
            <LightDarkInput
              placeholder={"Date de naissance"}
              label={"Date de naissance"}
              type="date"
              disabled={emailInfo.alreadyUsed}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs="birthDate"
            />
          </div>
          <div className="col my-1">
            <LighDarkMultiChoice
              label="Genre"
              data={genres}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              multiple={false}
              champs="genre"
            />
          </div>
          <div className="col my-1">
            <LightDarkInput
              placeholder={"Numéro de téléphone"}
              label={"Numéro de téléphone"}
              disabled={emailInfo.alreadyUsed}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs="phoneNumber"
              value={clientInputs.phoneNumber}
              phoneInput
            />
            {clientInputs.phoneNumber &&
              (isValidPhoneNumber(clientInputs.phoneNumber) ? undefined : (
                <span
                  className="field-error f11 position-absolute mt-1 text-red"
                  style={{
                    minWidth: "max-content",
                  }}
                >
                  Numéro de téléphone non valide
                </span>
              ))}
          </div>
        </div>
      ) : null}
      {!emailInfo.alreadyUsed ? (
        <div className="row align-items-center mt-4">
          <div className="col">
            <LightDarkInput
              placeholder={"Adresse"}
              label={"Adresse"}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs="adress"
            />
          </div>
          <div className="col">
            <LightDarkInput
              placeholder={"Code postal"}
              label={"Code postal"}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs="zipCode"
            />
          </div>
          <div className="col">
            <LightDarkInput
              placeholder={"Ville"}
              label={"Ville"}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs="city"
            />
          </div>{" "}
        </div>
      ) : null}
      <div className="row align-items-center">
        {userAdditionalFields.map((field) => (
          <div className={`w${field.attributes.width} mt-4`} key={field.id}>
            <LightDarkInput
              label={field.attributes.title}
              placeholder={field.attributes.placeHolder}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs={field.attributes.name}
              required={field.attributes.required}
            />
          </div>
        ))}
      </div>

      {!emailInfo.alreadyUsed ? null : (
        <LightDarkText
          className="f12 italic mt-5 mb-3"
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          L'utilisateur{" "}
          <span className="bolder">{emailInfo.userFound.email}</span>{" "}
          {emailInfo.inMyOrg ? (
            <>est déjà dans votre organisation</>
          ) : (
            <>
              est déjà enregistré dans une organisation{" "}
              <span className="bold">Agilix</span> .
              <br />
              {isNew
                ? `Vous avez la possibilité de l'ajouter à votre espace de travail. Une confirmation par email lui sera envoyée, et il sera ajouté après avoir confirmé son adhésion.`
                : "Vous ne pouvez pas utiliser cette adresse mail"}
            </>
          )}
        </LightDarkText>
      )}
    </div>
  );
};

export default UserInfoForm;

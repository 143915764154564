import React from "react";
import LightDarkText from "../../Common/LightDarkText";
import UserThumbnail from "../../Common/UserThumbnail";
import { useSelector } from "react-redux";
import { handleFirstLastName } from "../../../Utils/utils";

const RightLinks = ({ ticket }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const handleScroll = (id) => {
    document.querySelector(`#messageId-${id}`).scrollIntoView({
      behavior: "smooth",
    });
  };
  console.log(ticket.attributes.message_links.data);
  return (
    <div className="d-flex flex-column gap-2 scrollable mt-4">
      {ticket.attributes.message_links.data
        .filter(
          (el) =>
            el.attributes.type === "URL" ||
            el.attributes.type === "unknown" ||
            el.attributes.type === "email"
        )
        .map((el) => (
          <div
            key={el.id}
            className="text-start border-bottom pb-2 opacityHover"
          >
            <LightDarkText className="d-flex align-items-start gap-2 f12 ">
              <UserThumbnail
                userId={el.attributes.message.data.attributes.sender?.data?.id}
                connected={
                  el.attributes.message.data.attributes.sender?.data?.attributes
                    ?.connected
                }
                lastConnected={
                  el.attributes.message.data.attributes.sender?.data?.attributes
                    ?.lastConnected
                }
                apiImg={
                  el.attributes.message.data.attributes.sender?.data?.attributes
                    ?.avatar?.data?.attributes?.image?.data?.attributes?.url
                }
                img={`user${
                  el.attributes.message.data.attributes.sender?.data?.attributes
                    ?.genre || "3"
                }`}
                size={40}
              />
              {el.attributes.type === "URL" ||
              el.attributes.type === "unknown" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={el.attributes.link}
                  className="text-decoration-underline bold textBlueHover"
                >
                  {el.attributes.link}
                </a>
              ) : el.attributes.type === "email" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`mailto:${el.attributes.link}`}
                  className="text-decoration-underline bold textBlueHover"
                >
                  {el.attributes.link}
                </a>
              ) : null}
            </LightDarkText>
            <LightDarkText
              color={mode === "light" ? "text-light-text" : "text-white"}
              className="text-start bold f10 pointer mt-3"
              onclickCallback={() => {
                handleScroll(el.attributes.message.data.id);
              }}
            >
              Ajouté par{" "}
              {handleFirstLastName(
                el.attributes.message.data.attributes.sender.data
              )}
            </LightDarkText>
            <LightDarkText
              className="text-end xbold f10 pointer textBlueHover"
              onclickCallback={() => {
                handleScroll(el.attributes.message.data.id);
              }}
            >
              voir le message
            </LightDarkText>
          </div>
        ))}
    </div>
  );
};

export default RightLinks;

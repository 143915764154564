import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ChevronRight, CloudFolder } from "../Common/LightDarkSvg";
import LightDarkText from "../Common/LightDarkText";

const CloudBeadCrumb = ({ path, name, elementRoute, siteId = null }) => {
  const navigate = useNavigate();
  const [breadCrumbData, setbreadCrumbData] = useState([]);
  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );

  useEffect(() => {
    const handlebreadCrumb = () => {
      const pathParts = path.split("\\");

      const result = [];

      for (let i = 1; i < pathParts.length; i += 2) {
        const id = pathParts[i];
        const name = pathParts[i + 1];

        result.push({ id, name });
      }

      setbreadCrumbData(result);
    };
    if (path) {
      handlebreadCrumb();
    }
  }, [path]);

  const { documentsRoute } = useSelector((state) => state.organisationsReducer);

  return (
    <div className="alignH gap-2 mt-3 mb-2 flex-wrap">
      <LightDarkText
        className="text-start f14 bold pointer t-02 clickable-text"
        onclickCallback={() => navigate(documentsRoute)}
      >
        {"Mes dossiers"}
      </LightDarkText>
      <ChevronRight darkColor="#354074" />
      {breadCrumbData.map((el) => (
        <div className="alignH gap-1 t-02 clickable-text" key={el.id}>
          <div
            //SUPPRIMER DOSSIER COMPLET TELECHARGER DOSSIER COMPLET
            className="alignH gap-1 t-02 clickable-text"
            onClick={() =>
              navigate(
                `${organisationUrl}/${elementRoute}/${el.id}?site=${siteId}`
              )
            }
          >
            <CloudFolder size={24} />
            <LightDarkText className="text-start f14 pointer">
              {el.name}
            </LightDarkText>
          </div>
          <ChevronRight darkColor="#354074" />
        </div>
      ))}
      <div className="alignH gap-1 t-02">
        <CloudFolder size={24} />
        <LightDarkText className="alignH xbold f16">{name}</LightDarkText>
      </div>
    </div>
  );
};

export default CloudBeadCrumb;

import Api from "../../Api/Api";
import {
  READ_NOTIFICATION,
  UNREAD_NOTIFICATION,
} from "../Graphql/Mutations/notification";
import { GET_NOTIFICATION } from "../Graphql/Queries/notification";
import store from "../store/store";
import client from "./Client";
import { handleInfo } from "./toastMessage";
import { notificationsQuery } from "../apiQueries/apiQueries";
import { getAllTickets, getTicketItem } from "./tickets";

const color = "light-blue";

export const fetchNewNotification =
  ({ notificationId, type }) =>
  async (dispatch) => {
    try {
      if (!notificationId) return;
      const orgId =
        store.getState().organisationsReducer.selectedOrganisation.id;
      if (!orgId) {
        return;
      }
      const { data } = await client.query({
        query: GET_NOTIFICATION,
        variables: {
          id: notificationId,
          orgId: orgId,
        },
      });
      dispatch({
        type: "FETCH_NEW_NOTIFICATION",
        payload: data?.notification?.data,
      });
      if (type === "File") {
        const fullName =
          data?.notification?.data?.attributes?.from?.data?.attributes
            .firstName +
          " " +
          data?.notification?.data?.attributes?.from?.data?.attributes.lastName;
        dispatch(
          handleInfo(`${fullName} a partagé un fichier avec vous.`, 1, color)
        );
      } else if (type === "Folder") {
        const fullName =
          data?.notification?.data?.attributes?.from?.data?.attributes
            .firstName +
          " " +
          data?.notification?.data?.attributes?.from?.data?.attributes.lastName;
        dispatch(
          handleInfo(`${fullName} a partagé un dossier avec vous.`, 1, color)
        );
      }
    } catch {}
  };

export const fetchNotifications = () => async (dispatch, getState) => {
  dispatch({ type: "NOTIF_EDIT_LOAD", payload: true });

  try {
    const id = getState().userReducer.user?.id;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    const { data } = await Api.get(
      `/api/notifications?${notificationsQuery(id, orgId)}`
    );
    dispatch({
      type: "FETCH_NEW_NOTIFICATIONS",
      payload: data?.data,
    });
    dispatch({ type: "NOTIF_EDIT_LOAD", payload: false });
  } catch {
    dispatch({ type: "NOTIF_EDIT_LOAD", payload: false });
  }
};

export const readNotifications = (id) => async (dispatch) => {
  try {
    await client.mutate({
      mutation: READ_NOTIFICATION,
      variables: {
        id,
      },
    });
    dispatch(fetchNotifications());
    dispatch({ type: "READ_NOTIF", payload: id });
  } catch (error) {
    //console.log(error);
  }
};

export const handleReadTicketNotifications =
  (type, ticketId) => async (dispatch) => {
    const id = store.getState().userReducer.user?.id;
    try {
      await Api.post("/api/readGroupNotifs", { type, ticketId, id });
      dispatch(fetchNotifications());
      dispatch(getTicketItem(ticketId, () => {}, false));
    } catch (error) {
      //console.log(error);
    }
  };

export const handleAllNotifications =
  (readNotread, isCalendarNotifications) => async (dispatch) => {
    const id = store.getState().userReducer.user?.id;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    dispatch({ type: "NOTIF_EDIT_LOAD", payload: true });
    try {
      await Api.post("/api/handleAllNotifications", {
        readNotread,
        isCalendarNotifications,
        id,
        orgId,
      });
      dispatch(fetchNotifications());
      dispatch(getAllTickets(false));

      dispatch({ type: "NOTIF_EDIT_LOAD", payload: false });
    } catch (error) {
      dispatch({ type: "NOTIF_EDIT_LOAD", payload: false });

      //console.log(error);
    }
  };

export const markAsNotReadNotification = (id, ticketId) => async (dispatch) => {
  try {
    await client.mutate({
      mutation: UNREAD_NOTIFICATION,
      variables: {
        id,
      },
    });
    dispatch({ type: "UNREAD_NOTIF", payload: id });
    dispatch(fetchNotifications());
    if (ticketId) {
      dispatch(getTicketItem(ticketId, () => {}, false));
    }
  } catch (error) {
    //console.log(error);
  }
};

import React from "react";
import { useSelector } from "react-redux";

const NotificationCalendarName = ({ notification }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { sharedEvents } = useSelector((state) => state.calendarsReducer);

  return (
    <>
      {(notification?.calendar?.data?.id || notification?.event?.data?.id) &&
        !sharedEvents?.find(
          (e) => +e?.id == +notification?.event?.data?.id
        ) && (
          <>
            {" "}
            {!notification?.calendar?.data?.id && "sur l'agenda"}
            <span
              className={`${
                mode === "light" ? "text-light-blue" : "text-light-blue-grey"
              } `}
            >
              {` ${
                notification.type === "newCalendar" ||
                notification?.type === "sharedCalendar"
                  ? notification?.calendar?.data?.attributes?.name
                  : notification?.event?.data?.attributes?.calendar?.data
                      ?.attributes?.name
              } `}
            </span>
          </>
        )}
    </>
  );
};

export default NotificationCalendarName;

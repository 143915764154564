import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import UsersList from "../../Components/Clients/UsersList";
import UserSideBar from "../../Components/Clients/UserSideBar";
import UserForm from "../../Components/Clients/UserForm/UserForm";
import SideBar from "../../Components/Common/TableComponents/SideBar";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";

import { openModal } from "../../REDUX/actions/modal";
import { closeSideBarCard } from "../../REDUX/actions/general";
import { resetClientFilters } from "../../REDUX/actions/filters";
import { userSideBarTabs } from "../../Components/Societies/SocietyForm/societyFormUtils";

const ClientsView = () => {
  const dispatch = useDispatch();

  const { openSideBar } = useSelector((state) => state.genearlReducer);
  const { canCreateCollabs, isSupervisor } = useSelector(
    (state) => state.roleReducer
  );
  const { selectedUser } = useSelector((state) => state.clientsReducer);
  const [isToggle, setIsToggle] = useState("details");
  const [searchClients, setsearchClients] = useState("");

  useEffect(() => {
    return () => dispatch(resetClientFilters());
  }, [dispatch]);

  return (
    <div className="container-fluid">
      <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className={`${canCreateCollabs ? "col-10" : "col-12"} h100`}>
          <ListHeader showSocityFilter={false}>
            <SearchInput
              placeholder="ID, nom et prénom, adresse, email, téléphone..."
              onChange={(e) => {
                setsearchClients(e.target.value);
                dispatch(closeSideBarCard());
              }}
            />
          </ListHeader>
        </div>
        {canCreateCollabs && (
          <div className="col-2 ms-2 h100">
            <ColoredButton
              textButton="Ajouter un client"
              color="light-blue"
              height="100"
              callback={() => {
                dispatch(
                  openModal({
                    type: "small",
                    content: <UserForm isNew={true} />,
                  })
                );
              }}
            />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between gap-3 p-0">
        <UsersList search={searchClients} />
        {openSideBar && (
          <SideBar
            selectedItem={selectedUser}
            setSelectedItem={() => {}}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            sideBarHeaderInfo
            userSideBar
            tabsData={userSideBarTabs}
            tabsProps={{ isSupervisor }}
          >
            <UserSideBar isToggle={isToggle} />
          </SideBar>
        )}
      </div>
    </div>
  );
};

export default ClientsView;

const initialState = {
  notificationSettings: {},
  notificationSettingsLoading: false,
};
const notificationSettingsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "NOTIF_SETTINGS_LOADING":
      return { ...state, notificationSettingsLoading: true };
    case "FETCH_NOTIF_SETTINGS":
    case "UPDATE_NOTIF_SETTINGS":
    case "CREATE_NOTIF_SETTINGS":
      return {
        ...state,
        notificationSettings: payload || {},
        notificationSettingsLoading: false,
      };

    case "RESET_NOTIF_SETTINGS":
      return {
        ...state,
        notificationSettings: {},
        notificationSettingsLoading: false,
      };

    default:
      return state;
  }
};

export default notificationSettingsReducer;

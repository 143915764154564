import Api from "../../Api/Api";
import { ADD_DEVICE } from "../Graphql/Mutations/SharedData";
import store from "../store/store";
import client from "./Client";
import { getAllTickets } from "./tickets";
import { handleError } from "./toastMessage";

export const getInitialData =
  (withLoading = true) =>
  async (dispatch) => {
    const user = store.getState().userReducer.user;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    if (withLoading) {
      dispatch({ type: "INITIAL_LOADING" });
    }
    try {
      const initialData = await Api.get(
        `/api/getInitialData?user=${user.id}&organisation=${orgId}`
      );
      dispatch(getAllTickets(withLoading));
      dispatch({ type: "FETCH_INITIAL_DATA", payload: initialData.data });
    } catch (error) {
      console.log(error);
    }
  };

export const addPoste = (name) => async (dispatch) => {
  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  try {
    const { data } = await Api.post("/api/postes", {
      data: {
        name: name,
        organisation: orgId,
      },
    });

    dispatch({ type: "ADD_POSTE", payload: data.data });
    return { value: data.data.id, label: data.data.attributes.name };
  } catch (error) {
    dispatch(handleError());
  }
};

export const addDevice = (name) => async (dispatch) => {
  const { data } = await client.mutate({
    mutation: ADD_DEVICE(name),
  });

  dispatch({ type: "ADD_DEVICE", payload: data.createDevice.data });
};

export const getDevices = (prestations) => async (dispatch) => {
  dispatch({ type: "DEVICES_PER_PRESTATIONS", payload: prestations });
};

export const resetSelectedDevices = () => (dispatch) =>
  dispatch({ type: "RESET_PER_PRESTATIONS" });

import QueryString from "qs";

export const organisationQuery = (userId) => {
  return QueryString.stringify({
    filters: {
      user_org_permissions: { user: { id: { $in: userId } }, archived: false },
    },
    fields: [
      "id",
      "url",
      "name",
      "interventionLogic",
      "ticketHandleLogic",
      "withForfait",
      "withProspects",
      "withClicks",
      "withDuration",
      "hasDiffusion",
      "singleOperator",
    ],
    populate: {
      logo: {
        fields: ["id", "url"],
      },
      logo_mail: {
        fields: ["id", "url"],
      },
      wording: "*",
      languages: "*",
      notifications: {
        fields: ["id"],
        filters: {
          to_user: {
            id: { $eq: userId },
          },
          read: { $eq: false },
        },
      },
    },
  });
};

import React from "react";
import ListHeader from "../Common/ListHeader";
import SearchInput from "../Common/SearchInput";
import ColoredButton from "../Common/Buttons/ColoredButton";
import TicketForm from "../Tickets/TicketForm/TicketForm";
import { openModal } from "../../REDUX/actions/modal";
import { useDispatch, useSelector } from "react-redux";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { handleSearch } from "../../REDUX/actions/filters";
import { handleFilterTickets } from "../../REDUX/actions/tickets";
import { useEffect } from "react";

const TicketsLayout = () => {
  const dispatch = useDispatch();

  const screenWidth = useWindowWidth();

  const { canCreateTickets, isSupervisor } = useSelector(
    (state) => state.roleReducer
  );

  const { selectedStatus, selectedSociety, search } = useSelector(
    (state) => state.filtersReducer
  );
  const { tickets } = useSelector((state) => state.ticketsReducer);

  useEffect(() => {
    dispatch(handleFilterTickets(tickets));
  }, [search, tickets, selectedSociety, selectedStatus]);

  return (
    <div className="container-fluid d-flex flex-row align-items-center justify-content-between h50px mb-2">
      <div className={`${canCreateTickets ? "col-10" : "col-12"} h100`}>
        <ListHeader showTicketFilter={true} showOperatorsFilter={isSupervisor}>
          <SearchInput
            placeholder={
              screenWidth < 600 ? "Recherche" : "ID, ticket, société..."
            }
            onChange={(e) => dispatch(handleSearch(e.target.value))}
          />
        </ListHeader>
      </div>
      {canCreateTickets ? (
        <div className="col-2 ms-2 h100">
          <ColoredButton
            height="100"
            textButton={screenWidth > 600 && "Créer un ticket"}
            icon={screenWidth < 600 && "plus"}
            color="light-blue"
            callback={() =>
              dispatch(
                openModal({
                  type: "small",
                  content: <TicketForm isNew={true} />,
                })
              )
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default TicketsLayout;

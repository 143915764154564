import React from "react";
import moment from "moment";
import StatusButton from "../../Common/Buttons/StatusButton";
import LightDarkText from "../../Common/LightDarkText";
import { handleInterventionStatut } from "../../Interventions/interventionUtils";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { useSelector } from "react-redux";

const RightRelatedInterventions = ({ ticket }) => {
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  const handleScroll = (id) => {
    document.querySelector(`#inter${id}`).scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div className="directionC gap-2 mb-2">
      {ticket.attributes?.interventions.data.map((intervention, index) => (
        <div key={index}>
          <div
            onClick={() => handleScroll(intervention.id)}
            className={`d-flex justify-content-between align-items-center w-100 radius05 my-2 pointer t-02 scaledAttachement`}
          >
            <div
              className={`directionC align-items-start justify-content-center w50`}
            >
              <LightDarkText className="text-start bold custom-ticket-font-size break-word pe-2">
                {intervention.attributes.title}
                <span
                  className={
                    "custom-ticket-font-size bolder text-start text-light-blue ms-2"
                  }
                >
                  #{intervention.id}
                </span>
              </LightDarkText>
              <div className="d-flex w-100">
                <LightDarkText
                  className="text-start custom-ticket-font-size bold me-1 w-100"
                  color={
                    mode === "light" ? "text-blue-grey" : "text-light-text"
                  }
                >
                  {`Le 
                  ${moment(intervention.attributes.createdAt).format(
                    "DD.MM.YYYY"
                  )}`}
                </LightDarkText>
              </div>
            </div>
            <div className={`p-0 d-flex justify-content-end w50`}>
              <StatusButton
                height={screenWidth < 1200 ? "30px" : "40px"}
                type={
                  handleInterventionStatut(intervention.attributes.statut).color
                }
                text={
                  handleInterventionStatut(intervention.attributes.statut).text
                }
                background={
                  handleInterventionStatut(intervention.attributes.statut).bg
                }
              />
            </div>
          </div>
          <div
            style={{
              borderBottom: `2px solid ${
                mode === "light" ? "#F8F9FF" : "#252C45"
              }`,
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default RightRelatedInterventions;

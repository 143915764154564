import {
  HIDE_SIDE_BAR_CARD,
  OPEN_SIDE_BAR_CARD,
} from "../ActionsType/generalTypes";

const initialState = {
  mode: "light",
  sideBarShown: false,
  isNew: true,
  isEdit: false,
  fromCalendar: false,
  eventDetails: null,
  openSideBar: false,
  sideBarContent: null,
};

const genearlReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "DARK_MODE":
      return { ...state, mode: "dark" };
    case "LIGHT_MODE":
      return { ...state, mode: "light" };
    case "SHOW_SIDE_BAR":
      return {
        ...state,
        sideBarShown: true,
        isNew: payload?.isNew,
        isEdit: payload?.isEdit,
        fromCalendar: payload?.fromCalendar,
        eventDetails: payload?.eventDetails,
      };
    case "HIDE_SIDE_BAR":
      return { ...state, sideBarShown: false };
    case OPEN_SIDE_BAR_CARD:
      return { ...state, openSideBar: true };
    case HIDE_SIDE_BAR_CARD:
      return { ...state, openSideBar: false, sideBarContent: null };
    default:
      return state;
  }
};
export default genearlReducer;

export const filterNotificationsByType = (
  isCalendarNotifications,
  notifications
) => {
  const notificationTypes = isCalendarNotifications
    ? [
        "newCalendar",
        "sharedCalendar",
        "newEvent",
        "updateEvent",
        "revisionCreated",
        "eventToValidate",
        "validateEvent",
        "programEvent",
        "acceptEvent",
        "declineEvent",
      ]
    : [
        "Message",
        "Intervention",
        "Ticket_archive",
        "Ticket",
        "Action",
        "Mention",
        "Facture",
        "File",
        "Folder",
        "Folder_update",
        "newCollaborator",
      ];

  const filteredNotifications = notifications.filter((notification) =>
    notificationTypes.includes(notification?.attributes?.type?.toString())
  );

  return filteredNotifications;
};

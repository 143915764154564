import { useDispatch, useSelector } from "react-redux";

import ColoredButton from "../../../Common/Buttons/ColoredButton";
import EventRevision from "./EventRevision";

import { hideSideBar, showSideBar } from "../../../../REDUX/actions/general";
import { openModal } from "../../../../REDUX/actions/modal";
import {
  setEventPosition,
  validateEvent,
} from "../../../../REDUX/actions/calendars";

const EventActions = ({ event }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userReducer);

  return (
    <div className="mt-4 mb-4">
      {((event?.statut === 2 &&
        event?.validatingUsers?.data?.find((el) => el?.id == user?.id)) ||
        (event?.type === "Rendez-vous" &&
          event?.creator?.data?.id !== user?.id &&
          event?.participants?.data?.find((el) => el?.id == user?.id) &&
          !event?.confirmedParticipants?.data?.find(
            (el) => el?.id == user?.id
          ) &&
          !event?.declinedParticipants?.data?.find(
            (el) => el?.id == user?.id
          ))) && (
        <div className={`mt-4 d-flex gap-3 justify-content-between`}>
          <div className={"col-4"}>
            <ColoredButton
              color={"#0CCE91"}
              background="#0CCE9126"
              icon="check-square"
              square
              textButton="Accepter"
              callback={() => {
                dispatch(
                  validateEvent({
                    id: event?.id,
                    calendar: event?.calendar?.data,
                    statut: event?.type !== "Rendez-vous" && 3,
                    confirmedParticipants: [
                      ...event?.confirmedParticipants?.data?.map(
                        (el) => el?.id
                      ),
                      user?.id,
                    ],
                  })
                );
                dispatch(hideSideBar());
              }}
            />
          </div>
          <div className={"col-6"}>
            <ColoredButton
              color={"#FF2C2C"}
              background="#FF2C2C26"
              icon="x-square"
              textButton="Refuser"
              square
              callback={() => {
                dispatch(hideSideBar());
                event?.statut
                  ? dispatch(
                      openModal({
                        type: "small",
                        content: <EventRevision event={event} />,
                      })
                    )
                  : dispatch(
                      validateEvent({
                        id: event?.id,
                        calendar: event?.calendar?.data,
                        statut: event?.type !== "Rendez-vous" && 3,
                        declinedParticipants: [
                          ...event?.declinedParticipants?.data?.map(
                            (el) => el?.id
                          ),
                          user?.id,
                        ],
                      })
                    );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventActions;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AlerteNew,
  CalendarNew,
  NotificationNew,
} from "../../Common/LightDarkSvg";
import Alert from "../Alert/Alert";
import AlertTooltip from "../Alert/AlertToolTip";
import Notification from "../Notification/Notification";
import IconButton from "../../Common/Buttons/IconButton";

import { filterNotificationsByType } from "../Notification/notificationsUtils";

import { readAlert } from "../../../REDUX/actions/alerts";

const HeaderIcons = ({ showNewAlertTooltip, setShowNewAlertTooltip }) => {
  const dispatch = useDispatch();

  const { unreadAlerts } = useSelector((state) => state.alertsReducer);
  const { notifications } = useSelector((state) => state.notificationsReducer);

  const [showAlert, setShowAlert] = useState(false);
  const [showNotification, setshowNotification] = useState(false);
  const [showCalendarNotification, setshowCalendarNotification] =
    useState(false);

  return (
    <div className="d-flex gap-3">
      <div className="position-relative">
        <IconButton
          icon={
            <CalendarNew
              clicked={showCalendarNotification}
              newNotifs={
                notifications.filter(
                  (notification) =>
                    notification?.attributes?.read === false &&
                    (notification?.attributes?.type === "newCalendar" ||
                      notification?.attributes?.type === "sharedCalendar" ||
                      notification?.attributes?.type === "newEvent" ||
                      notification?.attributes?.type === "updateEvent" ||
                      notification?.attributes?.type === "revisionCreated" ||
                      notification?.attributes?.type === "eventToValidate" ||
                      notification?.attributes?.type === "validateEvent" ||
                      notification?.attributes?.type === "programEvent" ||
                      notification?.attributes?.type === "acceptEvent" ||
                      notification?.attributes?.type === "declineEvent")
                )?.length > 0
              }
            />
          }
          callBack={() => {
            setshowCalendarNotification(!showCalendarNotification);
          }}
          showAlertNotification={showCalendarNotification}
        />
        {showCalendarNotification && (
          <Notification
            show={setshowCalendarNotification}
            isCalendarNotifications
            notifications={filterNotificationsByType(true, notifications)}
          />
        )}
      </div>
      <div className="position-relative">
        <IconButton
          icon={
            <AlerteNew clicked={showAlert} newAlerts={unreadAlerts.length} />
          }
          callBack={() => {
            setShowAlert(!showAlert);
            unreadAlerts.map((alert) => dispatch(readAlert(alert)));
          }}
          showAlertNotification={showAlert}
        />
        {showNewAlertTooltip && <AlertTooltip show={setShowNewAlertTooltip} />}

        {showAlert && <Alert show={setShowAlert} />}
      </div>
      <div className="position-relative">
        <IconButton
          icon={
            <NotificationNew
              clicked={showNotification}
              newNotifs={
                notifications.filter(
                  (notification) =>
                    notification?.attributes?.read === false &&
                    (notification?.attributes?.type === "Message" ||
                      notification?.attributes?.type === "Intervention" ||
                      notification?.attributes?.type === "Ticket_archive" ||
                      notification?.attributes?.type === "Ticket" ||
                      notification?.attributes?.type === "Action" ||
                      notification?.attributes?.type === "Mention" ||
                      notification?.attributes?.type === "Facture" ||
                      notification?.attributes?.type === "File" ||
                      notification?.attributes?.type === "Folder" ||
                      notification?.attributes?.type === "Folder_update" ||
                      notification?.attributes?.type === "newCollaborator")
                )?.length > 0
              }
            />
          }
          callBack={() => {
            setshowNotification(!showNotification);
          }}
          showAlertNotification={showNotification}
        />
        {showNotification && (
          <Notification
            show={setshowNotification}
            notifications={filterNotificationsByType(false, notifications)}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderIcons;

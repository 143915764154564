import { useDispatch, useSelector } from "react-redux";
import ColoredButton from "../../../Common/Buttons/ColoredButton";
import EventTitle from "../EventCommonComponents/EventTitle";
import EventCalendar from "../EventCommonComponents/EventCalendar";
import EventDescription from "../EventCommonComponents/EventDescription";
import EventSociety from "../EventCommonComponents/EventSociety";
import EventDate from "../EventCommonComponents/EventDate";
import EventLocation from "../EventCommonComponents/EventLocation";
import EventRelatedTicket from "../EventCommonComponents/EventRelatedTicket";
import EventStatus from "../EventCommonComponents/EventStatus";
import ParticipantsList from "./ParticipantsList";
import EventRelatedFiles from "./EventRelatedFiles";
import EventSocialMedia from "./EventSocialMedia";
import FooterDetails from "../EventCommonComponents/FooterDetails";
import { showSideBar } from "../../../../REDUX/actions/general";
import EventHistoryList from "./EventHistoryList";
import EventType from "../EventCommonComponents/EventType";
import { getEventById } from "../../../../REDUX/actions/calendars";
import { useEffect } from "react";
import { useLocation } from "react-router";
import Loading from "../../../Common/Loading";
import EventActions from "../EventActions/EventActions";

const EventSideBarDetails = ({ event, isToggle }) => {
  const dispatch = useDispatch();
  const { ticketRoute } = useSelector((state) => state.organisationsReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);
  const { ticketEvent, eventLoading } = useSelector(
    (state) => state.calendarsReducer
  );

  const location = useLocation();

  let eventId = event?.id ? event?.id : location.state?.eventId;

  useEffect(() => {
    dispatch(getEventById(eventId));
  }, [eventId, event]);

  return (
    <>
      {eventLoading ? (
        <Loading />
      ) : (
        <div
          className="px-5 pb-3 sideChatListContainer h100"
          style={{ overflowY: "scroll" }}
        >
          {isToggle === "details" && (
            <div className="d-flex flex-column justify-content-between h100">
              <div>
                <EventTitle
                  title={ticketEvent?.attributes?.name}
                  eventsideBar
                />
                <EventDescription
                  description={ticketEvent?.attributes?.description}
                />
                {(ticketEvent?.attributes?.calendar?.data?.attributes?.sharedUsers?.data?.find(
                  (el) => +el?.id === +user?.id
                ) ||
                  +ticketEvent?.attributes?.creator?.data?.id === +user?.id ||
                  user?.attributes?.role?.data?.id === "3") && (
                  <EventCalendar
                    calendar={ticketEvent?.attributes?.calendar?.data}
                    sideBar
                  />
                )}
                <EventSociety
                  society={
                    ticketEvent?.attributes?.calendar?.data?.attributes?.societe
                      ?.data
                  }
                />
                <EventDate
                  dates={{
                    start: `${ticketEvent?.attributes?.startDate}T${ticketEvent?.attributes?.startTime}`,
                    end: `${ticketEvent?.attributes?.endDate}T${ticketEvent?.attributes?.endTime}`,
                  }}
                />
                <EventType type={ticketEvent?.attributes?.type} sideBar />
                <EventLocation place={ticketEvent?.attributes?.place} sideBar />
                <EventRelatedTicket
                  ticket={ticketEvent?.attributes?.ticket?.data}
                />
                <EventStatus
                  statut={ticketEvent?.attributes?.statut}
                  eventPopUp
                />
                <EventSocialMedia event={ticketEvent?.attributes} />
                {ticketEvent?.attributes?.participants?.data?.length ? (
                  <ParticipantsList
                    participants={ticketEvent?.attributes?.participants?.data}
                  />
                ) : null}
                {ticketEvent?.attributes?.fichiers?.data?.length ? (
                  <EventRelatedFiles
                    files={ticketEvent?.attributes?.fichiers?.data}
                  />
                ) : null}
              </div>
              {!location.pathname.includes(ticketRoute) && (
                <div>
                  {(ticketEvent?.attributes?.creator?.data?.id === user?.id ||
                    isSupervisor) && (
                    <ColoredButton
                      textButton="Modifier cet événement"
                      color="dark-blue"
                      loginBtn
                      callback={() =>
                        dispatch(
                          showSideBar({
                            isEdit: true,
                            isNew: false,
                            eventDetails: ticketEvent,
                            fromCalendar: true,
                          })
                        )
                      }
                    />
                  )}
                  <EventActions
                    event={{ ...ticketEvent?.attributes, id: ticketEvent?.id }}
                  />
                  {ticketEvent?.attributes?.ticket?.data && (
                    <div className="mt-3">
                      <ColoredButton
                        textButton="Voir le ticket"
                        color="dark-blue"
                        outlined
                        loginBtn
                        callback={() =>
                          window.open(
                            `${ticketRoute}/${ticketEvent?.attributes?.ticket?.data?.id}`
                          )
                        }
                      />
                    </div>
                  )}
                  <FooterDetails event={ticketEvent?.attributes} />
                </div>
              )}
            </div>
          )}
          {isToggle === "history" && (
            <div className="sideChatListContainer overflow-auto">
              <EventHistoryList
                actions={ticketEvent?.attributes?.actions?.data}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EventSideBarDetails;

import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkText from "../../../Common/LightDarkText";

import useWindowWidth from "../../../../Hooks/useWindowWidth";

import {
  handleFirstLastName,
  showMultipleSocieties,
} from "../../../../Utils/utils";

import { closeModal, openModal } from "../../../../REDUX/actions/modal";
import { CloseIcon } from "../../../Common/LightDarkSvg";
import UserSmallCard from "../../../Clients/UserSmallCard";
import UserThumbnail from "../../../Common/UserThumbnail";

const AvatarStack = ({
  participants,
  calendar,
  border,
  mainEventCard,
  eventSearch,
  calendarUsers,
}) => {
  const dispatch = useDispatch();

  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);
  // const [search, setSearch] = useState("");
  return (
    <>
      {participants?.length ? (
        <div
          className={`d-flex align-items-center ${
            screenWidth > 1200 ? "gap-2" : "gap-1"
          } ${!calendarUsers && !eventSearch && "mt-4"}`}
        >
          <div className="d-flex avatars">
            {participants
              ?.slice(0, 3)
              ?.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
              .map((participant, index) => (
                <OverlayTrigger
                  key={index}
                  placement="bottom"
                  overlay={
                    <Tooltip style={{ zIndex: 10000 }}>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        {handleFirstLastName(participant)} -{" "}
                        {showMultipleSocieties(participant)}
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div className="avatars__item">
                    <UserThumbnail
                      key={index}
                      img={`user${participant.attributes.genre || "3"}`}
                      size={screenWidth <= 1200 && !calendarUsers ? 25 : 32}
                      border={border}
                      apiImg={
                        participant?.attributes?.avatar?.data?.attributes?.image
                          ?.data?.attributes?.url
                      }
                    />
                  </div>
                </OverlayTrigger>
              ))}
          </div>
          {participants?.length > 3 && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="mt-1" style={{ zIndex: 10000 }}>
                  <LightDarkText className="custom-ticket-font-size py-1 text-white">
                    Consulter tous les membres de cet agenda
                  </LightDarkText>
                </Tooltip>
              }
            >
              <div
                onClick={() =>
                  dispatch(
                    openModal({
                      type: "small",
                      content: (
                        <div
                          className={`${
                            mode === "light" ? "bg-white" : "bg-dark-mode"
                          } p-4 radius05 d-flex flex-column justify-content-between`}
                          style={{
                            width: `${
                              screenWidth > 600 ? "600px" : "calc(100vw - 50px)"
                            }`,
                          }}
                        >
                          <div className="d-flex justify-content-between mb-3">
                            <LightDarkText
                              className="custom-title-font-size bold w-100"
                              color={
                                mode === "light"
                                  ? "text-dark-blue"
                                  : "text-white"
                              }
                            >
                              {calendar?.attributes?.name}
                            </LightDarkText>

                            <div
                              className="d-flex justify-content-end"
                              onClick={() => dispatch(closeModal())}
                            >
                              <CloseIcon />
                            </div>
                          </div>

                          <div
                            className="sideChatListContainer"
                            style={{ height: "200px" }}
                          >
                            {participants?.map((el, index) => (
                              <UserSmallCard key={index} responsible={el} />
                            ))}
                          </div>
                        </div>
                      ),
                    })
                  )
                }
              >
                <LightDarkText
                  className={`text-start bold pointer clickable-text t-02 ${
                    mainEventCard
                      ? "text-white f12"
                      : screenWidth > 1200
                      ? "f14"
                      : "f12"
                  }`}
                  color={
                    mode === "light" && (!mainEventCard || !calendarUsers)
                      ? "text-dark-blue"
                      : "text-white"
                  }
                >
                  {participants?.length > 3
                    ? `+${participants?.length - 3} ${
                        !mainEventCard && !calendarUsers
                          ? `participant${
                              participants?.length - 3 > 1 ? "s" : ""
                            }`
                          : ""
                      }`
                    : `${participants?.length} ${
                        !mainEventCard && !calendarUsers ? "participants" : ""
                      }`}
                </LightDarkText>
              </div>
            </OverlayTrigger>
          )}
        </div>
      ) : null}
    </>
  );
};

export default AvatarStack;

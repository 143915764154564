import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useWindowWidth from "../../Hooks/useWindowWidth";
import CloudBeadCrumb from "../../Components/Cloud/CloudBeadCrumb";
import CloudImportForm from "../../Components/Cloud/CloudImportForm";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import ListHeader from "../../Components/Common/ListHeader";
import { getFolderItem } from "../../REDUX/actions/cloud";
import Loading from "../../Components/Common/Loading";
import { openModal } from "../../REDUX/actions/modal";
import SearchInput from "../../Components/Common/SearchInput";
import FolderList from "../../Components/Cloud/FolderList";
import FilesList from "../../Components/Cloud/FilesList";

const CloudFolderView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const screenWidth = useWindowWidth();

  const [searchFiles, setsearchFiles] = useState("");
  const { folderItem, importFile, cloudLoading } = useSelector(
    (state) => state.cloudReducer
  );
  useEffect(() => {
    dispatch(getFolderItem(params.id));
  }, [dispatch, params.id]);

  return (
    <>
      {cloudLoading ? (
        <Loading />
      ) : (
        !importFile &&
        folderItem && (
          <div className="container-fluid">
            <div className="d-flex flex-row justify-content-between align-items-center h50px mb-2">
              <div className="col-10 h100">
                <ListHeader showSocityFilter={false} Archives={true}>
                  <SearchInput
                    onChange={(e) => setsearchFiles(e.target.value)}
                  />
                </ListHeader>
              </div>
              <div className="col-2 ms-2 h100">
                <ColoredButton
                  height="100"
                  textButton={screenWidth > 600 && "Importer un fichier"}
                  icon={screenWidth < 600 && "plus"}
                  color="light-blue"
                  callback={() =>
                    dispatch(
                      openModal({
                        type: "small",
                        content: (
                          <CloudImportForm folder={folderItem} inFolder />
                        ),
                      })
                    )
                  }
                />
              </div>
            </div>

            <CloudBeadCrumb
              name={folderItem.attributes.name}
              path={folderItem.attributes.path}
              elementRoute="dossier"
            />
            <div
              id="fileBody"
              className=" d-flex flex-column align-items-start"
              style={{ height: "70vh", overflow: "scroll" }}
            >
              <FolderList
                dossiers={folderItem?.attributes?.subFolders?.data}
                search={searchFiles}
                subFolder={true}
                parentFolder={params.id}
              />
              <FilesList
                fichiers={folderItem.attributes.fichiers.data}
                search={searchFiles}
              />

              {/* {importFile && folderItem && (
              <CloudImportForm inFolder folder={folderItem} />
            )} */}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default CloudFolderView;

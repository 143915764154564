const initialState = {
  organisations: [],
  organisationLoading: false,
  selectedOrganisation: {},
  organisation: {},
  tabs: [],
  ticketFields: [],
  userAdditionalFields: [],
  ticketAdditionalFields: [],
  interventionAdditionalFields: [],
  societyAdditionalFields: [],

  ticketHandleLogic: 1,
  ticketWording: "",
  interventionWording: "",
  societeWording: "",
  ticketsAccess: false,
  interventionsAccess: false,
  facturesAccess: false,
  prospetsAccess: false,
  devisAccess: false,
  diffusionAccess: false,
  societeAccess: false,
  organisationUrl: "",
};

const organisationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "ORGANISATIONS_LOADING":
      return {
        ...state,
        organisationLoading: true,
      };

    case "FETCH_INITIAL_DATA":
      return {
        ...state,
        //ROUTES
        dashboardRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Dashboard"
          )[0]?.attributes?.url,
        ticketRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Tickets"
          )[0]?.attributes?.url,
        interventionRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Interventions"
          )[0]?.attributes?.url,
        clientsRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Clients"
          )[0]?.attributes?.url,
        societeRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Sociétés"
          )[0]?.attributes?.url,
        documentsRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Documents"
          )[0]?.attributes?.url,
        operatorsRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Opérateurs"
          )[0]?.attributes?.url,
        facturesRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Factures"
          )[0]?.attributes?.url,
        calendarRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Calendriers"
          )[0]?.attributes?.url,
        alertesRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Alertes"
          )[0]?.attributes?.url,
        prospetsRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Prospets"
          )[0]?.attributes?.url,
        devisRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Devis"
          )[0]?.attributes?.url,

        commandesRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Commandes"
          )[0]?.attributes?.url,

        configurateurRoute:
          state.organisationUrl +
          payload.organisation.data.attributes.views.data.filter(
            (el) => el.attributes.type.data.attributes.name === "Configurateur"
          )[0]?.attributes?.url,
        //ROUTES====================================================================
        //ACCESS====================================================================
        ticketsAccess: payload.organisation.data.attributes.views.data.some(
          (view) => view.attributes.type.data.attributes.name === "Tickets"
        ),
        interventionsAccess:
          payload.organisation.data.attributes.views.data.some(
            (view) =>
              view.attributes.type.data.attributes.name === "Interventions"
          ),
        facturesAccess: payload.organisation.data.attributes.views.data.some(
          (view) => view.attributes.type.data.attributes.name === "Factures"
        ),
        prospetsAccess: payload.organisation.data.attributes.views.data.some(
          (view) => view.attributes.type.data.attributes.name === "Prospets"
        ),
        devisAccess: payload.organisation.data.attributes.views.data.some(
          (view) => view.attributes.type.data.attributes.name === "Devis"
        ),
        diffusionAccess: payload.organisation.data.attributes.views.data.some(
          (view) => view.attributes.type.data.attributes.name === "Alertes"
        ),
        societeAccess: payload.organisation.data.attributes.views.data.some(
          (view) => view.attributes.type.data.attributes.name === "Sociétés"
        ),
        organisation: payload.organisation,
        ticketHandleLogic:
          payload.organisation.data.attributes.ticketHandleLogic,
        ticketFields: payload.organisation.data.attributes.ticket_fields.data,
        userAdditionalFields:
          payload.organisation.data.attributes.additional_fields.data.filter(
            (el) => el.attributes.type === "user"
          ),
        ticketAdditionalFields:
          payload.organisation.data.attributes.additional_fields.data.filter(
            (el) => el.attributes.type === "ticket"
          ),
        interventionAdditionalFields:
          payload.organisation.data.attributes.additional_fields.data.filter(
            (el) => el.attributes.type === "intervention"
          ),
        societyAdditionalFields:
          payload.organisation.data.attributes.additional_fields.data.filter(
            (el) => el.attributes.type === "society"
          ),
        tabs: payload.views.data,
      };
    case "ORGANISATIONS_SUCCESS":
      const selected = localStorage.getItem("organisation");
      const alreadySelected = selected
        ? payload.filter((el) => +el.id === +selected)[0]
        : null;

      return {
        ...state,
        organisationUrl: (alreadySelected ? alreadySelected : payload[0])
          .attributes.url,
        organisationLoading: false,
        organisations: payload,
        selectedOrganisation: alreadySelected ? alreadySelected : payload[0],
      };
    case "CHNAGE_ORGANISATION":
      return {
        ...state,
        organisationLoading: false,
        selectedOrganisation: state.organisations.filter(
          (el) => el.id === payload
        )[0],
        organisationUrl: state.organisations.filter(
          (el) => el.id === payload
        )[0].attributes.url,
      };
    case "ORGANISATIONS_FAIL":
      return {
        ...state,
        organisationLoading: false,
        organisations: [],
      };
    default:
      return state;
  }
};

export default organisationsReducer;

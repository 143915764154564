import React from "react";
import { useSelector } from "react-redux";

import HistoryCard from "../../Common/HistoryCard";

const InterventionHistoriqueDetails = ({ intervention }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="d-flex mt-3 sideChatListContainer">
      <div
        style={{
          position: "relative",
          left: 25,
          borderLeft: `1px solid ${mode === "light" ? "#3540741F" : "#252C45"}`,
        }}
      />
      <div style={{ zIndex: 2 }}>
        <HistoryCard
          avatar={
            intervention?.attributes?.creator?.data?.attributes?.avatar?.data
              ?.attributes?.image?.data?.attributes?.url
          }
          user={intervention?.attributes?.creator?.data}
          creationDate={intervention?.attributes?.createdAt}
        />
        {intervention?.attributes?.actions?.map((action, index) => (
          <HistoryCard
            key={index}
            avatar={
              action?.attributes?.by?.data?.attributes?.avatar?.data?.attributes
                ?.image?.data?.attributes?.url
            }
            user={action.attributes?.by?.data}
            title={action.attributes?.title}
            creationDate={intervention?.attributes?.createdAt}
          />
        ))}
      </div>
    </div>
  );
};

export default InterventionHistoriqueDetails;

import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import LightDarkText from "../Common/LightDarkText";
import "./Charts.css";
import LightDarkContainer from "../Common/LightDarkContainer";
import { useSelector } from "react-redux";

const PieChart = ({ title, infos, tootltipLabel = "tickets" }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  ChartJS.register(ArcElement, Tooltip);

  const options = {
    cutout: "90%",
    maintainAspectRatio: false,
    label: true,
    plugins: {
      title: {
        display: true,
        text: title,
        align: "start",
        color: mode === "light" ? "#59638b" : "white",
        font: {
          weight: "bold",
          size: 15,
        },
      },
      legend: {
        display: true,
        position: "right",
        align: "center",
        fullSize: true,

        labels: {
          padding: 15,
          color: mode === "light" ? "#59638b" : "white",
          usePointStyle: true,
          boxHeight: 8,
          font: {
            weight: "bold",
          },
        },
      },
      tooltip: {
        callbacks: {
          afterLabel: function (context) {
            let label = context.dataset.label || "";
            return label + tootltipLabel;
          },
        },
      },
    },
  };
  const data = {
    labels: infos?.map((el) => el.name),
    datasets: [
      {
        data: infos?.map((el) => el.value),
        backgroundColor: infos?.map((el) => el.color),
        borderColor: infos?.map((el) => el.color),
        hoverBorderWidth: 0,
        hoverBorderColor: infos?.map((el) => el.color),
        borderWidth: 3,
        borderColor: mode === "light" ? "white" : "#181f3a81",
        borderRadius: 8,
      },
    ],
  };
  const highestValue = Math.max(...infos?.map((info) => info?.value));

  const objectsWithHighestValue = infos?.find(
    (info) => info?.value === highestValue
  );
  return (
    <LightDarkContainer
      className="py-4 radius05 px-4 h100"
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <Doughnut options={options} data={data} className="PieChartChart" />
    </LightDarkContainer>
  );
};

export default PieChart;

import client from "./Client";
import { handleError, handleInfo } from "./toastMessage";
import store from "../store/store";

import Api from "../../Api/Api";

import { GET_USER_BY_ID } from "../Graphql/Queries/user";
import { getConditionalUsers } from "./clients";
const color = "light-blue";

export const getUserById = (id) => async (dispatch) => {
  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  if (!orgId) {
    return;
  }
  const { data } = await client.query({
    query: GET_USER_BY_ID(id, orgId),
  });
  dispatch({
    type: "FETCH_USER_BY_ID",
    payload: data.usersPermissionsUsers.data[0],
  });
};

export const toggleUserArchive =
  (userId, archived, type) => async (dispatch) => {
    try {
      if (!userId) {
        dispatch(handleError("Erreur mise à jour de l'opérateur", 1));
        return;
      }
      const orgId =
        store.getState().organisationsReducer.selectedOrganisation.id;
      if (!orgId) {
        return;
      }

      await Api.post(
        `/api/users-permissions/user/toggle-archive-user?organisation=${orgId}`,
        {
          data: {
            id: userId,
            archived,
          },
        }
      );
      dispatch(getConditionalUsers(type === "client", false));

      dispatch(
        handleInfo(
          !archived
            ? type === "operator"
              ? "Opérateur archivé"
              : "Client archivé"
            : type === "operator"
            ? "Opérateur désarchivé"
            : "Client désarchivé",
          1,
          color
        )
      );

      // callback();
    } catch (error) {
      console.log(error);
      dispatch(handleError("Erreur mise à jour de l'opérateur", 1));
    }
  };

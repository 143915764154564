import { imageEndPoint } from "../../../Api/endPoint";

export const ticketUsersToMention = (ticket, user, admins) => {
  return [
    ...(ticket?.attributes?.visibleBy
      ?.map((user) => ({
        id: user?.id,
        value:
          (user?.attributes?.firstName || "") +
          " " +
          (user?.attributes?.lastName || ""),
        avatar:
          user?.attributes?.avatar?.data?.attributes?.image?.data?.attributes
            ?.url,
      }))
      .filter(({ id }) => +id !== +user?.id) || []),
    ...(admins
      .map(({ value, label, avatar }) => ({
        id: value,
        value: label,
        avatar,
      }))
      .filter(({ id }) => +id !== +user?.id) || []),
  ];
};

export const prepareMentionOverlay = (
  container,
  setMentionTag,
  setUserData,
  message
) => {
  const mentions = container?.querySelectorAll(".mention");
  mentions?.forEach((mention) => {
    mention.addEventListener("mouseover", () => {
      const userId = mention?.getAttribute("data-id");
      setMentionTag(mention);
      setUserData(
        message?.attributes?.mentions?.data?.find(
          (mention) => +mention?.id === +userId
        )
      );
    });
    mention.addEventListener("mouseout", () => {
      mention?.classList?.remove("hovered");
      const elExist = mention?.querySelector(".mention-overlay");
      if (elExist) elExist.remove();
      setMentionTag();
    });
  });
};

export const showMentionOverlay = (mentionTag, userData, defaultUserImg) => {
  if (mentionTag?.classList) {
    const messageContainer = document?.getElementById("messages-scroll");
    const distance =
      messageContainer?.getBoundingClientRect()?.bottom -
      mentionTag?.getBoundingClientRect()?.bottom;
    mentionTag?.classList.add("hovered");
    if (distance < 65) {
      mentionTag?.classList.add("bottom");
    }
    const elExist = mentionTag?.querySelector(".mention-overlay");
    if (!elExist) {
      const el = document.createElement("div");
      const img = document.createElement("img");
      const p = document.createElement("p");
      el.classList.add("mention-overlay");
      p.classList.add("text-nowrap");
      const posteP = document.createElement("p");
      posteP.textContent = userData?.attributes?.poste?.data?.attributes?.name;
      posteP.classList.add("mention-poste");
      const profileImage =
        userData?.attributes?.avatar?.data?.attributes?.image?.data?.attributes
          ?.url;
      img.src = profileImage ? imageEndPoint + profileImage : defaultUserImg;
      p.textContent = `${userData?.attributes?.firstName || ""} ${
        userData?.attributes?.lastName || ""
      }`;
      p.append(posteP);
      el.appendChild(img);
      el.appendChild(p);
      mentionTag.appendChild(el);
    }
  }
};

export const showConditionalMessage = (message, user, original) => {
  if (original) {
    return { message: message.content, translated: true };
  } else {
    const preferredLanguage =
      user?.attributes?.user_org_permissions?.data[0]?.attributes
        ?.preferredLanguage?.data?.attributes?.code;

    const field = `content${preferredLanguage}`;
    if (message[field] || original) {
      return { message: message[field], translated: true };
    } else {
      return { message: message.content, translated: false };
    }
  }
};

export const groupEmojisByUrl = (emojiArray) => {
  const groupedEmojis = {};

  emojiArray.forEach((emoji) => {
    const { url, emojiKey, user, createdAt } = emoji.attributes;
    const emoId = emoji.id;
    const userData = user.data;

    if (!groupedEmojis[url]) {
      groupedEmojis[url] = {
        url: url,
        emojiKey: emojiKey,
        number: 0,
        users: [],
      };
    }

    groupedEmojis[url].number += 1;
    groupedEmojis[url].users.push({
      ...userData,
      emojiDate: createdAt,
      emojiId: emoId,
    });
  });

  return Object.values(groupedEmojis);
};

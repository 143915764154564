import FormHeader from "../../Common/Forms/FormHeader";
import MenuTabs from "../../Common/MenuTabs";

import { SocietyFormTabs } from "./societyFormUtils";

const SocietyFormHeader = ({ isNew, isToggle, setIsToggle }) => {
  return (
    <>
      <FormHeader
        formTitle={isNew ? "Créer une société" : "Éditer la société"}
      />
      <div className="d-flex justify-content-start align-items-center mb-2">
        {SocietyFormTabs().map((tab, index) => (
          <MenuTabs
            key={index}
            tabTitle={tab.title}
            toggleValue={tab.toggleValue}
            isToggle={isToggle}
            setIsToggle={() => setIsToggle(tab.toggleValue)}
            isVisible={tab.isVisible}
          />
        ))}
      </div>
    </>
  );
};

export default SocietyFormHeader;

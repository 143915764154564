import React, { useState } from "react";
import LightDarkContainer from "../../Common/LightDarkContainer";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { useSelector } from "react-redux";
import RightParticipants from "./RightParticipants";
import RightPanelMenu from "./RightPanelMenu";
import RightAttachedFiles from "./RightAttachedFiles";
import RightInterventions from "./RightInterventions";
import RightFavorites from "./RightFavorites";
import RightLinks from "./RightLinks";

const MessageRightBottomPannel = () => {
  const screenWidth = useWindowWidth();
  const [isToggle, setIsToggle] = useState("Fichiers");
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { user } = useSelector((state) => state.userReducer);

  return (
    <LightDarkContainer
      className={`radius05 h50 mt-2 flex-1 directionC justify-content-start ${
        screenWidth < 1200 ? "py-3 px-1" : "p-3"
      } `}
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <RightPanelMenu
        setIsToggle={setIsToggle}
        isToggle={isToggle}
        ticket={ticket}
      />
      {isToggle === "Participants" && <RightParticipants ticket={ticket} />}
      {isToggle === "Fichiers" && (
        <RightAttachedFiles ticket={ticket} user={user} />
      )}
      {isToggle === "Interventions" && <RightInterventions ticket={ticket} />}
      {isToggle === "Links" && <RightLinks ticket={ticket} />}
      {isToggle === "Favorites" && <RightFavorites ticket={ticket} />}
    </LightDarkContainer>
  );
};

export default MessageRightBottomPannel;

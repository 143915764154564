import React from "react";
import UserThumbnail from "../../Common/UserThumbnail";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";

const RightFavorites = ({ ticket }) => {
  const handleScroll = (id) => {
    document.querySelector(`#messageId-${id}`).scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <div className="scrollable d-flex flex-column gap-3 mt-2">
      {ticket.attributes.message_favorites.data.map((fav) => (
        <LightDarkContainer
          className=" text-start f12 radius05 p-2 pointer opacityHover09"
          key={fav.id}
          callBack={() => handleScroll(fav.attributes.message.data.id)}
        >
          <LightDarkText className="d-flex align-items-start gap-2">
            <UserThumbnail
              userId={fav.attributes.message.data.attributes.sender?.data?.id}
              connected={
                fav.attributes.message.data.attributes.sender?.data?.attributes
                  ?.connected
              }
              lastConnected={
                fav.attributes.message.data.attributes.sender?.data?.attributes
                  ?.lastConnected
              }
              apiImg={
                fav.attributes.message.data.attributes.sender?.data?.attributes
                  ?.avatar?.data?.attributes?.image?.data?.attributes?.url
              }
              img={`user${
                fav.attributes.message.data.attributes.sender?.data?.attributes
                  ?.genre || "3"
              }`}
              size={40}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: `${fav.attributes.message.data.attributes.content
                  .replaceAll("<br>", "")
                  .slice(0, 350)} ${
                  fav.attributes.message.data.attributes.content.length > 350
                    ? "<span>...</span>"
                    : ""
                }`,
              }}
            ></div>
          </LightDarkText>
        </LightDarkContainer>
      ))}
    </div>
  );
};

export default RightFavorites;

import React from "react";

import LightDarkContainer from "../LightDarkContainer";
import SideBarHeader from "./SideBarHeader";
import SideBarTabs from "./SideBarTabs";

const SideBar = ({
  children,
  index,
  sideBarHeaderInfo = false,
  userSideBar = false,
  selectedItem,
  setSelectedItem,
  isToggle,
  setIsToggle,
  tabsData,
  tabsProps,
}) => {
  return (
    <LightDarkContainer
      key={index}
      index={index}
      className="d-flex flex-column justify-content-start p-3 w-100 radius05"
      colorLight="white"
      colorDark="dark-grey-bg"
      sideBar
    >
      <SideBarHeader
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        sideBarHeaderInfo={sideBarHeaderInfo}
        userSideBar={userSideBar}
      />
      {tabsData && (
        <SideBarTabs
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          data={tabsData}
          tabsProps={tabsProps}
          sideBarHeaderInfo={sideBarHeaderInfo}
        />
      )}
      {children}
    </LightDarkContainer>
  );
};

export default SideBar;

import LightDarkText from "./LightDarkText";

import { useSelector } from "react-redux";

const MenuTabs = ({
  tabTitle,
  toggleValue,
  isToggle,
  setIsToggle,
  isVisible,
  isdashboardTabs = false,
  sideBarHeaderInfo = false,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return isVisible ? (
    <LightDarkText
      className={`text-start ${
        isdashboardTabs ? "custom-font-size" : "custom-ticket-font-size"
      } bold ${!sideBarHeaderInfo && "me-4"} pointer clickable-text t-02`}
      color={
        isToggle === toggleValue
          ? "text-light-blue"
          : mode === "light"
          ? "text-blue-grey"
          : "text-light-text"
      }
      onclickCallback={setIsToggle}
    >
      {tabTitle}
      {isToggle === toggleValue && (
        <div
          className={`radius20 ${!isdashboardTabs && "mx-2"}`}
          style={{
            borderBottom: "4px solid #3365ff",
            position: "relative",
            top: isdashboardTabs ? 13 : 10,
          }}
        ></div>
      )}
    </LightDarkText>
  ) : null;
};

export default MenuTabs;

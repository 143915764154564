const initialState = {
  temporarySelected: [],
  temporarySelectedIntervention: [],
  uploadLoading: false,
  uploaderInputPlaceHolder: "Cliquer ou déplacer vos fichiers dans le cadre",

  isConfidentiel: false,
  percentComplete: 0,
  statut: null,
};

const filesReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "SELECT_TEMPORARY_FILE":
      const newTemporaryFiles = [...state.temporarySelected, ...payload];

      return {
        ...state,
        uploaderInputPlaceHolder:
          newTemporaryFiles.length === 1
            ? newTemporaryFiles[0].name
            : newTemporaryFiles.length < 1
            ? "Cliquez ou déplacez vos fichiers dans le cadre"
            : `${newTemporaryFiles.length} fichiers sélectionnés`,
        temporarySelected: newTemporaryFiles,
      };
    case "SELECT_TEMPORARY_FILE_INTERVENTION":
      const newInterventionTemporaryFiles = [
        ...state.temporarySelectedIntervention,
        ...payload,
      ];
      return {
        ...state,
        uploaderInputPlaceHolder:
          newInterventionTemporaryFiles.length === 1
            ? newInterventionTemporaryFiles[0].name
            : newInterventionTemporaryFiles.length < 1
            ? "Cliquez ou déplacez vos fichiers dans le cadre"
            : `${newInterventionTemporaryFiles.length} fichiers sélectionnés`,
        temporarySelectedIntervention: newInterventionTemporaryFiles,
      };
    case "UNSELECT_TEMPORARY_FILE":
      return {
        ...state,
        temporarySelected: state.temporarySelected.filter(
          (el) => el.name !== payload.name
        ),
      };
    case "CHANGE_CONFIDENTIALITY":
      return {
        ...state,
        isConfidentiel: payload,
      };
    case "UPLOAD_DONE":
      return {
        ...state,
        uploadLoading: false,
        percentComplete: 100,
        uploaderInputPlaceHolder:
          "Cliquer ou déplacer vos fichiers dans le cadre",
        statut: "Upload terminé",
      };
    case "ONGOING_UPLOADING":
      return {
        ...state,
        percentComplete: payload.percentage,
        statut: payload.statut,
      };
    case "LOADING_UPLOAD":
      return { ...state, uploadLoading: true };
    case "CLEAR_UPLOAD":
      return {
        ...state,
        uploadLoading: false,
        temporarySelected: [],
        statut: null,
        percentComplete: 0,
        uploaderInputPlaceHolder:
          "Cliquer ou déplacer vos fichiers dans le cadre",
        temporarySelectedIntervention: [],
        isConfidentiel: false,
      };
    default:
      return state;
  }
};

export default filesReducer;

import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";

import { handleDuration } from "../../../Tickets/ticketUtils";
import { sumList } from "../../../Charts/chartsUtils";

const SocietyAvailableConsumption = ({ plansData }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="directionC col-4">
      <div className="mb-1">
        <LightDarkText
          className="text-start custom-ticket-font-size bold"
          color={mode === "light" ? "text-blue-grey" : "text-light-text"}
        >
          Consommation disponible
        </LightDarkText>
        <LightDarkText
          className="text-start custom-title-font-size bold mt-1"
          color={mode === "light" ? "text-blue-grey" : "text-light-text"}
        >
          {handleDuration(sumList(plansData[3].data))}
        </LightDarkText>
      </div>
    </div>
  );
};

export default SocietyAvailableConsumption;

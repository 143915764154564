import React, { useEffect } from "react";
import { handleCloseWindow } from "../../REDUX/actions/socket";
import { useDispatch } from "react-redux";

const WindowWatcher = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(handleCloseWindow());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return <div></div>;
};

export default WindowWatcher;

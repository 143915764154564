import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";
import { handleDuration } from "../../../Tickets/ticketUtils";

const SocietyBundleConsumption = () => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { currentPlan } = useSelector((state) => state.planReducer);

  const consumption_0 = currentPlan?.attributes?.consumption_0 || 0;
  const monthlyPlan = currentPlan?.attributes?.monthlyPlan || 0;
  const monthlyQuota = currentPlan?.attributes?.monthlyQuota || 0;
  const remainder =
    monthlyPlan > consumption_0
      ? monthlyPlan - consumption_0 - monthlyQuota
      : 0;

  return (
    <div className={`col-7 d-flex flex-column gap-3 radius05`}>
      <div className="w-100 d-flex justify-content-start">
        <LightDarkText
          className="text-start custom-ticket-font-size bold mb-2 me-1"
          color={mode === "light" ? "text-dark-blue" : "text-light-text"}
        >
          Evolution du forfait ce mois-ci
        </LightDarkText>
        <LightDarkText
          className="text-start custom-ticket-font-size bold mb-2"
          color={mode === "light" ? "text-blue-grey" : "text-light-text"}
        >
          ({handleDuration(monthlyPlan)} au total)
        </LightDarkText>
      </div>
      <div
        className={`${
          mode === "light" ? "bg-light-mode" : "bg-light-message"
        } progress radius05`}
        style={{
          height: "8px",
          width: "100%",
          border: "transparent",
        }}
      >
        <div
          className="progress-bar bg-green p-0"
          role="progressbar"
          style={{
            width: `${(monthlyQuota / monthlyPlan) * 100}%`,
          }}
          aria-valuenow={(monthlyQuota / monthlyPlan) * 100}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <div
          className="progress-bar bg-light-blue"
          role="progressbar"
          style={{
            width: `${(consumption_0 / monthlyPlan) * 100}%`,
          }}
          aria-valuenow={(consumption_0 / monthlyPlan) * 100}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="w-100 d-flex justify-content-start">
        <div
          style={{
            width: `${(monthlyQuota / monthlyPlan) * 100}%`,
          }}
          className="text-center px-1 me-3"
        >
          <LightDarkText
            className="custom-font-size bold text-center"
            color="text-green"
          >
            {((monthlyQuota / monthlyPlan) * 100 || 0).toFixed(1)}%
          </LightDarkText>
        </div>
        <div
          style={{
            width: `${(consumption_0 / monthlyPlan) * 100}%`,
          }}
          className="text-center px-1 me-3"
        >
          <LightDarkText
            className="custom-font-size bold text-center ms-4"
            color="text-light-blue"
          >
            {((consumption_0 / monthlyPlan) * 100 || 0).toFixed(1)}%
          </LightDarkText>
        </div>
        <div
          className="text-center px-1"
          style={{
            width: `${(remainder / monthlyPlan) * 100}%`,
          }}
        >
          <LightDarkText
            className="text-center custom-font-size bold"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {((remainder / monthlyPlan) * 100 || 0).toFixed(1)}%
          </LightDarkText>
        </div>
      </div>
    </div>
  );
};

export default SocietyBundleConsumption;

import React, { useState } from "react";
import { useSelector } from "react-redux";

import CustomRange from "../../Common/CustomRange";
import MenuTabs from "../../Common/MenuTabs";
import MultipleBarChart from "../../Charts/MultipleBarChart";
import MultipleLineChart from "../../Charts/MultipleLineChart";
import SocietyBundleConsumption from "./SocietyBundleDetails/SocietyBundleConsumption";
import SocietyAvailableConsumption from "./SocietyBundleDetails/SocietyAvailableConsumption";

import {
  transformDataToMultiLines,
  transformPlansData,
  transformticketsToStatsPie,
} from "../../Charts/chartsUtils";
import { SocietyBundleTabs } from "./societyFormUtils";
import PieChart from "../../Charts/PieChart";
import _ from "lodash";

const SocietyBundleForm = ({
  isNew,
  editbundle,
  societyInputs,
  setsocietyInputs,
}) => {
  const { societeStats } = useSelector((state) => state.societesReducer);
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const [isPlanToggle, setIsPlanToggle] = useState(
    selectedOrganisation.attributes.withClicks ? "clics" : "tickets"
  );

  const plansData = transformPlansData(societeStats.plansStats);
  const click = transformticketsToStatsPie(societeStats?.clicksStats);
  const openedTickets = transformDataToMultiLines(
    societeStats.ticketStats?.openedTicket,
    "#3365FF",
    "Créés"
  );
  const resolvedTickets = transformDataToMultiLines(
    societeStats.ticketStats?.resolvedTickets,
    "#0CCE91",
    "Résolus"
  );
  const unresolvedTicket = transformDataToMultiLines(
    societeStats.ticketStats?.unresolvedTicket,
    "#FFBC20",
    "En cours"
  );

  return (
    <div className={`directionC justify-content-center ${isNew && "mt-4"}`}>
      {!isNew && !editbundle && (
        <>
          <div
            className={`d-flex justify-content-start align-items-center mb-2 mt-3 py-2 radius05`}
          >
            {SocietyBundleTabs(selectedOrganisation).map((tab, index) => (
              <MenuTabs
                key={index}
                tabTitle={tab.title}
                toggleValue={tab.toggleValue}
                isToggle={isPlanToggle}
                setIsToggle={() => setIsPlanToggle(tab.toggleValue)}
                isVisible={tab.isVisible}
              />
            ))}
          </div>
          {isPlanToggle === "consumed" && (
            <>
              <MultipleBarChart
                dataSets={plansData}
                title="Forfait les 12 derniers mois"
              />
              <div className="row justify-content-between mt-3 mb-1">
                <SocietyBundleConsumption />
                <SocietyAvailableConsumption plansData={plansData} />
              </div>
            </>
          )}
          {isPlanToggle === "tickets" && (
            <MultipleLineChart
              title="Tickets ouverts"
              dataSets={[openedTickets, resolvedTickets, unresolvedTicket]}
            />
          )}
          {isPlanToggle === "clics" && (
            <PieChart
              infos={click}
              tootltipLabel="clic"
              title={`Nombre de clic : ${_.sumBy(click, "value")}`}
            />
          )}
        </>
      )}
      {(isNew || editbundle) && (
        <>
          <CustomRange
            min={0}
            max={100}
            label={"Forfait mensuel"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="monthlyPlan"
            editbundle={true}
          />
          <CustomRange
            min={0}
            max={societyInputs.monthlyPlan}
            disabled={societyInputs.monthlyPlan === 0}
            label={"Quota mensuel"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="monthlyQuota"
            editbundle={true}
          />
        </>
      )}
    </div>
  );
};

export default SocietyBundleForm;

import _ from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LightDarkTextEditor from "../../../../Common/LightDarkTextEditor";
import { extractLinks, urlifyMessage } from "../../../../../Utils/utils";
import { ticketUsersToMention } from "../../messageUtils";
import useWindowWidth from "../../../../../Hooks/useWindowWidth";
import { sendMessage } from "../../../../../REDUX/actions/tickets";
import { clearTempUpload } from "../../../../../REDUX/actions/files";
import { useParams } from "react-router-dom";
import InfoQuestion from "../../../../Common/InfoQuestion";

const MessageInput = () => {
  const { id } = useParams();

  const screenWidth = useWindowWidth();

  const dispatch = useDispatch();

  const { sendMessageLoading } = useSelector((state) => state.messagesReducer);
  const { temporarySelected, isConfidentiel } = useSelector(
    (state) => state.filesReducer
  );
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { admins } = useSelector((state) => state.sharedDataReducer);

  const [messageToSend, setmessageToSend] = useState("");
  const [mentions, setMentions] = useState([]);

  const handleSendMessage = () => {
    let newMessage = {
      content: urlifyMessage(messageToSend.message),
      links: extractLinks(messageToSend.message),
      ticket: ticket.id,
      cloud:
        user?.attributes?.user_org_permissions.data[0].attributes.cloud?.data
          ?.id,
      sender: user.id,
      type: 0,
    };
    const flatMentions = mentions?.map(
      (mention) => mention?.insert?.mention?.id
    );

    dispatch(
      sendMessage(
        newMessage,
        {
          files: temporarySelected,
          isConfidentiel: isConfidentiel,
        },
        undefined,
        true,
        flatMentions
      )
    );
    dispatch(clearTempUpload());
  };

  return (
    <div
      className="text-start"
      style={{
        paddingBlock: screenWidth < 600 && "10px",
        position: "relative",
      }}
    >
      <LightDarkTextEditor
        height={"30"}
        value={messageToSend}
        setValue={setmessageToSend}
        champs="message"
        showSendButton
        callBack={handleSendMessage}
        disabledBtn={
          messageToSend?.message?.length || temporarySelected?.length
            ? false
            : true
        }
        messageInput={true}
        isLoading={sendMessageLoading}
        autoHeight
        canMention
        mentions={mentions}
        setMentions={setMentions}
        users={_.uniqBy(ticketUsersToMention(ticket, user, admins), "id")}
        ticketChanged={+id !== +ticket?.id}
        filesAndNoText={
          !messageToSend?.message?.replace(/<(.|\n)*?>/g, "").trim().length &&
          temporarySelected?.length
        }
      />
      {/* <InfoQuestion
        position="absolute"
        htmlMessage={`Retour à la ligne avec <br />
                            "Shift + Entrer" <br />
                            Envoyez votre message <br />
                            avec "Entrer"`}
      /> */}
    </div>
  );
};

export default MessageInput;

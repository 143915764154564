import { userAttributes } from "../general/userFields";
import { USER_ORG_ATTRIBUTES } from "./UserOrg";

export const EVENT_ATTRIBUTES = `
createdAt
name
type
startDate
endDate
startTime
endTime
recurrenceEndDate
place
description
color
statut
facebook
instagram
twitter
linkedIn
tikTok
global
Reminder
shareInTicket
recurrence
calendar {
  data {
    id
    attributes {
      name
      avatar {
        data {
          id
          attributes {
            image {
              data {
                id 
                attributes {
                  url
                }
              }
            }
          }
        }
      }
      sharedUsers (pagination: {page: 1, pageSize: 100}) {
        data {
          id
          attributes {
            ${userAttributes}
          }
        }
      }
      societe {
        data {
          id
          attributes {
            name
            avatar {
              data {
                id
                attributes {
                  image {
                    data {
                      id 
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
            equipe {
              data {
                id
                attributes {
                  ${userAttributes}
                  user_org_permissions (
                    filters: {
                      organisation: { id: { eq: $orgId} }  
                    }
                  ) 
                  {
                    data {
                      id
                      attributes {
                        ${USER_ORG_ATTRIBUTES}
                      }
                    }
                  }
                }
              }
            }
            responsableOperators {
              data {
                id
                attributes {
                  ${userAttributes}
                  user_org_permissions (
                    filters: {
                      organisation: { id: { eq: $orgId} }  
                    }
                  ) 
                  {
                    data {
                      id
                      attributes {
                        ${USER_ORG_ATTRIBUTES}
                      }
                    }
                  }
                }
              }
            }
            tickets {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
creator {
  data {
    id
    attributes {
      ${userAttributes}
      role {
        data {
          id
          attributes {
            name
          }
        }   
      }
      poste {
        data {
          id 
          attributes {
            name
          }
        }
      }
      user_org_permissions (
        filters: {
          organisation: { id: { eq: $orgId} }  
        }
      ) 
      {
        data {
          id
          attributes {
            ${USER_ORG_ATTRIBUTES}
          }
        }
      }
    }
  }
}
confirmedParticipants (pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      ${userAttributes}
      user_org_permissions (
        filters: {
          organisation: { id: { eq: $orgId} }  
        }
      ) 
      {
        data {
          id
          attributes {
            ${USER_ORG_ATTRIBUTES}
          }
        }
      }
    }
  }
}
declinedParticipants (pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      ${userAttributes}
      user_org_permissions (
        filters: {
          organisation: { id: { eq: $orgId} }  
        }
      ) 
      {
        data {
          id
          attributes {
            ${USER_ORG_ATTRIBUTES}
          }
        }
      }
    }
  }
}
validatingUsers (pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      ${userAttributes}
      user_org_permissions (
        filters: {
          organisation: { id: { eq: $orgId} }  
        }
      ) 
      {
        data {
          id
          attributes {
            ${USER_ORG_ATTRIBUTES}
          }
        }
      }
    }
  }
}
participants (pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      ${userAttributes}
        avatar {
          data {
            id
            attributes {
              image {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      role {
        data {
          id
          attributes {
            name
          }
        }   
      }
      user_org_permissions (
        filters: {
          organisation: { id: { eq: $orgId} }  
        }
      ) 
      {
        data {
          id
          attributes {
            ${USER_ORG_ATTRIBUTES}
          }
        }
      }
      poste {
        data {
          id 
          attributes {
            name
          }
        }
      }
    }
  }
}
ticket {
  data {
    id
    attributes {
      name
    }
  }
}
fichiers(pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      createdAt
      file {
        data {
          id
          attributes {
            url
            name
            mime
          }
        }
      }
    }
  }
}
facebookFiles(pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      file {
        data {
          id
          attributes {
            url
            name
            mime
          }
        }
      }
    }
  }
}
instagramFiles(pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      file {
        data {
          id
          attributes {
            url
            name
            mime
          }
        }
      }
    }
  }
}
linkedInFiles(pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      file {
        data {
          id
          attributes {
            url
            name
            mime
          }
        }
      }
    }
  }
}
twitterFiles(pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      file {
        data {
          id
          attributes {
            url
            name
            mime
          }
        }
      }
    }
  }
}
tiktokFiles(pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      file {
        data {
          id
          attributes {
            url
            name
            mime
          }
        }
      }
    }
  }
}
globalFiles(pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      file {
        data {
          id
          attributes {
            url
            name
            mime
          }
        }
      }
    }
  }
}
actions {
  data {
    id
    attributes {
      createdAt
      title
      motif
      statut
      by {
        data {
          id
          attributes {
            ${userAttributes}
            user_org_permissions (
              filters: {
                organisation: { id: { eq: $orgId} }  
              }
            ) 
            {
              data {
                id
                attributes {
                  ${USER_ORG_ATTRIBUTES}
                }
              }
            }
            avatar {
              data {
                id
                attributes {
                  image {
                    data {
                      id 
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
            role {
              data {
                id
                attributes {
                  name
                }
              }
            }
            poste {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

import {
  HIDE_SIDE_BAR_CARD,
  OPEN_SIDE_BAR_CARD,
} from "../ActionsType/generalTypes";

export const changeDisplayMode = (mode) => async (dispatch) => {
  if (mode === "dark") {
    dispatch({ type: "DARK_MODE" });
  } else {
    dispatch({ type: "LIGHT_MODE" });
  }
};

export const showSideBar = (data) => async (dispatch) => {
  dispatch({ type: "SHOW_SIDE_BAR", payload: data });
};
export const hideSideBar = () => async (dispatch) => {
  dispatch({ type: "HIDE_SIDE_BAR" });
};
export const openSideBarCard = () => async (dispatch) => {
  dispatch({ type: OPEN_SIDE_BAR_CARD });
};
export const closeSideBarCard = () => async (dispatch) => {
  dispatch({ type: HIDE_SIDE_BAR_CARD });
};

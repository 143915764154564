import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToggleIcon } from "./LightDarkSvg";
import LightDarkText from "./LightDarkText";

import { handleChecks } from "../../REDUX/actions/filters";
import { closeSideBarCard } from "../../REDUX/actions/general";
import { getAllTickets } from "../../REDUX/actions/tickets";
import { useNavigate } from "react-router-dom";

const ToggleArchiveSwitch = ({
  totalObject,
  archived,
  absolute = true,
  type,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ticketRoute } = useSelector((state) => state.organisationsReducer);

  const { mode } = useSelector((state) => state.genearlReducer);

  const handleToggle = () => {
    dispatch(closeSideBarCard());
    dispatch(handleChecks(totalObject));
    dispatch({ type: "FETCH_TICKET_ITEM_LOADING" });
    dispatch({ type: "FETCH_MESSAGES_LOAD" });
    if (type === "tickets") {
      dispatch(
        getAllTickets(true, true, (newId) =>
          navigate(`${ticketRoute}/${newId}`)
        )
      );
    }
  };
  return (
    <div
      className="archiveToggle d-flex align-items-center gap-2"
      style={{ position: absolute && "absolute", right: "1%" }}
      onClick={handleToggle}
    >
      <ToggleIcon archived={archived} />
      <LightDarkText
        className="f12 bold"
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        Archivés
      </LightDarkText>
    </div>
  );
};

export default ToggleArchiveSwitch;

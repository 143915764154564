import React from "react";
import { useDispatch, useSelector } from "react-redux";
import RightRelatedInterventions from "./RightRelatedInterventions";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import { openModal } from "../../../REDUX/actions/modal";
import InterventionForm from "../../Interventions/InterventionForm/InterventionForm";
import RightInterventionPlaceHolder from "../../PlaceHolders/RightInterventionPlaceHolder";

const RightInterventions = ({ ticket }) => {
  const dispatch = useDispatch();

  const { canCreateInterventions } = useSelector((state) => state.roleReducer);
  const { messagesLoading } = useSelector((state) => state.messagesReducer);

  return (
    <div
      className="directionC justify-content-between "
      style={{ height: "90%" }}
    >
      <div className="sideChatListContainer  overflow-auto">
        {messagesLoading ? (
          <RightInterventionPlaceHolder />
        ) : (
          <RightRelatedInterventions ticket={ticket} />
        )}
      </div>
      {!ticket?.attributes?.archived &&
      ticket?.attributes?.statut !== 2 &&
      canCreateInterventions ? (
        <div>
          <ColoredButton
            color={"light-blue"}
            textButton="Ajouter une intervention"
            callback={() =>
              dispatch(
                openModal({
                  type: "small",
                  preventClose: true,
                  content: (
                    <InterventionForm
                      societeId={ticket?.attributes?.societe?.data?.id}
                      relatedTicket={ticket}
                      responsibleOperators={ticket?.attributes?.responsibleOperators?.data?.map(
                        (el) => el.id
                      )}
                      ticketItemIntervention={true}
                      isNew={true}
                    />
                  ),
                })
              )
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default RightInterventions;

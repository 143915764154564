import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../REDUX/actions/modal";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import { getTicketItem, updateTicket } from "../../REDUX/actions/tickets";
import { handleFirstLastName, showRoleNameByUser } from "../../Utils/utils";
import UserModalInfo from "../Layout/FunctionModal/UserModalInfo";
import LightDarkText from "../Common/LightDarkText";
import { CloseIcon, DeleteIcon, EditIcon } from "../Common/LightDarkSvg";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { shareFile, shareFolder } from "../../REDUX/actions/cloud";
import { useNavigate } from "react-router-dom";
import UserForm from "./UserForm/UserForm";
import TooltipActions from "../Common/Tooltip/TooltipActions";
import UserThumbnail from "../Common/UserThumbnail";

const UserSmallCard = ({
  index,
  responsible,
  societeId,
  ticket,
  inFolderListUser = false,
  folderId,
  inFileListUser = false,
  fileId,
  inTicketParticipants = false,
  societySideBar = false,
  sharedUsers,
  eventParticipants,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { canCreateCollabs, canCreateOperators } = useSelector(
    (state) => state.roleReducer
  );
  const [showTooltip, setshowTooltip] = useState(false);

  const editCollaborateur = () => {
    if (
      responsible.attributes?.roleId === 3 ||
      responsible.attributes?.roleId === 4 ||
      responsible.attributes?.roleId === 5
    ) {
      dispatch(
        openModal({
          type: "small",
          content: (
            <UserForm
              client={responsible}
              societeId={societeId}
              clientSocietyModal
            />
          ),
        })
      );
      setshowTooltip(false);
    } else if (
      responsible.attributes?.roleId === 1 ||
      responsible.attributes?.roleId === 2
    ) {
      dispatch(
        openModal({
          type: "small",
          content: (
            <UserForm
              client={responsible}
              societeId={societeId}
              operatorModal
              isOp={true}
            />
          ),
        })
      );
      setshowTooltip(false);
    }
  };

  const showUSerDetails = () => {
    if (
      ((responsible?.attributes?.roleId === 1 ||
        responsible?.attributes?.roleId === 2) &&
        canCreateOperators) ||
      (responsible?.attributes?.roleId >= 3 &&
        responsible?.attributes?.roleId <= 5 &&
        canCreateCollabs)
    ) {
      dispatch(
        openModal({
          type: "small",
          content: <UserModalInfo responsible={responsible} />,
        })
      );
    }
  };

  const tooltipData = [
    {
      id: 1,
      text: "Éditer",
      icon: <EditIcon className="me-2" />,
      action: () => {
        editCollaborateur();
        setshowTooltip(false);
      },
      condition: true,
    },
    {
      id: 2,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(
                updateTicket({
                  id: ticket.id,
                  visibleBy: ticket.attributes?.visibleBy?.filter(
                    (el) => el.id !== responsible.id
                  ),
                })
              ).then(() => {
                dispatch(getTicketItem(ticket?.id, navigate));
                dispatch(closeModal());
              });
            },
            message: `Voulez vous vraiment supprimer ${handleFirstLastName(
              responsible
            )}?`,
            message2:
              "En confirmant la suppression, le participant sera enlevé du ticket et ne pourra plus y accéder.",
            confirmButtonText: "Je confirme la suppression",
          })
        );
      },
      condition: !societySideBar,
    },
  ];
  return (
    <>
      <div key={index} className="alignH justify-content-between my-2">
        <div
          className={`alignH gap-3 ${
            !inFolderListUser &&
            !inFileListUser &&
            !inTicketParticipants &&
            "pointer"
          }`}
          onClick={() =>
            !inFolderListUser &&
            !inFileListUser &&
            !inTicketParticipants &&
            showUSerDetails()
          }
        >
          <UserThumbnail
            userId={responsible?.id}
            connected={responsible?.attributes?.connected}
            lastConnected={responsible?.attributes?.lastConnected}
            img={`user${responsible?.attributes?.genre || "3"}`}
            apiImg={
              responsible?.avatar ||
              responsible?.attributes?.avatar?.data?.attributes?.image?.data
                ?.attributes?.url
            }
            size={screenWidth < 1200 ? 30 : 40}
          />
          <div>
            <LightDarkText className="text-start bold custom-font-size me-2">
              {responsible?.label || handleFirstLastName(responsible)}
            </LightDarkText>
            <LightDarkText
              className="text-start bold f10 me-2"
              color={mode === "light" ? "text-light-text" : "text-white"}
            >
              {responsible?.role || showRoleNameByUser(responsible?.attributes)}
            </LightDarkText>
            <LightDarkText
              className="text-start custom-ticket-font-size bold"
              color={
                responsible?.attributes?.roleId === 1
                  ? "text-light-blue"
                  : mode === "light"
                  ? "text-blue-grey"
                  : "text-light-text"
              }
            >
              {responsible?.poste || responsible?.attributes?.stringPoste}
            </LightDarkText>
          </div>
        </div>
        {!eventParticipants &&
          (((responsible?.attributes?.roleId === 1 ||
            responsible?.attributes?.roleId === 2) &&
            canCreateOperators) ||
            (responsible?.attributes?.roleId >= 3 &&
              responsible?.attributes?.roleId <= 5 &&
              canCreateCollabs)) && (
            <>
              {!ticket?.attributes?.archived &&
              !inFolderListUser &&
              !inFileListUser &&
              !inTicketParticipants &&
              user.id !== responsible.id ? (
                <div className="d-flex justify-content-end w10 pointer">
                  <TooltipActions
                    setshowTooltip={setshowTooltip}
                    showTooltip={showTooltip}
                    tooltipData={tooltipData}
                  />
                </div>
              ) : null}
              {inFolderListUser || inFileListUser || inTicketParticipants ? (
                <CloseIcon
                  clickCallback={() => {
                    dispatch(
                      openConfirmationModal({
                        callBack: () => {
                          inFolderListUser
                            ? dispatch(
                                shareFolder(
                                  folderId,
                                  sharedUsers.sharedUsers
                                    .filter(
                                      (user) => user.value !== responsible.value
                                    )
                                    .map((user) => user.value),
                                  user
                                )
                              )
                            : inFileListUser
                            ? dispatch(
                                shareFile(
                                  fileId,
                                  sharedUsers.sharedUsers
                                    .filter(
                                      (user) => user.value !== responsible.value
                                    )
                                    .map((user) => user.value),

                                  folderId
                                )
                              )
                            : inTicketParticipants
                            ? dispatch(
                                updateTicket({
                                  id: ticket.id,
                                  visibleBy:
                                    ticket.attributes?.visibleBy?.filter(
                                      (el) => el.id !== responsible.id
                                    ),
                                })
                              ).then(() => {
                                dispatch(getTicketItem(ticket?.id, navigate));
                              })
                            : null;

                          dispatch(closeModal());
                        },
                        message: inTicketParticipants
                          ? `Voulez vous vraiment supprimer ${handleFirstLastName(
                              responsible
                            )}?`
                          : `Voulez vous vraiment enlever ${responsible?.label} de la liste de partage?`,
                        message2: inTicketParticipants
                          ? "En confirmant la suppression, le participant sera enlevé du ticket et ne pourra plus y accéder."
                          : `En confirmant la suppression, le participant sera enlevé de la liste et ne pourra plus accéder au ${
                              inFileListUser ? "fichier" : "dossier"
                            } .`,
                        confirmButtonText: "Je confirme la suppression",
                      })
                    );
                  }}
                />
              ) : null}
            </>
          )}
      </div>
    </>
  );
};

export default UserSmallCard;

import React, { useEffect, useRef, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";

import Thumbnail from "./Thumbnail";
import LightDarkText from "./LightDarkText";
import { SelectArrow } from "./LightDarkSvg";
import { CheckIcon } from "./Icons/CheckIcon";

import { capitalizeFirst } from "../../Utils/utils";

import { addPoste } from "../../REDUX/actions/sharedData";

import "./LighDarkMultiChoice.css";
import InfoQuestion from "./InfoQuestion";

const LighDarkMultiChoice = ({
  label = "",
  data = [],
  multiple = true,
  createNew,
  noSearch,
  totalInputs,
  setValue,
  champs,
  operatorModal,
  clientSocietyModal,
  disabled = false,
  required,
  societySelectInput = false,
  userSocietySelectInput = false,
  ticketParticipantsInput = false,
  calendarForm = false,
  folderSelectInput = false,
  hideArrow = false,
  placeholder = "Choisir...",
  selectMoreInfo = undefined,
  htmlElements = false,
}) => {
  const container = useRef(null);
  const dispatch = useDispatch();
  const [direction, setDirection] = useState("top");
  const [sortedData, setSortedData] = useState([]);
  const { mode } = useSelector((state) => state.genearlReducer);

  useEffect(() => {
    setSortedData([
      ...totalInputs[champs],
      ...data.filter(
        (el) => !totalInputs[champs].find((s) => +s?.value === +el?.value)
      ),
    ]);
  }, [totalInputs, data]);

  useEffect(() => {
    if (data.length === 0) {
      handleChange([]);
    }
  }, [data.length]);

  const handleChange = (selected) => {
    if (multiple) {
      setValue({
        ...totalInputs,
        [champs]: selected.filter((el) => el?.value && el?.label),
      });
      setSortedData([
        ...selected,
        ...data.filter((el) => !selected.find((s) => +s?.value === +el?.value)),
      ]);
    } else {
      const lastItem = selected?.filter((el) => el?.value && el?.label)[
        selected?.length - 1
      ];
      let labelValue = lastItem?.label;
      if (htmlElements && lastItem?.label) {
        labelValue = lastItem.label.split("<strong>")[1].split("</strong>")[0];
      }

      setValue({
        ...totalInputs,
        [champs]: lastItem ? [{ ...lastItem, label: labelValue }] : [],
      });
      if (lastItem) {
        setSortedData([
          lastItem,
          ...data.filter((el) => +el.value !== +lastItem?.value),
        ]);
      } else return setSortedData([]);
    }
  };
  const handleNewField = (label) => {
    // TODO
    if (champs === "poste") {
      dispatch(addPoste(capitalizeFirst(label))).then((data) => {
        setValue({
          ...totalInputs,
          poste: [data],
        });
      });
    }

    return {};
  };

  useEffect(() => {
    if (!container?.current) return;
    const containerHeight =
      container.current.getBoundingClientRect().height * data?.length ?? 0;
    const top = container.current.getBoundingClientRect().bottom;
    const availableHeight = window.screen.height - top;
    if (availableHeight < containerHeight + 100) {
      setDirection("bottom");
    } else {
      setDirection("top");
    }
  }, [data?.length]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <LightDarkText
          className={"text-start bold custom-ticket-font-size mb-2"}
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          {label} {required ? <span className="text-red">*</span> : null}
        </LightDarkText>

        {selectMoreInfo && <InfoQuestion small htmlMessage={selectMoreInfo} />}
      </div>

      {data?.length === 1 &&
      !ticketParticipantsInput &&
      !userSocietySelectInput &&
      !folderSelectInput &&
      !calendarForm ? (
        <div
          className={`d-flex align-items-center px-2 h40 radius05 w-100 ${
            mode === "light" ? "bg-light-mode" : "bg-dark-message"
          }`}
          style={{
            border: `1px solid ${mode === "light" ? "#f4f6fd" : "#252c45"} `,
            height: `${!societySelectInput ? "40px" : ""}`,
          }}
        >
          {societySelectInput && !userSocietySelectInput && (
            <Thumbnail
              rounded
              apiImg={data[0]?.avatar}
              img="society"
              size={32}
            />
          )}

          <LightDarkText
            className={`${societySelectInput && "ms-2"} f12`}
            color={mode === "light" ? "text-dark-blue" : "text-light-text"}
          >
            {data[0].label}
          </LightDarkText>
        </div>
      ) : (
        <form
          autoComplete="new-password"
          className={`w-100 select-${direction}`}
          onClick={(e) => e.stopPropagation()}
          ref={container}
        >
          <MultiSelect
            options={sortedData?.length ? sortedData : data}
            ArrowRenderer={() => (hideArrow ? null : <SelectArrow />)}
            value={totalInputs[champs]}
            onChange={(e) => handleChange(e)}
            labelledBy="Select"
            isCreatable={createNew}
            disableSearch={noSearch}
            disabled={operatorModal || clientSocietyModal || disabled}
            onCreateOption={createNew ? handleNewField : null}
            hasSelectAll={multiple}
            ClearSelectedIcon={null}
            closeOnChangedValue={!multiple}
            ItemRenderer={({ checked, option, onClick, disabled }) => {
              const itemFromData = data.find(
                (item) => +item.value === +option.value
              );
              return (
                <div
                  className={`item-renderer position-relative ${
                    disabled ? "disabled" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    onChange={onClick}
                    checked={checked}
                    tabIndex={-1}
                    disabled={disabled}
                    hidden
                  />
                  <span
                    className={`select-checkbox d-flex align-items-center justify-content-center radius05 d-inline-block me-2 ${
                      checked ? "checked" : ""
                    } ${!multiple ? "single" : ""}
              `}
                  >
                    <CheckIcon />
                  </span>
                  {(societySelectInput || userSocietySelectInput) &&
                    option?.label !== "Séléctionner tout" &&
                    option?.label !== "Désélectionner tout" &&
                    option?.label !== "Séléctionner tout (Filtré)" && (
                      <Thumbnail
                        rounded
                        apiImg={itemFromData?.avatar}
                        img="society"
                        size={20}
                        className={"me-2"}
                      />
                    )}
                  {htmlElements ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: option?.label }}
                    ></span>
                  ) : (
                    <span>{option?.label}</span>
                  )}
                </div>
              );
            }}
            className={`flex-1 ${
              mode === "light" ? "multichoiceLight" : "multichocieDark"
            } ${disabled && "disabled"} radius10 h40 multichoiceContainer`}
            overrideStrings={{
              allItemsAreSelected: "Tous les éléments sont séléctionnés",
              clearSearch: "Effacer la recherche",
              clearSelected: "Effacer séléctionnés",
              noOptions: "Pas de resultat",
              search: "Recherche",
              selectAll:
                totalInputs[champs]?.length === data?.length
                  ? "Désélectionner tout"
                  : "Séléctionner tout",
              selectAllFiltered: "Séléctionner tout (Filtré)",
              selectSomeItems: placeholder,
              create: "Créer",
            }}
          />
        </form>
      )}
    </>
  );
};

export default LighDarkMultiChoice;

const initialState = {
  messages: [],
  messagesLoading: false,
  sendMessageLoading: false,
  error: null,
};
const messagesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_MESSAGES_LOAD": {
      return { ...state, messagesLoading: true };
    }
    case "SEND_MESSAGE_LOADING": {
      return { ...state, sendMessageLoading: false };
    }
    case "SEND_MESSAGE_SUCCESS": {
      return { ...state, sendMessageLoading: false };
    }

    case "FETCH_MESSAGES_SUCC": {
      return {
        ...state,
        messagesLoading: false,
        error: null,
        messages: payload,
      };
    }

    case "FETCH_MESSAGES_FAIL": {
      return { ...state, messagesLoading: false, error: payload };
    }

    case "RESET_MESSAGES": {
      return { ...state, messages: [], messagesLoading: false };
    }

    case "DELETE_MESSAGE": {
      let updatedMessages = state?.messages?.filter(
        (message) => +message?.id !== +payload
      );
      return {
        ...state,
        messages: updatedMessages,
        messagesLoading: false,
      };
    }

    case "DELETE_OTHER_MESSAGE": {
      const updatedMessages = state?.messages?.filter(
        (message) => +message?.id !== +payload
      );
      return {
        ...state,
        messages: updatedMessages,
        messagesLoading: false,
      };
    }

    case "UPDATE_MESSAGE": {
      let newMessagesAfterUpdate = state?.messages?.map((message) =>
        +message.id === +payload.id
          ? {
              ...message,
              attributes: {
                ...message.attributes,
                content: payload.attributes.content,
              },
            }
          : message
      );

      return {
        ...state,
        messages: newMessagesAfterUpdate,
        messagesLoading: false,
      };
    }

    case "INTERVENTION_UPDATED": {
      const updatedMessages = state?.messages?.map((item) => {
        if (+item?.attributes?.relatedIntervention?.data?.id === +payload?.id) {
          item.attributes.relatedIntervention.data = payload;
        }
        return item;
      });

      return { ...state, messages: updatedMessages, messagesLoading: false };
    }
    case "REFETCH_MESSAGE_SUCCESS": {
      return {
        ...state,
        messages: state.messages.map((el) =>
          el.id === payload.id ? payload : el
        ),
      };
    }

    default: {
      return state;
    }
  }
};

export default messagesReducer;

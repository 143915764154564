import { handleError } from "@apollo/client/link/http/parseAndCheckHttpResponse";

import { messageItemQuery, messagesQuery } from "../apiQueries/apiQueries";
import Api from "../../Api/Api";
import store from "../store/store";
import QueryString from "qs";
import { userApiAttributes } from "../apiQueries/general/userApiFields";

export const getMessages =
  (ticketId, page = 0, withLoading = true) =>
  async (dispatch) => {
    if (withLoading) {
      dispatch({ type: "FETCH_MESSAGES_LOAD" });
    }
    const userId = store.getState().userReducer.user.id;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    try {
      const { data } = await Api.get(
        `/api/messages?${messagesQuery(ticketId, page, orgId, userId)}`
      );
      dispatch({
        type: "FETCH_MESSAGES_SUCC",
        payload: data?.data,
      });
    } catch (error) {
      dispatch({ type: "FETCH_MESSAGES_FAIL", payload: error });
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const resetMessages = () => (dispatch) => {
  dispatch({ type: "RESET_MESSAGES" });
};

export const addReact = (data) => async (dispatch) => {
  try {
    const response = await Api.post(`/api/handleReact`, {
      data,
    });
    if (response.data?.message === "Duplicate") {
      return;
    }
    dispatch(refetchMessage(data.message));
  } catch (error) {
    console.log(error);
  }
};

export const removeReact = (reactId, messageId) => async (dispatch) => {
  try {
    await Api.delete(`/api/reacts/${reactId}`);
    dispatch(refetchMessage(messageId));
  } catch (error) {
    console.log(error);
  }
};

export const refetchMessage = (messageId) => async (dispatch) => {
  const userId = store.getState().userReducer.user.id;
  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  if (!orgId) {
    return;
  }
  try {
    const { data } = await Api.get(
      `/api/messages/${messageId}?${messageItemQuery(orgId, userId)}`
    );
    dispatch({
      type: "REFETCH_MESSAGE_SUCCESS",
      payload: data?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "FETCH_MESSAGES_FAIL", payload: error });
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const addFavoriteMessage = (data) => async (dispatch) => {
  try {
    const response = await Api.post(`/api/message-favorites`, {
      data,
    });
    dispatch(refetchMessage(data.message));
    const query = QueryString.stringify({
      populate: {
        message: {
          fields: ["id", "content"],
          populate: {
            sender: {
              fields: userApiAttributes,
              populate: {
                avatar: {
                  fields: ["id"],
                  populate: { image: { fields: ["url"] } },
                },
              },
            },
          },
        },
      },
    });
    const newFavorite = await Api.get(
      `/api/message-favorites/${response.data.data.id}?${query}`
    );

    dispatch({
      type: "ADD_FAVORITE_MESSAGE",
      payload: newFavorite.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeFavoriteMessage = (favId, messageId) => async (dispatch) => {
  try {
    const response = await Api.delete(`/api/message-favorites/${favId}`);

    dispatch(refetchMessage(messageId));

    dispatch({
      type: "REMOVE_FAVORITE_MESSAGE",
      payload: response.data.data.id,
    });
  } catch (error) {
    console.log(error);
  }
};

import { createRef } from "react";
import { useDispatch } from "react-redux";

import EventTitle from "../EventCommonComponents/EventTitle";
import EventDate from "../EventCommonComponents/EventDate";
import EventLocation from "../EventCommonComponents/EventLocation";
import EventStatus from "../EventCommonComponents/EventStatus";
import SocialMediaIcons from "../EventCommonComponents/SocialMediaIcons";
import AvatarStack from "../EventCommonComponents/AvatarStack";

import { handleEventDuration } from "../../calendarUtils";

import { setEventPosition } from "../../../../REDUX/actions/calendars";
import { showSideBar } from "../../../../REDUX/actions/general";

const EventCard = ({ eventInfo }) => {
  const cardRef = createRef();

  const dispatch = useDispatch();

  const handleEventPopPosition = () => {
    dispatch(
      showSideBar({
        isNew: false,
        eventDetails: eventInfo.event._def.extendedProps,
        fromCalendar: true,
      })
    );
    // if (cardRef.current) {
    //   const cal = document.querySelector(".fc-scrollgrid-section-body");
    //   const parentRect = cal.getBoundingClientRect();
    //   const elementRect = cardRef.current.getBoundingClientRect();
    //   dispatch(
    //     setEventPosition({
    //       position: {
    //         x: elementRect.left,
    //         y: elementRect.top - parentRect.top + cal.scrollTop + 100,
    //       },
    //       eventCardWidth: cardRef?.current?.offsetWidth,
    //       event: eventInfo,
    //     })
    //   );
    // }
  };

  return (
    <div
      ref={cardRef}
      className={`${
        handleEventDuration(
          eventInfo.event?.startStr,
          eventInfo.event?.endStr
        ) < "01:00" || eventInfo?.view?.type === "dayGridMonth"
          ? "px-2"
          : "p-2"
      } radius06 h100 w-100 event-card`}
      style={{
        overflow: "hidden",
        backgroundColor: `${eventInfo?.backgroundColor}`,
      }}
      onClick={handleEventPopPosition}
    >
      <div className="d-flex flex-column justify-content-between h100">
        <div>
          <EventTitle title={eventInfo.event.title} eventMainCard />
          {eventInfo?.view?.type !== "dayGridMonth" && (
            <>
              {handleEventDuration(
                eventInfo.event?.startStr,
                eventInfo.event?.endStr
              ) >= "01:00" && (
                <EventDate
                  color={"white"}
                  dates={{
                    start: eventInfo.event.startStr,
                    end: eventInfo.event.endStr,
                  }}
                />
              )}
              {handleEventDuration(
                eventInfo.event?.startStr,
                eventInfo.event?.endStr
              ) >= "01:30" && (
                <>
                  <EventLocation
                    place={eventInfo?.event?.extendedProps?.place}
                    eventMainCard
                  />
                  <EventStatus
                    statut={eventInfo?.event?.extendedProps.statut}
                  />
                  <SocialMediaIcons event={eventInfo?.event?.extendedProps} />
                </>
              )}
            </>
          )}
        </div>
        {eventInfo?.view?.type !== "dayGridMonth" &&
          handleEventDuration(
            eventInfo.event?.startStr,
            eventInfo.event?.endStr
          ) >= "01:30" &&
          !eventInfo?.event?.extendedProps?.place && (
            <AvatarStack
              mainEventCard
              calendar={eventInfo?.event?.extendedProps?.calendar?.data}
              border={eventInfo?.event?.backgroundColor}
              participants={eventInfo?.event?.extendedProps?.participants?.data}
            />
          )}
      </div>
    </div>
  );
};

export default EventCard;

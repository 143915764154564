import React from "react";
import { useSelector } from "react-redux";

import LightDarkText from "../LightDarkText";

const Tooltipitem = ({ id, text, action, icon }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      <div className="tooltip-item  p-3" key={id} onClick={action}>
        <LightDarkText
          className={
            "text-start custom-ticket-font-size alignH bold clickable-text t-02 pointer"
          }
          color={mode === "light" ? "text-dark-blue" : "text-light-text"}
        >
          <div>{icon}</div>
          <div className="bolder">{text}</div>
        </LightDarkText>
      </div>
    </>
  );
};

export default Tooltipitem;

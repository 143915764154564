import { useSelector, useDispatch } from "react-redux";

import { CloseIcon } from "../LightDarkSvg";
import LightDarkText from "../LightDarkText";

import { clearTempUpload } from "../../../REDUX/actions/files";
import { closeModal } from "../../../REDUX/actions/modal";

const FormHeader = ({ formTitle }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="d-flex justify-content-between mb-4">
      <LightDarkText className="text-start custom-title-font-size bold">
        {formTitle}
      </LightDarkText>
      <CloseIcon
        clickCallback={() => {
          dispatch(clearTempUpload());
          dispatch(closeModal());
        }}
      />
    </div>
  );
};

export default FormHeader;

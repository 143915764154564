import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { imageLoader } from "../../Utils/utils";
import "./Thumbnail.css";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import LightDarkText from "./LightDarkText";
import moment from "moment";

const UserThumbnail = ({
  userId,
  loader = false,
  size = 40,
  apiImg,
  img,
  border,
  connected,
  lastConnected,
  historyThumbnail = false,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { user } = useSelector((state) => state.userReducer);

  const { io } = useSelector((state) => state.socketReducer);
  const [userConnected, setUserConnected] = useState(
    user?.id?.toString() === userId?.toString() ? true : connected
  );
  const [userLastConnected, setUserLastConnected] = useState(lastConnected);
  const { canSeeWhoOnline } = useSelector((state) => state.roleReducer);

  useEffect(() => {
    let timeoutId;

    if (io) {
      io.on("userConnected", async (data) => {
        if (parseInt(data.id) === parseInt(userId)) {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          timeoutId = setTimeout(() => {
            setUserLastConnected(data.lastConnected);
            setUserConnected(data.connected);
          }, 10000);
        }
      });
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      io?.off("userConnected");
    };
  }, [io, userId]);

  return loader ? (
    <div className="alignC" style={{ height: `${size}px`, width: `${size}px` }}>
      <Spinner size="sm" className="text-light-text" />
    </div>
  ) : (
    <div
      className={` ${
        historyThumbnail &&
        `d-flex align-items-center p-4 justify-content-center ${
          mode === "light" ? "bg-light-mode" : "bg-dark-mode"
        } history-thumbnail`
      }`}
      style={{
        border:
          historyThumbnail &&
          `${mode === "light" ? "1px solid #3540741F" : "1px solid #252C45"}`,
        width: size,
        height: size,
      }}
    >
      <div
        className={`radius10 thumbnailContainer thumbnailRounded`}
        style={{
          backgroundImage: apiImg
            ? `url(${imageLoader(apiImg)})`
            : img
            ? `url(${require(`../../Images/temp/${img}.png`)})`
            : null,
          width: size,
          height: size,
          minWidth: size,
          minHeight: size,
          boxSizing: "border-box",
          backgroundColor: "white",
          backgroundPosition: "center",
          border: border && `2px solid ${border}`,
        }}
      >
        {canSeeWhoOnline && userId ? (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip style={{ zIndex: 10000 }}>
                <LightDarkText className="custom-ticket-font-size py-1 text-white">
                  {userConnected
                    ? "En ligne"
                    : userLastConnected
                    ? `En ligne il y a ${moment
                        .duration(moment().diff(userLastConnected))
                        .humanize()}`
                    : "Hors ligne"}
                </LightDarkText>
              </Tooltip>
            }
          >
            <div
              className="connectedThumbnail"
              style={{
                backgroundColor: userConnected
                  ? "#2f9a48"
                  : moment().diff(userLastConnected, "minutes") < 30
                  ? "#e67b0f"
                  : "grey",
              }}
            ></div>
          </OverlayTrigger>
        ) : null}
      </div>
    </div>
  );
};

export default UserThumbnail;

import React from "react";
import { useSelector } from "react-redux";

import { isValidPhoneNumber } from "react-phone-number-input";

import LightDarkInput from "../../Common/LightDarkInput";
import LightDarkText from "../../Common/LightDarkText";
import Uploader from "../../Common/Uploader";

import { validMail } from "../../../Utils/utils";

import useWindowWidth from "../../../Hooks/useWindowWidth";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";

const SocietyInfosForm = ({
  setAvatar,
  avatar,
  currentImage,
  societyInputs,
  setsocietyInputs,
}) => {
  const screenWidth = useWindowWidth();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { societyTypesValues } = useSelector(
    (state) => state.sharedDataReducer
  );
  return (
    <>
      <div className="mt-5">
        <div className="row align-items-center">
          <div className={`${screenWidth > 600 ? "col-4" : "col-12"}`}>
            <Uploader
              setState={setAvatar}
              state={avatar}
              id="image"
              name="image"
              placeholder="Ajouter une photo de profil"
              defaultImage={currentImage}
            />
          </div>
          <div className={`${screenWidth > 600 ? "col-4" : "col-12"}`}>
            <LightDarkInput
              placeholder={"Nom de la société"}
              label={"Nom de la société"}
              totalInputs={societyInputs}
              setValue={setsocietyInputs}
              champs="name"
              required
            />
            <div className="mt-4">
              <LightDarkInput
                placeholder={"contact@societe.com"}
                label={"E-mail"}
                totalInputs={societyInputs}
                setValue={setsocietyInputs}
                champs="email"
                required
              />
              {societyInputs.email && !validMail(societyInputs.email) && (
                <span className="field-error f11 position-absolute mt-1 w-100 text-red">
                  Adresse mail non valide
                </span>
              )}
            </div>
          </div>
          <div className={`${screenWidth > 600 ? "col-4" : "col-12 mt-4"}`}>
            <LightDarkInput
              placeholder={"Siret / RCI"}
              label={"Siret / RCI"}
              type="number"
              totalInputs={societyInputs}
              setValue={setsocietyInputs}
              champs="siret"
            />
            <div className="mt-4">
              <LightDarkInput
                placeholder={"contact@societe.com"}
                label={"E-mail de facturation"}
                totalInputs={societyInputs}
                setValue={setsocietyInputs}
                champs="emailFacture"
                required
              />
              {societyInputs.emailFacture &&
                !validMail(societyInputs.emailFacture) && (
                  <span className="field-error f11 position-absolute mt-1 w-100 text-red">
                    Adresse mail non valide
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className={societyTypesValues.length !== 0 ? "col-4" : "col-6"}>
          <LightDarkInput
            placeholder={"www.societe.com"}
            label={"Site internet"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="siteWeb"
          />
        </div>
        <div className={societyTypesValues.length !== 0 ? "col-4" : "col-6"}>
          <LightDarkInput
            placeholder={"Numéro de téléphone"}
            label={"Téléphone"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            value={societyInputs.phoneNumber}
            champs="phoneNumber"
            phoneInput
          />
          {societyInputs.phoneNumber &&
            (isValidPhoneNumber(societyInputs.phoneNumber) ? undefined : (
              <span
                className="field-error f11 position-absolute mt-1 text-red"
                style={{
                  minWidth: "max-content",
                }}
              >
                Numéro de téléphone non valide
              </span>
            ))}
        </div>
        {societyTypesValues.length !== 0 && (
          <div className="col-4">
            <LighDarkMultiChoice
              label="Type"
              data={societyTypesValues}
              totalInputs={societyInputs}
              setValue={setsocietyInputs}
              multiple={false}
              champs="society_type"
              required
            />
          </div>
        )}
      </div>
      <div className="row mt-4">
        <div>
          <LightDarkInput
            placeholder={"83, avenue Lorem ipsum"}
            label={"Adresse"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="adress"
          />
        </div>
      </div>
      <div className="row mt-4  mb-3">
        <div className="col-6">
          <LightDarkInput
            placeholder={"00000"}
            label={"Code postal"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="zipCode"
          />
        </div>
        <div className="col-6">
          <LightDarkInput
            placeholder={"Ville"}
            label={"Ville"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="city"
          />
        </div>
      </div>
      <LightDarkText className="text-start bold custom-ticket-font-size mt-4">
        <span className="text-red">*</span>Champs obligatoires
      </LightDarkText>
    </>
  );
};

export default SocietyInfosForm;

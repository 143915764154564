import moment from "moment";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import LightDarkContainer from "../../../../../../Common/LightDarkContainer";
import LightDarkText from "../../../../../../Common/LightDarkText";
import {
  DeleteIcon,
  EditIcon,
  VerticalPoints,
} from "../../../../../../Common/LightDarkSvg";
import SettingTooltip from "../../../../../../Common/Tooltip/SettingTooltip";
import Tooltipitem from "../../../../../../Common/Tooltip/Tooltipitem";
import AttachementFiles from "../../../../../../Common/AttachementFiles";

import UpdateMessage from "../../../../UpdateMessage";

import { openModal } from "../../../../../../../REDUX/actions/modal";
import { openConfirmationModal } from "../../../../../../../REDUX/actions/confirmationModal";
import { deleteFile } from "../../../../../../../REDUX/actions/cloud";
import {
  deleteMessage,
  getTicketItem,
} from "../../../../../../../REDUX/actions/tickets";

import { endPoint } from "../../../../../../../Api/endPoint";
import {
  prepareMentionOverlay,
  showMentionOverlay,
} from "../../../../messageUtils";

import defaultUserImg from "../../../../../../../Images/temp/user.png";
import MessageCardFooter from "../MessageCardFooter";
import MessagesReacts from "./MessagesReacts";

const MessageContent = ({
  ticketId,
  isArchived,
  message,
  messageContenttoShow,
  showOriginal,
  setshowOriginal,
  toolTipRef,
  showTooltip,
  setshowTooltip,
  tooltipCoordinates,
  setTooltipCoordinates,
  meSending,
  setMeSending,
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { languages } = useSelector((state) => state.sharedDataReducer);

  const [userData, setUserData] = useState();
  const [mentionTag, setMentionTag] = useState();

  useEffect(() => {
    const messageContent = document?.querySelector(
      `.message-content-${message?.id}`
    );
    prepareMentionOverlay(messageContent, setMentionTag, setUserData, message);
  }, [message]);

  useEffect(() => {
    if ((mentionTag, userData)) {
      showMentionOverlay(mentionTag, userData, defaultUserImg);
    }
  }, [mentionTag, userData, message]);

  const addClassToElement = (html, elementTag, className) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const element = doc.querySelector(elementTag);
    if (element) {
      element.classList.add(className.class1, className.class2);
    }
    return doc.documentElement.outerHTML;
  };

  const modifiedHtmlContent = addClassToElement(
    messageContenttoShow.message || "",
    messageContenttoShow.message?.substring(1, 3).startsWith("h")
      ? messageContenttoShow.message?.substring(1, 3)
      : "p",
    { class1: "message-text", class2: "custom-ticket-font-size" }
  );

  const handleDeleteMessage = (
    messageId,
    message2,
    message,
    btnMessage,
    files
  ) => {
    dispatch(
      openConfirmationModal({
        callBack: async () => {
          dispatch(deleteMessage(messageId));
          for (let i = 0; i < files?.length; i++) {
            dispatch(deleteFile(files[i])).then(() => {
              i === files?.length - 1 &&
                dispatch(getTicketItem(ticketId, navigate));
            });
          }
        },
        icon: "archive",
        message: message || "Voulez vous supprimer le message",
        message2: message2,
        confirmButtonText: btnMessage || "Supprimer le message",
      })
    );
  };

  var a = moment(new Date());
  var b = moment(message?.attributes?.createdAt);
  const diff = a.diff(b, "hours");

  const tooltipData = [
    {
      id: 1,
      text: "Modifier",
      icon: <EditIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openModal({
            type: "small",
            content: <UpdateMessage message={message} />,
          })
        );
      },
      condition: true,
    },
    {
      id: 2,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        handleDeleteMessage(
          message.id,
          `#${message.id} ?`,
          "Êtes-vous sur de supprimer ce message",
          "Je confirme la suppression",
          message.attributes?.fichiers?.data
        );
      },
      condition: true,
    },
  ];

  return (
    <div className="w-100 position-relative">
      {languages.length && !meSending ? (
        messageContenttoShow.translated ? (
          <div className="d-flex gap-2 justify-content-end ">
            <LightDarkText
              className="f10 pointer"
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
              onclickCallback={() => setshowOriginal(!showOriginal)}
            >
              {showOriginal ? "Afficher la traduction" : "Afficher l'original"}
            </LightDarkText>
          </div>
        ) : (
          <div className="d-flex gap-2 justify-content-end ">
            <LightDarkText
              className="f10"
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
            >
              Traduction indisponible
            </LightDarkText>
          </div>
        )
      ) : null}
      <div className="messageLeft">
        <LightDarkContainer
          colorLight={meSending ? "lighter-blue" : "light-mode"}
          colorDark={meSending ? "light-message" : "dark-message"}
          className={"p-3 messageCardMessage position-relative radius05"}
        >
          <div
            className={`d-flex justify-content-between ${
              message?.attributes?.fichiers?.data?.length &&
              messageContenttoShow.message &&
              "mb-3"
            }`}
          >
            {meSending && !isArchived ? (
              <>
                {diff >= 24 ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <LightDarkText className="custom-ticket-font-size py-1 text-white">
                          Il s'est écoulé plus de 24 heures depuis que vous avez
                          créé votre message, par souci de conformité, il n'est
                          désormais plus possible de le modifier ou de le
                          supprimer.
                        </LightDarkText>
                      </Tooltip>
                    }
                  >
                    <div
                      className="order-1 d-flex align-items-start ps-1"
                      style={{ height: "25px" }}
                    >
                      <VerticalPoints darkColor="#354074" />
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div
                    ref={toolTipRef}
                    onClick={(e) => {
                      setTooltipCoordinates({
                        x: toolTipRef?.current?.getBoundingClientRect().x,
                        y: toolTipRef?.current?.getBoundingClientRect().y + 16,
                      });
                      e.stopPropagation();
                      setshowTooltip(true);
                    }}
                    className="order-1 d-flex align-items-start ps-1"
                  >
                    <VerticalPoints darkColor="#354074" />
                  </div>
                )}
                {showTooltip ? (
                  <div
                    style={{
                      position: "fixed",
                      top: tooltipCoordinates?.y + 12 + "px",
                      left: tooltipCoordinates?.x + 16 + "px",
                      zIndex: 1,
                    }}
                  >
                    <SettingTooltip
                      showTooltip={showTooltip}
                      setshowTooltip={setshowTooltip}
                    >
                      {tooltipData
                        .filter((item) => item.condition)
                        .map(({ id, text, action, icon }) => {
                          return (
                            <Tooltipitem
                              key={id}
                              id={id}
                              text={text}
                              icon={icon}
                              action={action}
                            />
                          );
                        })}
                    </SettingTooltip>
                  </div>
                ) : null}
              </>
            ) : null}
            <LightDarkText
              className={`bold text-start break-word`}
              color={mode === "light" ? "text-blue-grey" : "text-white"}
            >
              <span
                className={`message-content-${message?.id} text-start custom-ticket-font-size p-nomb`}
                dangerouslySetInnerHTML={{
                  __html: modifiedHtmlContent,
                }}
                style={{
                  "--username": `${userData?.attributes?.firstName || ""} ${
                    userData?.attributes?.last || ""
                  }`,
                  "--avatar": `${
                    endPoint +
                    userData?.attributes?.avatar?.data?.attributes?.image?.data
                      ?.attributes?.url
                  }`,
                }}
              ></span>
            </LightDarkText>
          </div>
          <AttachementFiles message={message} />
        </LightDarkContainer>
      </div>
      <MessagesReacts message={message} meSending={meSending} />
      <MessageCardFooter
        message={message}
        setMeSending={setMeSending}
        meSending={meSending}
      />
    </div>
  );
};

export default MessageContent;

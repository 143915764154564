import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../REDUX/actions/modal";
import { validMail } from "../../../Utils/utils";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import NotificationManagement from "../../Layout/Notification/NotificationManagement";
import UserInfoForm from "./UserInfoForm";
import UserSettingsForm from "./UserSettingsForm";
import UserFormHeader from "./UserFormHeader";
import UserOpStats from "./UserOpStats";
import {
  handleInviteUser,
  handleNewUser,
  handleUpdateuser,
} from "../../../REDUX/actions/user";
import UserPermissionForm from "./UserPermissionForm";
import {
  checkButtonDisabledUser,
  notificationsFields,
  permissionFields,
  userFields,
} from "./userFormUtils";
import Loading from "../../Common/Loading";
import ServicesList from "../../MarketPlace/ServicesList";
import { getDevices } from "../../../REDUX/actions/sharedData";

const UserForm = ({ isNew, client, isOp }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { notificationSettings, notificationSettingsLoading } = useSelector(
    (state) => state.notificationSettingsReducer
  );
  const { userAdditionalFields } = useSelector(
    (state) => state.organisationsReducer
  );
  const [isToggle, setIsToggle] = useState("Coordonnées");
  const [settingValues, setSettingValues] = useState({});
  const [permissionValues, setpermissionValues] = useState({});
  const [emailInfo, setemailInfo] = useState({
    notValid: false,
    alreadyUsed: false,
    userFound: null,
    inMyOrg: null,
  });
  const [avatar, setAvatar] = useState();
  const [clientInputs, setclientInputs] = useState(
    userFields(isNew, client, isOp)
  );

  const currentImage =
    client?.attributes?.avatar?.data?.attributes?.image?.data?.attributes?.url;
  const handleSubmitClient = async () => {
    if (emailInfo.alreadyUsed) {
      dispatch(
        handleInviteUser({
          id: emailInfo?.userFound.id,
          isOp,
          clientInputs,
          notificationsSetting: settingValues,
          permissionValues: permissionValues,
        })
      );
    } else if (isNew) {
      dispatch(
        handleNewUser({
          isOp,
          clientInputs,
          avatar,
          notificationsSetting: settingValues,
          permissionValues: permissionValues,
        })
      );
    } else {
      dispatch(
        handleUpdateuser({
          isOp,
          clientInputs,
          avatar,
          notificationsSetting: settingValues,
          permissionValues: permissionValues,
          id: client?.id,
          userOrgId: client.attributes.user_org_permissions.data[0].id,
          notifSettingId:
            client.attributes.user_org_permissions.data[0].attributes
              .notification_setting.data.id,
        })
      );
    }

    return dispatch(closeModal());
  };
  useEffect(() => {
    setSettingValues(notificationsFields(client));
  }, [
    dispatch,
    notificationSettings,
    client?.id,
    notificationSettingsLoading,
    client,
  ]);
  console.log(clientInputs);
  useEffect(() => {
    setpermissionValues(
      permissionFields(
        clientInputs?.role[0]?.value,
        client,
        client?.attributes?.user_org_permissions?.data[0]?.attributes?.role !==
          clientInputs?.role[0]?.value
      )
    );
  }, [dispatch, client?.id, clientInputs?.role, client]);
  useEffect(() => {
    dispatch(getDevices(clientInputs?.prestations));
  }, [clientInputs?.prestations, dispatch, client]);
  return !permissionValues || !clientInputs ? (
    <Loading />
  ) : (
    <div
      className={`${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      } px-4 py-3 radius05`}
      style={{
        height: "740px",
        width: "700px",
      }}
    >
      <div
        className="h100 directionC justify-content-between gap-3"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="flex-1">
          <UserFormHeader
            isNew={isNew}
            client={client}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            isOp={isOp}
            clientInputs={clientInputs}
            emailInfo={emailInfo}
          />
        </div>
        <div className="h100 overflow-hidden scrollable">
          {isToggle === "Coordonnées" && (
            <UserInfoForm
              setAvatar={setAvatar}
              avatar={avatar}
              currentImage={currentImage}
              clientInputs={clientInputs}
              setclientInputs={setclientInputs}
              isNew={isNew}
              setemailInfo={setemailInfo}
              emailInfo={emailInfo}
              client={client}
              isOp={isOp}
            />
          )}
          {isToggle === "personnalisation" && (
            <UserSettingsForm
              client={client}
              clientInputs={clientInputs}
              setclientInputs={setclientInputs}
              invitation={emailInfo.alreadyUsed}
              isOp={isOp}
            />
          )}

          {isToggle === "Statistiques" && <UserOpStats operator={client} />}
          {isToggle === "Notifications" && (
            <NotificationManagement
              role={
                +client?.attributes?.user_org_permissions[0]?.role ||
                clientInputs?.role[0]?.value
              }
              values={settingValues}
              setValues={setSettingValues}
            />
          )}
          {isToggle === "Permissions" && (
            <UserPermissionForm
              role={
                +client?.attributes?.user_org_permissions[0]?.role ||
                +clientInputs?.role[0]?.value
              }
              values={permissionValues}
              setValues={setpermissionValues}
            />
          )}
          {isToggle === "externalServices" && <ServicesList />}
        </div>

        <div className={"alignC flex-1"}>
          {!isNew && emailInfo.alreadyUsed ? null : (
            <div className={`col-5`}>
              <ColoredButton
                disabled={checkButtonDisabledUser(
                  clientInputs,
                  isNew,
                  emailInfo,
                  userAdditionalFields
                )}
                textButton={
                  emailInfo.alreadyUsed
                    ? "Ajouter à votre espace"
                    : isNew
                    ? isOp
                      ? "Ajouter l'opérateur"
                      : "Ajouter le client"
                    : "Mettre à jour les informations"
                }
                color="light-blue"
                callback={() => handleSubmitClient()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserForm;

import Api from "../../Api/Api";
import { validMail } from "../../Utils/utils";
import { organisationQuery } from "../apiQueries/organisation";
import store from "../store/store";
import { hideSideBar } from "./general";
import { getInitialData } from "./sharedData";
import { logout } from "./user";

export const getAllOrganisations = () => async (dispatch) => {
  dispatch({ type: "ORGANISATIONS_LOADING" });
  try {
    const user = store.getState().userReducer.user;

    const { data } = await Api.get(
      `/api/organisations?${organisationQuery(user?.id)}`
    );
    if (!data.data.length) {
      throw "";
    }
    dispatch({ type: "ORGANISATIONS_SUCCESS", payload: data.data });
  } catch (error) {
    dispatch({ type: "ORGANISATIONS_FAIL" });
    dispatch(logout());
  }
};

export const selectorganisation = (id) => async (dispatch) => {
  dispatch({ type: "RESET_ORGANISATION" });

  dispatch(hideSideBar());
  dispatch({ type: "CHNAGE_ORGANISATION", payload: id });
  localStorage.setItem("organisation", id);
  dispatch(getInitialData(id));
};

export const handleCheckEmail = (email) => async () => {
  try {
    if (!validMail(email)) {
      return { notValid: true, alreadyUsed: false };
    }
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    const { data } = await Api.get(
      `/api/users-permissions/checkEmail?email=${email}&organisation=${orgId}`
    );
    if (data.text === "email déja utilisé") {
      return { notValid: false, alreadyUsed: true, userFound: data.user };
    } else if (data.text === "email deja dans l'oragnisation") {
      return {
        notValid: false,
        alreadyUsed: true,
        userFound: data.user,
        inMyOrg: true,
      };
    }
    {
      return { notValid: false, alreadyUsed: false };
    }
  } catch (error) {
    console.log(error);
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneDriveFolder } from "../../REDUX/actions/externalServices";
import OneDriveFodlerList from "../../Components/Cloud/OneDrive/OneDriveFodlerList";
import OneDriveFileList from "../../Components/Cloud/OneDrive/OneDriveFileList";
import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import CloudBeadCrumb from "../../Components/Cloud/CloudBeadCrumb";
import SharePointSiteName from "../../Components/Cloud/OneDrive/SharePointSiteName";

const OneDriveFolderView = () => {
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const site = queryParams.get("site");
  const dispatch = useDispatch();
  const {
    oneDriveChildFiles,
    oneDriveChildFolders,
    oneDriveLoading,
    fileInfo,
  } = useSelector((state) => state.externalServicesReducer);
  const [searchFiles, setsearchFiles] = useState("");
  useEffect(() => {
    dispatch(getOneDriveFolder(params.id, true, site));
  }, [dispatch, params.id]);
  return oneDriveLoading || !fileInfo ? (
    <Loading showLoader />
  ) : (
    <div>
      <div className="container-fluid">
        <div className="d-flex flex-row justify-content-between align-items-center h50px mb-2">
          <div className="col-12 h100">
            <ListHeader showSocityFilter={false} Archives={true}>
              <SearchInput onChange={(e) => setsearchFiles(e.target.value)} />
            </ListHeader>
          </div>
        </div>
        {site && <SharePointSiteName />}
        <CloudBeadCrumb
          name={fileInfo.name}
          path={fileInfo.path}
          elementRoute="onedrivefolder"
          siteId={site}
        />

        <OneDriveFodlerList
          search={searchFiles}
          oneDriveFolders={oneDriveChildFolders}
          siteId={site}
        />

        <OneDriveFileList
          search={searchFiles}
          oneDriveFiles={oneDriveChildFiles}
          siteId={site}
        />
      </div>
    </div>
  );
};

export default OneDriveFolderView;

import Api from "../../Api/Api";
import {
  ADD_DIFFUSION_FAILED,
  ADD_DIFFUSION_SUCCESS,
  DELETE_DIFFUSION_FAILED,
  DELETE_DIFFUSION_SUCCESS,
  GET_DIFFUSION_SUCCESS,
  LOADING_ADD_DIFFUSION,
  UPDATE_DIFFUSION_FAILED,
  UPDATE_DIFFUSION_SUCCESS,
} from "../ActionsType/diffusiontype";
import {
  DELETE_DIFFUSION,
  UPDATE_DIFFUSION,
} from "../Graphql/Mutations/Diffusions";
import { CREATE_FILE } from "../Graphql/Mutations/cloud";
import { UPLOAD } from "../Graphql/Mutations/files";
import store from "../store/store";
import client from "./Client";
import { closeModal } from "./modal";
import { handleError, handleInfo } from "./toastMessage";
const color = "light-blue";

export const createDiffusion = (alertData) => async (dispatch) => {
  // dispatch({ type: LOADING_ADD_DIFFUSION });
  const organisation =
    store.getState().organisationsReducer.selectedOrganisation;
  const user = store.getState().userReducer.user;
  if (!organisation?.id) {
    return;
  }
  const userOrg = await user.attributes?.user_org_permissions?.data?.filter(
    (userOrgElt) =>
      +userOrgElt?.attributes?.organisation?.data?.id === +organisation?.id
  )[0];
  try {
    let fileId = "";
    if (alertData?.image) {
      const { data } = await client.mutate({
        mutation: UPLOAD,
        variables: {
          file: alertData?.image[0],
        },
      });
      fileId = data.upload.data.id;
      await client.mutate({
        mutation: CREATE_FILE,
        variables: {
          file: fileId,
          name: alertData?.image[0]?.name?.replace(/\.[^.]+$/, ""),
          cloud: userOrg?.attributes?.cloud?.data?.id,
        },
      });
    }
    const { data } = await Api.post(
      `/api/handleNewDiffusion?organisation=${+organisation?.id}`,
      {
        data: {
          titre: alertData?.title,
          description: alertData?.description,
          link: alertData?.url,
          image: fileId !== "" ? +fileId : +alertData?.document,
          isFullWidth: alertData?.is_fullWidth,
          expirationDate: alertData?.expirationDate,
          departements: alertData.department.map((el) => {
            return +el?.value;
          }),
          prestations: alertData?.prestations.map((el) => {
            return +el?.value;
          }),
          creator: user?.id,
        },
      }
    );
    dispatch({
      type: ADD_DIFFUSION_SUCCESS,
      payload: data.createDiffusion.data,
    });
    dispatch(closeModal());
    dispatch(handleInfo("Diffusion créée", 1, color));
  } catch (error) {
    dispatch({ type: ADD_DIFFUSION_FAILED });

    dispatch(handleError("Erreur de la création de la diffusion", 1));
  }
};

export const getAllDifffusions = () => async (dispatch) => {
  dispatch({ type: "ALERTS_LOADING" });

  const organisation =
    store.getState().organisationsReducer.selectedOrganisation;
  if (!organisation.id) {
    return;
  }

  try {
    const { data } = await Api.get(
      `/api/fetchConditionalDiffusion?organisation=${organisation.id}`
    );

    dispatch({ type: GET_DIFFUSION_SUCCESS, payload: data.alerts.data });
  } catch (error) {
    console.log(error);
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const deleteDiffusion = (id) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_DIFFUSION(id),
    });
    dispatch({
      type: DELETE_DIFFUSION_SUCCESS,
      payload: data.deleteDiffusion.data.id,
    });
    dispatch(handleInfo("Diffusion supprimée", 1, color));
  } catch (error) {
    dispatch({ type: DELETE_DIFFUSION_FAILED });

    dispatch(handleError("Erreur de la suppresion  de la diffusion", 1));
  }
};

// const getUserForDepByOrg = (deps) => {
//   let userIds = [];
//   const organisation =
//     store.getState().organisationsReducer.selectedOrganisation;

//   if (!organisation.id) {
//     return;
//   }
//   const departments = store.getState().departmentReducer.departments;
//   for (const dep of deps) {
//     const filtreDep = departments.find((el) => +el.id === +dep.value);
//     const ids = filtreDep?.attributes?.userOrgDepartment?.data
//       .filter(
//         (el) => +el?.attributes?.organisation?.data?.id === +organisation?.id
//       )
//       .map((el) => {
//         return +el.id;
//       });
//     userIds = userIds.concat(ids);
//   }
//   return userIds;
// };

export const updateDiffusion = (formData, id) => async (dispatch) => {
  dispatch({ type: LOADING_ADD_DIFFUSION });
  const user = store.getState().userReducer.user;
  const cloud =
    user.attributes.user_org_permissions.data[0].attributes.cloud.data;
  const departments = store.getState().departmentReducer.departments;
  try {
    let fileId = "";
    if (formData?.image) {
      const { data } = await client.mutate({
        mutation: UPLOAD,
        variables: {
          file: formData?.image[0],
        },
      });
      fileId = data.upload.data.id;
      await client.mutate({
        mutation: CREATE_FILE,
        variables: {
          file: fileId,
          name: formData?.image[0]?.name?.replace(/\.[^.]+$/, ""),
          cloud: cloud?.id,
        },
      });
    }
    const matchingIds = [];

    for (const dep of formData.department) {
      const foundedDep = departments
        .find((el) => +el?.id === +dep?.value)
        ?.attributes?.userOrgDepartment?.data?.map((el) => +el?.id);

      if (foundedDep) {
        foundedDep.forEach((id) => {
          if (!matchingIds.includes(id)) {
            matchingIds.push(id);
          }
        });
      }
    }

    const { data } = await client.mutate({
      mutation: UPDATE_DIFFUSION,
      variables: {
        id: id,
        titre: formData?.title,
        description: formData?.description,
        link: formData?.link,
        image: fileId !== "" ? +fileId : +formData?.document,
        departements: formData?.department?.map((el) => +el.value),
        is_fullWidth: formData?.is_fullWidth,
        expirationDate: formData?.expirationDate,
        creator: user?.id,
        visibleBy: matchingIds,
      },
    });
    dispatch({
      type: UPDATE_DIFFUSION_SUCCESS,
      payload: data.updateDiffusion.data,
    });
    dispatch(closeModal());
    dispatch(handleInfo("Diffusion mise à jour", 1, color));
  } catch (error) {
    console.log(error);
    dispatch({ type: UPDATE_DIFFUSION_FAILED });

    dispatch(handleError("Erreur de la modifications de la diffusion", 1));
  }
};

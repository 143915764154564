import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import SocietyList from "../../Components/Societies/SocietyList";
import SideBar from "../../Components/Common/TableComponents/SideBar";
import SocietySideBar from "../../Components/Societies/SocietySideBar";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import SocietyForm from "../../Components/Societies/SocietyForm/SocietyForm";

import { SocietySideBarTabs } from "../../Components/Societies/SocietyForm/societyFormUtils";

import { resetSocieteFilters } from "../../REDUX/actions/filters";
import { openModal } from "../../REDUX/actions/modal";

const SocietesView = () => {
  const dispatch = useDispatch();

  const { openSideBar } = useSelector((state) => state.genearlReducer);
  const { canCreateSociety, canSeeCollabs, canSeeOperators } = useSelector(
    (state) => state.roleReducer
  );

  const [selectedItem, setSelectedItem] = useState();
  const [isToggle, setIsToggle] = useState("infos");
  const [searchSociety, setsearchSociety] = useState("");

  useEffect(() => {
    return () => dispatch(resetSocieteFilters());
  }, [dispatch]);

  return (
    <div className="container-fluid">
      <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className={`${canCreateSociety ? "col-10" : "col-12"} h100`}>
          <ListHeader showSocityFilter={false} showArchive={true}>
            <SearchInput
              placeholder="ID, nom, adresse, email, site web, téléphone..."
              onChange={(e) => {
                setsearchSociety(e.target.value);
              }}
            />
          </ListHeader>
        </div>
        {canCreateSociety && (
          <div className="col-2 ms-2 h100">
            <ColoredButton
              height="100"
              textButton="Créer une société"
              color="light-blue"
              callback={() =>
                dispatch(
                  openModal({
                    type: "small",
                    preventClose: true,
                    content: <SocietyForm isNew={true} />,
                  })
                )
              }
            />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between gap-3">
        <SocietyList
          searchSociety={searchSociety}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
        {openSideBar && (
          <SideBar
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            tabsData={SocietySideBarTabs}
            tabsProps={{ canSeeCollabs, canSeeOperators }}
            sideBarHeaderInfo
          >
            <SocietySideBar societe={selectedItem} isToggle={isToggle} />
          </SideBar>
        )}
      </div>
    </div>
  );
};

export default SocietesView;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";

import "./SettingTooltip.css";

const SettingTooltip = ({ children, showTooltip, setshowTooltip }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const [dropPosition, setdropPosition] = useState("");

  useEffect(() => {
    const element = document.querySelector(".cloudOpenMenu");
    if (element) {
      const rect = element.getBoundingClientRect();

      if (rect.left < window.innerWidth / 2) {
        setdropPosition("dropLeft");
      } else {
        setdropPosition("dropRight");
      }
    }
  }, []);

  return showTooltip ? (
    <OutsideClickHandler onOutsideClick={() => setshowTooltip(false)}>
      <div
        className={`cloudOpenMenu ${dropPosition} fitContent
        d-flex flex-column text-start radius05  ${
          mode === "light" ? "lightoverlayContainer" : "darkoverlayContainer"
        }  ${showTooltip ? "showSettingMenu" : "hideSettingMenu"}`}
      >
        {children}
      </div>
    </OutsideClickHandler>
  ) : (
    <></>
  );
};

export default SettingTooltip;

import { gql } from "@apollo/client";

export const UPLOAD = gql`
  mutation ($file: Upload!) {
    upload(file: $file) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const CREATE_AVATAR = gql`
  mutation ($image: ID, $user: ID, $societe: ID, $calendar: ID) {
    createAvatar(
      data: {
        image: $image
        user: $user
        societe: $societe
        calendar: $calendar
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_FILE = gql`
  mutation ($id: ID!) {
    deleteUploadFile(id: $id) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_AVATAR = gql`
  mutation ($id: ID!, $imageId: ID) {
    updateAvatar(id: $id, data: { image: $imageId }) {
      data {
        id
      }
    }
  }
`;

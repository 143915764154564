import React from "react";
import UserSmallCard from "../../Clients/UserSmallCard";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../REDUX/actions/modal";
import AddParticipants from "./AddParticipants";
import ParticipantsPlaceHolder from "../../PlaceHolders/ParticipantsPlaceHolder";
import { handleTicketParticipants } from "../ticketUtils";

const RightParticipants = ({ ticket }) => {
  const dispatch = useDispatch();

  const { role } = useSelector((state) => state.roleReducer);
  const { messagesLoading, messages } = useSelector(
    (state) => state.messagesReducer
  );
  const { admins } = useSelector((state) => state.sharedDataReducer);

  return (
    <div
      className="directionC justify-content-between "
      style={{ height: "90%" }}
    >
      <div className="sideChatListContainer h100 overflow-auto">
        {messagesLoading ? (
          <ParticipantsPlaceHolder />
        ) : (
          handleTicketParticipants(ticket, admins, messages).map(
            (el, index) => (
              <UserSmallCard
                key={index}
                responsible={el}
                societeId={ticket?.attributes?.societe?.data?.id}
                ticket={ticket}
              />
            )
          )
        )}
      </div>

      {role === 1 && !ticket?.attributes?.archived ? (
        <div style={{ paddingTop: "5px" }}>
          <ColoredButton
            color={"light-blue"}
            textButton="Ajouter un participant"
            callback={() =>
              dispatch(
                openModal({
                  type: "small",
                  content: (
                    <AddParticipants
                      ticket={ticket}
                      participants={ticket?.attributes?.visibleBy}
                    />
                  ),
                })
              )
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default RightParticipants;

import React from "react";
import { useSelector } from "react-redux";
import {
  handleFirstLastName,
  showMultipleSocieties,
} from "../../../Utils/utils";

const NotificationUserName = ({ notification, showCompanies = true }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      <span
        className={`${
          mode === "light" ? "text-dark-blue" : "text-light-blue-grey"
        } `}
      >
        {` ${handleFirstLastName(notification?.from?.data)} `}
      </span>

      {(notification?.from?.data?.attributes?.role?.data?.id === "1" ||
        notification?.from?.data?.attributes?.role?.data?.id === "4" ||
        notification?.from?.data?.attributes?.role?.data?.id === "5") &&
      showCompanies ? (
        <span
          className={`${
            mode === "light" ? "text-dark-blue" : "text-light-blue-grey"
          } `}
        >
          {` - ${showMultipleSocieties(
            notification?.from?.data?.attributes?.workIn?.data
          )} `}
        </span>
      ) : null}
    </>
  );
};

export default NotificationUserName;

import React from "react";
import { useSelector } from "react-redux";
import LightDarkContainer from "../../Common/LightDarkContainer";
import MessageInputContainer from "./TicketChatContainer/MessageInputContainer";
import MessagesList from "./TicketChatContainer/MessagesList/MessagesList";
import ChatHeader from "./TicketChatContainer/ChatHeader";

import "./TicketChat.css";

const TicketChat = () => {
  const { ticket, ticketsLoading } = useSelector(
    (state) => state.ticketsReducer
  );

  const { canSendMessages } = useSelector((state) => state.roleReducer);

  return ticketsLoading || !ticket.attributes ? null : (
    <LightDarkContainer
      className={`px-4 d-flex flex-column justify-content-between radius05 h100 
      ${!canSendMessages && "pb-3"}`}
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <ChatHeader />
      <MessagesList />
      <MessageInputContainer />
    </LightDarkContainer>
  );
};

export default TicketChat;

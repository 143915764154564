import { useSelector } from "react-redux";

import AttachementUploader from "../../Common/AttachementUploader";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";
import LightDarkTextEditor from "../../Common/LightDarkTextEditor";
import LightDarkInput from "../../Common/LightDarkInput";
import CustomRange from "../../Common/CustomRange";
import SwitchItem from "../../Layout/Notification/SwitchItem";
import InfoQuestion from "../../Common/InfoQuestion";

const InterventionInfosform = ({
  isNew,
  societeId,
  interventionInputs,
  setinterventionInputs,
  societerelatedTickets,
}) => {
  const { societes } = useSelector((state) => state.sharedDataReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const maxDuration = 147;
  return (
    <div className="row">
      <div className={`${isNew && !societeId ? "col-8" : "col-12"}`}>
        <LightDarkInput
          label={"Nom de l'intervention"}
          totalInputs={interventionInputs}
          setValue={setinterventionInputs}
          champs="title"
          required
        />
        {!societeId && (
          <div className="row mt-4">
            <div className="col-6">
              <LighDarkMultiChoice
                label="Société"
                data={societes}
                totalInputs={interventionInputs}
                champs="societe"
                disabled={societeId ? true : false}
                multiple={false}
                required
                setValue={setinterventionInputs}
                societySelectInput
              />
            </div>
            <div className="col-6">
              <LighDarkMultiChoice
                label="Ticket rattaché"
                data={societerelatedTickets}
                totalInputs={interventionInputs}
                champs="ticket"
                multiple={false}
                required
                setValue={setinterventionInputs}
              />
            </div>
          </div>
        )}
        {selectedOrganisation?.attributes?.withDuration &&
          interventionInputs.statut === 1 && (
            <>
              <div className={isNew && !societeId && "mt-4"}>
                <CustomRange
                  min={0}
                  max={maxDuration}
                  label={"Durée"}
                  totalInputs={interventionInputs}
                  setValue={setinterventionInputs}
                  champs="duration"
                  editbundle={true}
                />
              </div>
              {interventionInputs.duration && isSupervisor ? (
                <div
                  className={`${
                    !societeId ? "w80" : "w40"
                  } my-3 position-relative alignH gap-4`}
                >
                  <div className="flex-1">
                    <SwitchItem
                      label="Intervention hors forfait"
                      value={interventionInputs.hors_forfait}
                      onChange={() =>
                        setinterventionInputs({
                          ...interventionInputs,
                          hors_forfait: !interventionInputs.hors_forfait,
                        })
                      }
                    />
                  </div>
                  <InfoQuestion
                    htmlMessage={
                      "L'intervention ne sera pas comptabilisée dans les forfaits"
                    }
                  />
                </div>
              ) : null}
            </>
          )}
      </div>
      <div className={`${isNew && !societeId ? "col-4" : "col-12 mt-4"}`}>
        <AttachementUploader
          ticketUploaderHeight={isNew && !societeId}
          key={"intervention"}
          label={"Pièces jointes"}
        />
      </div>
      <div className={`${isNew && !societeId ? "mt-2" : "mt-4"}`}>
        <LightDarkTextEditor
          height={"80"}
          label={"Description"}
          required
          totalInputs={interventionInputs}
          setValue={setinterventionInputs}
          champs="description"
          value={interventionInputs}
          autoHeight
          reformulateText
        />
      </div>
    </div>
  );
};

export default InterventionInfosform;

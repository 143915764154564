import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import { CloseIcon } from "../../Common/LightDarkSvg";
import NotificationList from "./NotificationList";

import { handleAllNotifications } from "../../../REDUX/actions/notifications";

import useWindowWidth from "../../../Hooks/useWindowWidth";

import "./Notification.css";

const Notifications = ({ show, isCalendarNotifications, notifications }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const elRef = useRef(null);

  const { mode } = useSelector((state) => state.genearlReducer);
  const { editNotificationsLoading } = useSelector(
    (state) => state.notificationsReducer
  );

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      show(false);
    }
  };

  useEffect(() => {
    elRef?.current?.focus();
  }, [elRef]);

  return (
    <div ref={elRef} onBlur={handleBlur} tabIndex={-1}>
      <LightDarkContainer
        colorLight="white"
        colorDark="dark-grey-bg"
        className={`${
          mode === "light" ? "lightNotificationList" : "darkNotificationList"
        }
      notificationList text-start d-flex flex-column align-items-center radius05 py-3 position-absolute`}
      >
        <div className="pb-0 px-3 w-100 d-flex justify-content-between">
          <LightDarkText className="custom-title-font-size bold">
            {isCalendarNotifications ? "Calendriers" : "Notifications"}
          </LightDarkText>
          <div className="d-flex align-items-center">
            {notifications.length ? (
              <div
                className={`
            ${mode === "light" ? "lightReadNotif" : "darkReadNotif"}
            radius05 px-2 py-1 me-2 custom-ticket-font-size bold t-02 text-center readNotif-btn pointer`}
                onClick={() => {
                  notifications?.filter((el) => !el?.attributes.read).length
                    ? dispatch(
                        handleAllNotifications(
                          true,
                          isCalendarNotifications ? true : false
                        )
                      )
                    : dispatch(
                        handleAllNotifications(
                          false,
                          isCalendarNotifications ? true : false
                        )
                      );
                }}
              >
                {notifications?.filter((el) => !el?.attributes.read).length
                  ? "Marquer tout en lues"
                  : "Marquer tout en non lues"}
              </div>
            ) : null}
            {editNotificationsLoading ? (
              <Spinner size="sm" variant="light-text" />
            ) : null}
            <CloseIcon
              dimension={screenWidth < 600 ? "22" : "24"}
              clickCallback={() => show(false)}
            />
          </div>
        </div>
        <div className="w-100 mt-3 sideChatContainer">
          <NotificationList show={show} notifications={notifications} />
        </div>
      </LightDarkContainer>
    </div>
  );
};
export default Notifications;

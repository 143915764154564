import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkText from "../../Common/LightDarkText";
import CalendarForm from "../CalendarForms/CalendarForm";
import CalendarName from "../CalendarHeader/CalendarName";
import LightDarkContainer from "../../Common/LightDarkContainer";
import TooltipActions from "../../Common/Tooltip/TooltipActions";
import { DeleteIcon, EditIcon, ShareIcon } from "../../Common/LightDarkSvg";

import {
  deleteCalendar,
  selectCalendars,
} from "../../../REDUX/actions/calendars";
import { closeModal, openModal } from "../../../REDUX/actions/modal";
import { openConfirmationModal } from "../../../REDUX/actions/confirmationModal";

const CalendarCard = ({
  calendar,
  index,
  searchList = false,
  setSearch = () => {},
}) => {
  const dispatch = useDispatch();

  const toolTipRef = useRef();

  const { user } = useSelector((state) => state.userReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);
  const { selectedCalendars } = useSelector((state) => state.calendarsReducer);
  const [calendarMine] = useState(
    calendar?.attributes?.owner?.data?.id === user?.id
  );

  const [showTooltip, setshowTooltip] = useState(false);
  const [tooltipCoordinates, setTooltipCoordinates] = useState({ x: 0, y: 0 });

  const tooltipData = [
    {
      id: 1,
      text: "Modifier l'agenda",
      icon: <EditIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openModal({
            type: "small",
            content: <CalendarForm calendar={calendar} />,
          })
        );
      },
      condition: true,
    },
    {
      id: 2,
      text: "Partager",
      icon: <ShareIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openModal({
            type: "small",
            content: <CalendarForm calendar={calendar} shareCalendar />,
          })
        );
      },
      condition: true,
    },
    {
      id: 3,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(deleteCalendar(calendar?.id));
              dispatch(closeModal());
            },
            message: "Êtes-vous sur de vouloir supprimer cet agenda?",
            message2:
              "En confirmant la suppression, l'agenda sera définitivement supprimé.",
            confirmButtonText: "Je confirme la suppression",
          })
        );
      },
      condition: true,
    },
  ];

  const closeTooltip = () => {
    setshowTooltip(false);
  };
  useEffect(() => {
    document
      .getElementById("calendar-list")
      .addEventListener("scroll", closeTooltip);
    return () => {
      document
        ?.getElementById("calendar-list")
        ?.removeEventListener("scroll", closeTooltip);
    };
  }, []);

  return (
    <LightDarkContainer
      className={`d-flex align-items-center justify-content-between pointer position-relative radius05  px-3 py-2
    ${
      selectedCalendars?.find((el) => el?.id === calendar?.id) &&
      !searchList &&
      "selectedCalendarCard"
    }`}
      colorLight="white"
      colorDark={`${searchList ? "dark-mode" : "dark-grey-bg"}`}
      index={index}
    >
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip style={{ zIndex: `${searchList ? 0 : 400} ` }}>
            <LightDarkText className="custom-ticket-font-size py-1 text-white">
              Vous pouvez sélectionner plusieurs agendas en même temps
            </LightDarkText>
          </Tooltip>
        }
      >
        <div
          onClick={() => {
            dispatch(selectCalendars(calendar?.id));
            searchList && setSearch("");
          }}
        >
          <CalendarName
            id={calendar.id}
            name={calendar?.attributes?.name}
            icon={
              calendar?.attributes?.societe?.data
                ? calendar?.attributes?.societe?.data?.attributes?.avatar
                : calendar?.attributes?.avatar
            }
            isSelectedCalendar={selectedCalendars?.find(
              (el) => el?.id === calendar?.id
            )}
            searchList={searchList}
            className={` ${
              searchList ? "f14" : "custom-date-font-size "
            } bold w-100 ${calendarMine ? "me-3" : ""}`}
          />
        </div>
      </OverlayTrigger>

      {(calendarMine || isSupervisor) && !searchList && (
        <div
          onClick={(e) => {
            setTooltipCoordinates({
              x: toolTipRef?.current?.getBoundingClientRect().x + 16,
              y: toolTipRef?.current?.getBoundingClientRect().y,
            });
            e.stopPropagation();
            setshowTooltip(!showTooltip);
          }}
          ref={toolTipRef}
        >
          <div className="ms-4">
            <TooltipActions
              setshowTooltip={setshowTooltip}
              showTooltip={showTooltip}
              tooltipData={tooltipData}
              isVertical
              isCloufFileCard
              left={tooltipCoordinates?.x + "px"}
            />
          </div>
        </div>
      )}
    </LightDarkContainer>
  );
};

export default CalendarCard;

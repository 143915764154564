import React, { useState } from "react";
import { useSelector } from "react-redux";
import PieChart from "../../Components/Charts/PieChart";
import LightDarkText from "../Common/LightDarkText";
import {
  calculateInterventionDurations,
  transformInterventionsToStatsMultiBars,
  transformDataToMultiLines,
  transformticketsToStatsPie,
  transformPlansData,
} from "../Charts/chartsUtils";
import MultipleLineChart from "../Charts/MultipleLineChart";
import LightDarkContainer from "../Common/LightDarkContainer";
import "./Statistics.css";
import MultipleBarChart from "../Charts/MultipleBarChart";
import TicketsGeneralStats from "./TicketsGeneralStats";
import FranceDepartementsMap from "../Charts/FranceDepartementsMap";
import PropetctsStats from "./PropetctsStats";
import _ from "lodash";
import Loading from "../Common/Loading";

const StatisticsTab = () => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { stats } = useSelector((state) => state.statsReducer);
  const { isSupervisor, isClientAdmin } = useSelector(
    (state) => state.roleReducer
  );
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const [isToggle, setIsToggle] = useState("Types");

  const openedTicketIdsPerDevice = transformticketsToStatsPie(
    stats?.ticketStats?.ticketByDevice
  );
  const click = transformticketsToStatsPie(stats?.clicksStats);
  const openedTicketIdsPerPrestation = transformticketsToStatsPie(
    stats?.ticketStats?.ticketsByPrestation
  );

  const ticketsPerType = transformticketsToStatsPie(
    stats?.ticketStats?.ticketsByType
  );

  const openedTickets = transformDataToMultiLines(
    stats?.ticketStats?.openedTicket,
    "#3365FF",
    "Créés"
  );
  const resolvedTickets = transformDataToMultiLines(
    stats?.ticketStats?.resolvedTickets,
    "#0CCE91",
    "Résolus"
  );
  const unresolvedTicket = transformDataToMultiLines(
    stats?.ticketStats?.unresolvedTicket,
    "#FFBC20",
    "En cours"
  );

  const plansData = transformPlansData(stats?.plansStats);

  const interventionsData = transformInterventionsToStatsMultiBars(
    [
      stats?.interventionsStats.allInterventions,
      stats?.interventionsStats.statut1Interventions,
      stats?.interventionsStats.statut2Interventions,
      stats?.interventionsStats.statut3Interventions,
      stats?.interventionsStats.statut4Interventions,
      stats?.interventionsStats.statut5Interventions,
      stats?.interventionsStats.statut6Interventions,
    ],
    [
      "Créée",
      "En Attente",
      "En cours",
      "En attente de validation",
      "Finalisée",
      "Refusé",
      "En révision",
    ]
  );

  return !stats ? (
    <Loading showLoader />
  ) : (
    <div className="w-100 scrollable d-flex flex-column gap-4">
      <TicketsGeneralStats
        tickets={stats?.ticketStats.ticketsNumber.length}
        interventions={stats.interventionsStats.interventionsNumber.length}
        hours={calculateInterventionDurations(
          stats.interventionsStats.interventionsNumber
        )}
      />
      {isSupervisor &&
      selectedOrganisation.attributes.ticketHandleLogic === 2 ? (
        <div>
          <FranceDepartementsMap statsData={stats.ticketsUserByDepartements} />
        </div>
      ) : null}
      <div className={`statisticsLine alignH gap-2`}>
        <div className={`w70 h100 `}>
          <MultipleLineChart
            title="Tickets ouverts"
            dataSets={[openedTickets, resolvedTickets, unresolvedTicket]}
          />
        </div>

        <LightDarkContainer
          className="w30 h100 d-flex flex-column justify-content-between radius10 px-2"
          colorLight="white"
          colorDark="dark-grey-bg"
        >
          {isToggle === "Prestations" && (
            <PieChart
              title={"Répartition des tickets ouverts par prestations"}
              infos={openedTicketIdsPerPrestation}
            />
          )}
          {isToggle === "Device" && (
            <PieChart
              title={"Répartition des tickets ouverts par détails"}
              infos={openedTicketIdsPerDevice}
              width="30"
            />
          )}
          {isToggle === "Types" && (
            <PieChart
              title={"Répartition des tickets ouverts par type"}
              infos={ticketsPerType}
              width="30"
            />
          )}

          <div className="d-flex justify-content-start align-items-start">
            <LightDarkText
              className="text-start custom-font-size bold pointer px-2"
              color={
                isToggle === "Types"
                  ? "text-light-blue"
                  : mode === "light"
                  ? "text-blue-grey"
                  : "text-light-blue-grey"
              }
              onclickCallback={() => setIsToggle("Types")}
            >
              Types
              {isToggle === "Types" && (
                <div
                  className="radius05"
                  style={{
                    marginTop: "10px",
                    borderBottom: "4px solid #3365ff",
                    position: "relative",
                    top: 1,
                  }}
                ></div>
              )}
            </LightDarkText>{" "}
            <LightDarkText
              className={`text-start bold custom-font-size px-2 pointer`}
              color={
                isToggle === "Prestations"
                  ? "text-light-blue"
                  : mode === "light"
                  ? "text-blue-grey"
                  : "text-light-blue-grey"
              }
              onclickCallback={() => setIsToggle("Prestations")}
            >
              Prestations
              {isToggle === "Prestations" && (
                <div
                  className="radius05"
                  style={{
                    marginTop: "10px",
                    borderBottom: "4px solid #3365ff",
                    position: "relative",
                    top: 1,
                  }}
                ></div>
              )}
            </LightDarkText>
            <LightDarkText
              className="text-start custom-font-size bold pointer px-2"
              color={
                isToggle === "Device"
                  ? "text-light-blue"
                  : mode === "light"
                  ? "text-blue-grey"
                  : "text-light-blue-grey"
              }
              onclickCallback={() => setIsToggle("Device")}
            >
              Détails
              {isToggle === "Device" && (
                <div
                  className="radius05"
                  style={{
                    marginTop: "10px",
                    borderBottom: "4px solid #3365ff",
                    position: "relative",
                    top: 1,
                  }}
                ></div>
              )}
            </LightDarkText>
          </div>
        </LightDarkContainer>
      </div>
      <div
        className={`statisticsLine alignH w100 ${
          selectedOrganisation.attributes.withClicks && "gap-2"
        }`}
      >
        {selectedOrganisation.attributes.withClicks && (
          <div className="w30 h100 ">
            <PieChart
              title={`Nombre de clic : ${_.sumBy(click, "value")}`}
              infos={click}
              tootltipLabel=""
            />
          </div>
        )}
        <div
          className={`${
            selectedOrganisation.attributes.withClicks ? "w70" : "w-100"
          }`}
        >
          <MultipleBarChart
            dataSets={interventionsData}
            title="Interventions les 12 derniers mois"
          />
        </div>
      </div>
      {selectedOrganisation.attributes.withProspects ? (
        <PropetctsStats />
      ) : null}

      {(isSupervisor || isClientAdmin) &&
      selectedOrganisation.attributes.withForfait ? (
        <div className={`statisticsLine alignH w100`}>
          <div className="w-100">
            <MultipleBarChart
              dataSets={plansData}
              title="Forfait les 12 derniers mois"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default StatisticsTab;

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import MessageDate from "./MessageDate.jsx";
import MessageCardContainer from "./MessageCardContainer";
import EventMessage from "../../../MessagesTypes/EventMessage.jsx";
import LightDarkText from "../../../../../Common/LightDarkText.jsx";
import InterventionMessage from "../../../MessagesTypes/InterventionMessages/InterventionMessage.jsx";
import InterventionActionMessage from "../../../MessagesTypes/InterventionMessages/InterventionActionMessage.jsx";

import { showTimeMessage } from "../../../../ticketUtils.js";
import { showConditionalMessage } from "../../../messageUtils.js";

import "./MessageCard.css";

const MessageCard = ({ message, ticketId, measureRef, isArchived }) => {
  const { user } = useSelector((state) => state.userReducer);

  const { mode } = useSelector((state) => state.genearlReducer);

  const [meSending, setMeSending] = useState();
  const [showTooltip, setshowTooltip] = useState(false);
  const [tooltipCoordinates, setTooltipCoordinates] = useState({ x: 0, y: 0 });
  const [showOriginal, setshowOriginal] = useState(false);
  const [messageContenttoShow, setmessageContenttoShow] = useState(
    showConditionalMessage(message?.attributes, user, showOriginal || meSending)
  );

  useEffect(() => {
    setmessageContenttoShow(
      showConditionalMessage(
        message?.attributes,
        user,
        showOriginal || meSending
      )
    );
  }, [message, showOriginal, meSending]);

  const toolTipRef = useRef();

  const handleResize = () => {
    setTooltipCoordinates({
      x: toolTipRef?.current?.getBoundingClientRect().x + 12,
      y: toolTipRef?.current?.getBoundingClientRect().y + 16,
    });
  };

  const closeTooltip = () => {
    document?.querySelector(".mention-overlay")?.remove();
    document?.querySelector(".mention.hovered")?.classList?.remove("hovered");
    setshowTooltip(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    document
      .getElementById("messages-scroll")
      .addEventListener("scroll", closeTooltip);
    return () => {
      window.removeEventListener("resize", handleResize);
      document
        ?.getElementById("messages-scroll")
        ?.removeEventListener("scroll", closeTooltip);
    };
  }, []);

  return message.attributes.type === 7 ? (
    <EventMessage
      event={message.attributes.event.data}
      message={messageContenttoShow.message}
    />
  ) : message.attributes.content === "" &&
    !message?.attributes?.fichiers?.data?.length &&
    !message.attributes.relatedIntervention?.data?.id ? null : message
      .attributes.relatedIntervention?.data?.id ? (
    message.attributes.type === 1 ? (
      <div
        className={`${
          message.attributes.relatedIntervention?.data?.attributes?.statut !== 1
            ? "d-flex gap-2"
            : ""
        }`}
        ref={measureRef}
      >
        <div className="w-100">
          <InterventionMessage message={message} />
          <div className="text-end">
            <LightDarkText
              color={mode === "light" ? "text-light-text" : "text-white"}
              className={" f10 bold light-message"}
            >
              {showTimeMessage(message)}
            </LightDarkText>
          </div>
        </div>
      </div>
    ) : (
      <InterventionActionMessage message={message} measureRef={measureRef} />
    )
  ) : (
    <div className="directionC" ref={measureRef}>
      <MessageDate message={message} />
      <MessageCardContainer
        message={message}
        ticketId={ticketId}
        isArchived={isArchived}
        messageContenttoShow={messageContenttoShow}
        showOriginal={showOriginal}
        setshowOriginal={setshowOriginal}
        toolTipRef={toolTipRef}
        showTooltip={showTooltip}
        setshowTooltip={setshowTooltip}
        tooltipCoordinates={tooltipCoordinates}
        setTooltipCoordinates={setTooltipCoordinates}
        meSending={meSending}
        setMeSending={setMeSending}
      />
    </div>
  );
};

export default MessageCard;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MenuTabs from "../../Components/Common/MenuTabs";
import OverviewTab from "../../Components/Dashboard/OverviewTab";
import StatisticsTab from "../../Components/Dashboard/Statistics";
import LightDarkFilterSelect from "../../Components/Common/LightDarkFilterSelect";

import { getConditionalStats } from "../../REDUX/actions/stats";

import "./DashBoardView.css";
import StatisticsLog from "../../Components/Dashboard/StatisticsLog";

const DashBoardView = () => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { societes } = useSelector((state) => state.sharedDataReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { isOperator, isSupervisor, role } = useSelector(
    (state) => state.roleReducer
  );
  const [values, setValues] = useState(
    societes.length === 1
      ? { societes: societes }
      : {
          societes: [],
        }
  );
  const [isToggle, setIsToggle] = useState("Overview");

  const DashboardTabs = [
    {
      title: "Général",
      toggleValue: "Overview",
      isVisible: true,
    },
    {
      title: "Statistiques",
      toggleValue: "Statistics",
      isVisible: true,
    },
    {
      title: "Connexion",
      toggleValue: "Connexion",
      isVisible: isSupervisor,
    },
  ];
  const connexionMenu = [
    { value: "days", label: "Jours" },
    { value: "months", label: "Mois" },
    { value: "years", label: "Année" },
  ];

  useEffect(() => {
    dispatch(getConditionalStats(values?.societes));
  }, [dispatch, user, values?.societes]);

  useEffect(() => {
    if (isSupervisor || isOperator) {
      setValues((currState) => ({ ...currState, societe: societes[0] }));
    }
  }, [role, societes]);

  const [connexionFields, setConnexionFields] = useState({
    duration: [{ value: "years", label: "Année" }],
  });

  return (
    <div className="container-fluid d-flex flex-column gap-4">
      <div
        className={`radius05 d-flex justify-content-between align-items-center t-02 ${
          mode === "dark" ? "bg-dark-grey-bg" : "bg-white"
        }`}
        style={{ height: "50px" }}
      >
        <div className="d-flex justify-content-start gap-4 align-items-center mx-5">
          {DashboardTabs.map((tab, index) => (
            <MenuTabs
              key={index}
              tabTitle={tab.title}
              toggleValue={tab.toggleValue}
              isToggle={isToggle}
              setIsToggle={() => setIsToggle(tab.toggleValue)}
              isVisible={tab.isVisible}
              isdashboardTabs
            />
          ))}
        </div>
        {isToggle === "Statistics" && (
          <div className="me-2">
            <LightDarkFilterSelect
              data={societes}
              multiple
              canSelectAll
              newField="societes"
              champs="societes"
              totalInputs={values}
              setValue={setValues}
              placeholder="Toutes les sociétés"
              societySelectInput
            />
          </div>
        )}
        {isToggle === "Connexion" && (
          <div className="me-2">
            <LightDarkFilterSelect
              data={connexionMenu}
              champs="duration"
              multiple={false}
              totalInputs={connexionFields}
              setValue={setConnexionFields}
              placeholder="Durée"
            />
          </div>
        )}
      </div>
      <div className="w-100" style={{ height: "80vh" }}>
        {isToggle === "Overview" && <OverviewTab />}
        {isToggle === "Statistics" && <StatisticsTab />}
        {isToggle === "Connexion" && (
          <StatisticsLog duration={connexionFields.duration[0]} />
        )}
      </div>
    </div>
  );
};

export default DashBoardView;

import moment from "moment";

import {
  extractLinks,
  handleFirstLastName,
  showAllPepartements,
  urlifyMessage,
} from "../../Utils/utils";
import _ from "lodash";

export const ticketPriority = (prior) => {
  switch (prior) {
    case 1:
      return {
        el: <span className="text-blue-grey xbold">Faible</span>,
        text: "⏳ Faible",
        icon: "⏳",
        color: "blue-grey",
        bg: "#59638b40",
      };
    case 2:
      return {
        el: <span className="text-light-blue xbold"> Normal</span>,
        text: "🙌 Normal",
        icon: "🙌",
        color: "light-blue",
        bg: "#3365ff40",
      };
    case 3:
      return {
        el: <span className="text-red xbold"> Urgent</span>,
        text: "🔥 Urgent",
        icon: "🔥",
        color: "red",
        bg: "#FF2C2C40",
      };

    default:
      return {
        el: <span className="text-light-blue xbold">Normal</span>,
        text: "🙌 Normal",
        icon: "🙌",
        color: "light-blue",
        bg: "#3365ff40",
      };
  }
};

export const ticketStatut = (statut) => {
  switch (statut) {
    case 1:
      return {
        el: <span className="text-purple xbold">En Cours</span>,
        text: "En Cours",
        color: "purple",
        bg: "#7E25FF40",
      };
    case 2:
      return {
        el: <span className="text-dark-green xbold">Résolu</span>,
        text: "Résolu",
        color: "dark-green",
        bg: "#2ecc7140",
      };
    default:
      return {
        el: <span className="text-dark-green xbold">Résolu</span>,
        text: "Résolu",
        color: "dark-green",
        bg: "#2ecc7140",
      };
  }
};

export const prepareticketsFields = (ticket) => {
  return {
    ...ticket,
    description: urlifyMessage(ticket.description),
    links: extractLinks(ticket.description),
    device: ticket?.device[0]?.value ?? null,
    prestation: ticket?.prestation[0]?.value ?? null,
    priority: ticket?.priority[0]?.value ?? null,
    societe: ticket?.societe[0]?.value ?? null,
    type: ticket?.type[0]?.value ?? null,
    statut: ticket?.statut[0]?.value ?? null,
    visibleBy: ticket?.visibleBy?.data ?? null,
    departements: ticket?.departements?.map((el) => el.value),
  };
};

export const creationDate = (element) => {
  return moment(
    element?.attributes?.oldCreatedAt || element?.attributes?.createdAt
  ).format("DD.MM.YYYY");
};

export const showTimeMessage = (message) => {
  return message.attributes.oldDate
    ? moment(message.attributes.oldDate).format("HH:mm")
    : moment(message.attributes.createdAt).format("HH:mm");
};

export const showDateMessage = (message) => {
  return message.attributes.oldDate
    ? moment(message.attributes.oldDate).format("DD.MM.YYYY")
    : moment(message.attributes.createdAt).format("DD.MM.YYYY");
};

export const messageType = (type, id, date, by, singleOp = false) => {
  switch (type) {
    case 0:
      return;
    case 1:
      return {
        title: "Intervention Créée & en attente d'acceptation",
        color: "blue-grey",
        longText: by
          ? `👋 Intervention #${id} Créé le ${moment(date).format(
              "DD-MM-YYYY à HH:mm"
            )} par ${by.attributes.firstName || ""} ${
              by.attributes.lastName || ""
            }`
          : null,
      };
    case 2:
      return {
        title: "Intervention acceptée",
        color: "purple",
        longText: by
          ? `👍 Intervention #${id} accéptée le ${moment(date).format(
              "DD-MM-YYYY à HH:mm"
            )} par ${by.attributes.firstName || ""} ${
              by.attributes.lastName || ""
            } ${
              singleOp
                ? ", les autres opérateurs ont été retirés de ce ticket"
                : ""
            }`
          : null,
      };
    case 3:
      return {
        title: "Intervention en attente de validation",
        color: "light-blue",
        longText: by
          ? `⌛️ Intervention #${id} mise en attente de validation le ${moment(
              date
            ).format("DD-MM-YYYY à HH:mm")} par ${
              by.attributes.firstName || ""
            } ${by.attributes.lastName || ""}`
          : null,
      };
    case 4:
      return {
        title: "Intervention validée & finalisée",
        color: "green",
        longText: by
          ? `✅ Intervention #${id} validée & finalisée le ${moment(
              date
            ).format("DD-MM-YYYY à HH:mm")} par ${
              by.attributes.firstName || ""
            } ${by.attributes.lastName || ""}`
          : null,
      };
    case 5:
      return {
        title: "Intervention refusée",
        color: "red",
        longText: by
          ? `❌ Intervention #${id} refusée le ${moment(date).format(
              "DD-MM-YYYY à HH:mm"
            )} par ${by.attributes.firstName || ""} ${
              by.attributes.lastName || ""
            }`
          : null,
      };

    case 6:
      return {
        title: "Intervention en revision",
        color: "orange",
        longText: by
          ? `🔁 Intervention #${id} demandé en revision le ${moment(
              date
            ).format("DD-MM-YYYY à HH:mm")} par ${
              by.attributes.firstName || ""
            } ${by.attributes.lastName || ""}`
          : null,
      };

    default:
      return { title: ``, color: "green", longText: `` };
  }
};

export const handleOldInterventionMessages = (message) => {
  return `${message.attributes.content} le ${moment(
    message.attributes.oldDate
  ).format("DD-MM-YYYY à HH:mm")}`;
};

export const handleDuration = (duration) => {
  let hours = duration;

  const remaining = hours - Math.floor(hours);

  if (hours < 7) {
    if (remaining) {
      return "0" + Math.floor(hours) + "H30";
    } else {
      return "0" + duration + "H00";
    }
  } else if (hours === 7) {
    return "1 jr";
  } else {
    let days = Math.trunc(duration / 7);
    let rest = duration % 7;
    if (rest === 0) {
      return `${days}jrs`;
    } else {
      if (days === 1) {
        if (rest - Math.floor(rest) && Math.floor(rest) < 1) {
          return `${days}jr et 30min`;
        } else if (rest - Math.floor(rest)) {
          return `${days}jr et ${Math.floor(rest)}h30`;
        } else {
          return `${days}jr et ${rest}h`;
        }
      } else if (rest - Math.floor(rest) && Math.floor(rest) < 1) {
        return `${days}jrs et 30min`;
      } else if (rest - Math.floor(rest)) {
        return `${days}jrs et ${Math.floor(rest)}h30`;
      } else {
        return `${days}jrs et ${rest}h`;
      }
    }
  }
};

export const interventionActionStatut = (statut) => {
  switch (statut) {
    case 1:
      return "👋 créée et en attente d'acceptation";
    case 2:
      return "✅ acceptée";
    case 3:
      return "⌛️ réalisée et en attente de validation";
    case 4:
      return "👍 validée et finalisée";
    case 5:
      return "❌ refusée";
    case 6:
      return "🔁 en révision";

    default:
      return "";
  }
};

export const eventActionStatut = (statut) => {
  switch (statut) {
    case 2:
      return "a été modifié";
    case 4:
      return 'a été passé à "à valider"';
    case 5:
      return "a été validé";
    case 6:
      return 'a été passé à "programmé"';
    case 7:
      return "a été accepté";
    case 8:
      return "a été refusé";

    default:
      return "";
  }
};

export const ticketInfosData = (ticket, ticketHandleLogic, screenWidth) => {
  const additionalFields =
    ticket.attributes.additionalFields?.map((el) => {
      return {
        label: `${el.title} :`,
        value: el.value,
        icon: null,
        isLink: false,
        isVisible: el.value,
        showOverlay: true,
      };
    }) || [];
  return {
    generalInfos: [
      {
        label: "Type :",
        value: ticket?.attributes?.type?.data?.attributes?.name,
        icon: null,
        isLink: false,
        isVisible: true,
        showOverlay: false,
      },
      {
        label: "Département :",
        value: showAllPepartements(ticket.attributes.departements.data),

        isLink: false,
        isVisible: ticketHandleLogic === 2,
        showOverlay: true,
      },
      {
        label: "Prestation :",
        value: ticket?.attributes?.prestation?.data?.attributes?.name,
        icon: ticket?.attributes?.prestation?.data?.attributes?.logo?.data
          ?.attributes?.icon?.data?.attributes?.url,
        isLink: false,
        isVisible: true,
        showOverlay: true,
      },
      {
        label: "Détail :",
        value: ticket?.attributes?.device?.data?.attributes?.name,
        icon: ticket?.attributes?.device?.data?.attributes?.logo?.data
          ?.attributes?.icon?.data?.attributes?.url,
        isLink: false,
        isVisible: true,
        showOverlay: false,
      },
      {
        label: "Site web :",
        value: ticket?.attributes?.societe?.data?.attributes?.siteWeb
          ? ticket?.attributes?.societe?.data?.attributes?.siteWeb
          : "Pas de site web",
        icon: null,
        isLink: ticket?.attributes?.societe?.data?.attributes?.siteWeb,
        isVisible: true,
        showOverlay: false,
      },
      {
        label: "Crée par :",
        value: handleFirstLastName(ticket?.attributes?.creator?.data),
        icon: null,
        isLink: false,
        isVisible: true,
        showOverlay: false,
      },
      ...additionalFields,
    ],
    stateInfos: [
      {
        type: "dark-grey",
        text: "Archivé",
        background: "#86909c40",
        isVisible: ticket?.attributes?.archived,
      },
      {
        type: ticketPriority(ticket?.attributes?.priority)?.color,
        text:
          screenWidth < 1200
            ? ticketPriority(ticket?.attributes?.priority)?.icon
            : ticketPriority(ticket?.attributes?.priority)?.text,
        background: ticketPriority(ticket?.attributes?.priority)?.bg,
        isVisible: true,
      },
      {
        type: ticketStatut(ticket?.attributes?.statut)?.color,
        text: ticketStatut(ticket?.attributes?.statut)?.text,
        background: ticketStatut(ticket?.attributes?.statut)?.bg,
        isVisible: true,
      },
    ],
  };
};

export const handleTicketParticipants = (ticket, admins, messages) => {
  //SHOW ADMINS WHO SENT MESSAGES
  const filteredAdmins = admins.filter((admin) =>
    messages.map((el) => el?.attributes?.sender?.data?.id).includes(admin.id)
  );
  //VISIBLE BY MUST NOT BE ADMINS IT MAY HAPPEN SOMETIMES SO WE HAVE TO FILTER THEM
  const filterdVisibleBy = ticket.attributes.visibleBy.filter(
    (el) => el.attributes.user_org_permissions[0]?.data?.attributes?.role !== 1
  );

  return _.uniqBy([...filteredAdmins, ...filterdVisibleBy], "id");
};

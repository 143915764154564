import moment from "moment";

export const transformDataToMultiLines = (data, color, label = "") => {
  if (!data) {
    return [];
  }
  const dataMap = data.reduce((map, entry) => {
    const month = moment.utc(entry.month).format("YYYY-MM");
    map[month] = entry.count;
    return map;
  }, {});

  const dataset = {
    label: label,
    data: [],
    backgroundColor: color,
    borderColor: color,
    borderWidth: 2,
    pointRadius: 4,
    pointBackgroundColor: color,
  };

  for (let i = 0; i < 24; i++) {
    const month = moment().subtract(i, "months").format("YYYY-MM");
    dataset.data.unshift(dataMap[month] || 0);
  }

  return dataset;
};

export const sumList = (arr) =>
  arr.reduce((sum, el) => (el !== null && !isNaN(el) ? sum + el : sum), 0);

export const transformInterventionsToStatsMultiBars = (
  interventionsData,
  labels
) => {
  const months = getRecentMonths(12);
  const datasets = [];

  interventionsData.forEach((statutInterventions, index) => {
    const statutDataset = {
      label: labels[index],
      data: Array(12).fill(0),
      backgroundColor: colors[index],
      borderWidth: 0,
      borderRadius: 10,
      innerWidth: 1,
      pointRadius: 6,
    };

    if (statutInterventions && statutInterventions.length > 0) {
      statutInterventions.forEach(({ month, count }) => {
        const formattedMonth = moment.utc(month).format("YYYY-MM");
        const monthIndex = months.indexOf(formattedMonth);
        if (monthIndex !== -1) {
          statutDataset.data[monthIndex] = count;
        }
      });
    }

    datasets.push(statutDataset);
  });

  return datasets;
};

export const transformLogsToStatsMultiBars = (
  logsData,
  labels,
  durationType,
  type
) => {
  let range;
  let timeFormat;

  // Define range and format based on duration type
  if (durationType === "days") {
    range = 30;
    timeFormat = "YYYY-MM-DD";
  } else if (durationType === "months") {
    range = 12;
    timeFormat = "YYYY-MM";
  } else if (durationType === "years") {
    range = 10;
    timeFormat = "YYYY";
  }

  const timePeriods = [];

  // Generate recent time periods based on duration type (oldest to newest)
  for (let i = range - 1; i >= 0; i--) {
    const period = moment()
      .subtract(i, durationType)
      .endOf(durationType)
      .format(timeFormat);
    timePeriods.push(period);
  }

  const datasets = [];

  logsData.forEach((roleLogs, index) => {
    const roleDataset = {
      label: labels[index],
      data: Array(range).fill(0), // Initialize array for each time period
      backgroundColor: colors[index],
      borderWidth: 0,
      borderRadius: 10,
      innerWidth: 1,
      pointRadius: 6,
    };

    if (roleLogs && roleLogs.length > 0) {
      roleLogs.forEach(({ label, volume, count }) => {
        // Ensure that the label from roleLogs is in the same format as timeFormat
        const formattedTime = moment.utc(label).format(timeFormat);
        const timeIndex = timePeriods.indexOf(formattedTime); // Find the correct time index
        if (timeIndex !== -1) {
          roleDataset.data[timeIndex] = type === "count" ? count : volume; // Use count or volume based on what you need
        }
      });
    }

    datasets.push(roleDataset);
  });

  return datasets;
};

export const transformPlansData = (data) => {
  if (!data || data.length === 0) {
    return [];
  }

  const months = getRecentMonths(12);
  const transformedData = [];
  const monthlyPlanData = {
    label: "Reste",
    data: [],
    backgroundColor: colors[7],
    borderRadius: 10,
    barThickness: 15,
    stack: "Stack 1",
    hoverBorderWidth: 0,
  };
  const totalConsumptionData = {
    label: "Consommé",
    data: [],
    backgroundColor: colors[1],
    borderRadius: 10,
    barThickness: 15,
    stack: "Stack 1",
    hoverBorderWidth: 0,
  };
  const overConsumption = {
    label: "Surconsommation",
    data: [],
    backgroundColor: colors[8],
    borderRadius: 10,
    barThickness: 15,
    stack: "Stack 1",
    hoverBorderWidth: 0,
  };

  const totalQuotaData = {
    label: "Quota mensuelle",
    data: [],
    backgroundColor: colors[2],
    borderRadius: 10,
    barThickness: 15,
    stack: "Stack 1",
    hoverBorderWidth: 0,
  };
  const totalHorsForfait = {
    label: "Hors forfait",
    data: [],
    backgroundColor: colors[6],
    borderRadius: 10,
    barThickness: 15,
    stack: "Stack 2",
    hoverBorderWidth: 0,
  };

  months.forEach((month) => {
    const monthData = data.find(
      (entry) => moment(entry.month).format("YYYY-MM") === month
    );
    overConsumption.data.push(monthData ? monthData.overConsumption || NaN : 0);
    totalConsumptionData.data.push(
      monthData ? monthData.totalConsumption || NaN : 0
    );
    monthlyPlanData.data.push(
      monthData
        ? monthData.monthlyPlan -
            monthData.totalConsumption -
            monthData.totalQuota -
            monthData.overConsumption || NaN
        : 0
    );
    totalHorsForfait.data.push(monthData ? monthData.hors_forfait || NaN : 0);
    totalQuotaData.data.push(monthData ? monthData.totalQuota || NaN : 0);
  });

  transformedData.push(
    totalQuotaData,
    totalConsumptionData,
    overConsumption,
    monthlyPlanData,
    totalHorsForfait
  );

  return transformedData;
};

export const calculateInterventionDurations = (interventions) => {
  if (!interventions || interventions.length === 0) {
    return 0;
  }

  let sum = 0;
  interventions.forEach((intervention) => {
    if (intervention.statut === 4) {
      sum += intervention.duration;
    }
  });
  return sum;
};

// Helper function to get the last 12 months in the format "YYYY-MM"
function getRecentMonths(numMonths) {
  const today = moment();
  const months = [];
  for (let i = numMonths - 1; i >= 0; i--) {
    const month = moment(today).subtract(i, "months").startOf("month");
    months.push(month.format("YYYY-MM"));
  }
  return months;
}

const colors = [
  "#3365FF",
  "#17DCCD",
  "#FFBC20",
  "#FF5D9E",
  "#0CCE91",
  "#FF721B",
  "#7E25FF",
  "grey",
  "#B2022D",
];

export const transformticketsToStatsPie = (stats) => {
  if (!stats) {
    return [];
  }
  const sortedStats = stats?.slice().sort((a, b) => b.count - a.count);
  const top5 = sortedStats?.slice(0, 5);
  const sumOfRest = sortedStats
    ?.slice(5)
    .reduce((sum, stat) => sum + stat.count, 0);

  const result = top5?.map(({ name, count }, index) => ({
    name,
    color: colors[index],
    value: count,
  }));

  if (sumOfRest !== 0) {
    result.push({ name: "autres", color: colors[6], value: sumOfRest });
  }

  return result;
};

import React from "react";
import LightDarkContainer from "../Common/LightDarkContainer";
import { useDispatch, useSelector } from "react-redux";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { generateProspectData } from "../../Utils/utils";
import LightDarkText from "../Common/LightDarkText";
import InformationComponent from "../Prospect/InformationComponent";
import { useEffect } from "react";
import { useState } from "react";
import DetailsDevis from "./DetailsDevis";
import { GetProspectById } from "../../REDUX/actions/prospect";
import { tableListData } from "../../Utils/tableStaticData";

const DevisSideBar = ({ devisId, index, openDetails = false }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const screenWidth = useWindowWidth();
  const [isToggle, setIsToggle] = useState();
  const { devis, selectedDevis } = useSelector((state) => state.devisReducer);
  useEffect(() => {
    if (openDetails) {
      setIsToggle("Details");
    } else {
      setIsToggle("Informations");
    }
  }, [devisId, openDetails]);
  useEffect(() => {
    dispatch(GetProspectById(devisId, true));
  }, [devisId]);
  const choosenProspect = devis?.find((el) => el.id === devisId);
  const headerNav = [
    {
      id: 1,
      text: "Informations",
      component: (
        <InformationComponent
          choosenItem={selectedDevis}
          tableListData={tableListData}
          extraInfo={generateProspectData(choosenProspect)}
        />
      ),
    },
    {
      id: 2,
      text: "Details",
      component: <DetailsDevis choosenItem={selectedDevis} />,
    },
  ];

  return (
    <div
      className="d-flex flex-column justify-content-between  sideChatListContainer"
      style={{ height: "95%" }}
    >
      <div className="d-flex justify-content-center h5">
        {headerNav.map(({ id, text, component }) => {
          return (
            <LightDarkText
              key={id}
              className={` text-start custom-ticket-font-size bold me-4 pointer clickable-text t-02`}
              color={
                isToggle === text
                  ? "text-light-blue"
                  : mode === "light"
                  ? "text-blue-grey"
                  : "text-light-text"
              }
              onclickCallback={() => setIsToggle(text)}
            >
              {text}
              {isToggle === text && screenWidth > 600 && (
                <div
                  className="radius20 "
                  style={{
                    borderBottom: "4px solid #3365ff",
                    position: "relative",
                    top: 10,
                  }}
                ></div>
              )}
            </LightDarkText>
          );
        })}
      </div>
      <div style={{ height: "95%" }}>
        {headerNav.find((el) => el.text === isToggle)?.component}
      </div>
    </div>
  );
};

export default DevisSideBar;

import { getFileSize } from "../../Utils/utils";

const initialState = {
  fichiers: [],
  dossiers: [],
  sharedFolders: [],
  sharedFiles: [],
  ticketsAttchment: [],
  totalTicketsAttchment: 0,
  cloudLoading: false,
  foldersSelect: [],
  folderItem: null,
  sharingFolder: [],
  sharingFiles: [],
  refetchCloud: true,
  ticketsAttchmentLoading: false,
};

const cloudReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "LOADING_CLOUD":
      return { ...state, cloudLoading: true };
    case "DELETE_FICHIER":
      return {
        ...state,
        cloudLoading: false,
        fichiers: state.fichiers.filter((el) => el.id !== payload.id),
      };
    case "DELETE_FICHIER_INFOLDER":
      const newFiles = state.folderItem.attributes.fichiers.data.filter(
        (el) => el.id !== payload.id
      );

      return {
        ...state,
        cloudLoading: false,
        folderItem: {
          ...state.folderItem,
          attributes: {
            ...state.folderItem.attributes,
            fichiers: {
              ...state.folderItem.attributes.fichier,
              data: newFiles,
            },
          },
        },
      };
    case "FETCH_FOLDER_ITEM":
      let files = payload.attributes?.fichiers?.data?.map((fichier) => {
        let getSize = async (f) => {
          return await getFileSize(f);
        };
        return {
          ...fichier,
          attributes: {
            ...fichier.attributes,

            fileName: fichier?.attributes?.file?.data?.attributes?.name,
            fileSize: getSize(fichier),
          },
        };
      });
      return {
        ...state,
        cloudLoading: false,
        folderItem: {
          ...payload,
          attributes: {
            ...payload.attributes,
            fichiers: { ...payload.attributes?.fichiers, data: files },
          },
        },
      };

    case "FETCH_CLOUD":
      const fichiers = payload.attributes.fichiers.data.filter(
        (fichier) =>
          !fichier.attributes.folder.data && !fichier.attributes.ticket.data
      );

      const foldersSelect = payload.attributes.folders.data.map((folder) => {
        return { value: folder.id, label: folder.attributes.name };
      });

      const sharedFoldersSelect = state.sharedFolders?.map((folder) => {
        return { value: folder.id, label: folder.attributes.name };
      });

      return {
        ...state,
        refetchCloud: false,
        cloudLoading: false,
        fichiers: fichiers,
        dossiers: payload.attributes.folders.data,
        foldersSelect: [...foldersSelect, ...sharedFoldersSelect],
      };
    case "RESET_ATTACHMENTS":
      return {
        ...state,
        ticketsAttchmentLoading: false,
        totalTicketsAttchment: 0,
        ticketsAttchment: [],
      };
    case "RESET_ORGANISATION":
      return {
        ...state,
        fichiers: [],
        dossiers: [],
        sharedFolders: [],
        sharedFiles: [],
        ticketsAttchment: [],
        totalTicketsAttchment: 0,
        cloudLoading: false,
        foldersSelect: [],
        folderItem: null,
        sharingFolder: [],
        sharingFiles: [],
        refetchCloud: true,
        ticketsAttchmentLoading: false,
      };
    case "FETCH_ATTACHMENTS_LOADING":
      return { ...state, ticketsAttchmentLoading: true };
    case "FETCH_ATTACHMENTS": {
      const ticketsAttchment = payload.data.map((fichier) => {
        let getSize = async (f) => {
          return await getFileSize(f);
        };
        return {
          ...fichier,
          attributes: {
            ...fichier.attributes,
            ticketId: fichier.attributes.ticket.data?.id,
            ticketName: fichier.attributes.ticket.data?.attributes.name,
            fileName: fichier.attributes.file.data?.attributes.name,
            fileSize: getSize(fichier),
          },
        };
      });
      return {
        ...state,
        ticketsAttchment: [...state?.ticketsAttchment, ...ticketsAttchment],
        ticketsLoading: false,
        ticketsAttchmentLoading: false,
        totalTicketsAttchment: payload?.total,
      };
    }

    case "FETCH_SHARED_FOLDERS":
      return { ...state, sharedFolders: payload };
    case "FETCH_SHARED_FILES":
      return { ...state, sharedFiles: payload };
    case "FETCH_NEW_FOLDER":
      return {
        ...state,
        dossiers: [...state?.dossiers, payload],
        foldersSelect: [
          ...state?.foldersSelect,
          { value: payload.id, label: payload.attributes.name },
        ],
      };
    case "FETCH_NEW_FILE":
      return { ...state, fichiers: [payload, ...state?.fichiers] };
    case "FETCH_NEW_FOLDER_FILE": {
      let getSize = async (f) => {
        return await getFileSize(f);
      };
      const newFile = {
        ...payload,
        attributes: {
          ...payload.attributes,

          fileName: payload?.attributes?.file?.data?.attributes?.name,
          fileSize: getSize(payload),
        },
      };
      const updatedFolder = state?.folderItem;
      updatedFolder.attributes.fichiers.data = [
        ...updatedFolder.attributes?.fichiers?.data,
        newFile,
      ];

      return { ...state, folderItem: updatedFolder };
    }
    default:
      return state;
  }
};

export default cloudReducer;

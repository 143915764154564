import { isValidPhoneNumber } from "react-phone-number-input";

import { validMail } from "../../../Utils/utils";

export const societyFields = (isNew, society) => {
  return isNew
    ? {
        name: "",
        siret: "",
        email: "",
        adress: "",
        phoneNumber: "",
        city: "",
        zipCode: "",
        archived: false,
        emailFacture: "",
        siteWeb: "",
        monthlyPlan: 0,
        monthlyQuota: 0,
        archive: false,
        society_type: [],
      }
    : {
        ...society.attributes,
        society_type: society?.attributes?.society_type.data
          ? [
              {
                label:
                  society?.attributes?.society_type?.data?.attributes?.name,
                value: society?.attributes?.society_type?.data?.id,
              },
            ]
          : [],
      };
};

export const checkRequiredSocietyInputs = (
  societyInputs,
  societyTypesValues
) => {
  return (
    !societyInputs.name ||
    (societyTypesValues?.length !== 0 &&
      !societyInputs?.society_type?.length) ||
    !validMail(societyInputs.email) ||
    // !societyInputs.phoneNumber ||
    (!isValidPhoneNumber(societyInputs.phoneNumber || "") &&
      societyInputs.phoneNumber) ||
    !validMail(societyInputs.emailFacture)
  );
};

export const SocietyFormTabs = () => {
  return [
    {
      title: "Données de la société",
      toggleValue: "infos",
      isVisible: true,
    },
    {
      title: "Statistiques",
      toggleValue: "forfait",
      isVisible: true,
    },
  ];
};

export const SocietyBundleTabs = (selectedOrganisation) => {
  return [
    {
      title: "Tickets créés",
      toggleValue: "tickets",
      isVisible: !selectedOrganisation.attributes.withClicks,
    },
    {
      title: "Consommation",
      toggleValue: "consumed",
      isVisible: selectedOrganisation.attributes.withForfait,
    },
    {
      title: "Nombre de clic",
      toggleValue: "clics",
      isVisible: selectedOrganisation.attributes.withClicks,
    },
  ];
};

export const SocietySideBarTabs = ({ canSeeCollabs, canSeeOperators }) => {
  return [
    {
      title: "Données",
      toggleValue: "infos",
      isVisible: true,
    },
    {
      title: "Collaborateurs",
      toggleValue: "collaborators",
      isVisible: canSeeCollabs,
    },
    {
      title: "Opérateurs",
      toggleValue: "operators",
      isVisible: canSeeOperators,
    },
  ];
};

export const userSideBarTabs = ({ isSupervisor }) => {
  return [
    {
      title: "Détails",
      toggleValue: "details",
      isVisible: true,
    },
    {
      title: "Historique de connexion",
      toggleValue: "loginLogs",
      isVisible: isSupervisor,
    },
  ];
};

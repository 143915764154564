import { useSelector } from "react-redux";

import TicketTitle from "../../TicketCommonComponents/TicketTitle";
import LightDarkPlaceHolder from "../../../PlaceHolders/LightDarkPlaceHolder";

const ChatHeader = () => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { messagesLoading } = useSelector((state) => state.messagesReducer);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between pt-4 pb-2">
        {messagesLoading ? (
          <LightDarkPlaceHolder customClass={"py-1 w70"} />
        ) : (
          <TicketTitle
            id={ticket?.id}
            title={ticket?.attributes?.name}
            textsize="custom-font-size"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          />
        )}
      </div>
      <div
        className="my-2"
        style={{
          borderBottom: `2px solid ${mode === "light" ? "#F8F9FF" : "#252C45"}`,
        }}
      />
    </>
  );
};

export default ChatHeader;

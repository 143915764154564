import React from "react";
import { useSelector } from "react-redux";

const NotificationEventName = ({ notification }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <span
      className={`${
        mode === "light" ? "text-dark-blue" : "text-light-blue-grey"
      } `}
    >
      {` ${notification?.event?.data?.attributes?.name} `}
    </span>
  );
};

export default NotificationEventName;

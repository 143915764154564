import { Offcanvas } from "react-bootstrap";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideSideBar } from "../../../../REDUX/actions/general";

import LightDarkText from "../../../Common/LightDarkText";
import { CloseIcon } from "../../../Common/LightDarkSvg";
import EventForm from "./EventForm";
import EventSideBarDetails from "./EventSideBarDetails";
import EventHeader from "./EventHeader";

const SideBar = ({ show }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);

  const { isNew, isEdit, eventDetails, fromCalendar } = useSelector(
    (state) => state.genearlReducer
  );

  const [isToggle, setIsToggle] = useState("details");

  return (
    <Offcanvas
      show={show}
      onHide={() => {
        dispatch(hideSideBar());
        setIsToggle("details");
      }}
      scroll
      backdrop
      placement="end"
      className={`${mode === "light" ? "bg-white" : "bg-dark-grey-bg"} w30`}
    >
      <div
        className={`px-4 d-flex align-items-center ${
          isNew || isEdit ? "py-4" : "pt-4"
        }`}
      >
        <CloseIcon
          dimension={30}
          clickCallback={() => {
            dispatch(hideSideBar());
            setIsToggle("details");
          }}
        />
        {(isNew || isToggle === "details") && (
          <Offcanvas.Header>
            <LightDarkText
              className="text-start f24 bold"
              color={mode === "light" ? "text-dark-blue" : "text-light-text"}
            >
              {isNew
                ? "Ajouter un événement"
                : isEdit && "Modifier l'événement"}
            </LightDarkText>
          </Offcanvas.Header>
        )}
      </div>

      {!isNew && !isEdit && (
        <>
          <EventHeader isToggle={isToggle} setIsToggle={setIsToggle} />
          <div
            className={`mt-2 mx-5 mb-4 `}
            style={{
              borderBottom: `2px solid ${
                mode === "light" ? "#F8F9FF" : "#252C45"
              }`,
            }}
          />
        </>
      )}

      {isNew || isEdit ? (
        <EventForm
          isNew={isNew}
          fromCalendar={fromCalendar}
          event={isEdit && eventDetails}
        />
      ) : (
        <EventSideBarDetails event={eventDetails} isToggle={isToggle} />
      )}
    </Offcanvas>
  );
};

export default SideBar;

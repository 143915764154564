import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UserForm from "../UserForm/UserForm";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { openModal } from "../../../REDUX/actions/modal";
import { openConfirmationModal } from "../../../REDUX/actions/confirmationModal";
import { userModalData } from "../userModalData";

const UserSideBarActions = ({ isOp }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const { canCreateCollabs } = useSelector((state) => state.roleReducer);
  const { selectedUser } = useSelector((state) => state.clientsReducer);

  return (
    canCreateCollabs && (
      <div className="row justify-content-between mt-3">
        <div
          className={`${
            screenWidth < 1200 && screenWidth > 992
              ? " mt-1  order-1"
              : "col-5 pe-0"
          }`}
        >
          <ColoredButton
            textButton={
              selectedUser.attributes.user_org_permissions.data[0].attributes
                .archived
                ? "Désarchiver"
                : "Archiver"
            }
            outlined
            color="blue-grey"
            callback={() => {
              dispatch(
                openConfirmationModal(userModalData(selectedUser, dispatch))
              );
            }}
          />
        </div>
        <div
          className={`${
            screenWidth < 1200 && screenWidth > 992 ? " mt-2" : "col-7 ps-0"
          }`}
        >
          <ColoredButton
            color="light-blue"
            textButton="Éditer le profil"
            callback={() => {
              dispatch(
                openModal({
                  type: "small",
                  content: <UserForm client={selectedUser} isOp={isOp} />,
                })
              );
            }}
          />
        </div>
      </div>
    )
  );
};

export default UserSideBarActions;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Header from "./Header/Header";
import Drawer from "./Drawer/Drawer";
import Loading from "../Common/Loading";
import TicketsLayout from "./TicketsLayout";
import AlertPopUp from "./Alert/AlertPopUp";
import ToastMessage from "./ToastMessage/ToastMessage";
import FunctionModal from "./FunctionModal/FunctionModal";
import OrganisationBar from "./OrganisationBar/OrganisationBar";
import NotificationPopup from "./Notification/NotificationPopup";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";

import { openModal } from "../../REDUX/actions/modal";
import { getAllAlerts } from "../../REDUX/actions/alerts";
import { getAllTickets } from "../../REDUX/actions/tickets";
import { getAllSocietes } from "../../REDUX/actions/societe";
import { getAllInvoices } from "../../REDUX/actions/facture";
import { closeSideBarCard } from "../../REDUX/actions/general";
import { getAllCalendars } from "../../REDUX/actions/calendars";
import { resetTicketFilters } from "../../REDUX/actions/filters";
import { getConditionalUsers } from "../../REDUX/actions/clients";
import { getAllDifffusions } from "../../REDUX/actions/diffusions";
import { GetAllDepatements } from "../../REDUX/actions/departement";
import { fetchNotifications } from "../../REDUX/actions/notifications";
import { socketConnect, updateRoute } from "../../REDUX/actions/socket";
import { getAllInterventions } from "../../REDUX/actions/interventions";

import "./Layout.css";
import Footer from "./Footer";
import ExternalLoader from "../Cloud/OneDrive/ExternalLoader";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { clientArchived, archivedSociety, operateursArchived } = useSelector(
    (state) => state.filtersReducer
  );
  const {
    ticketRoute,
    interventionRoute,
    organisationLoading,
    selectedOrganisation,
  } = useSelector((state) => state.organisationsReducer);

  const { factures } = useSelector((state) => state.facturesReducer);
  const { departments } = useSelector((state) => state.departmentReducer);
  const { user, userLoading } = useSelector((state) => state.userReducer);
  const { alerts, lastAlert } = useSelector((state) => state.alertsReducer);
  const { initialLoading } = useSelector((state) => state.sharedDataReducer);
  const { isSupervisor, canSeeInvoices, role, isClientAdmin } = useSelector(
    (state) => state.roleReducer
  );

  useEffect(() => {
    if (selectedOrganisation?.attributes?.hasDiffusion) {
      dispatch(getAllDifffusions());
    }
  }, [dispatch, selectedOrganisation]);

  useEffect(() => {
    if (user && role) {
      dispatch(getAllInterventions());

      if (!alerts.length) {
        dispatch(getAllAlerts());
      }
      if (!factures.length && canSeeInvoices) {
        dispatch(getAllInvoices());
      }
      dispatch(getAllCalendars());

      if (!departments?.length) {
        dispatch(GetAllDepatements());
      }
    }
  }, [selectedOrganisation, user, dispatch, role]);

  useEffect(() => {
    dispatch(updateRoute(pathname));
    dispatch(closeSideBarCard());
  }, [pathname]);

  useEffect(() => {
    if (
      !pathname.includes(ticketRoute) &&
      !pathname.includes(interventionRoute)
    ) {
      dispatch(resetTicketFilters());
    }
  }, [pathname, dispatch]);

  useEffect(() => {
    if (isSupervisor) {
      dispatch(getConditionalUsers(false));
    }
  }, [dispatch, role, user, operateursArchived, selectedOrganisation]);

  useEffect(() => {
    if (isSupervisor || isClientAdmin) {
      dispatch(getConditionalUsers());
    }
  }, [dispatch, role, user, clientArchived, selectedOrganisation]);

  useEffect(() => {
    dispatch(getAllSocietes());
    dispatch(fetchNotifications());
  }, [dispatch, user, archivedSociety, selectedOrganisation]);

  useEffect(() => {
    dispatch(getAllTickets());
  }, [dispatch]);

  useEffect(() => {
    if (user?.id) {
      dispatch(socketConnect());
    }
  }, [dispatch, user?.id, selectedOrganisation, ticketRoute]);

  useEffect(() => {
    if (lastAlert) {
      dispatch(
        openModal({
          type: "alertModal",
          preventClose: true,
          content: <AlertPopUp alert={lastAlert} />,
        })
      );
    }
  }, [lastAlert]);
  return userLoading || organisationLoading || initialLoading ? (
    <Loading showLoader />
  ) : user ? (
    <div className="d-flex" style={{ height: "100vh" }}>
      <OrganisationBar />
      <Drawer />
      <div className="layoutRight d-flex flex-column">
        <Header />

        {pathname.includes(ticketRoute) ? <TicketsLayout /> : null}
        <ConfirmationModal />
        <ToastMessage />
        <FunctionModal />
        {Notification.permission === "default" && <NotificationPopup />}
        <div className="layoutApp">
          <div className="h100 notScrollable">{children}</div>
        </div>
        <ExternalLoader />
        <Footer />
      </div>
    </div>
  ) : (
    <div>{children}</div>
  );
};

export default Layout;

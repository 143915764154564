import _ from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import FilesCarousel from "./FilesCarousel";
import LightDarkText from "./LightDarkText";
import LightDarkContainer from "./LightDarkContainer";
import { CloseIcon, Download, EyeIcon } from "./LightDarkSvg";

import { donwloadFile } from "../../Utils/utils";

import { openModal } from "../../REDUX/actions/modal";
import { deleteFile } from "../../REDUX/actions/cloud";
import { deleteMessage } from "../../REDUX/actions/tickets";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import moment from "moment";

const AttachmentsList = ({
  files,
  eventAttachedFiles = false,
  ticketAttachedFiles,
  ticketNotArchived = true,
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userReducer);
  const { mode } = useSelector((state) => state.genearlReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);

  const handleDeleteFile = (file) => {
    dispatch(
      openConfirmationModal({
        callBack: () => {
          dispatch(deleteFile(file));
          if (
            file?.attributes?.message?.data?.attributes?.content === null &&
            ticketAttachedFiles
          ) {
            dispatch(deleteMessage(file?.attributes?.message?.data?.id));
          }
        },
        message: "Êtes-vous sur de vouloir supprimer le fichier?",
        message2: `#${file.id} ${file.attributes?.file.data?.attributes?.name}`,
        confirmButtonText: "Supprimer le fichier",
      })
    );
  };
  return (
    <div className={`${eventAttachedFiles ? "mb-4" : ""}`}>
      {_.uniqBy(files, (item) => item.id).map((file, index) => {
        const canDelete =
          isSupervisor ||
          user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
            ?.data?.id == file?.attributes?.cloud?.data?.id;
        return (
          <LightDarkContainer
            key={index}
            colorLight="light-mode"
            colorDark="dark-mode"
            className="my-3 px-3 py-4 radius05 h40 alignH justify-content-between scaledAttachement"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip style={{ zIndex: 10000 }}>
                  <LightDarkText
                    className={
                      "custom-ticket-font-size bold pointer text-white"
                    }
                  >
                    {file?.attributes?.file?.data?.attributes?.name}
                  </LightDarkText>
                </Tooltip>
              }
            >
              <div className="w80 p-0 m-0">
                <LightDarkText
                  className="text-start custom-ticket-font-size bold pointer"
                  color={
                    mode === "light" ? "text-blue-grey" : "text-light-text"
                  }
                  onclickCallback={() => {
                    file?.attributes?.file?.data?.attributes?.mime?.includes(
                      "image"
                    ) ||
                    file?.attributes?.file?.data?.attributes?.mime?.includes(
                      "pdf"
                    )
                      ? dispatch(
                          openModal({
                            type: "pdfFile",
                            content: (
                              <FilesCarousel firstFile={file} files={files} />
                            ),
                          })
                        )
                      : donwloadFile(file);
                  }}
                >
                  <div className="text-ellipsis">
                    {file?.attributes?.file?.data?.attributes?.name}
                  </div>
                </LightDarkText>
              </div>
            </OverlayTrigger>
            <div className="d-flex align-items-center">
              {(file?.attributes?.file?.data?.attributes?.mime?.includes(
                "image"
              ) ||
                file?.attributes?.file?.data?.attributes?.mime?.includes(
                  "pdf"
                )) && (
                <div
                  onClick={() =>
                    dispatch(
                      openModal({
                        type: "pdfFile",
                        content: (
                          <FilesCarousel firstFile={file} files={files} />
                        ),
                      })
                    )
                  }
                >
                  <EyeIcon size={24} darkColor="#a9bcd3" />
                </div>
              )}
              <Download size={24} clickCallback={() => donwloadFile(file)} />
              {canDelete && ticketNotArchived && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip style={{ zIndex: 2000 }}>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        {moment().diff(file.attributes.createdAt, "hours") > 24
                          ? `Il s'est écoulé plus de 24 heures depuis que vous avez
                        créé votre message, par souci de conformité, il n'est
                        désormais plus possible de le modifier ou de le
                        supprimer.`
                          : "Supprimer"}
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div>
                    <CloseIcon
                      dimension={24}
                      clickCallback={() =>
                        moment().diff(file.attributes.createdAt, "hours") > 24
                          ? () => {}
                          : handleDeleteFile(file)
                      }
                    />
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </LightDarkContainer>
        );
      })}
    </div>
  );
};

export default AttachmentsList;

import React from "react";
import {
  CardActionsIcon,
  HorzontalLines,
  VerticalPoints,
} from "../LightDarkSvg";
import SettingTooltip from "./SettingTooltip";
import Tooltipitem from "./Tooltipitem";

const TooltipActions = ({
  showTooltip,
  setshowTooltip,
  tooltipData,
  isVertical = false,
  isHorizontal = false,
  left,
}) => {
  return (
    <div className="customindex">
      {isHorizontal ? (
        <HorzontalLines clickCallback={() => setshowTooltip(!showTooltip)} />
      ) : isVertical ? (
        <VerticalPoints
          showTooltip={showTooltip}
          clickCallback={() => setshowTooltip(!showTooltip)}
        />
      ) : (
        <CardActionsIcon clickCallback={() => setshowTooltip(!showTooltip)} />
      )}
      <div
        style={{
          position: left ? "fixed" : "relative",
          zIndex: 500,
          left: left || null,
        }}
      >
        {showTooltip && (
          <SettingTooltip
            showTooltip={showTooltip}
            setshowTooltip={setshowTooltip}
          >
            {tooltipData
              .filter((item) => item.condition)
              .map(({ id, text, action, icon }) => {
                return (
                  <Tooltipitem
                    key={id}
                    id={id}
                    text={text}
                    action={action}
                    icon={icon}
                    lastIndex={tooltipData.length}
                  />
                );
              })}
          </SettingTooltip>
        )}
      </div>
    </div>
  );
};

export default TooltipActions;

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import LightDarkText from "../../Common/LightDarkText";
import { NotifBadge } from "../../Common/LightDarkSvg";

import useWindowWidth from "../../../Hooks/useWindowWidth";

import { handleReadTicketNotifications } from "../../../REDUX/actions/notifications";

const RightPanelMenu = ({ setIsToggle, isToggle, ticket }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div style={{ overflowX: "scroll" }}>
      <div
        className={`alignH justify-content-between gap-3 ${
          screenWidth < 1200 ? "" : "mx-3 "
        }`}
      >
        <LightDarkText
          className="text-start custom-ticket-font-size bold pointer clickable-text"
          onclickCallback={() => setIsToggle("Fichiers")}
          color={
            isToggle === "Fichiers"
              ? "text-light-blue"
              : mode === "light"
              ? "text-blue-grey"
              : "text-light-text"
          }
        >
          Fichiers
          {isToggle === "Fichiers" && (
            <div
              className="radius05"
              style={{
                borderBottom: "4px solid #3365ff",
                position: "relative",
                top: 5,
              }}
            ></div>
          )}
        </LightDarkText>
        <LightDarkText
          className="text-start custom-ticket-font-size bold pointer clickable-text"
          onclickCallback={() => setIsToggle("Participants")}
          color={
            isToggle === "Participants"
              ? "text-light-blue"
              : mode === "light"
              ? "text-blue-grey"
              : "text-light-text"
          }
        >
          Participants
          {isToggle === "Participants" && (
            <div
              className="radius05"
              style={{
                borderBottom: "4px solid #3365ff",
                position: "relative",
                top: 5,
              }}
            ></div>
          )}
        </LightDarkText>
        <div>
          <LightDarkText
            className="text-start d-flex custom-ticket-font-size bold pointer clickable-text"
            onclickCallback={() => {
              setIsToggle("Interventions");
              dispatch(
                handleReadTicketNotifications(
                  ["Intervention", "Action"],
                  ticket.id
                )
              );
            }}
            color={
              isToggle === "Interventions"
                ? "text-light-blue"
                : mode === "light"
                ? "text-blue-grey"
                : "text-light-text"
            }
          >
            Interventions
            {ticket?.attributes?.notifications?.data.filter(
              (notification) =>
                !notification.attributes?.read &&
                (notification.attributes.type === "Intervention" ||
                  notification.attributes.type === "Action")
            ).length ? (
              <NotifBadge />
            ) : null}
          </LightDarkText>
          {isToggle === "Interventions" && (
            <div
              className="radius05"
              style={{
                borderBottom: "4px solid #3365ff",
                position: "relative",
                top: 5,
              }}
            ></div>
          )}
        </div>
        <LightDarkText
          className="text-start custom-ticket-font-size bold pointer clickable-text"
          onclickCallback={() => setIsToggle("Links")}
          color={
            isToggle === "Links"
              ? "text-light-blue"
              : mode === "light"
              ? "text-blue-grey"
              : "text-light-text"
          }
        >
          Liens
          {isToggle === "Links" && (
            <div
              className="radius05"
              style={{
                borderBottom: "4px solid #3365ff",
                position: "relative",
                top: 5,
              }}
            ></div>
          )}
        </LightDarkText>
        <LightDarkText
          className="text-start custom-ticket-font-size bold pointer clickable-text"
          onclickCallback={() => setIsToggle("Favorites")}
          color={
            isToggle === "Favorites"
              ? "text-light-blue"
              : mode === "light"
              ? "text-blue-grey"
              : "text-light-text"
          }
        >
          Favoris
          {isToggle === "Favorites" && (
            <div
              className="radius05"
              style={{
                borderBottom: "4px solid #3365ff",
                position: "relative",
                top: 5,
              }}
            ></div>
          )}
        </LightDarkText>
      </div>
      <div
        className="mt-1 mb-3 mx-2"
        style={{
          borderBottom: `2px solid ${mode === "light" ? "#F8F9FF" : "#252C45"}`,
        }}
      ></div>
    </div>
  );
};

export default RightPanelMenu;

import React from "react";
import { useSelector } from "react-redux";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Thumbnail from "../../Common/Thumbnail";
import LightDarkText from "../../Common/LightDarkText";
import LightDarkPlaceHolder from "../../PlaceHolders/LightDarkPlaceHolder";
import ThumbnailPlaceHolder from "../../PlaceHolders/ThumbnailPlaceHolder";

import useWindowWidth from "../../../Hooks/useWindowWidth";

const TitleImageBlock = ({
  isLoading = false,
  historyThumbnail = false,
  userName,
  societyName,
  interventionTitle,
  avatar,
  defaultImage,
  extraData,
}) => {
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div
      className={`d-flex gap-3 ${
        historyThumbnail ? "align-items-start" : "align-items-center"
      } w-100`}
    >
      {isLoading && (avatar || !interventionTitle) ? (
        <div>
          <ThumbnailPlaceHolder smallThumbnail={true} />
        </div>
      ) : (
        (avatar || !interventionTitle) && (
          <Thumbnail
            img={defaultImage}
            apiImg={avatar}
            size={screenWidth < 1200 ? 30 : 40}
            rounded
            historyThumbnail={historyThumbnail}
          />
        )
      )}
      {isLoading ? (
        <div className="w-100">
          <LightDarkPlaceHolder customClass="py-1 w-100 mb-2" />
          <LightDarkPlaceHolder customClass="py-1 w60" />
        </div>
      ) : (
        <div className="flex-1">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                <LightDarkText className="custom-ticket-font-size py-1 text-white">
                  {societyName || interventionTitle || userName}
                </LightDarkText>
              </Tooltip>
            }
          >
            <div>
              <LightDarkText
                className="custom-font-size bolder text-start"
                style={{
                  textWrap: "wrap",
                }}
              >
                {societyName || interventionTitle || userName}
              </LightDarkText>
            </div>
          </OverlayTrigger>
          {extraData}
        </div>
      )}
    </div>
  );
};

export default TitleImageBlock;

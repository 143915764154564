import React from "react";

import MessageTime from "./MessageTime";
import MessageSender from "./MessageSender";

const MessageCardFooter = ({ message, setMeSending }) => {
  return (
    <div className="d-flex justify-content-between mt-4">
      <MessageSender
        sender={message?.attributes?.sender?.data}
        message={message}
        setMeSending={setMeSending}
      />
      <MessageTime message={message} />
    </div>
  );
};

export default MessageCardFooter;

export const tableListData = [
  {
    id: 1,
    name: "ID",
    accessor: "id",
    width: 5,
    widthSideBar: 5,
    sortField: "id",
    alwaysVisible: true,
  },
  {
    id: 2,
    name: "Prenom",
    accessor: "firstname",
    width: 10,
    widthSideBar: 15,

    alwaysVisible: true,
  },
  {
    id: 3,
    name: "Nom",
    accessor: "lastname",
    width: 10,
    widthSideBar: 15,

    alwaysVisible: true,
  },
  {
    id: 4,
    name: "Email",
    accessor: "email",
    width: 15,
    widthSideBar: 20,

    alwaysVisible: false,
  },
  {
    id: 5,
    name: "Departement",
    accessor: "department",

    width: 10,
    widthSideBar: 15,

    alwaysVisible: true,
  },
  {
    id: 6,
    name: "Date",
    accessor: "date",

    width: 10,
    widthSideBar: 10,

    alwaysVisible: true,
  },
  {
    id: 7,
    name: "Status",
    accessor: "status",
    width: 15,
    widthSideBar: 25,
    sortField: "status",

    alwaysVisible: true,
  },
  {
    id: 8,
    name: "Acteurs",
    accessor: "actors",
    width: 15,
    widthSideBar: 10,

    alwaysVisible: false,
  },
  {
    id: 9,
    name: "Origine",
    accessor: "origin",
    width: 5,
    widthSideBar: 10,
    sortField: "origin",

    alwaysVisible: true,
  },
  {
    id: 10,
    accessor: "actions",
    width: 5,
    widthSideBar: 5,

    alwaysVisible: true,
  },
];

export const tableCommandeListData = [
  {
    id: 1,
    name: "ID",
    accessor: "id",
    width: 5,
    widthSideBar: 5,
    sortField: "id",

    alwaysVisible: true,
  },
  {
    id: 2,
    name: "Prenom",
    accessor: "firstname",
    width: 10,
    widthSideBar: 15,
    alwaysVisible: true,
  },
  {
    id: 3,
    name: "Nom",
    accessor: "lastname",
    width: 15,
    widthSideBar: 15,
    alwaysVisible: true,
  },
  {
    id: 4,
    name: "Email",
    accessor: "email",
    width: 15,
    widthSideBar: 0,
    alwaysVisible: false,
  },
  {
    id: 5,
    name: "Date de livraison souhaitée",
    accessor: "dateLivraison",
    width: 15,
    widthSideBar: 20,
    alwaysVisible: true,
  },
  {
    id: 6,
    name: "Date",
    accessor: "date",
    width: 10,
    widthSideBar: 20,
    alwaysVisible: true,
  },
  {
    id: 7,
    name: "Status",
    accessor: "status",
    width: 15,
    widthSideBar: 20,
    sortField: "status",

    alwaysVisible: true,
  },
  {
    id: 8,
    name: "Acteurs",
    accessor: "actors",
    width: 10,
    widthSideBar: 10,
    alwaysVisible: false,
  },

  {
    id: 10,
    accessor: "actions",
    width: 5,
    widthSideBar: 5,
    alwaysVisible: true,
  },
];

export const interventionTableData = (withDuration) => [
  {
    id: 1,
    name: "ID",
    accessor: "id",
    sortField: "id",
    width: 5,
    widthSideBar: 5,
    alwaysVisible: true,
  },
  {
    id: 2,
    name: "Société",
    accessor: "attributes?.stringSociety",
    sortField: "stringSociety",

    width: 20,
    widthSideBar: 20,
    alwaysVisible: true,
  },
  {
    id: 3,
    name: "Titre",
    accessor: "attributes?.title",
    sortField: "title",

    width: 20,
    widthSideBar: 20,
    alwaysVisible: true,
  },
  {
    id: 4,
    name: "ID Ticket",
    accessor: "attributes?.ticket?.data?.id",
    sortField: "stringTicketId",

    width: withDuration ? 10 : 15,
    widthSideBar: withDuration ? 10 : 15,
    alwaysVisible: true,
  },
  {
    id: 5,
    name: "Création",
    accessor: "stringCreatedAt",
    sortField: "createdAt",

    width: withDuration ? 15 : 20,
    widthSideBar: withDuration ? 15 : 20,
    alwaysVisible: true,
  },
  {
    id: 6,
    name: "Durée",
    accessor: "duration",
    sortField: "duration",
    width: !withDuration ? 0 : 15,
    widthSideBar: !withDuration ? 0 : 15,
    alwaysVisible: true,
  },
  {
    id: 7,
    name: "Statut",
    accessor: "status",
    sortField: "statut",
    alignCenter: true,
    width: withDuration ? 10 : 15,
    widthSideBar: withDuration ? 10 : 15,
    alwaysVisible: true,
  },
];

export const userTableData = (usersView) => [
  {
    id: 1,
    name: "ID",
    accessor: "id",
    width: 5,
    widthSideBar: 5,
    alwaysVisible: false,
    sortField: "id",
  },
  {
    id: 2,
    name: "Nom",
    accessor: "attributes?.firstName",
    sortField: "firstName",
    width: usersView ? 20 : 40,
    widthSideBar: 20,
    alwaysVisible: true,
  },
  {
    id: 3,
    name: "Dernière connexion",
    accessor: "attributes?.lastConnected",
    sortField: "connected",
    width: 10,
    widthSideBar: 20,
    alwaysVisible: false,
  },

  {
    id: 4,
    name: "Adresse",
    accessor: "attributes?.adress",
    sortField: "adress",
    width: 20,
    widthSideBar: 20,
    alwaysVisible: false,
  },
  {
    id: 5,
    name: "E-mail",
    accessor: "attributes?.email",
    sortField: "email",
    width: 20,
    widthSideBar: 20,
    alwaysVisible: false,
  },
  {
    id: 6,
    name: "Téléphone",
    accessor: "attributes?.phoneNumber",
    sortField: "phoneNumber",
    width: 15,
    widthSideBar: 15,
    alwaysVisible: false,
  },
  {
    id: 7,
    name: "Poste",
    accessor: "attributes?.stringPoste",
    sortField: "stringPoste",
    width: 10,
    widthSideBar: 10,
    alwaysVisible: false,
  },
  {
    id: 8,
    name: "Société",
    accessor: "attributes?.stringSocieties",
    sortField: "stringSocieties",
    width: usersView ? 15 : 35,
    widthSideBar: 15,
    alwaysVisible: true,
  },
  {
    id: 9,
    name: "Rôle",
    accessor: "attributes?.stringRole",
    sortField: "stringRole",
    width: usersView ? 10 : 25,
    widthSideBar: 10,
    alwaysVisible: true,
  },
];

export const societyTableData = ({ withForfait, isDashboardView }) => [
  {
    id: 1,
    name: "ID",
    accessor: "id",
    sortField: "id",
    width: 5,
    widthSideBar: 5,
    alwaysVisible: true,
    dashboardView: true,
  },
  {
    id: 2,
    name: "Société",
    accessor: "attributes?.name",
    sortField: "name",
    width: isDashboardView ? 20 : 15,
    widthSideBar: 15,
    alwaysVisible: true,
    dashboardView: true,
  },
  {
    id: 3,
    name: "Adresse",
    accessor: "adresse",
    sortField: "adress",
    width: isDashboardView ? 35 : 20,
    widthSideBar: 20,
    alwaysVisible: true,
    dashboardView: true,
  },
  {
    id: 4,
    name: "Téléphone",
    accessor: "attributes?.phoneNumber",
    sortField: "phoneNumber",
    width: withForfait ? 10 : 15,
    widthSideBar: 15,
    alwaysVisible: true,
    dashboardView: false,
  },
  {
    id: 5,
    name: "Site Web",
    accessor: "attributes?.siteWeb",
    sortField: "siteWeb",
    width: withForfait ? 10 : 15,
    widthSideBar: 10,
    alwaysVisible: false,
    dashboardView: false,
  },
  {
    id: 6,
    name: "E-mail",
    accessor: "attributes?.email",
    sortField: "email",
    width: withForfait ? 15 : 20,
    widthSideBar: 15,
    alwaysVisible: true,
    dashboardView: false,
  },
  {
    id: 7,
    name: "Forfait mensuel",
    accessor: "forfait",
    sortField: "monthlyPlan",
    width: withForfait && !isDashboardView ? 15 : isDashboardView ? 25 : 0,
    widthSideBar: withForfait ? 15 : 0,
    alwaysVisible: true,
    dashboardView: true,
  },
  {
    id: 8,
    name: "Création",
    accessor: "attributes?.createdAt",
    sortField: "createdAt",
    width: 10,
    widthSideBar: 15,
    alwaysVisible: true,
    dashboardView: true,
  },
];

export const ticketTableData = (ticketHandleLogic) => {
  return [
    {
      id: 1,
      name: "ID",
      accessor: "id",
      width: 5,
      widthSideBar: null,
      alwaysVisible: true,
      sortField: "id",
    },
    {
      id: 2,
      name: "Société",
      accessor: "attributes?.stringSociety",
      width: 20,
      widthSideBar: null,
      alwaysVisible: true,
      sortField: "stringSociety",
    },
    {
      id: 3,
      name: "Titre",
      accessor: "attributes?.name",
      width: 20,
      widthSideBar: null,
      alwaysVisible: true,
      sortField: "name",
    },
    {
      id: 4,
      name: "Créé par",
      accessor: "attributes?.stringCreator",
      width: 10,
      widthSideBar: null,
      alwaysVisible: true,
      sortField: "stringCreator",
    },
    {
      id: 5,
      name: "Création",
      accessor: "stringCreatedAt",
      width: 5,
      widthSideBar: null,
      alwaysVisible: true,
      sortField: "stringCreatedAt",
    },
    {
      id: 6,
      name: "Type",
      accessor: "attributes?.stringType",
      width: 5,
      widthSideBar: null,
      alwaysVisible: true,
      sortField: "stringType",
    },
    {
      id: 7,
      name: ticketHandleLogic === 2 ? "Département" : "Prestation",
      accessor: "attributes?.stringPrestation",
      width: ticketHandleLogic === 2 ? 20 : 10,
      widthSideBar: null,
      alwaysVisible: true,
      sortField: "stringPrestation",
    },
    {
      id: 8,
      name: "Détail",
      accessor: "stringDevice",
      width: 5,
      widthSideBar: null,
      alwaysVisible: ticketHandleLogic !== 2,
      sortField: "stringDevice",
    },

    {
      id: 9,
      name: "Priorité",
      accessor: "priority",
      width: 10,
      widthSideBar: null,
      alwaysVisible: true,
      alignCenter: true,
      sortField: "priority",
    },
    {
      id: 10,
      name: "Statut",
      accessor: "statut",
      width: 10,
      widthSideBar: null,
      alwaysVisible: true,
      alignCenter: true,
      sortField: "statut",
    },
  ];
};

export const invoiceTableData = [
  {
    id: 1,
    name: "Numéro",
    accessor: "attributes?.number",
    width: 10,
    widthSideBar: null,
    alwaysVisible: true,
    sortField: "number",
  },
  {
    id: 2,
    name: "Société",
    accessor: "attributes?.stringSociety",
    width: 30,
    widthSideBar: null,
    alwaysVisible: true,
    sortField: "stringSociety",
  },
  {
    id: 3,
    name: "Type",
    accessor: "attributes?.type",
    width: 20,
    widthSideBar: null,
    alwaysVisible: true,
    sortField: "Type",
  },
  {
    id: 4,
    name: "Montant hors taxe",
    accessor: "attributes?.HT",
    width: 20,
    widthSideBar: null,
    alwaysVisible: true,
    sortField: "HT",
  },
  {
    id: 5,
    name: "TVA",
    accessor: "attributes?.TVA",
    width: 10,
    widthSideBar: null,
    alwaysVisible: true,
    sortField: "TVA",
  },
  {
    id: 6,
    name: "Montant TTC",
    accessor: "attributes?.TTC",
    width: 10,
    widthSideBar: null,
    alwaysVisible: true,
    sortField: "TTC",
  },
];

import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "../../../Common/LightDarkSvg";
import LightDarkText from "../../../Common/LightDarkText";
import { openModal } from "../../../../REDUX/actions/modal";
import EventModalInfo from "../../../Layout/FunctionModal/EventModalInfo";
import { handleError } from "../../../../REDUX/actions/toastMessage";

const EventMessage = ({ event, message }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);

  const eventClick = (id) => {
    if (event) {
      dispatch(
        openModal({
          type: "small",
          content: <EventModalInfo event={event} />,
        })
      );
    } else {
      dispatch(
        handleError("L'évènement a été supprimé ou dissocié de ce ticket.")
      );
    }
  };

  return (
    <LightDarkText
      className={`radius05 t02 mt-4 px-4 py-2 custom-ticket-font-size bold d-flex justify-content-center align-items-center gap-2 pointer clickable-text ${
        mode === "light" ? "bg-light-mode" : "bg-dark-message"
      }`}
      onclickCallback={() => eventClick(event)}
    >
      <Calendar sideBar /> {message}
    </LightDarkText>
  );
};

export default EventMessage;

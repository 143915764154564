import { conditionsData } from "../../Utils/utils";
import { UPDATE_QUESTIONS_SUCCESS } from "../ActionsType/formQuestionTypes";
import {
  CREATE_CONDITION,
  DELETE_CONDITION,
  UPDATE_CONDITION,
} from "../Graphql/Mutations/formConditions";
import { GET_QUESTION_BY_ID } from "../Graphql/Queries/FormQuestion";
import client from "./Client";
import { closeModal } from "./modal";
import { handleInfo } from "./toastMessage";

export const createNewCondition = (id, condition) => async (dispatch) => {
  try {
    for (let cond in condition) {
      await client.mutate({
        mutation: CREATE_CONDITION,
        variables: {
          question: id,
          condition: condition[cond].question,
          answer: condition[cond].answer,
        },
      });
    }

    //   dispatch({
    //     type: ADD_CATEGORY_SUCCESS,
    //     payload: data.createFormCategory.data,
    //   });
    dispatch(closeModal());
  } catch (error) {
    console.log(error);
  }
};

export const createOrUpdateCondition =
  (question, conditionItems, oldData) => async (dispatch) => {
    const updatePromises = [];
    const addPromises = [];
    const deletePromises = [];
    try {
      const addedItems = conditionItems.filter(
        (newItem) =>
          !conditionsData(oldData).some((oldItem) => oldItem.id === newItem.id)
      );
      const deletedItemIds = conditionsData(oldData)
        .filter(
          (oldItem) =>
            !conditionItems.some((newItem) => newItem.id === oldItem.id)
        )
        .map((item) => item.id);

      const updatedItems = [];
      for (const newItem of conditionItems) {
        const matchingOldItem = conditionsData(oldData).find(
          (oldItem) => oldItem.id === newItem.id
        );
        if (
          matchingOldItem &&
          JSON.stringify(matchingOldItem) !== JSON.stringify(newItem)
        ) {
          updatedItems.push(newItem);
        }
      }

      for (const newItem of addedItems) {
        addPromises.push(addCondition(question, newItem));
      }

      for (const itemId of deletedItemIds) {
        deletePromises.push(deleteCondition(itemId));
      }

      for (const itemId of updatedItems.map((item) => item.id)) {
        const updatedItem = conditionItems.find((item) => item.id === itemId);
        updatePromises.push(updateCondition(itemId, updatedItem));
      }
      await Promise.all([...updatePromises, ...addPromises, ...deletePromises]);
      const { data } = await client.query({
        query: GET_QUESTION_BY_ID(question),
      });
      dispatch({
        type: UPDATE_QUESTIONS_SUCCESS,
        payload: data.formQuestions.data[0],
      });
      dispatch(closeModal());
      dispatch(
        handleInfo("Condition mise à jour avec succès ", 1, "dark-green")
      );
    } catch (error) {
      console.log(error);
    }
  };

const addCondition = async (questionId, condition) => {
  const response = await client.mutate({
    mutation: CREATE_CONDITION,
    variables: {
      question: questionId,
      condition: condition.question,
      answer: condition.answer,
    },
  });
  return response;
};

const updateCondition = async (conditionId, condition) => {
  const response = await client.mutate({
    mutation: UPDATE_CONDITION,
    variables: {
      id: conditionId,
      condition: condition.question,
      answer: condition.answer,
    },
  });
  return response;
};

const deleteCondition = async (conditionId) => {
  const response = await client.mutate({
    mutation: DELETE_CONDITION,
    variables: {
      id: conditionId,
    },
  });
  return response;
};

import moment from "moment";

import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LightDarkText from "../../Common/LightDarkText";
import LightDarkContainer from "../../Common/LightDarkContainer";
import NotificationUserName from "./NotificationUserName";
import NotificationIcon from "./NotificationIcon";
import NotificationTicketTitle from "./NotificationTicketTitle";
import NotificationInterventionTitle from "./NotificationInterventionTitle";
import NotificationSocietyName from "./NotificationSocietyName";
import NotificationCloudItemName from "./NotificationCloudItemName";
import NotificationInvoice from "./NotificationInvoice";
import NotificationCalendarName from "./NotificationCalendarName";
import NotificationEventName from "./NotificationsEventName";

import { interventionActionStatut } from "../../Tickets/ticketUtils";

import {
  markAsNotReadNotification,
  readNotifications,
} from "../../../REDUX/actions/notifications";
import { showSideBar } from "../../../REDUX/actions/general";
import {
  getEventById,
  selectCalendars,
} from "../../../REDUX/actions/calendars";

const NotificationCard = ({ notification, index, show }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const {
    ticketRoute,
    facturesRoute,
    documentsRoute,
    clientsRoute,
    calendarRoute,
  } = useSelector((state) => state.organisationsReducer);
  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );
  const { selectedCalendars, sharedEvents, calendarRef } = useSelector(
    (state) => state.calendarsReducer
  );

  const handleClick = () => {
    switch (notification?.type) {
      case "Message":
      case "Intervention":
      case "Ticket_archive":
      case "Ticket":
      case "Action":
      case "Mention":
        if (notification?.ticket?.data?.id) {
          navigate(`${ticketRoute}/${notification.ticket.data.id}`, {
            state: { notification: true },
          });
        }

        break;
      case "Facture":
        navigate(facturesRoute);
        break;
      case "File":
        navigate(documentsRoute);
        break;
      case "Folder":
      case "Folder_update":
        navigate(
          `${organisationUrl}/dossier/${notification?.redirection || ""}`
        );
        break;
      case "newCollaborator":
        navigate(clientsRoute);
        break;
      case "newCalendar":
      case "sharedCalendar":
        navigate(calendarRoute);
        if (
          !selectedCalendars?.find(
            (el) => el?.id == notification?.calendar?.data?.id
          )
        ) {
          dispatch(selectCalendars(notification?.calendar?.data?.id));
        }
        break;
      case "newEvent":
      case "updateEvent":
      case "revisionCreated":
      case "eventToValidate":
      case "validateEvent":
      case "programEvent":
      case "acceptEvent":
      case "declineEvent":
        navigate(calendarRoute);
        if (
          !sharedEvents?.find((e) => e?.id == notification?.event?.data?.id) &&
          !selectedCalendars?.find(
            (el) =>
              el?.id ==
              notification?.event?.data?.attributes?.calendar?.data?.id
          )
        ) {
          dispatch(
            selectCalendars(
              notification?.event?.data?.attributes?.calendar?.data?.id
            )
          );
        }
        if (calendarRef) {
          calendarRef?.gotoDate(
            notification?.event?.data?.attributes?.startDate
          );
          dispatch(getEventById(notification?.event?.data?.id));
          dispatch(
            showSideBar({
              isNew: false,
              eventDetails: notification?.event?.data?.attributes,
              fromCalendar: true,
            })
          );
        }

      default:
        break;
    }
    dispatch(readNotifications(notification?.id));
    show(false);
  };

  return (
    <div>
      <LightDarkContainer
        key={index}
        colorLight="white"
        colorDark="dark-grey-bg"
        className={`px-3 py-4 pointer ${
          mode === "light" ? "lightHover2" : "darkHover2"
        }`}
      >
        <div
          className="w-100 gap-3 d-flex align-items-start"
          onClick={handleClick}
        >
          <NotificationIcon notification={notification} />
          <div className={"text-start bold"}>
            <LightDarkText
              className="custom-font-size w-100"
              color={mode === "light" ? "text-blue-grey" : "text-white"}
            >
              {notification?.type === "Message" ? (
                <>
                  Nouveau message de
                  <NotificationUserName notification={notification} />
                  sur le ticket
                  <NotificationTicketTitle notification={notification} /> -{" "}
                  <NotificationSocietyName notification={notification} />
                </>
              ) : notification.type === "Intervention" ? (
                <>
                  Nouvelle intervention
                  <NotificationInterventionTitle notification={notification} />
                  du ticket
                  <NotificationTicketTitle notification={notification} />
                  est créée et en attente d'acceptation par la société
                  <NotificationSocietyName notification={notification} />
                </>
              ) : notification.type === "Ticket_archive" ? (
                <>
                  Le ticket
                  <NotificationTicketTitle notification={notification} /> de la
                  société
                  <NotificationSocietyName notification={notification} />a été
                  {notification?.ticket?.data?.attributes?.archived
                    ? " archivé "
                    : " désarchivé "}
                </>
              ) : notification.type === "Ticket" ? (
                <>
                  Un nouveau ticket
                  <NotificationTicketTitle notification={notification} /> a été{" "}
                  créé par
                  <NotificationUserName notification={notification} /> -{" "}
                  <NotificationSocietyName notification={notification} />
                </>
              ) : notification.type === "Action" &&
                notification?.ticket?.data?.attributes?.statut === 2 ? (
                <>
                  Le ticket
                  <NotificationTicketTitle notification={notification} /> a été
                  résolu, il sera archivé automatiquement si aucune action n'est
                  effectuée dans les 48h.
                </>
              ) : notification?.type === "Action" ? (
                notification?.intervention?.data && (
                  <>
                    Intervention
                    <NotificationInterventionTitle
                      notification={notification}
                    />
                    du ticket
                    <NotificationTicketTitle notification={notification} /> est
                    {` ${interventionActionStatut(
                      notification?.action?.data?.attributes?.statut
                    )} `}
                    par
                    {notification?.action?.data?.attributes?.statut === 3 ? (
                      <>
                        {" "}
                        la société
                        <NotificationSocietyName notification={notification} />
                      </>
                    ) : (
                      <>
                        <NotificationUserName notification={notification} />
                      </>
                    )}
                  </>
                )
              ) : notification?.type === "Facture" ? (
                <NotificationInvoice notification={notification} />
              ) : notification?.type === "File" ||
                notification?.type === "Folder" ||
                notification?.type === "Folder_update" ? (
                <NotificationCloudItemName notification={notification} />
              ) : notification?.type === "newCollaborator" ? (
                <>
                  Un nouveau collaborateur à été créé
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />
                  {notification?.societe?.data ? (
                    <>
                      pour la société{" "}
                      <NotificationSocietyName notification={notification} />
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : notification?.type === "Mention" ? (
                <>
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />
                  vous a mentionné dans le ticket
                  <NotificationTicketTitle notification={notification} /> de la
                  société{" "}
                  <NotificationSocietyName notification={notification} />
                </>
              ) : notification?.type === "newCalendar" ? (
                <>
                  Un nouvel agenda{" "}
                  <NotificationCalendarName notification={notification} /> a été
                  partagé avec vous par{" "}
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />
                </>
              ) : notification?.type === "sharedCalendar" ? (
                <>
                  L'agenda{" "}
                  <NotificationCalendarName notification={notification} /> a été
                  partagé avec vous par{" "}
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />
                </>
              ) : notification?.type === "newEvent" ? (
                <>
                  Un événement a été ajouté par
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />{" "}
                  <NotificationCalendarName notification={notification} />
                </>
              ) : notification?.type === "updateEvent" ? (
                <>
                  L'événement
                  <NotificationEventName notification={notification} /> auquel
                  vous avez été ajouté
                  <NotificationCalendarName notification={notification} /> a été
                  modifié par
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />
                </>
              ) : notification?.type === "revisionCreated" ? (
                <>
                  Une révision pour l'événement
                  <NotificationEventName notification={notification} />
                  <NotificationCalendarName notification={notification} />
                  a été créée par
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />
                </>
              ) : notification?.type === "eventToValidate" ? (
                <>
                  L'événement
                  <NotificationEventName notification={notification} />
                  <NotificationCalendarName notification={notification} />
                  a été passé à "à valider" par
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />
                </>
              ) : notification?.type === "validateEvent" ? (
                <>
                  L'événement
                  <NotificationEventName notification={notification} />
                  <NotificationCalendarName notification={notification} />
                  a été validé par
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />
                </>
              ) : notification?.type === "programEvent" ? (
                <>
                  L'événement
                  <NotificationEventName notification={notification} />
                  <NotificationCalendarName notification={notification} />
                  a été passé à "programmé" par
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />
                </>
              ) : notification?.type === "acceptEvent" ? (
                <>
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />
                  a accepté l'invitation à l'événement
                  <NotificationEventName notification={notification} />
                </>
              ) : notification?.type === "declineEvent" ? (
                <>
                  <NotificationUserName
                    showCompanies={false}
                    notification={notification}
                  />
                  a refusé l'invitation à l'événement
                  <NotificationEventName notification={notification} />
                </>
              ) : null}
            </LightDarkText>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <LightDarkText
                className="custom-ticket-font-size "
                color={mode === "light" ? "text-light-text" : "text-white"}
              >
                {moment(notification?.createdAt).format("DD/MM/YYYY")} à{" "}
                {moment(notification?.createdAt).format("HH:mm")}
              </LightDarkText>
            </div>
          </div>
        </div>
        {notification.read && (
          <div className="d-flex position-relative justify-content-end mt-1">
            <div
              className={`
              ${mode === "light" ? "lightReadNotif" : "darkReadNotif"} 
              radius05 px-2 py-1 custom-ticket-font-size bold t-02 text-center w35 readNotif-btn`}
              style={{ position: "absolute", bottom: 0 }}
              onClick={() =>
                dispatch(
                  markAsNotReadNotification(
                    notification?.id,
                    notification?.ticket?.data?.id
                  )
                )
              }
            >
              Passer en non lu
            </div>
          </div>
        )}
      </LightDarkContainer>
      <div
        className="mx-3"
        style={{
          borderTop: `2px solid ${mode === "light" ? "#F4F6FD" : "#252C45"}`,
        }}
      ></div>
    </div>
  );
};

export default NotificationCard;

const initialState = {
  notifications: [],
  notificationsLloading: false,
  editNotificationsLoading: false,
};
const notificationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_NEW_NOTIFICATION":
      return {
        ...state,
        notifications: [payload, ...state.notifications],
        notificationsLloading: false,
      };
    case "NOTIFICATIONS_LOADING":
      return { ...state, notificationsLloading: true };
    case "FETCH_NEW_NOTIFICATIONS":
      return {
        ...state,
        notifications: payload ?? [],
        notificationsLloading: false,
      };
    case "READ_NOTIF":
      return {
        ...state,
        notifications: state.notifications,
      };
    case "NOTIF_EDIT_LOAD":
      return {
        ...state,
        editNotificationsLoading: payload,
      };
    case "UNREAD_NOTIF":
      return {
        ...state,
        notifications: state.notifications,
      };
    default:
      return state;
  }
};
export default notificationsReducer;

import {
  ADD_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  LOADING,
  SWITCH_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
} from "../ActionsType/formCategoryTypes";
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} from "../Graphql/Mutations/formCategories";
import { GET_CATEGORIES } from "../Graphql/Queries/FormCategory";
import store from "../store/store";
import client from "./Client";
import { handleInfo } from "./toastMessage";

export const createNewCategory = (category) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_CATEGORY,
      variables: {
        name: category.name,
        position: category.position,
      },
    });
    dispatch({
      type: ADD_CATEGORY_SUCCESS,
      payload: data.createFormCategory.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateCategory = (category) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_CATEGORY,
      variables: {
        id: category?.id,
        name: category?.name,
        position: category?.position,
      },
    });
    dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
      payload: data.updateFormCategory.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const GetCategories = () => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  try {
    const { data } = await client.query({
      query: GET_CATEGORIES,
    });
    dispatch({
      type: GET_CATEGORIES_SUCCESS,
      payload: data.formCategories.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const SwitchCategory = (payload) => async (dispatch) => {
  dispatch({
    type: SWITCH_CATEGORY,
    payload: payload,
  });
};

export const deleteCategory = (category) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_CATEGORY,
      variables: {
        id: category.id,
      },
    });
    dispatch({
      type: DELETE_CATEGORY_SUCCESS,
      payload: data.deleteFormCategory.data,
    });
    const categories = store.getState().formCategoriesReducer.categories;
    const updatedQuestions = categories.map((question, index) => ({
      id: question?.id,
      position: index + 1,
    }));
    for (const newItem of updatedQuestions) {
      await client.mutate({
        mutation: UPDATE_CATEGORY,
        variables: {
          id: newItem.id,
          position: Number(newItem.position),
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCategoryPosition = (id, position) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_CATEGORY,
      variables: {
        id: id,
        position: Number(position),
      },
    });

    dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
      payload: data.updateFormCategory.data,
    });
    dispatch(handleInfo("Mise à jour avec succès", 1, "dark-green"));
  } catch (error) {
    console.log(error);
  }
};

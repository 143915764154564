export const handleRoleFilters = (user) => {
  if (!user) {
    return "";
  }
  const role = user.attributes.user_org_permissions.data[0].attributes.role;

  if (role === 1) {
    return {
      attahcmentFilters: "{ ticket : { id : { notNull : true } } }",
    };
  } else {
    return {
      attahcmentFilters: `{ ticket: { visibleBy: { id: { in: ${user.id} } } } }`,
    };
  }
};

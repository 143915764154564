import { FORGOTPASSWORD, GET_ME, LOGIN } from "../Graphql/Mutations/user";
import API from "../../Api/Api";
import client from "./Client";
import { closeNotification, handleError, handleInfo } from "./toastMessage";
import { getInitialData } from "./sharedData";
import { getAllOrganisations } from "./organisations";
import { uploadFile } from "./file";
import Api from "../../Api/Api";
import store from "../store/store";
import { getConditionalUsers } from "./clients";
import { getUserById } from "./operateurs";
import { getAllCalendars } from "./calendars";
import { CREATE_AVATAR, UPLOAD } from "../Graphql/Mutations/files";
const color = "light-blue";

export const login = (email, password) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: LOGIN,
      variables: {
        email: email,
        password: password,
      },
    });
    dispatch({ type: "LOADING_USER" });

    localStorage.setItem("token", "Bearer " + data.login.jwt);
    localStorage.setItem("sitechToken", "Bearer " + data.login.jwt);
    API.defaults.headers.common["Authorization"] = "Bearer " + data.login.jwt;
    const me = await client.query({
      query: GET_ME,
      variables: {
        id: data.login.user.id,
      },
    });
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: me.data.usersPermissionsUser.data,
    });
    dispatch(initCurrentUser());
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", payload: error });
    // dispatch({ type: "LOGOUT" });
  }
};

export const logout = () => async (dispatch) => {
  // Clear LocalStroge
  localStorage.removeItem("token");
  // Reset Apollo Client cache
  client.resetStore();
  // getState().socketReducer.io?.disconnect();
  dispatch({ type: "LOGOUT" });
  dispatch(closeNotification());
};

export const initCurrentUser =
  (withLoading = true) =>
  async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      if (withLoading) {
        dispatch({ type: "LOADING_USER" });
      }

      API.defaults.headers.common["Authorization"] = token;

      try {
        const { data } = await API.get("/api/users/me");
        if (!data.connected) {
          await API.put(`/api/users/${data.id}`, { connected: true });
        }
        const me = await client.query({
          query: GET_ME,
          variables: {
            id: data.id,
          },
        });
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: me.data.usersPermissionsUser.data,
        });
        await dispatch(getAllOrganisations(withLoading));

        await dispatch(getInitialData(withLoading));
      } catch (error) {
        localStorage.removeItem("token");
        if (error.response?.status === 401) {
          dispatch({ type: "UNAUTHORIZED", payload: error });
        } else {
          console.log(error);
          dispatch(handleError("Erreur réseau", 1));
        }
      }
    } else {
      dispatch(logout());
    }
  };

export const forgotpassword = (email) => async () => {
  try {
    await client.mutate({
      mutation: FORGOTPASSWORD,
      variables: {
        email: email,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const resetpassword =
  (password, repassword, code, initPassword = false) =>
  async (dispatch) => {
    dispatch({ type: "LOADING_RESET" });
    try {
      if (initPassword) {
        await API.post(`/api/users-permissions/initialize-password`, {
          code: code,
          password: password,
        });
      } else {
        await API.post(`/api/auth/reset-password`, {
          code: code,
          password: password,
          passwordConfirmation: repassword,
        });
      }

      dispatch({ type: "RESET_SUCCESS" });
    } catch (error) {
      dispatch({ type: "RESET_ERROR" });
      console.log(error);
    }
  };

export const handleNewUser = (userInformation) => async (dispatch) => {
  try {
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    let avatar = null;

    if (userInformation.avatar) {
      const avatarCreated = await client.mutate({
        mutation: UPLOAD,
        variables: {
          file: userInformation.avatar,
        },
      });
      const fileId = avatarCreated.data.upload.data.id;

      avatar = await client.mutate({
        mutation: CREATE_AVATAR,
        variables: {
          image: fileId,
        },
      });
    }

    await Api.post(
      `/api/users-permissions/handleNewUser?organisation=${orgId}`,

      {
        ...userInformation,
        avatar: avatar?.data?.createAvatar?.data?.id || null,
      }
    );

    // await dispatch(uploadFile(userInformation.avatar, data.user.id));
    await dispatch(
      getConditionalUsers(userInformation.isOp ? false : true, false)
    );
    dispatch(
      handleInfo(
        userInformation.isOp ? "Opérateur ajouté" : "Client ajouté",
        1,
        color
      )
    );
    await dispatch(getAllCalendars());
  } catch (error) {
    dispatch(
      handleError(
        userInformation.isOp
          ? "Erreur lors de l'ajour de l'opérateur"
          : "Erreur lors de l'ajout du client",
        1
      )
    );

    console.log(error);
  }
};

export const handleUpdateuser = (userInformation) => async (dispatch) => {
  try {
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    const user = store.getState().userReducer.user;

    await Api.post(
      `/api/users-permissions/handleUpdateuser?organisation=${orgId}`,

      userInformation
    );
    if (userInformation.avatar) {
      await dispatch(uploadFile(userInformation.avatar, userInformation.id));
    }

    if (userInformation.id === user.id) {
      await dispatch(initCurrentUser(false));
    } else {
      await dispatch(
        getConditionalUsers(userInformation.isOp ? false : true, false)
      );
      await dispatch(getUserById(userInformation.id));
    }

    dispatch(handleInfo("Profil mis à jour", 1, color));
    await dispatch(getAllCalendars());
  } catch (error) {
    dispatch(handleError("Erreur mise à jour du profil", 1));
    console.log(error);
  }
};

export const handleInviteUser = (userInformation) => async (dispatch) => {
  try {
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    await Api.post(
      `/api/users-permissions/handleInviteuser?organisation=${orgId}`,

      userInformation
    );

    await dispatch(getUserById(userInformation.id));

    await dispatch(
      getConditionalUsers(userInformation.isOp ? false : true, false)
    );
    dispatch(handleInfo("Invitation envoyé", 1, color));
    await dispatch(getAllCalendars());
  } catch (error) {
    dispatch(handleError("Erreur lors de l'envoi de l'invitation", 1));
    console.log(error);
  }
};
